import { Fragment } from 'react';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import { store } from "../../../store";
import BaseDecisionView from "./BaseDecisionView";
import DecisionBody from "./DecisionBody";
import ErrorBox from "../../../components/ErrorBox";
import DecisionLink from "./DecisionLink"
import gameService from "../../../services/GameService";
import intraturnService from "../../../services/IntraturnService";

import NewScenarioButton from "./components/NewScenarioButton";
import SubmitDecisionModal from "./components/SubmitDecisionModal";
import SubmitWhatIfModal from "./components/SubmitWhatIfModal";

/**
 * The DecisionsView class.
 */
class DecisionsView extends BaseDecisionView
{
    /**
     * The load data method.
     */
    loadData()
    {
        // Call mom...
        super.loadData();
     
        // Get out if not ready to load the investment purchases...
        if (!this.props.game)
            return;

        // Load the active intraturns
        this.loadActiveIntraturns();
    }


    /**
     * This method loads the active intraturns.
     */
    loadActiveIntraturns()
    {
       // Only load once...
        if (this.loading)
            return;

        // Mark us as loading...
        this.loading = true;
        this.loaded  = false;

        // Load the active intraturns
        gameService.loadActiveIntraturns(this.props.match.params.team_id, this.onIntraTurnsLoaded);
    }

    /**
     * This method checks the user's team role 
     * 
     */
    checkRoleForSubmission()
    {
        // Check force roles flag...
        if (!this.props.game.force_roles)
        	return true;
      
        // Get user's role
        let userRole = store.getState().authentication.role;

        // Checks if user is a student or not
        if (userRole !== 'Student')
        	return true;
      
          // Checks if student can submit based on their team role
          let teamRoles = this.props.gameDetails.user_team_roles;

          if (teamRoles && teamRoles.roles)
          {
               // Splits multiple team roles if student
              teamRoles = teamRoles.roles.split(',');

              // Checks if user has the correct team role
              for (let role of teamRoles)
              {
                  // Get out...
                  if ((role === 'ceo') || (role === 'cfo/treasurer'))
                      return true;
              }
          }
      
          // Get out
          return false;
    }


    /**
     * This method determines if the intraturns have been loaded.
     *
     * @param {*} intraturns The intraturns.
     */
    onIntraTurnsLoaded = (intraturns) =>
    {
        // Get the active intraturn
        let activeIntraturn = intraturns.find((intraturn) => intraturn.status);

        // Reset the flags
        this.loading = false;
        this.loaded  = true;

        // Set the state
        this.setState({ intraturns: intraturns, activeIntraturn: activeIntraturn });
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Call mom...
        let result = super.isComponentReady();

        // Load the active intraturns if necessary...
        if (this.props.game && !this.loaded)
        {
            // Load the active intraturns
            this.loadActiveIntraturns();

            // Not ready...
            return false;
        }

        // Get out...
        return result;
    }


    /**
     * The new scenario handler.
     *
     * @param intraturn The intraturn.
     */
    onNewScenario = (intraturn) =>
    {
        // Push the new intraturn
        let intraturns = [ ...this.state.intraturns ];
        intraturns.push(intraturn);

        // Update the state
        this.setState({ intraturns: intraturns, activeIntraturn: intraturn });
    }

    /**
     * This method handles changes to the active intraturn.
     *
     * @param {*} event The event.
     */
    onChangeActiveIntraturn = (event) =>
    {
        // Get the active intraturn
        let activeIntraturn = this.state.intraturns.find((intraturn) => intraturn.intraturn_id === parseInt(event.target.value));
        if (!activeIntraturn)
            return;

        // Set the active intraturn
        intraturnService.setActiveIntraturn(activeIntraturn, this.onSetActiveIntraturnComplete);
    }


    /**
     * This method handles the active intraturn complete event.
     *
     * @param {*} intraturn the intraturn.
     */
    onSetActiveIntraturnComplete = (intraturn) =>
    {
        // Update the state
        this.setState({ activeIntraturn: intraturn });
    }


    /**
     * This method handles the delete scenario event.
     */
    onClickDeleteScenario = () =>
    {
        // Can't delete nothing...
        if (!this.state.activeIntraturn)
            return;

        // Make sure they really want to delete it
        if (!window.confirm("Are you sure you want to delete the scenario?"))
            return;

        // Delete the scenario
        intraturnService.deleteScenario(this.state.activeIntraturn.intraturn_id, this.props.team.team_id, this.onIntraTurnsLoaded);    
    }


    /**
     * This method handles decision submissions.
     *
     * @param {*} intraturn The intraturn.
     */
    onSubmitDecision = (intraturn) =>
    {
        // Hide the modal
        this.hideSubmitDecisionModal();

        // No permission
        if (!this.checkRoleForSubmission())
        {
            alert("You must be a CEO or CFO/Treasurer to submit decisions.");
            return;
        }

         // Submit the intraturn
        intraturnService.submitTurn(intraturn.intraturn_id, this.props.game.game_id, this.props.team.team_id, this.onDecisionSubmitComplete);
    }


    /**
     * The decision complete event handler.
     */
    onDecisionSubmitComplete = () =>
    {
        alert("The decision has been successfully submitted.");
    }


    /**
     * This method hides the submit decision modal.
     */
    hideSubmitDecisionModal = () =>
    {
        this.setState({ submitDecisionVisible: false });
    }

    
    /**
     * This method handles what-if submissions.
     *
     * @param {*} intraturn The intraturn.
     */
    onSubmitWhatIf = () =>
    {
        if (!this.state.activeIntraturn)
        {
            alert("You must select a turn in order to submit a forecast.");
            return;
        }

        // Hide the modal
        this.hideWhatIfModal();

        // Submit the what-if
        intraturnService.submitWhatIf(this.state.activeIntraturn.intraturn_id, this.props.match.params.game_id, this.props.match.params.team_id,  this.onWhatIfSubmitComplete);
    }


    /**
     * The what-if complete event handler.
     */
    onWhatIfSubmitComplete = () =>
    {
        // Let the user know...
        alert("The scenario has been successfully submitted.");
    }


    /**
     * This method hides the submit what-if modal.
     */
    hideWhatIfModal = () =>
    {
        this.setState({ submitWhatIfVisible: false });
    }


    /**
     * This method renders the view.
     */
    renderView()
    {
        // Get the game
        let team = this.props.team
        if (team == null)
        {
            return (
                <ErrorBox>The team was not found.</ErrorBox>
            );
        }

        // Get the active intraturn ID.
        let activeIntraturnId = this.state.activeIntraturn ? this.state.activeIntraturn.intraturn_id : 0;

        return (
            <Fragment>
                <SubmitDecisionModal intraturns= { this.state.intraturns }
                                     visible={ this.state.submitDecisionVisible } 
                                     onSubmitDecision = { this.onSubmitDecision }
                                     onHide={ this.hideSubmitDecisionModal } />

                <SubmitWhatIfModal visible={ this.state.submitWhatIfVisible } onSubmitWhatIf={ this.onSubmitWhatIf } onHide={ this.hideWhatIfModal } />                                     

                <DecisionBody name="Decisions" game={ this.props.game } hideSaveAndHome="true" intraturnId={ activeIntraturnId } >
                    <Row className="padding-10">
                        <Col xs={2} sm={2} md={1} lg={1}><label class="form-label">Scenario:</label></Col>
                        <Col xs={4} sm={4} md={3} lg={2}>
                            <select name={ this.props.name } className="form-select" onChange={ this.onChangeActiveIntraturn } { ...this.newProps }>
                            {
                                this.state.intraturns.map((intraturn, key) =>
                                {
                                    return <option key={ key } value={ intraturn.intraturn_id } selected={ intraturn.intraturn_id === activeIntraturnId } >{ intraturn.name }</option>;
                                })
                            }
                            </select>
                        </Col>
                        <Col xs={6} sm={6} md={8} lg={9}>
                            <NewScenarioButton activeIntraturnId={ activeIntraturnId } onNewScenario={ this.onNewScenario } disabled={ this.props.team.frozen } />&nbsp;&nbsp;&nbsp;
                            <Button name="delete" variant="primary" disabled={ this.props.team.frozen || (this.state.activeIntraturn && (this.state.activeIntraturn.name === "Base")) } onClick={ this.onClickDeleteScenario } >Delete Scenario</Button>
                        </Col>
                    </Row>
                    <Row className="g-0 decision-row">
                        <DecisionLink type="investment-purchases" text="Investment Purchases" intraturn={ this.state.activeIntraturn } gameType={ this.props.game.type } />
                        <DecisionLink type="investment-sales" text="Investment Sales" intraturn={ this.state.activeIntraturn } gameType={ this.props.game.type } />
                        { this.props.game.type === "Full" ? 
                            <DecisionLink type="loan-administration" text="Loan Administration" intraturn={ this.state.activeIntraturn } gameType={ this.props.game.type } />
                            : ""
                        }
                        <DecisionLink type="loan-origination" text="Loan Origination" intraturn={ this.state.activeIntraturn } gameType={ this.props.game.type } />
                        <DecisionLink type="loan-sales" text="Loan Sales" intraturn={ this.state.activeIntraturn } gameType={ this.props.game.type } />
                        <DecisionLink type="non-maturity-deposits" text="Non-Maturity Deposits" intraturn={ this.state.activeIntraturn } gameType={ this.props.game.type } />
                        <DecisionLink type="cds" text="CDs" intraturn={ this.state.activeIntraturn } gameType={ this.props.game.type } />
                        { this.props.game.type === "Full" ? 
                            <Fragment>
                                <DecisionLink type="borrowings" text="Borrowings" intraturn={ this.state.activeIntraturn } gameType={ this.props.game.type } />
                                <DecisionLink type="long-term-debt" text="Long-Term Debt" intraturn={ this.state.activeIntraturn } gameType={ this.props.game.type } />
                            </Fragment> : ""
                        }
                        <DecisionLink type="equity" text={ this.props.game.type === "Lite" ? "Equity & Borrowings" : "Equity" } intraturn={ this.state.activeIntraturn } gameType={ this.props.game.type } />
                        <DecisionLink type="business-development" subtype="business-development" text="Marketing & General Administration" intraturn={ this.state.activeIntraturn } gameType={ this.props.game.type } />
                        <DecisionLink type="general-administration" subtype="dividends" text="Dividends & Housekeepinng" intraturn={ this.state.activeIntraturn } gameType={ this.props.game.type } />
                    </Row>
                    <Row className="padding-10">
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Button name="what-if" onClick={ () => this.setState({ submitWhatIfVisible: true }) } disabled={ this.props.team.disable_whatifs || this.props.team.frozen || (activeIntraturnId == 0) ? true : false }>Forecast</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button name="submit-decisions" onClick={ () => this.setState({ submitDecisionVisible: true }) } disabled={ this.props.team.frozen || !this.checkRoleForSubmission() }>Submit Decisions</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button name="view-reports" onClick={ () => this.props.history.push("/game/" + this.props.match.params.game_id + "/decisions/" + this.props.match.params.team_id + "/reports") }>View Reports</Button>
                        </Col>
                    </Row>
                </DecisionBody>
            </Fragment>
        );
    }
}

// Export the decisions view...
export default withRouter(connect(BaseDecisionView.mapStateToProps)(DecisionsView));
