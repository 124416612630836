import { Fragment } from 'react';
import { withRouter } from "react-router";

import Utils from "../app/utils/Utils"


/**
 * The watch function.
 * 
 * @param {*} props
 * @returns The watch object.
 */
function Watch(props) 
{
    // Set the route parameters
    Utils.setRouteParams(props.match.params);

    // Get out...
    return <Fragment />;
}

export default withRouter(Watch);