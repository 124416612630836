import React from 'react';

import InputControl from "./InputControl";

/**
 * The CheckBoxControl component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
const CheckBoxControl = (props) =>
{
    // Get out...
    return (
        <InputControl type="checkbox" className="form-check-input" { ...props } checkbox={ true } />
    );
}

// Get out...
export default CheckBoxControl;



