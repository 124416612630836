import { createSlice } from '@reduxjs/toolkit';

// Create the initial state
const initialState = 
{
    intraturnMap: {},
};

// Create the slice
export const intraturnsSlice = createSlice(
{
    name: 'intraturns',
    initialState,

    // The `reducers` field lets us define reducers and generate associated actions
    reducers: 
    {
        /**
         * This method updates the state for the set games operation.
         * 
         * @param {*} state The state.
         * @param {*} action The action.
         */
        setIntraturn: (state, action) => 
        {
            // Add it to the map
            state.intraturnMap[action.payload.intraturn_id] = action.payload;
        }
    }
});

// Export the intraturns actions
export const { setIntraturn } = intraturnsSlice.actions;

// Get out...
export default intraturnsSlice.reducer;
