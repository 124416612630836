import { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AgChartsReact } from 'ag-charts-react';
import { buildData, buildSeries, createGraphDetails } from "./TeamKeyIndicatorsGraphGroup";


/**
 * The team key indicators graph component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
const TeamYTDKeyIndicatorsGraphGroup = (props) =>
{
    // Get out if nothing to do...
    if (!props.kpi)
        return <Fragment />;

    // Create the quarter data & series
    let quarterData   = buildData(props.kpi, "Bank");
    let quarterSeries = buildSeries(props.kpi, "Bank");

    // Create the community data & series
    let communityData   = buildData(props.kpi, "Community", "communityQuarters");
    let communitySeries = buildSeries(props.kpi, "Community");

    // Create the graph details
    let graphDetails = createGraphDetails(quarterData, quarterSeries);
    let communityGraphDetails = createGraphDetails(communityData, communitySeries);

    // Update the details
    for (let counter = 0; counter < graphDetails.length; counter++)
    {
        // Enable the legend
        graphDetails[counter].legend.enabled = true;

        // Add the data & series to the details...
        graphDetails[counter].data = [ ...graphDetails[counter].data, ...communityGraphDetails[counter].data ];
        graphDetails[counter].series.push(communityGraphDetails[counter].series[0]);
    }

    // Create the options
    let options = [];

    // Generate the options
    for (let counter = 0; counter < graphDetails.length; counter++)
    {
        // Save the name
        let name = props.kpi.kpis[counter].name;

        options.push(
        {
            title: 
            {
                text: name,
            },

            ...graphDetails[counter]
        });
    }

    // Get out...
    return (
        <Container>
            <Row className="key-indicator-graph-group">
                <Col xs={12} sm={12} md={12} lg={12} className="key-indicator">
                    <b>{ props.name }</b>
                </Col>
            </Row>

            <Row className="kpi-indicator-graph-row">
            {
                options.map((option, key) =>
                {
                    return (
                        <Col xs={12} sm={12} md={ 12 / props.maxGraphCols } lg={12 / props.maxGraphCols } className="key-indicator-graph">
                            <AgChartsReact key={ "chart_" + key } options={ option } style={ { width: "100%", height: "500px" } }/>
                        </Col>
                    )
                })
            }
            </Row>
        </Container>
    );
}

// Get out...
export default TeamYTDKeyIndicatorsGraphGroup;


