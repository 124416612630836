import { Fragment } from 'react';
import { withRouter, Prompt } from "react-router";
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Utils from "../../../utils/Utils";


import BaseDecisionGridView from "./BaseDecisionGridView";
import COLUMNS from "./grid/LongTermDebtColumns";
import DecisionGrid from "./components/DecisionGrid";
import TurnSelect from "./components/TurnSelect";
import ScenarioName from "./components/ScenarioName";

import DecisionBody from "./DecisionBody";
import gameService from "../../../services/GameService";
import intraturnService from "../../../services/IntraturnService";


/**
 * The LongTermDebtView class.
 */
class LongTermDebtView extends BaseDecisionGridView
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the loaded flag
        this.loaded = false;

        // Initialize the state
        this.state =
        {
            rows: [],
            dirtyRows: {},
            sortColumns: [],
            selectedTurn: -1,
        };

        // Initialize the columns
        this.columns = COLUMNS;
    }


    /**
     * This method loads the long-term debt.
     *
     * @param turn The turn.
     */
    loadLongTermDebts(turn)
    {
        // Get out if the state is loaded
        if (!this.props.game)
            return;

        // Only load once...
        if (this.loadingLongTermDebts)
            return;

        // Get out if nothing to do...
        if (this.state.selectedTurn === turn)
            return;

        // Warn the user if there are changes...
        if (this.isDirty())
        {
            if (!window.confirm("You have unsaved changes.  Do you wish to proceed?"))
                return;
        }

        // Mark us as loading...
        this.loadingLongTermDebts = true;

        // Load the long-term debt
        gameService.loadLongTermDebts(this.props.match.params.team_id, turn, this.onLongTermDebtLoaded);
    }


    /**
     * The long-term debt loaded event handler.
     *
     * @param {*} longTermDebts The long-term debts.
     * @param {*} lastUpdated The last updated time.
     * @param {*} turn The turn.
     */
    onLongTermDebtLoaded = (longTermDebts, lastUpdated, turn) =>
    {
        // Make sure we have something...
        if (!longTermDebts)
            longTermDebts = [];

        // Initialize the state
        let state =
        { 
            rows: longTermDebts, 
            selectedTurn: turn,
            lastUpdated: lastUpdated,
            summary: this.calculateSummary(longTermDebts)
        }

        // Set the intraturn Id if appropriate
        if (longTermDebts.length > 0)
            state.intraturnId = longTermDebts[0].intraturn_id;

        // Set the state
        this.setState(state);

        // Clear the dirty rows
        this.clearDirtyRows();

        // Reset the loading long-term debts flag
        this.loadingLongTermDebts = false;

        // Set the loaded flag
        this.loaded = true;
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Call mom...
        let result = super.isComponentReady();

        // Load the investment purchases if necessary...
        if (this.props.game && !this.loaded)
        {
            // Load the investment purchases
            this.loadLongTermDebts(this.props.game.turns_completed + 1);

            // Not ready...
            return false;
        }

        // Get out...
        return result;
    }

    /**
     * This method updates the state when the cell value has been modified.
     *
     * @param {*} params The params.
     */
     onCellValueChanged = (params) =>
    {
        // Get the dirty rows map...
        let dirtyRows = this.state.dirtyRows;

        // Mark it dirty
        dirtyRows[params.data.term_debt_id] = true;

        // Update Summary
        let summary = this.calculateSummary(this.state.rows);

        // Save the state
        this.setState(
        {
            dirtyRows: dirtyRows,
            summary: summary
        });

        // Displays should most recent up to date values.
        params.api.refreshCells();
    }


    /**
     * This method handles view saves.
     */
    onSave = () =>
    {
        // Get out if this is not actually dirty (shouldn't happen)
        if (!this.isDirty())
            return;

        // Update the dirty rows
        this.updateDirtyRows(false);
    }


    /**
     * This method updates the dirty rows.
     *
     * @param {*} force The force flag.
     */
    updateDirtyRows(force)
    {
        // Initialize the updated rows
        let updatedRows = [];

        // Check each row...
        for (let row of this.state.rows)
        {
            // See if it is dirty...
            if (!this.state.dirtyRows[row.term_debt_id])
                continue;
                
            // Convert the cap
            row.cap = Utils.parseCurrency(row.cap)

            // Create the updated row
            let updatedRow = { ...row };

            // Push the updated row
            updatedRows.push(updatedRow);
        }

        // Update the long-term debt
        intraturnService.updateLongTermDebts(this.state.intraturnId, updatedRows, this.state.lastUpdated, force, this.onSaveComplete);

        // Get out...
        return true;
    }


    /**
     * The save complete event handler.
     *
     * @param {*} modifiedBy The modified by user.
     * @param {*} lastUpdated The last updated time.
     */
    onSaveComplete = ({ modifiedBy, lastUpdated }) =>
    {
        // See if it was modified since we loaded the data...
        if (modifiedBy)
        {
            // See if the user wants to force the matter...
            if (!window.confirm("Long-Term Debt records have modified by " + modifiedBy + " at " + new Date(lastUpdated).toLocaleTimeString() + ".\n\nWould you like save your changes anyway?"))
                return;

            // Force the update
            this.updateDirtyRows(true);

            // Get out...
            return;
        }

        // Update the state
        this.setState({ lastUpdated: lastUpdated });

        // Clear the dirty flag
        this.clearDirtyRows();

        // let the user know
        alert("Saved.");
    }


    /**
     * This method clears the dirty rows.
     */
    clearDirtyRows()
    {
        // Reset the dirty IDs
        this.setState(
        {
            dirtyRows: {}
        });
    }


    /**
     * This method handles turn changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onTurnChange = (name, value) =>
    {
        // Load the long-term debt
        this.loadLongTermDebts(parseInt(value));
    }


    /**
     * This method calculates the summary.
     *
     * @param {*} longTermDebts The long-term debts.
     */
    calculateSummary(longTermDebts)
    {
        // Initialize the summary
        let summary =
        {
            fixed_or_variable: "Total",
            maturities: 0,
            cap: 0
        }

        // Calculate the summary
        for (let longTermDebt of longTermDebts)
        {
            summary.maturities += Utils.parseCurrency(longTermDebt.maturities);
            summary.cap        += Utils.parseCurrency(longTermDebt.cap);
        }

        // Get out...
        return summary;
    }




    /**
     * This method renders the view.
     */
    renderGridView()
    {
        return (
            <Fragment>
                <Prompt when={ this.isDirty() } message="There are unsaved changes on this screen.  Are you sure you want to leave?" />

                <DecisionBody name="Long-Term Debt" noteType="Tres" game={ this.props.game } intraturnId={ this.state.intraturnId } onSave={ this.onSave } dirty={ this.isDirty() }>

                    <Row>
                        <Col xs={6} sm={6} md={4} lg={4}>
                            <TurnSelect name="turns" onChange={ this.onTurnChange } value= { this.state.selectedTurn } />
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4}>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="right col-padding-right bold col-middle-align">
                            <ScenarioName intraturnId={ this.state.intraturnId } />
                        </Col>
                    </Row>

                    <div class="grid-body">
                        <DecisionGrid 
                            columns={ this.columns } 
                            turn={ this.state.selectedTurn }
                            sortColumns={ this.state.sortColumns }
                            rowData={ this.state.rows } 
                            onCellValueChanged={ this.onCellValueChanged }
                            onSortColumnsChange={ this.onSortColumnsChange }
                            pinnedBottomRowData={[ this.state.summary ]}
                        />
                    </div>

                </DecisionBody>
            </Fragment>
        );
    }
}

// Export the decisions view...
export default withRouter(connect(BaseDecisionGridView.mapStateToProps)(LongTermDebtView));