import React from 'react';
import IntegerInput from "../IntegerInput";

import SelectControl from '../form/SelectControl';
import CurrencyControl from "../../controls/form/CurrencyControl";
import PercentageControl from "../../controls/form/PercentageControl";


/**
 * The is editable cell method.
 *
 * @param {*} params The parameters.
 * @returns The cell class.
 */
export function getEditableCellClass(params)
{
    return !params.node.rowPinned ? "editable-cell" : "";
} 


/**
 * The currency cell editor class.
 */
export class CurrencyCellEditor extends React.Component 
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props) 
    {
        // Call mom...
        super(props);

        // Create the input reference
        this.inputRef = React.createRef();

        // Initialize the state
        this.state = 
        {
            value: props.value
        };
    }

    /**
     * The component did mount event handler.
     */
    componentDidMount() 
    {
        this.inputRef.current.focus();
    }

    /**
     * This method gets the value. 
     */
    getValue() 
    {
        return this.state.value;
    }

    /**
     * This method determines if editing should be canceled before it even starts.
     *
     * @returns true if it should be canceled, else false.
     */
    isCancelBeforeStart() 
    {
        return false;
    }

    /**
     * This method determines if editing should be canceled after it ends.
     *
     * @returns true if editing should be canceled, else false.
     */
    isCancelAfterEnd() 
    {
        return false;
    }


    /**
     * The blur event handler.
     */
    onBlur = () =>
    {
        this.props.api.stopEditing();
    }


    /**
     * The render method.
     *
     * @returns The rendered component.
     */
    render() 
    {
        // Grab the maximum value...
        let maxValue = this.props.column.colDef.maxValue ? this.props.column.colDef.maxValue : null;

        return (
            <CurrencyControl 
                ref={ this.inputRef }
                name="value"
                value={this.state.value}
                onChange={ (name, value) => this.setState({value: value }) }
                style={{width: "100%"}}
                onBlur={ this.onBlur }
                maxValue={ maxValue }
            />
        );
    }
}


/**
 * The percentage cell editor.
 */
export class PercentageCellEditor extends React.Component 
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props) 
    {
        // Call mom...
        super(props);

        // Create the input reference
        this.inputRef = React.createRef();

        // Initialize the state
        this.state = 
        {
            value: props.value
        };
    }

    /**
     * The component did mount event handler.
     */
    componentDidMount() 
    {
        this.inputRef.current.focus();
    }

    /**
     * This method gets the value. 
     */
    getValue() 
    {
        return this.state.value;
    }

    /**
     * This method determines if editing should be canceled before it even starts.
     *
     * @returns true if it should be canceled, else false.
     */
    isCancelBeforeStart() 
    {
        return false;
    }

    /**
     * This method determines if editing should be canceled after it ends.
     *
     * @returns true if editing should be canceled, else false.
     */
    isCancelAfterEnd() 
    {
        return false;
    }
    

    /**
     * The blur event handler.
     */
    onBlur = () =>
    {
        this.props.api.stopEditing();
    }


    /**
     * The render method.
     *
     * @returns The rendered component.
     */
    render() 
    {
        return (
            <PercentageControl 
                ref={ this.inputRef }
                name="value"
                value={this.state.value}
                onChange={ (name, value) => this.setState({value: value }) }
                style={{width: "100%"}}
                onBlur={ this.onBlur }
            />
        );
    }
}


/**
 * The select editor control class.
 */
export class SelectEditorControl extends React.Component
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            value: props.row[props.column.key]
        }
    }
    
    /**
    * This method handles value changes.
    */
    onChangeValue()
    {
        // Create the new row
        let newRow = { ...this.props.row };

        // Remove the %
        let value = this.state.value;

        // Set the new value
        newRow[this.props.column.key] = value;
        
        // Change the row
        this.props.onRowChange(newRow, true);
    } 

    /**
     * The component will unmount method.
     */
    componentWillUnmount()
    {
        // Change the value
        this.onChangeValue();
    }


    /**
     * The blur event handler.
     */
    onBlur()
    {
        // Change the value
        this.onChangeValue();
    }


    /**
     * The render method.
     *
     * @returns The rendered component.
     */
    render()
    {
        return <SelectControl type="checkbox" className = "grid-editor" value = { this.state.value } items = { this.props.items } onChange = { (name, value) => { this.setState({ value: value }) } } onBlur = { () => this.onBlur() } notAForm={ true } />;
    }
}

/**
 * The select editor function.
 *
 * @param {*} items The items.
 * @returns The select editor control.
 */
export const SelectEditor = (items) => (props) =>
{
    return <SelectEditorControl items={ items } { ...props } />;
}

/**
 * The integer editor.
 *
 * @param {*} props The properties.
 */
 export class IntegerEditor extends React.Component
 {
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Create the input reference
        this.inputRef = React.createRef();

        this.state =
        {
            value: props.value
        }
    }

    /**
     * The component did mount event handler.
     */
    componentDidMount() 
    {
        this.inputRef.current.focus();
    }

    /**
     * This method gets the value. 
     */
    getValue() 
    {
        return this.state.value;
    }

    /**
     * This method determines if editing should be canceled before it even starts.
     *
     * @returns true if it should be canceled, else false.
     */
    isCancelBeforeStart() 
    {
        return false;
    }

    /**
     * This method determines if editing should be canceled after it ends.
     *
     * @returns true if editing should be canceled, else false.
     */
    isCancelAfterEnd() 
    {
        return false;
    }
    

    /**
     * The blur event handler.
     */
    onBlur = () =>
    {
        this.props.api.stopEditing();
    }


    /**
     * The render method.
     *
     * @returns The rendered component.
     */
    render() 
    {
        return (
            <IntegerInput 
                ref={ this.inputRef }
                name="grid-editor"
                value={this.state.value}
                onChange={ (event) => this.setState({value: event.target.value }) }
                style={{width: "100%"}}
                onBlur={ this.onBlur }
            />
        );
    }
 }