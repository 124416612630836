import { Fragment } from 'react';
import { withRouter, Prompt } from "react-router";
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DownloadTurnReportsLink from "./components/DownloadTurnReportsLink";
import DownloadTeamReportsLink from "./components/DownloadTeamReportsLink";
import BaseDecisionGridView from "./BaseDecisionGridView";
import COLUMNS from "./grid/ReportColumns";
import TurnSelect from "./components/TurnSelect";
import DecisionGrid from "./components/DecisionGrid";
import ScenarioName from "./components/ScenarioName";
import ErrorBox from "../../../components/ErrorBox";

import DecisionBody from "./DecisionBody";
import gameService from "../../../services/GameService";
import SelectControl from '../../../controls/form/SelectControl';

/**
 * The report types.
 */
let REPORT_TYPES =
{
    "WhatIf": 1,
    "Summary": 2,
    "Detail": 3,
    "Peer": 4,
    "Survey": 5,
    "Dashboard": 6,
    "Analysis": 7,
    "Regulatory": 8, 
    "Instructor": 9,
    "Startup": 10
};


/**
 * The report types.
 */
let LITE_REPORT_TYPES =
{
    "WhatIf": 1,
    "Summary": 2,
    "Detail": 3,
    "Peer": 4,
    "Survey": 5,
    "Dashboard": 6,
    "Analysis": 7,
    "Regulatory": 8, 
    "Instructor": 9,
    "Startup": 10
};



/**
 * The report name mappings.
 */
let REPORT_NAME_MAPPING =
{
    "WhatIf": "Forecasts",
    "Peer": "Community Peer",
    "Survey": "Community Market",
    "Regulatory": "Interest Rate Risk" 
};


/**
 * This method gets the report type.
 *
 * @param {*} reportType The report type.
 * @returns The ordinal value.
 */
function getOrdinal(reportType)
{
    let ordinal = REPORT_TYPES[reportType];
    if (!ordinal)
        return 0;

    return ordinal;
}


/**
 * The translate name method.
 *
 * @param {*} reportType The report type.
 * @returns The translated name.
 */
function translateName(reportType)
{
    if (REPORT_NAME_MAPPING[reportType])
        return REPORT_NAME_MAPPING[reportType];

    return reportType;
}


/**
 * The ReportsView class.
 */
class ReportsView extends BaseDecisionGridView
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the loaded flag
        this.loaded = false;

        // Initialize the state
        this.state =
        {
            rows: [],
            sortColumns: [],
            selectedReportType: "WhatIf",
            selectedTurn: -1,
            selectedRows: new Set(),
            deletedRows: [],
            scenarioName: 'Base'
        };

        // Initialize the columns
        this.columns = COLUMNS;
    }


    /**
    * This method maps the state to the properties.
    * 
    * @param {*} state The state.
    * @param {*} ownProps The own properties.
    * @returns The mapping.
    */
    static mapStateToProps(state, ownProps)
    {
        let result = 
        {
            games: state.games.games,
            authentication: state.authentication
        };
    
        return result;
    }



    /**
     * This method loads the reports.
     *
     * @param turn The turn.
     * @param reportType The report type.
     */
    loadReports(turn, reportType)
    {
        // Get out if the state is loaded
        if (!this.props.game)
            return;

        // Only load once...
        if (this.loadingReports)
            return;

        // Get out if nothing to do...
        if ((this.state.selectedTurn === turn) && (this.state.selectedReportType === reportType))
            return;

        // Warn the user if there are changes...
        if (this.isDirty())
        {
            if (!window.confirm("You have unsaved changes.  Do you wish to proceed?"))
                return;
        }

        // Make sure we're using the key
        for (let key in REPORT_NAME_MAPPING)
        {
            if (REPORT_NAME_MAPPING[key] !== reportType)
                continue;

            reportType = key;
            break; 
        }

        // Mark us as loading...
        this.loadingReports = true;

        // Load the reports
        gameService.loadReports(this.props.match.params.team_id, turn, reportType, this.onReportsLoaded);
    }


    /**
     * The reports loaded event handler.
     *
     * @param {*} reports The reports.
     * @param {*} turn The turn.
     * @param {*} reportType The report type.
     */
    onReportsLoaded = (reports, turn, reportType, intraturn_id) =>
    {
        // Make sure we have something...
        if (!reports)
            reports = [];

        // Initialize the state
        let state =
        { 
            rows: reports, 
            selectedTurn: turn, 
            selectedReportType: REPORT_NAME_MAPPING[reportType] ? REPORT_NAME_MAPPING[reportType] : reportType
        }

        // Set the intraturn Id if appropriate
        state.intraturnId = intraturn_id;

        // Set the state
        this.setState(state);

        // Clear the dirty rows
        this.clearDirtyRows();

        // Reset the loading reports flag
        this.loadingReports = false;

        // Set the loaded flag
        this.loaded = true;
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Call mom...
        let result = super.isComponentReady();

        // Load the reports if necessary...
        if (result && this.props.game && !this.loaded)
        {
            var selectedReportType = this.state.selectedReportType;

            // Find a report type that does exist
            if (!this.containsReportType(selectedReportType))
            {
                for (var reportType in REPORT_TYPES)
                {
                    if (this.containsReportType(reportType))
                    {
                        selectedReportType = reportType;
                        break;
                    }
                }
            }


            // Load the reports
            this.loadReports(this.props.game.turns_completed + 1, selectedReportType);

            // Not ready...
            return false;
        }

        // Get out...
        return result;
    }


    /**
     * This method determines if the game contains the report type.
     *
     * @param {*} The report type.
     * @returns true if it contains it, else false.
     */
    containsReportType(reportType)
    {
        for (var existingReportType of this.props.gameDetails.report_types)
        {
            if (existingReportType === reportType)
                return true;
        }

        return false;
    }


    /**
     * This method handles selected row changes.
     *
     * @param {*} selectedRows The selected rows.
     */
    onSelectedRowsChange = (selectedRows) =>
    {
        this.setState({ selectedRows: selectedRows });
    }


    /**
     * This method handles delete row clicks.
     */
    onClickDeleteRows = () =>
    {
        // Initialize the rows & deleted rows
        let rows        = [];
        let selectedRows = this.gridApi.getSelectedRows();
        let deletedRows = [ ...this.state.deletedRows ];

        // Create the selected row map
        let selectedRowMap = {};
        for (let selectedRow of selectedRows)
            selectedRowMap[selectedRow.report_id] = true;

        // Add it to the appropriate list
        for (let row of this.state.rows)
        {
            if (selectedRowMap[row.report_id])
                deletedRows.push(row.report_id);
            else
                rows.push(row);
        }

        // Set the state
        this.setState({ rows: rows, deletedRows: deletedRows });
    }    


    /**
     * This method handles view saves.
     */
    onSave = () =>
    {
        // Get out if this is not actually dirty (shouldn't happen)
        if (!this.isDirty())
            return;

        // Handle the save...
        this.updateDirtyRows();
    }


    /**
     * This method updates the dirty rows.
     *
     * @return true if there are dirty rows, else false...
     */
    updateDirtyRows()
    {
        // Get out if nothing to do...
        if (Object.keys(this.state.deletedRows).length === 0)
            return false;

        // Update the reports
        gameService.deleteReports(this.state.deletedRows, this.props.match.params.team_id, this.state.selectedTurn, this.state.selectedReportType, this.onSaveComplete);

        // Get out...
        return true;
    }


    /**
     * The save complete event handler.
     *
     * @param {*} rows The rows.
     */
    onSaveComplete = (rows) =>
    {
        // Clear the dirty flag
        this.clearDirtyRows();

        // Update the state
        this.setState({ rows: rows });

        // let the user know
        alert("Saved.");
    }


    /**
     * This method clears the dirty rows.
     */
    clearDirtyRows()
    {
        // Reset the dirty IDs
        this.setState(
        {
            deletedRows: []
        });
    }


    /**
     * This method handles turn changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onTurnChange = (name, value) =>
    {
        // Load the reports
        this.loadReports(parseInt(value), this.state.selectedReportType);
    }


    /**
     * This method handles report type changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onReportTypeChange = (name, value) =>
    {
        // Load the reports
        this.loadReports(this.state.selectedTurn, value);

        // Update the state
        this.setState({ selectedReportType: value });
    }


    /**
     * This method determines if the state is dirty.
     *
     * @returns true if the state is dirty, else false.
     */
    isDirty()
    {
        // Are we dirty?
        if (this.state.deletedRows.length > 0)
            return true;

        return false;
    }

    /**
     * The grid ready event handler.
     *
     * @params {*} params The parameters.
     */
    onGridReady = (params) => 
    {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    /**
     * This method renders the grid view.
     */
    renderGridView()
    {
        // Get the game
        let team = this.props.team;
        if (team == null)
        {
            return (
                <ErrorBox>The team was not found.</ErrorBox>
            );
        }

        // Initialize the report types
        let reportTypes = [ ...this.props.gameDetails.report_types.map(type => ( { key: type, value: type })) ];

        // Sort the report types
        reportTypes.sort((reportType1, reportType2) => getOrdinal(reportType1.key) - getOrdinal(reportType2.key));

        // Translate the names
        for (var counter = 0; counter < reportTypes.length; counter++)
            reportTypes[counter].value = translateName(reportTypes[counter].value);


        return (
            <Fragment>
                <Prompt when={ this.isDirty() } message="There are unsaved changes on this screen.  Are you sure you want to leave?" />

                <DecisionBody name="Reports" game={ this.props.game } intraturnId={ this.state.intraturnId } onSave={ this.onSave } dirty={ this.isDirty() }>

                    <Row>
                        <Col xs={6} sm={6} md={4} lg={4}>
                            <TurnSelect name="turns" onChange={ this.onTurnChange } value= { this.state.selectedTurn } />
                        </Col>
                        <Col xs={4} sm={4} md={3} lg={3}>
                            <SelectControl items={ reportTypes } name="reportTypes"  onChange={ this.onReportTypeChange } value= { this.state.selectedReportType } />
                        </Col>
                        <Col xs={2} sm={2} md={1} lg={1}>
                        {
                            this.state.rows.length > 0 ? 
                                <span class= "fa-buttons" style={{color:"orange"}}>
                                    <DownloadTurnReportsLink gameId={ this.props.match.params.game_id } teamId={ team.team_id } turnNum={ this.state.selectedTurn } />
                                    &nbsp; &nbsp; &nbsp; 
                                    <DownloadTeamReportsLink teamId={ team.team_id } />
                                </span>                  
                            : ""
                        }
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="right col-padding-right bold col-middle-align">
                            <ScenarioName intraturnId={ this.state.intraturnId } />
                        </Col>
                    </Row>

                    <Row className="icon-buttons" >
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <a onClick={ this.onClickDeleteRows }><i class="fa fa-trash"></i></a>
                        </Col>
                    </Row>

                    <div class="grid-body">
                        <DecisionGrid 
                                onGridReady = { this.onGridReady }  
                                turn= { this.state.selectedTurn }
                                columns={ this.columns } 
                                sortColumns={ this.state.sortColumns }
                                selectedRows={ this.state.selectedRows }
                                rowData={ this.state.rows } 
                                onSortColumnsChange={ this.onSortColumnsChange }
                                rowKeyGetter={ (row) => { return row.report_id; } }
                                rowSelection="multiple"
                                onSelectionChanged={ this.onSelectedRowsChange }
                        />
                    </div>
                </DecisionBody>
            </Fragment>
        );
    }
}

// Export the decisions view...
export default withRouter(connect(BaseDecisionGridView.mapStateToProps)(ReportsView));