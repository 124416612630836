import React from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

import watchService from "./app/services/WatchService";
import PrivateRoute from './routes/PrivateRoute.js';
import AdminRoute from './routes/AdminRoute.js';
import InstructorRoute from './routes/InstructorRoute.js';
import AppTemplate from "./app/components/AppTemplate";
import LoginView from "./app/views/public/LoginView";
import GameDetailsView from "./app/views/game/GameDetailsView";
import RunTurnView from "./app/views/game/RunTurnView";
import TeamDashboardView from "./app/views/game/TeamDashboardView";
import GameView from "./app/views/game/GameView";
import AdjustmentsView from "./app/views/game/AdjustmentsView";
import TeamNotesView from "./app/views/game/TeamNotesView";
import DecisionsView from "./app/views/game/decisions/DecisionsView";
import InvestmentPurchasesView from "./app/views/game/decisions/InvestmentPurchasesView";
import InvestmentSalesView from "./app/views/game/decisions/InvestmentSalesView";
import LoanAdministrationView from "./app/views/game/decisions/LoanAdministrationView";
import LoanOriginationView from "./app/views/game/decisions/LoanOriginationView";
import LoanSalesView from "./app/views/game/decisions/LoanSalesView";
import NMDView from "./app/views/game/decisions/NMDView";
import CDsView from "./app/views/game/decisions/CDsView";
import BorrowingsView from "./app/views/game/decisions/BorrowingsView";
import LongTermDebtView from "./app/views/game/decisions/LongTermDebtView";
import EquityView from "./app/views/game/decisions/EquityView";
import BDMView from "./app/views/game/decisions/BDMView";
import BDMGenAdminView from './app/views/game/decisions/BDMGenAdminView';
import FacilitiesView from "./app/views/game/decisions/FacilitiesView";
import ITView from "./app/views/game/decisions/ITView";
import HousekeepingView from "./app/views/game/decisions/HousekeepingView";
import ReportsView from "./app/views/game/decisions/ReportsView";
import DividendsView from "./app/views/game/decisions/DividendsView";
import HomeView from "./app/views/HomeView";
import RequestPasswordView from "./app/views/public/RequestPasswordView";
import PasswordView from "./app/views/public/PasswordView";
import UserOptionsView from "./app/views/user/UserOptionsView";
import MergersAndAquisitionsView from './app/views/game/mergers-and-acquisitions/MergersAndAquisitionsView';

import { clearRedirectRoute } from "./features/redirect/RedirectSlice";
import { store } from "./app/store";

import './App.css';


import {
    BrowserRouter as Router,
    Switch,
    Route
  } from "react-router-dom";
import MeasuresOfSuccessView from './app/views/game/decisions/MeasuresOfSuccessView.js';
import ManagementRatiosView from './app/views/game/ManagementRatiosView.js';
import RegulatorReportView from './app/views/game/RegulatorReportView.js';



/**
 * This method maps the state to the properties.
 * 
 * @param {*} state The state.
 * @param {*} ownProps The own properties.
 * @returns The mapping.
 */
const mapStateToProps = (state, ownProps) =>
{
    let result = 
    {
        blocking: state.block.blocking,
        blockMessage: state.block.blockMessage,
        redirectRoute: state.redirect.redirectRoute
    };

    return result;
}


/**
 * The app renderer.
 * 
 * @param {*} props The properties.
 * @returns The rendered object model.
 */
class App extends React.Component
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Save the app
        window.app = this;
    }


    /**
     * This method is called when the component is mounted.
     */
    componentDidMount()
    {
        watchService.watch();
    }


    /**
     * This method renders the component. 
     *
     * @returns The rendered component.
     */
    render()
    {
        // Handle redirects...
        if (this.props.redirectRoute)
            store.dispatch(clearRedirectRoute());


        return (
            <Router>
                <AppTemplate>
                {
                    this.props.redirectRoute ? <Redirect to={ this.props.redirectRoute } /> : 
                    <BlockUi tag="div" blocking={this.props.blocking} message={ this.props.blockMessage } className={ this.props.blockMessage ? "fisim-block" : "fisim-block fisim-block-hide" }>
                        <div className="App" id="outer-content-block">
                            <Switch>
                                <Route path="/login">
                                    <LoginView />
                                </Route>
                                
                                <Route path="/session-timeout">
                                    <h3>Your session has timed out.</h3>
                                    <LoginView />
                                </Route>

                                <Route path="/request-password">
                                    <RequestPasswordView name="Forgot Password" />
                                </Route>
                                <Route path="/new-user">
                                    <RequestPasswordView name="New User" />
                                </Route>
                                <Route path="/reset-password/:uuid">
                                    <PasswordView />
                                </Route>

                                <AdminRoute path="/admin/create-game" help="Create Game">
                                    <GameDetailsView />
                                </AdminRoute>

                                <PrivateRoute path="/user-settings" help="User Settings">
                                    <UserOptionsView />
                                </PrivateRoute>

                                <AdminRoute path="/game/:game_id/edit" help="Edit Game">
                                    <GameDetailsView />
                                </AdminRoute>

                                <AdminRoute path="/game/:game_id/run-turn" help="Run Turn">
                                    <RunTurnView />
                                </AdminRoute>

                                <InstructorRoute path="/game/:game_id/adjustments" help="Adjustments">
                                    <AdjustmentsView />
                                </InstructorRoute>

                                <PrivateRoute path="/game/:game_id/team/:team_id" help="Team Dashboard">
                                    <TeamDashboardView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/management-ratios/:team_id" help="Management Ratios">
                                    <ManagementRatiosView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/regulator/:team_id" help="Regulator">
                                    <RegulatorReportView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/mergers-and-acquisitions/:team_id" help="Mergers and Acquisitions">
                                    <MergersAndAquisitionsView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/team-notes/:team_id/:section_type" help="Notes">
                                    <TeamNotesView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/team-notes/:team_id" help="Notes">
                                    <TeamNotesView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/decisions/:team_id/investment-purchases" help="Investment Purchases">
                                    <InvestmentPurchasesView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/decisions/:team_id/investment-sales" help="Investment Sales">
                                    <InvestmentSalesView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/decisions/:team_id/loan-administration" help="Loan Administration">
                                    <LoanAdministrationView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/decisions/:team_id/loan-origination" help="Loan Origination">
                                    <LoanOriginationView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/decisions/:team_id/loan-sales" help="Loan Sales">
                                    <LoanSalesView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/decisions/:team_id/non-maturity-deposits" help="Non-Maturity Deposits">
                                    <NMDView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/decisions/:team_id/cds" help="CDs">
                                    <CDsView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/decisions/:team_id/borrowings" help="Borrowings">
                                    <BorrowingsView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/decisions/:team_id/long-term-debt" help="Long-Term Debt">
                                    <LongTermDebtView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/decisions/:team_id/equity" help="Equity">
                                    <EquityView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/decisions/:team_id/business-development/business-development" help="Business Development">
                                    <BDMView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/decisions/:team_id/business-development/learning-development" help="Business Development">
                                    <BDMGenAdminView account="Learning/Development" />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/decisions/:team_id/business-development/facilities" help="General Administration">
                                    <FacilitiesView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/decisions/:team_id/business-development/it" help="General Administration">
                                    <ITView account="IT" />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/decisions/:team_id/business-development/staffing" help="General Administration">
                                    <BDMGenAdminView account="Staffing" />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/decisions/:team_id/general-administration/dividends" help="Strategic Inputs">
                                    <DividendsView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/decisions/:team_id/general-administration/housekeeping">
                                    <HousekeepingView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/decisions/:team_id/general-administration/measures-of-success">
                                    <MeasuresOfSuccessView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/decisions/:team_id/reports" help="Reports">
                                    <ReportsView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id/decisions/:team_id" help="Decisions">
                                    <DecisionsView />
                                </PrivateRoute>

                                <PrivateRoute path="/game/:game_id" help="Run Game">
                                    <GameView />
                                </PrivateRoute>

                                <PrivateRoute path="/" help="Existing Games">
                                    <HomeView />
                                </PrivateRoute>
                            </Switch>
                        </div>
                    </BlockUi>
                }
                </AppTemplate>
            </Router>
        );
    }
}

export default connect(mapStateToProps)(App);