import { Fragment } from 'react';
import { Row, Col } from "react-bootstrap";

import FormControl from "../../../controls/form/FormControl";
import CurrencyControl from '../../../controls/form/CurrencyControl';
import BaseMergersAndAcquisitionsTab from './BaseMergersAndAcquisitionsTab';
import calculationHelpers from './CalculationHelpers';
import Utils from '../../../utils/Utils';
import { formatPercentage } from '../../../controls/Percentage';
import PercentageControl from '../../../controls/form/PercentageControl';


// One million...
const ONE_MILLION = 1000000;


/**
 * The Holding Company Statistics class.
 */
class HoldingCompanyStatistics extends BaseMergersAndAcquisitionsTab
{
    /**
     * This method renders the component.
     */
    render()
    {
        // Set the teams
        let team1 = (this.props.mergersAndAcquisitionsMap && this.props.commonState.team1) ? this.props.mergersAndAcquisitionsMap[this.props.commonState.team1] : null;
        let team2 = (this.props.mergersAndAcquisitionsMap && this.props.commonState.team2) ? this.props.mergersAndAcquisitionsMap[this.props.commonState.team2] : null;
        let team3 = (this.props.mergersAndAcquisitionsMap && this.props.commonState.team3) ? this.props.mergersAndAcquisitionsMap[this.props.commonState.team3] : null;

        // Calculate the disclosure
        let disclosure = this.calculateDisclosure();

        // Calculate the total cash paid
        let totalCashPaid = disclosure.totalCashPaid2 + disclosure.totalCashPaid3;

        // Get the cash from borrowings
        let cashFromBorrowings = Utils.parseCurrency(this.props.commonState.cashFromBorrowings);

        // Calculate the total cash
        let totalCash = ((Utils.parseCurrency(this.props.commonState.holdingCompanyDividends) + 
                          Utils.parseCurrency(this.props.commonState.cashFromNewCapitalIssues) + 
                          cashFromBorrowings) * ONE_MILLION) - totalCashPaid;

        // Calculate the total investment
        let totalInvestment = calculationHelpers.sumValues(team1, team2, team3, "Equity") - 
                              (Utils.parseCurrency(this.props.commonState.holdingCompanyDividends) * ONE_MILLION);

        // Calculate the good will
        let goodWill = ((team2 ? disclosure.totalValue2 - calculationHelpers.getValue(team2, "Equity") : 0) + 
                       (team3 ? disclosure.totalValue3 - calculationHelpers.getValue(team3, "Equity") : 0)) / ONE_MILLION;

        // Calculate the  total assets
        let totalAssets = totalCash + totalInvestment + (goodWill * ONE_MILLION);

        // Get the equity
        let equity = totalAssets - (cashFromBorrowings * ONE_MILLION);

        // Calculate the debt to equity
        let debtToEquity = cashFromBorrowings * 100 / ((equity / ONE_MILLION) - goodWill);

        // Calculate the total number of shares
        let totalShares = calculationHelpers.getValue(team1, "Common Shares") + disclosure.bankSharesTendered2 + disclosure.bankSharesTendered3;

        // Calculate the annualized net income
        let annualizedNetIncome = calculationHelpers.sumValues(team1, team2, team3, "Net Income after Tax")

        // Calculate the gross EPS
        let grossEPS = annualizedNetIncome / totalShares;

        // Get the opportunity cost
        let opportunityCost = team1 ? calculationHelpers.getValue(team1, "Prime") / 100.0 : 0;

        // Calculate the HC cash income
        let hcCashIncome = ((totalCash * opportunityCost) / calculationHelpers.sumValues(team1, team2, team3, "Common Shares")) * (1 - calculationHelpers.TAX_RATE);

        // Calculate the cost per share of the HC dividend
        let costPerShareOfHCDividend = (((Utils.parseCurrency(this.props.commonState.holdingCompanyDividends) * ONE_MILLION * opportunityCost) * (1 - calculationHelpers.TAX_RATE)) / totalShares);

        // Calculate the new capital cost
        let newCapitalCost = (Utils.parseCurrency(this.props.commonState.cashFromNewCapitalIssues) * ONE_MILLION) / (calculationHelpers.getValue(team1, "Stock Price") * .9) / totalShares * grossEPS;

        // Calculate the new borrowing cost
        let newBorrowingCost = (Utils.parseCurrency(this.props.commonState.cashFromBorrowings) * ONE_MILLION * opportunityCost) * (1 - calculationHelpers.TAX_RATE) / totalShares;

        // Calculate the total consolidation cost
        let totalConsolidationCost = costPerShareOfHCDividend + newCapitalCost + newBorrowingCost;

        // Calculate the dividend cost...
        let dividendCost = costPerShareOfHCDividend * (1 - calculationHelpers.TAX_RATE);

        // Calculate the dividend cost...
        let newBorrowingCostPostTax = newBorrowingCost * (1 - calculationHelpers.TAX_RATE);

        // Calculate the individual cost savings
        let costSavings1 = calculationHelpers.getValue(team1, "Non-Interest Expense") * this.parsePercentage(this.props.commonState.costSavingsPercentage1);
        let costSavings2 = team2 ? calculationHelpers.getValue(team2, "Non-Interest Expense")  * this.parsePercentage(this.props.commonState.costSavingsPercentage2) : 0;
        let costSavings3 = team3 ? calculationHelpers.getValue(team3, "Non-Interest Expense")  * this.parsePercentage(this.props.commonState.costSavingsPercentage3) : 0;

        // Calculate the total cost savings
        let totalCostSavings = ((costSavings1 + costSavings2 + costSavings3) / totalShares / ONE_MILLION) * (1 - calculationHelpers.TAX_RATE);


        // Calculate the individual interest income enhancement
        let interestIncomeEnhancement1 = calculationHelpers.getValue(team1, "Annualized Net Interest Income") * this.parsePercentage(this.props.commonState.enhancementRate1);
        let interestIncomeEnhancement2 = team2 ? calculationHelpers.getValue(team2, "Annualized Net Interest Income")  * this.parsePercentage(this.props.commonState.enhancementRate2) : 0;
        let interestIncomeEnhancement3 = team3 ? calculationHelpers.getValue(team3, "Annualized Net Interest Income")  * this.parsePercentage(this.props.commonState.enhancementRate3) : 0;

        // Calculate the total interest income enhancement
        let totalInterestIncomeEnhancement = ((interestIncomeEnhancement1 + interestIncomeEnhancement2 + interestIncomeEnhancement3) / totalShares / ONE_MILLION) * (1 - calculationHelpers.TAX_RATE);


        // Calculate the individual fee income enhancement
        let feeEnhancement1 = calculationHelpers.getValue(team1, "Non-Interest Income") * this.parsePercentage(this.props.commonState.feeEnhancementRate1);
        let feeEnhancement2 = team2 ? calculationHelpers.getValue(team2, "Non-Interest Income")  * this.parsePercentage(this.props.commonState.feeEnhancementRate2) : 0;
        let feeEnhancement3 = team3 ? calculationHelpers.getValue(team3, "Non-Interest Income")  * this.parsePercentage(this.props.commonState.feeEnhancementRate3) : 0;

        // Calculate the total interest income enhancement
        let totalFeeEnhancement = ((feeEnhancement1 + feeEnhancement2 + feeEnhancement3) / totalShares / ONE_MILLION) * (1 - calculationHelpers.TAX_RATE);

        // Calculate the annual earnings per share
        let annualEarningsPerShare1 = calculationHelpers.getValue(team1, "Net Income after Tax") / calculationHelpers.getValue(team1, "Common Shares");
        let annualEarningsPerShare2 = team2 && (disclosure.exchangeRatio2 != 0) ? calculationHelpers.getValue(team2, "Net Income after Tax") / calculationHelpers.getValue(team2, "Common Shares") / disclosure.exchangeRatio2 : 0;
        let annualEarningsPerShare3 = team3 && (disclosure.exchangeRatio3 != 0) ? calculationHelpers.getValue(team3, "Net Income after Tax") / calculationHelpers.getValue(team3, "Common Shares") / disclosure.exchangeRatio3 : 0;

        // Calculate the proform earnings per share
        let proFormaEarningsPerShare = grossEPS + hcCashIncome - totalConsolidationCost + ((totalCostSavings + totalInterestIncomeEnhancement + totalFeeEnhancement) * ONE_MILLION);

        // Calculate the proforma stock pice per assumed market book ratio
        let proformaStockPricePerAssumedMarketBookRatio = 
                calculationHelpers.getValue(team1, "Market Book") * equity /
                (((Utils.parseCurrency(this.props.commonState.cashFromNewCapitalIssues) * ONE_MILLION) /
                  (calculationHelpers.getValue(team1, "Stock Price") * .9)) + totalShares);


        // Initialize the tab index
        let tabIndex = 1;

        return(
            <Fragment>
                <FormControl name="Balance Sheet (millions)">
                    <div className="manda-grid panel-body">
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Dividends from Banks</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><CurrencyControl name="holdingCompanyDividends" value={ this.props.commonState.holdingCompanyDividends } onChange={ this.onFieldChange } style={ { textAlign: "right" } } allowDecimal={ true } tabIndex={ tabIndex++ } allowDecimal={ true } /></Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Plus Cash from New Capital Issues</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><CurrencyControl name="cashFromNewCapitalIssues" value={ this.props.commonState.cashFromNewCapitalIssues } onChange={ this.onFieldChange } style={ { textAlign: "right" } } allowDecimal={ true } tabIndex={ tabIndex++ } allowDecimal={ true } /></Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Plus Cash from Borrowings</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><CurrencyControl name="cashFromBorrowings" value={ this.props.commonState.cashFromBorrowings } onChange={ this.onFieldChange } style={ { textAlign: "right" } } allowDecimal={ true } tabIndex={ tabIndex++ } allowDecimal={ true } /></Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Less Cash from Purchase of Bank Stock</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.formatCurrencyInMillions(totalCashPaid) }</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Total Cash</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className={ "value right " + (totalCash < 0 ? "bad" : "good") }>{ calculationHelpers.formatCurrencyInMillions(totalCash) }</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Investment In Banks</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.formatCurrencyInMillions(totalInvestment) }</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Goodwill</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter().format(goodWill) }</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Total Assets</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.formatCurrencyInMillions(totalAssets) }</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Debt</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter().format(Utils.parseCurrency(this.props.commonState.cashFromBorrowings)) }</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Equity</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.formatCurrencyInMillions(equity) }</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Total Liabilities & Capital</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.formatCurrencyInMillions((Utils.parseCurrency(this.props.commonState.cashFromBorrowings) * ONE_MILLION) + equity) }</Col>
                        </Row>
                    </div>
                </FormControl>

                <div className="manda-spacer"></div>

                <FormControl name="Ratios">
                    <div className="manda-grid panel-body">
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Holding Company Debt to Equity (Maximum 50%)</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className={ "right value " + (debtToEquity > 50 ? "bad" : "good") }>{ formatPercentage(debtToEquity) }</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>New Tier 1 Capital Ratio (Minimum 6%)</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className={ "right value " + (this.calculateMergedBankLevelRatio(team1, team2, team3) < 6 ? "bad" : "good") }>{ this.printMergedBankLevelRatio(team1, team2, team3) }</Col>
                        </Row>
                    </div>
                </FormControl>

                <div className="manda-spacer"></div>

                <FormControl name="Earnings Per Share">
                    <div className="manda-grid panel-body">
                        <Row className="g-3">
                            <Col xs={18} sm={18} md={9} lg={10} className="left"><b>Gross EPS</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter().format(grossEPS) }</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={18} sm={18} md={9} lg={10} className="left"><b>Plus HC Cash Income</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter().format(hcCashIncome) }</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={18} sm={18} md={9} lg={10} className="left"><b>Dividend Cost</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter().format(dividendCost) }</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={18} sm={18} md={9} lg={10} className="left"><b>New Capital Cost</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter().format(newCapitalCost) }</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={18} sm={18} md={9} lg={10} className="left"><b>New Borrowing Cost</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter().format(newBorrowingCostPostTax) }</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={18} sm={18} md={9} lg={10} className="left"><b>Total Cost of Consolidation</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter().format(totalConsolidationCost) }</Col>
                        </Row>

                        <Row className="g-3">
                            <Col xs={12} sm={12} md={12} lg={12} className="left">&nbsp;</Col>
                        </Row>

                        <Row className="odd-row header g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><b>{ team1 ? this.props.teamMap[this.props.commonState.team1].team_name : "None Selected" }</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><b>{ team2 ? this.props.teamMap[this.props.commonState.team2].team_name : "None Selected" }</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><b>{ team3 ? this.props.teamMap[this.props.commonState.team3].team_name : "None Selected" }</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">&nbsp;</Col>
                        </Row>

                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Cost Savings</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><PercentageControl name="costSavingsPercentage1" value={ this.props.commonState.costSavingsPercentage1 } onChange={ this.onFieldChange } style={ { textAlign: "right" } } tabIndex={ tabIndex++ }/></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? formatPercentage(this.props.commonState.costSavingsPercentage2) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? formatPercentage(this.props.commonState.costSavingsPercentage3) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter().format(totalCostSavings * ONE_MILLION) }</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Int Inc Enhancement Rates</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><PercentageControl name="enhancementRate1" value={ this.props.commonState.enhancementRate1 } onChange={ this.onFieldChange } style={ { textAlign: "right" } } tabIndex={ tabIndex++ }/></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? formatPercentage(this.props.commonState.enhancementRate2) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? formatPercentage(this.props.commonState.enhancementRate3) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter().format(totalInterestIncomeEnhancement * ONE_MILLION) }</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Fee Inc Enhancement Rates</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><PercentageControl name="feeEnhancementRate1" value={ this.props.commonState.feeEnhancementRate1 } onChange={ this.onFieldChange } style={ { textAlign: "right" } } tabIndex={ tabIndex++ }/></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? formatPercentage(this.props.commonState.feeEnhancementRate2) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? formatPercentage(this.props.commonState.feeEnhancementRate3) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter().format(totalFeeEnhancement * ONE_MILLION) }</Col>
                        </Row>

                        <Row className="odd-row g-3">
                            <Col xs={12} sm={12} md={12} lg={12} className="left">&nbsp;</Col>
                        </Row>

                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Bk Stk Hurdle EPS</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter2Decimals().format(annualEarningsPerShare1) }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className={ "right value " + (annualEarningsPerShare2 < annualEarningsPerShare1 ? "bad" : "good") }>{ team2 ? calculationHelpers.getDollarFormatter2Decimals().format(annualEarningsPerShare2) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className={ "right value " + (annualEarningsPerShare3 < annualEarningsPerShare1 ? "bad" : "good") }>{ team3 ? calculationHelpers.getDollarFormatter2Decimals().format(annualEarningsPerShare3) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">&nbsp;</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={18} sm={18} md={9} lg={10} className="left"><b>Proforma Earnings Per Share - HC Stock</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter().format(proFormaEarningsPerShare) }</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Stock Hurdle Price</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className={ "right value " + (calculationHelpers.getValue(team1, "Stock Price") > (proFormaEarningsPerShare * calculationHelpers.getValue(team1, "PE")) ? "bad" : "good") }>{ calculationHelpers.getDollarFormatter2Decimals().format(calculationHelpers.getValue(team1, "Stock Price")) }</Col>
                            <Col xs={0} sm={0} md={6} lg={6} className="right">&nbsp;</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={18} sm={18} md={9} lg={10} className="left"><b>Assumed Price/Earnings Ratio for Holding Companies</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printRatio(team1, "PE") }</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={18} sm={18} md={9} lg={10} className="left"><b>Proforma H C Stock Price per an Assumed EPS</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className={ "right value " + (proFormaEarningsPerShare * calculationHelpers.getValue(team1, "PE") < calculationHelpers.getValue(team1, "Stock Price") ? "bad" : "good") }>{ calculationHelpers.getDollarFormatter2Decimals().format(proFormaEarningsPerShare * calculationHelpers.getValue(team1, "PE")) }</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={12} sm={12} md={12} lg={12} className="left">&nbsp;</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={18} sm={18} md={9} lg={10} className="left"><b>Assumed Market/Book Ratio for Holding Companies</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printRatio(team1, "Market Book") }</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={18} sm={18} md={9} lg={10} className="left"><b>Proforma HC Stock Price Per an Assumed Market/Book ratio</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className={ "right value " + (proformaStockPricePerAssumedMarketBookRatio < calculationHelpers.getValue(team1, "Stock Price") ? "bad" : "good") }>{ calculationHelpers.getDollarFormatter2Decimals().format(proformaStockPricePerAssumedMarketBookRatio) }</Col>
                        </Row>
                    </div>
                </FormControl>
            </Fragment>
        );
    }
}

// Export the holding company statistics...
export default HoldingCompanyStatistics;