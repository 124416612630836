import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'

/**
 * The time input component.
 *
 * @param {*} props The properties.
 * @returns The time input control.
 */
class TimeInput extends React.Component
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Create the input reference
        this.inputRef = React.createRef();
    }


    /**
     * This method places focus on the cell.
     */
    focus()
    {
        this.inputRef.current.inputElement.focus();
    }


    /**
     * This method renders the component.
     *
     * @returns The rendered component.
     */
    render()
    {
        let mask= [/[0-2]/, /\d/, ':', /\d/, /\d/];

        // Return the masked input control...
        return <MaskedInput ref={ this.inputRef } mask={ mask } { ...this.props } />;
    }
}


// Export the time input component
export default TimeInput;