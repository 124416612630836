import React from 'react';

import InputControl from "./InputControl";

/**
 * The PasswordControl component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
const PasswordControl = (props) =>
{
    // Get out...
    return (
        <InputControl type="password" { ...props } />
    );
}

// Get out...
export default PasswordControl;



