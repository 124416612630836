import React, { Fragment } from 'react';
import { withRouter, Prompt } from "react-router";
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Utils from "../../../utils/Utils";

import BaseDecisionGridView from "./BaseDecisionGridView";
import COLUMNS from "./grid/EquityColumns";
import TurnSelect from "./components/TurnSelect";
import DecisionGrid from "./components/DecisionGrid";
import ScenarioName from "./components/ScenarioName";

import DecisionBody from "./DecisionBody";
import gameService from "../../../services/GameService";
import intraturnService from "../../../services/IntraturnService";
import BorrowingsControl from './components/BorrowingsControl';


/**
 * The EquityView class.
 */
class EquityView extends BaseDecisionGridView
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the loaded flag
        this.loaded = false;

        // Create the reference
        this.borrowingsControlRef = React.createRef();

        // Initialize the state
        this.state =
        {
            rows: [],
            dirtyRows: {},
            sortColumns: [],
            selectedTurn: -1,
        };

        // Initialize the columns
        this.columns = COLUMNS;
    }


    /**
     * This method determines if the state is dirty.
     *
     * @returns true if the state is dirty, else false.
     */
    isDirty()
    {
        // Are we dirty?
        if (super.isDirty() || this.isBorrowingsDirty())
            return true;

        return false;
    }


    /**
     * This method determines if borrowings are dirty.
     * 
     * @returns true borrowings are dirty, else false.
     */
    isBorrowingsDirty()
    {
        return this.borrowingsControlRef.current && this.borrowingsControlRef.current.isDirty();
    }


    /**
     * This method loads the equity.
     *
     * @param turn The turn.
     */
    loadEquity(turn)
    {
        // Get out if the state is loaded
        if (!this.props.game)
            return;

        // Only load once...
        if (this.loadingEquity)
            return;

        // Get out if nothing to do...
        if (this.state.selectedTurn === turn)
            return;

        // Warn the user if there are changes...
        if (this.isDirty())
        {
            if (!window.confirm("You have unsaved changes.  Do you wish to proceed?"))
                return;
        }

        // Mark us as loading...
        this.loadingEquity = true;

        // Load the equity
        gameService.loadEquity(this.props.match.params.team_id, turn, this.onEquityLoaded);
    }


    /**
     * The equity loaded event handler.
     *
     * @param {*} equity The equity.
     * @param {*} canRaise The can raise flag.
     * @param {*} lastUpdated The last updated flag.
     * @param {*} turn The turn.
     */
    onEquityLoaded = (equity, canRaise, lastUpdated, turn) =>
    {
        // Make sure we have something...
        if (!equity)
            equity = [];

        // Set the can-raise property...
        for (let row of equity)
            row.readOnly = !canRaise;

        // Initialize the state
        let state =
        { 
            rows: equity, 
            selectedTurn: turn,
            lastUpdated: lastUpdated
        }

        // Set the intraturn Id if appropriate
        if (equity.length > 0)
            state.intraturnId = equity[0].intraturn_id;

        // Set the state
        this.setState(state);

        // Clear the dirty rows
        this.clearDirtyRows();

        // Reset the loading equity flag
        this.loadingEquity = false;

        // Set the loaded flag
        this.loaded = true;
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Call mom...
        let result = super.isComponentReady();

        // Load the investment purchases if necessary...
        if (this.props.game && !this.loaded)
        {
            // Load the investment purchases
            this.loadEquity(this.props.game.turns_completed + 1);

            // Not ready...
            return false;
        }

        // Get out...
        return result;
    }


    
    /**
     * This method updates the state when the cell value has been modified.
     *
     * @param {*} params The params.
     */
    onCellValueChanged = (params) =>
    {
        // Get the dirty rows map...
        let dirtyRows = this.state.dirtyRows;

        // Mark it dirty
        dirtyRows[params.data.equity_id] = true;

        // Parse the repurchase
        params.data.repurchase = Utils.parseCurrency(params.data.repurchase);

        // Save the state
        this.setState(
        {
            dirtyRows: dirtyRows
        });
    }


    /**
     * This method handles view saves.
     */
    onSave = () =>
    {
        // Set the dirty fields
        let dirty =
        {
            equityDirty: super.isDirty(),
            borrowingsDirty: this.isBorrowingsDirty()
        }

        // This should never happen
        if (!dirty.equityDirty && !dirty.borrowingsDirty)
            return;

        // Set the state
        this.setState(dirty);

        // Save borrowings if necessary
        if (dirty.borrowingsDirty)
            this.borrowingsControlRef.current.save(this.onBorrowingsSaveComplete);

        // Get out if equity is not dirty...
        if (!dirty.equityDirty)
            return;

        // Initialize the total
        let total = 0;

        // Check each row...
        for (let row of this.state.rows)
        {
            row.raised = Utils.parseCurrency(row.raised)
            total += row.raised;
        }

        // Check the total...
        if ((total > 0) && (total < 5000))
        {
            alert("You must raise a minumum of $5m.");
            return;
        }

        // Check the total...
        if (total > 20000)
        {
            alert("You may only raise a maximum of $20m per quarter.");
            return;
        }

        // Update the dirty rows
        this.updateDirtyRows(false);
    }


    /**
     * This method updates the dirty rows.
     *
     * @param {*} force The force flag.
     */
    updateDirtyRows(force)
    {
        // Initialize the updated rows
        let updatedRows = [];

        // Check each row...
        for (let row of this.state.rows)
        {
            // See if it is dirty...
            if (!this.state.dirtyRows[row.equity_id])
                continue;

            // Create the updated row
            let updatedRow = { ...row };

            // Push the updated row
            updatedRows.push(updatedRow);
        }

        // Update the equity
        intraturnService.updateEquity(this.state.intraturnId, updatedRows, this.state.lastUpdated, force, this.onSaveComplete);

        // Get out...
        return true;
    }


    /**
     * The save complete event handler.
     *
     * @param {*} modifiedBy The modified by user.
     * @param {*} lastUpdated The last updated time.
     */
    onSaveComplete = ({ modifiedBy, lastUpdated }) =>
    {
        // See if it was modified since we loaded the data...
        if (modifiedBy)
        {
            // See if the user wants to force the matter...
            if (!window.confirm("Equity records have modified by " + modifiedBy + " at " + new Date(lastUpdated).toLocaleTimeString() + ".\n\nWould you like save your changes anyway?"))
                return;

            // Force the update
            this.updateDirtyRows(true);

            // Get out...
            return;
        }

        // Update the state
        this.setState({ lastUpdated: lastUpdated, equityDirty: false });

        // Clear the dirty flag
        this.clearDirtyRows();

        // let the user know
        if (!this.state.borrowingsDirty)
            alert("Saved.");
    }


    /**
     * The borrowings save complete event handler.
     */
    onBorrowingsSaveComplete = () =>
    {
        // Update the state
        this.setState({ borrowingsDirty: false });

        // let the user know
        if (!this.state.equityDirty)
            alert("Saved.");
    }


    /**
     * This method clears the dirty rows.
     */
    clearDirtyRows()
    {
        // Reset the dirty IDs
        this.setState(
        {
            dirtyRows: {}
        });
    }


    /**
     * This method handles turn changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onTurnChange = (name, value) =>
    {
        // Load the equity
        this.loadEquity(parseInt(value));
    }




    /**
     * This method renders the view.
     */
    renderView()
    {
        return (
            <Fragment>
                <Prompt when={ this.isDirty() } message="There are unsaved changes on this screen.  Are you sure you want to leave?" />

                <DecisionBody name={ this.props.game.type === "Lite" ? "Equity & Borrowings" : "Equity" } noteType="Tres" intraturnId={ this.state.intraturnId } 
                              game={ this.props.game } onSave={ this.onSave } dirty={ this.isDirty() }>

                    <Row>
                        <Col xs={6} sm={6} md={4} lg={4}>
                            <TurnSelect name="turns" onChange={ this.onTurnChange } value= { this.state.selectedTurn } />
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4}>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="right col-padding-right bold col-middle-align">
                            <ScenarioName intraturnId={ this.state.intraturnId } />
                        </Col>
                    </Row>

                    <div class={ "grid-body" + (this.props.game.type === "Lite") ? " small" : "" }>
                        <DecisionGrid 
                            columns={ this.columns } 
                            turn={ this.state.selectedTurn }
                            sortColumns={ this.state.sortColumns }
                            rowData={ this.state.rows } 
                            onCellValueChanged={ this.onCellValueChanged }
                            onSortColumnsChange={ this.onSortColumnsChange }
                            height={ (this.props.game.type === "Lite") ? " 30vh" : ""}
                        />
                    </div>

                    {
                        (this.props.game.type === "Lite") ?
                            <div className="embedded-borrowings">
                                <BorrowingsControl ref={ this.borrowingsControlRef } selectedTurn={ this.state.selectedTurn } team={ this.props.team } game={ this.props.game } />
                            </div>
                        : ""
                    }
                </DecisionBody>

            </Fragment>
        );
    }
}

// Export the decisions view...
export default withRouter(connect(BaseDecisionGridView.mapStateToProps)(EquityView));