import { Fragment } from 'react';
import { withRouter, Prompt } from "react-router";
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';

import Utils from '../../../utils/Utils';
import BaseDecisionGridView from "./BaseDecisionGridView";
import COLUMNS from "./grid/LoanSalesColumns";
import TurnSelect from "./components/TurnSelect";
import DecisionGrid from "./components/DecisionGrid";
import ScenarioName from "./components/ScenarioName";
import PercentageInput from "../../../controls/PercentageInput";
import InputControl from '../../../controls/form/InputControl';
import CurrencyControl from '../../../controls/form/CurrencyControl';
import NumberControl from '../../../controls/form/NumberControl';
import PercentageControl from '../../../controls/form/PercentageControl';
import { parsePercentage } from '../../../controls/Percentage';

import DecisionBody from "./DecisionBody";
import gameService from "../../../services/GameService";
import intraturnService from "../../../services/IntraturnService";
import SelectControl from '../../../controls/form/SelectControl';


// The default modal data...
let DEFAULT_MODAL_DATA = 
{
    loan_account: "",
    loan_category: "",
    amount: 0,
    gain_or_loss: 0,
    term: 0,
    yield: 0
}


/**
 * The LoanSalesView class.
 */
class LoanSalesView extends BaseDecisionGridView
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the loaded flag
        this.loaded = false;

        // Initialize the state
        this.state =
        {
            rows: [],
            dirtyRows: {},
            sortColumns: [],
            selectedRows: new Set(),
            deletedRows: [],
            selectedLoanCategory: "All",
            selectedTurn: -1,
            fillPercent: "0.00%",
            showAddModal: false,
            addedRowCount: 0,
            modalData: {},
            loanSaleTypes: []
        };

        // Initialize the columns
        this.columns = COLUMNS;
    }


    /**
     * This method loads the loan sales.
     *
     * @param turn The turn.
     * @param loanCategory The loan category type.
     */
    loadLoanSales(turn, loanCategory)
    {
        // Get out if the state is loaded
        if (!this.props.game)
            return;

        // Only load once...
        if (this.loadingLoanSales)
            return;

        // Get out if nothing to do...
        if ((this.state.selectedTurn === turn) && (this.state.selectedLoanCategory === loanCategory))
            return;

        // Warn the user if there are changes...
        if (this.isDirty())
        {
            if (!window.confirm("You have unsaved changes.  Do you wish to proceed?"))
                return;
        }

        // Mark us as loading...
        this.loadingLoanSales = true;

        // Load the loan sales
        gameService.loadLoanSales(this.props.match.params.team_id, turn, loanCategory, this.onLoanSalesLoaded);
    }


    /**
     * The loan sales loaded event handler.
     *
     * @param {*} loanSales The loan sales.
     * @param {*} lastUpdated The last updated time.
     * @param {*} turn The turn.
     * @param {*} loanCategory The loan category type.
     */
    onLoanSalesLoaded = (loanSales, lastUpdated, turn, loanCategory) =>
    {
        // Make sure we have something...
        if (!loanSales)
            loanSales = [];

        // Calculate the sale gain or loss
        for (let loanSale of loanSales)
        {
            // Parse the sales percent
            let salePercent = parseFloat(loanSale.sale_percent);

            if (salePercent === 0)
                loanSale.sale_gain_or_loss = 0;
            else
                loanSale.sale_gain_or_loss = parseFloat(loanSale.gain_or_loss) * parseFloat(loanSale.sale_percent) / 100.0;

            // Set the checkbox
            if (salePercent === 100)
                loanSale.sell_100_percent = true;
            else
                loanSale.sell_100_percent = false;
        }

        // Initialize the state
        let state =
        { 
            rows: loanSales, 
            summary: this.calculateSummary(loanSales),
            selectedTurn: turn, 
            selectedLoanCategory: loanCategory,
            lastUpdated: lastUpdated
        }

        // Set the intraturn Id if appropriate
        if (loanSales.length > 0)
            state.intraturnId = loanSales[0].intraturn_id;

        // Set the state
        this.setState(state);

        // Clear the dirty rows
        this.clearDirtyRows();

        // Reset the loading loan sales flag
        this.loadingLoanSales = false;

        // Set the loaded flag
        this.loaded = true;
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Call mom...
        let result = super.isComponentReady();

        // Load the loan sales if necessary...
        if (this.props.game && !this.loaded)
        {
            // Load the loan sales
            this.loadLoanSales(this.props.game.turns_completed + 1, this.state.selectedLoanCategory);

            // Not ready...
            return false;
        }

        // Get out...
        return result;
    }


    /**
     * This method updates the state when the cell value has been modified.
     *
     * @param {*} params The params.
     */
    onCellValueChanged = (params) =>
    {
        let markDirty; 

        // Get the dirty rows map...
        let dirtyRows = this.state.dirtyRows;

        // Update the sale percent if the checkbox was clicked, or the sale percent was reduced below 100%
        if (params.column.colId === "sell_100_percent")
        {
            // Determine if a value is actually changing...
            if (params.data.sell_100_percent && (parseFloat(params.data.sale_percent) !== 100))
            {
                // Update the sale percentage...
                params.data.sale_percent = "100.0";

                // Mark it dirty...
                markDirty = true;
            }
            else
            {
                // No change...
                markDirty = false;
            }
        }
        else
        if (params.column.colId === "sale_percent") 
        {
            if (parseInt(params.data.sale_percent) !== 100)
                params.data.sell_100_percent = false;
            else
                params.data.sell_100_percent = true;
            
            // Set the mark dirty flag
            markDirty = true;
        }

        // Mark the row as dirty
        if (markDirty)
            dirtyRows[params.data.loan_sale_id] = true;

        // Adjust the sale amount & sale gain or loss...
        params.data.sale_amount = parseFloat(params.data.sale_percent) *  params.data.amount / 100;
        params.data.sale_gain_or_loss = parseFloat( params.data.sale_percent) *  params.data.gain_or_loss / 100;

        // Update Summary
        let summary = this.calculateSummary(this.state.rows);

        // Save the state
        this.setState(
        {
            dirtyRows: dirtyRows,
            summary: summary
        });

        // Displays should most recent up to date values.
        params.api.refreshCells();
    }


    /**
     * This method handles selected row changes.
     *
     * @param {*} selectedRows The selected rows.
     */
    onSelectedRowsChange = (selectedRows) =>
    {
        if (this.isEditable())
            this.setState({ selectedRows: selectedRows });
    }


    /**
     * This method handles view saves.
     */
    onSave = () =>
    {
        // Get out if this is not actually dirty (shouldn't happen)
        if (!this.isDirty())
            return;

        // Handle the save...
        this.updateDirtyRows(false);
    }


    /**
     * This method determines if there are dirty fields.
     *
     * @returns true if there are dirty fields, else false.
     */
    hasDirtyFields()
    {
        if (this.state.deletedRows.length)
            return true;

        return false;
    }


    /**
     * This method updates the dirty rows.
     *
     * @param {*} force The force flag.
     * @return true if there are dirty rows, else false...
     */
    updateDirtyRows(force)
    {
        // Get out if nothing to do...
        if ((Object.keys(this.state.dirtyRows).length === 0) && !this.state.deletedRows.length)
            return false;

        // Initialize the added & updated rows
        let addedRows   = [];
        let updatedRows = [];

        // Check each row...
        for (let row of this.state.rows)
        {
            // See if it is dirty...
            if (!this.state.dirtyRows[row.loan_sale_id])
                continue;
            
            // Converts sale percent
            row.sale_percent = parsePercentage(row.sale_percent).toFixed(3);

            // Put it in the correct bucket...
            if (row.loan_sale_id < 0)
                addedRows.push(row);
            else
                updatedRows.push(row);
        }

        // Update the loan sales
        intraturnService.updateLoanSales(this.state.intraturnId, this.state.selectedTurn, this.state.selectedLoanCategory, 
                                         addedRows, updatedRows, this.state.deletedRows, this.state.lastUpdated, force, this.onSaveComplete);

        // Get out...
        return true;
    }


    /**
     * The save complete event handler.
     *
     * @param {*} modifiedBy The modified by user.
     * @param {*} lastUpdated The last updated time.
     * @param {*} loanSales The loan sales.
     * @param {*} turn The turn.
     * @param {*} loanCategory The loan category type.
     */
    onSaveComplete = (modifiedBy, lastUpdated, loanSales, turn, loanCategory) =>
    {
        // See if it was modified since we loaded the data...
        if (modifiedBy)
        {
            // See if the user wants to force the matter...
            if (!window.confirm("Loan Sales records were modified by " + modifiedBy + " at " + new Date(lastUpdated).toLocaleTimeString() + ".\n\nWould you like save your changes anyway?"))
                return;

            // Force the update
            this.updateDirtyRows(true);

            // Get out...
            return;
        }

        // Update the data
        this.onLoanSalesLoaded(loanSales, lastUpdated, turn, loanCategory);

        // Clear the dirty flag
        this.clearDirtyRows();

        // let the user know
        alert("Saved.");
    }


    /**
     * This method clears the dirty rows.
     */
    clearDirtyRows()
    {
        // Reset the dirty IDs
        this.setState(
        {
            dirtyRows: {},
            deletedRows: []
        });
    }


    /**
     * This method handles turn changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onTurnChange = (name, value) =>
    {
        // Load the loan sales
        this.loadLoanSales(parseInt(value), this.state.selectedLoanCategory);
    }


    /**
     * This method handles loan category changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onLoanCategoryChange = (name, value) =>
    {
        // Load the loan categories
        this.loadLoanSales(this.state.selectedTurn, value);
    }


    /**
     * This method handles fill percentage changes.
     *
     * @param {*} event The event.
     */
    onChangeFillPercent = (event) =>
    {
        // save the value
        this.state.fillPercent = event.target.value;
    }


    /**
     * This method fills the sales percentage.
     */
    fillSalePercent()
    {
        // Get the percentage
        let percent = this.state.fillPercent;

        // Get the % index...
        let index = percent.indexOf("%");
        if (index !== -1)
            percent = percent.substring(0, index);

        // Parse the percentage
        percent = parseFloat(percent);

        // Get it as text...
        let percentText = percent.toFixed(3);

        // Initialize the sell all flag
        let sellAll = (percent === 100) ? true : false;

        // Create the new rows
        let newRows = [ ...this.state.rows ];

        // Create the dirty rows
        let dirtyRows = { ...this.state.dirtyRows };

        // Update the rows
        for (let row of newRows)
        {
            // Skip if there is nothing to do...
            if (row.sale_percent === percentText)
                continue;

            // Mark the row as dirty
            dirtyRows[row.loan_sale_id] = true;

            // Set the percentage
            row.sale_percent = percentText;
            row.sell_100_percent = sellAll;

            // Adjust the sale amount & sale gain or loss...
            row.sale_amount = percent * row.amount / 100;
            row.sale_gain_or_loss = percent * parseFloat(row.gain_or_loss) / 100;
        }

        // Recalculate the summary
        let summary = this.calculateSummary(newRows);

        // Set the state
        this.setState({ rows: newRows, summary: summary, dirtyRows: dirtyRows });

        // Displays should most recent up to date values.
        this.api.refreshCells();
    }


    /**
     * This method calculates the summary.
     *
     * @param {*} rows The rows.
     * @returns The summary row.
     */
    calculateSummary(rows)
    {
        // Initialize the summary
        let summary =
        {
            loan_account: "Total",
            amount: 0,
            gain_or_loss: 0,
            sale_percent: 0,
            sale_amount: 0,
            sale_gain_or_loss: 0
        }

        // Calculate the summary...
        for (let row of rows)
        {
            summary.amount            += Utils.parseCurrency(row.amount);
            summary.gain_or_loss      += Utils.parseCurrency(row.gain_or_loss);
            summary.sale_percent      += parsePercentage(row.sale_percent);
            summary.sale_amount       += Utils.parseCurrency(row.sale_amount);
            summary.sale_gain_or_loss += Utils.parseCurrency(row.sale_gain_or_loss);
        }

        // Adjust for the number of rows...
        summary.sale_percent /= rows.length;

        // Get out...
        return summary;
    }


    /**
     * This method handles delete row clicks.
     */
    onClickDeleteRows()
    {
        // Initialize the rows & deleted rows
        let rows        = [];
        let deletedRows = [ ...this.state.deletedRows ];

        // Add it to the appropriate list
        for (let row of this.state.rows)
        {
            if (this.state.selectedRows.has(row.loan_sale_id))
            {
                if (row.loan_sale_id > 0)
                    deletedRows.push({ loan_sale_id: row.loan_sale_id, intraturn_id: row.intraturn_id });
            }
            else
                rows.push(row);
        }

        // Set the state
        this.setState({ rows: rows, deletedRows: deletedRows });
    }


    /**
     * This method adds the row.
     */
    addRow = () =>
    {
        // Get the added row count
        let addedRowCount = this.state.addedRowCount + 1;

        // Create the new row
        let newRow = 
        { 
            loan_sale_id: -addedRowCount,
            intraturn_id: this.state.intraturnId,
            sale_percent: 0,
            sale_amount: 0,
            sale_gain_or_loss: 0,
                
            ...this.state.modalData 
        };

        // Format the values
        newRow.amount       = Utils.parseCurrency(newRow.amount);
        newRow.gain_or_loss = Utils.parseCurrency(newRow.gain_or_loss);
        newRow.yield        = parsePercentage(newRow.yield).toFixed(3);

        // Set the rows
        let rows = [...this.state.rows, newRow];

        // Set the dirty rows
        let dirtyRows = { ...this.state.dirtyRows };
        dirtyRows[newRow.loan_sale_id] = true;

        // Hide the modal
        this.setState({ rows: rows, dirtyRows: dirtyRows, addedRowCount: addedRowCount, showAddModal: false });
    }


    /**
     * The modal data change event handler.
     *
     * @param {*} key The key.
     * @param {*} value The vale.
     */
    onChangeModalData = (key, value) =>
    {
        // Get the modal data
        let modalData = { ...this.state.modalData };

        // Set the value
        modalData[key] = value;

        // Update the state
        this.setState({ modalData: modalData});
    }


    /**
     * This method determines if the view is editable.
     *
     * @returns true if the view is editable, else false.
     */
    isEditable()
    {
        return !this.props.team.frozen && (this.state.selectedTurn === this.props.game.turns_completed + 1) ? true : false;
    }


    /**
     * This method renders the view.
     */
    renderGridView()
    {
        // Initialize the editable flag
        let editable = this.isEditable();

        // Get the loan sale types
        let loanSaleTypes = this.props.gameDetails.loan_sale_types.map(type => ( { key: type, value: type }));

        // Initialize the loan category types
        let loanCategoryTypes = [ { key: "All", value: "All" }, ...loanSaleTypes ];

        return (
            <Fragment>
                <Prompt when={ this.isDirty() } message="There are unsaved changes on this screen.  Are you sure you want to leave?" />

                <DecisionBody name="Loan Sales" noteType="Loan" game={ this.props.game } intraturnId={ this.state.intraturnId } onSave={ this.onSave } dirty={ this.isDirty() }>

                    <Row>
                        <Col xs={6} sm={6} md={4} lg={4}>
                            <TurnSelect name="turns" onChange={ this.onTurnChange } value= { this.state.selectedTurn } />
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4}>
                            <SelectControl items={ loanCategoryTypes } name="loanCategoryTypes"  onChange={ this.onLoanCategoryChange } value= { this.state.selectedLoanCategory } />
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="right col-padding-right bold col-middle-align">
                            <ScenarioName intraturnId={ this.state.intraturnId } />
                        </Col>
                    </Row>

                    <div className="grid-body">
                        <DecisionGrid 
                                rowKeyGetter={ (row) => { return row.loan_sale_id; } }
                                turn={ this.state.selectedTurn }
                                columns={ this.columns } 
                                sortColumns={ this.state.sortColumns }
                                rowData={ this.state.rows } 
                                selectedRows={ this.state.selectedRows }
                                onCellValueChanged={ this.onCellValueChanged } 
                                onSelectedRowsChange={ this.onSelectedRowsChange }
                                onSortColumnsChange={ this.onSortColumnsChange }
                                pinnedBottomRowData={[ this.state.summary ]}
                                onGridReady = { (params) => { this.api = params.api }}
                        />
                    </div>

                    <Row>
                        <Col xs={12} sm={12} md={ { span: 7, offset: 5 } } lg={ { span: 5, offset: 7 } } className="vertical-spacing right">
                            <PercentageInput value={ this.state.fillPercent } onChange={ this.onChangeFillPercent } min={0} style={{ width: "90px", display: "inline" }} className="auto-input form-control" disabled={ !editable } />
                            <Button variant="primary" onClick={ () => this.fillSalePercent() } disabled= { !editable }>Fill Sale Percent</Button>
                        </Col>
                    </Row>
                </DecisionBody>

                <Modal show={ this.state.showAddModal } onHide={() => this.setState({ showAddModal: false }) } dialogClassName="loan-sales-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>New Loan Sale</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col xs={6} sm={6} md={4} lg={4}><label>Sub Sector</label></Col>
                                <Col xs={12} sm={12} md={8} lg={8}><SelectControl items={ loanSaleTypes } name="loan_category"  onChange={ this.onChangeModalData } value= { this.state.modalData.loan_category } /></Col>
                            </Row>
                            <Row>
                                <Col xs={6} sm={6} md={4} lg={4}><label>CUSIP</label></Col>
                                <Col xs={12} sm={12} md={8} lg={8}><InputControl name="loan_account" value={ this.state.modalData.loan_account } onChange={ this.onChangeModalData } /></Col>
                            </Row>
                            <Row>
                                <Col xs={6} sm={6} md={4} lg={4}><label>Amount (000)s</label></Col>
                                <Col xs={12} sm={12} md={8} lg={8}><CurrencyControl name="amount" value={ this.state.modalData.amount } onChange={ this.onChangeModalData } /></Col>
                            </Row>
                            <Row>
                                <Col xs={6} sm={6} md={4} lg={4}><label>Estimated Gain / Loss</label></Col>
                                <Col xs={12} sm={12} md={8} lg={8}><CurrencyControl name="gain_or_loss" value={ this.state.modalData.gain_or_loss } onChange={ this.onChangeModalData } /></Col>
                            </Row>
                            <Row>
                                <Col xs={6} sm={6} md={4} lg={4}><label>Remaining Term</label></Col>
                                <Col xs={12} sm={12} md={8} lg={8}><NumberControl name="term" value={ this.state.modalData.term } onChange={ this.onChangeModalData } /></Col>
                            </Row>
                            <Row>
                                <Col xs={6} sm={6} md={4} lg={4}><label>Yield</label></Col>
                                <Col xs={12} sm={12} md={8} lg={8}><PercentageControl name="yield" value={ this.state.modalData.yield } onChange={ this.onChangeModalData } /></Col>
                            </Row>
                        </Container>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="primary" onClick={ () => this.addRow() }>Add</Button>
                        <Button variant="secondary" onClick={() => this.setState({ showAddModal: false}) }>Cancel</Button>
                    </Modal.Footer>
                </Modal>

            </Fragment>
        );
    }
}

// Export the decisions view...
export default withRouter(connect(BaseDecisionGridView.mapStateToProps)(LoanSalesView));