import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { slide as SlideMenu } from "react-burger-menu";

import { store } from "../store";
import ajax from "../utils/Ajax";
import Utils from "../utils/Utils";
import { logout } from "../../features/authentication/AuthenticationSlice";

import "./menu.css";


/**
 * This method maps the state to the properties.
 * 
 * @param {*} state The state.
 * @param {*} ownProps The own properties.
 * @returns The mapping.
 */
 const mapStateToProps = (state, ownProps) =>
 {
     let result = 
     {
         role: state.authentication.role
     };
 
     return result;
 }


/**
 * The menu component.
 */
class Menu extends Component
{
    /**
     * Object Constructor.
     * 
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the state
        this.state =
        {
            menuOpen: false
        }
    }

    /**
     * This method toggles the menu.
     * 
     * @param {*} isOpen The is-open flag.
     */
    toggleMenu = ({ isOpen }) => 
    {
        this.setState({ menuOpen: isOpen });
    };


    /**
     * This method closes the menu.
     */
    closeMenu = () =>
    {
        this.setState({ menuOpen: false });
    }


    /**
     * This method handles logout click events.
     */
    onClickLogOut = () =>
    {
        this.closeMenu();

        ajax.post("/logout", null, this.onLogOutComplete);
    }
    

    /**
     * This method handles the logout complete event.
     */
     onLogOutComplete = () =>
    {
        // Perform the logout...
        store.dispatch(logout());
    }
    
      
    /**
     * This method renders the component.
     * 
     * @returns The component.
     */
    render()
    {
        return (
            <SlideMenu noOverlay isOpen={ this.state.menuOpen } onStateChange={this.toggleMenu} pageWrapId="page-wrap" outerContainerId="outer-content-block" left="false">
                <Link to="/" className="menu-item" onClick={ this.closeMenu }>Home</Link>
                <hr/>
                {
                    (this.props.role === "Administrator") && Utils.getActiveParams().game_id ?
                            <Fragment>
                                <Link to={ "/game/" + Utils.getActiveParams().game_id + "/run-turn" } className="menu-item" onClick={ this.closeMenu }>Run Turn</Link>
                                <hr/>
                            </Fragment>
                            :
                            ""
                }
                {
                    (this.props.role === "Administrator") || (this.props.role === "Instructor") ? 
                            Utils.getActiveParams().game_id ? 
                                <Fragment>
                                    <Link to={ "/game/" + Utils.getActiveParams().game_id + "/adjustments" } className="menu-item" onClick={ this.closeMenu }>Adjustments</Link>
                                    <hr/>
                                </Fragment>
                                : ""
                    : ""
                }
                {
                    this.props.role === "Administrator" ? 
                            <Fragment>
                                <Link to="/admin/create-game" className="menu-item" onClick={ this.closeMenu }>Create New Game</Link>
                                <hr/>
                            </Fragment>
                        : ""
                }
                <Link to="/user-settings" className="menu-item">User Settings</Link>
                <hr/>
                <Link to="/login" className="menu-item" onClick={ this.onClickLogOut }>Sign Out</Link>
            </SlideMenu>
        );
    }
}

// Export the menu
export default connect(mapStateToProps)(Menu);