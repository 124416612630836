import { Component } from 'react';
import calculationHelpers from './CalculationHelpers';
import Utils from '../../../utils/Utils';
import { formatPercentage, parsePercentage } from '../../../controls/Percentage';

// One million...
const ONE_MILLION = 1000000;


/**
 * The BaseMergersAndAcquisitionsTab class.
 */
class BaseMergersAndAcquisitionsTab extends Component
{
    /**
     * This method calculates the statistics.
     */
    calculateStatistics()
    {
        // Set the teams
        let team2 = (this.props.mergersAndAcquisitionsMap && this.props.commonState.team2) ? this.props.mergersAndAcquisitionsMap[this.props.commonState.team2] : null;
        let team3 = (this.props.mergersAndAcquisitionsMap && this.props.commonState.team3) ? this.props.mergersAndAcquisitionsMap[this.props.commonState.team3] : null;

        // Create the result
        let result = {};

        // Calculate the cost savings
        result.costSavings2 = calculationHelpers.getValueInMillions(team2, "Non-Interest Expense") * parsePercentage(this.props.commonState.costSavingsPercentage2) / 100.0;
        result.costSavings3 = calculationHelpers.getValueInMillions(team3, "Non-Interest Expense") * parsePercentage(this.props.commonState.costSavingsPercentage3) / 100.0;

        // Calculate the enhancement amount
        result.enhancementAmount2 = calculationHelpers.getValueInMillions(team2, "Annualized Net Interest Income") * parsePercentage(this.props.commonState.enhancementRate2) / 100.0;
        result.enhancementAmount3 = calculationHelpers.getValueInMillions(team3, "Annualized Net Interest Income") * parsePercentage(this.props.commonState.enhancementRate3) / 100.0;

        // Calculate the fee enhancement amount
        result.feeEnhancementAmount2 = calculationHelpers.getValueInMillions(team2, "Non-Interest Income") * parsePercentage(this.props.commonState.feeEnhancementRate2) / 100.0;
        result.feeEnhancementAmount3 = calculationHelpers.getValueInMillions(team3, "Non-Interest Income") * parsePercentage(this.props.commonState.feeEnhancementRate3) / 100.0;

        // Calculate the pro forma earnings
        result.proFormaEarnings2 = calculationHelpers.getValueInMillions(team2, "Net Income after Tax") + ((result.costSavings2 + result.enhancementAmount2 + result.feeEnhancementAmount2) * (1 - calculationHelpers.TAX_RATE));
        result.proFormaEarnings3 = calculationHelpers.getValueInMillions(team3, "Net Income after Tax") + ((result.costSavings3 + result.enhancementAmount3 + result.feeEnhancementAmount3) * (1 - calculationHelpers.TAX_RATE));

        // Get out...
        return result;
    }


    /**
     * This method calculates the disclosure.
     *
     * @returns The disclosure.
     */
    calculateDisclosure()
    {
        // Calculate the statistics
        let result = this.calculateStatistics();

        // Set the teams
        let team1 = this.props.mergersAndAcquisitionsMap ? this.props.mergersAndAcquisitionsMap[this.props.team.team_id] : null;
        let team2 = (this.props.mergersAndAcquisitionsMap && this.props.commonState.team2) ? this.props.mergersAndAcquisitionsMap[this.props.commonState.team2] : null;
        let team3 = (this.props.mergersAndAcquisitionsMap && this.props.commonState.team3) ? this.props.mergersAndAcquisitionsMap[this.props.commonState.team3] : null;

        // Calculate the implied stock value
        result.impliedStockValue1 = calculationHelpers.getValue(team1, "Stock Price") + Utils.parseCurrency(this.props.commonState.stockPremiumOffered1);
        result.impliedStockValue2 = team2 ? calculationHelpers.getValue(team2, "Stock Price") + Utils.parseCurrency(this.props.commonState.stockPremiumOffered2) : 0;
        result.impliedStockValue3 = team3 ? calculationHelpers.getValue(team3, "Stock Price") + Utils.parseCurrency(this.props.commonState.stockPremiumOffered3) : 0;

        // Calculate the exchange ratio
        result.exchangeRatio2 = (team2 && Utils.parseCurrency(this.props.commonState.stockPremiumOffered2) >= 0) ? (result.impliedStockValue2 / result.impliedStockValue1) : 0;
        result.exchangeRatio3 = (team3 && Utils.parseCurrency(this.props.commonState.stockPremiumOffered3) >= 0) ? (result.impliedStockValue3 / result.impliedStockValue1) : 0;

        // Calculate the bank shares tendered
        result.bankSharesTendered2 = team2 ? Math.round(calculationHelpers.getValue(team2, "Common Shares") * result.exchangeRatio2) : 0;
        result.bankSharesTendered3 = team3 ? Math.round(calculationHelpers.getValue(team3, "Common Shares") * result.exchangeRatio3) : 0;

        // Calculate the total cash paid
        result.totalCashPaid2 = team2 ? calculationHelpers.getValue(team2, "Common Shares") * Utils.parseCurrency(this.props.commonState.cashPaid2) : 0;
        result.totalCashPaid3 = team3 ? calculationHelpers.getValue(team3, "Common Shares") * Utils.parseCurrency(this.props.commonState.cashPaid3) : 0;

        // Calculate the total value
        result.totalValue1 = calculationHelpers.getValue(team1, "Stock Price") * calculationHelpers.getValue(team1, "Common Shares");

        if (Utils.parseCurrency(this.props.commonState.stockPremiumOffered2) >= 0)
            result.totalValue2 = team2 ? calculationHelpers.getValue(team1, "Stock Price") * result.bankSharesTendered2 : 0;
        else
            result.totalValue2 = team2 ? calculationHelpers.getValue(team1, "Stock Price") *  calculationHelpers.getValue(team2, "Common Shares") : 0;

        if (Utils.parseCurrency(this.props.commonState.stockPremiumOffered3) >= 0)
            result.totalValue3 = team3 ? calculationHelpers.getValue(team1, "Stock Price") * result.bankSharesTendered3 : 0;
        else
            result.totalValue3 = team3 ? calculationHelpers.getValue(team1, "Stock Price")  * calculationHelpers.getValue(team3, "Common Shares") : 0;

        // Calculate the implied stock offer
        result.impliedStockOffer2 = result.holdingCompanyShares2 * (calculationHelpers.getValue(team2, "Stock Price") + Utils.parseCurrency(this.props.commonState.stockPremiumOffered2)) / calculationHelpers.getValue(team2, "Common Shares");
        result.impliedStockOffer3 = result.holdingCompanyShares3 * (calculationHelpers.getValue(team3, "Stock Price") + Utils.parseCurrency(this.props.commonState.stockPremiumOffered3)) / calculationHelpers.getValue(team3, "Common Shares");

        // Calculate the implied values
        result.impliedValue2 = calculationHelpers.getValue(team2, "Common Shares") * result.impliedStockOffer2;
        result.impliedValue3 = calculationHelpers.getValue(team3, "Common Shares") * result.impliedStockOffer3;
               
        // Get out...
        return result;
    }


    /**
     * This method calculates the combined level ratio.
     *
     * @param {*} team1 The first team.
     * @param {*} team2 The second team.
     * @param {*} team3 The third team.
     */
    calculateCombinedLevelRatio(team1, team2, team3)
    {
        // Calculate the equity and total assets
        let numerator = calculationHelpers.sumValues(team1, team2, team3, "Tier 1 Leverage Ratio Numerator");
        let denominator = calculationHelpers.sumValues(team1, team2, team3, "Tier 1 Leverage Ratio Denominator");

        // Return the value
        return (numerator * 100) / denominator;
    }


    /**
     * This method prints the combined level ratio.
     *
     * @param {*} team1 The first team.
     * @param {*} team2 The second team.
     * @param {*} team3 The third team.
     */
    printCombinedLevelRatio(team1, team2, team3)
    {
        // Calculate the combined level ratio
        let combinedLevelRatio = this.calculateCombinedLevelRatio(team1, team2, team3);

        // Format the percentage
        return formatPercentage(combinedLevelRatio);
    }


    /**
     * This method calculated the merged bank level ratio.
     *
     * @param {*} team1 The first team.
     * @param {*} team2 The second team.
     * @param {*} team3 The third team.
     * @returns The calculated merged bank level ratio.
     */
    calculateMergedBankLevelRatio(team1, team2, team3)
    {
        // Calcluate the equity and total assets
        let equity      = calculationHelpers.sumValues(team1, team2, team3, "Equity");
        let totalAssets = calculationHelpers.sumValues(team1, team2, team3, "Total Assets");

        // Get the holding company dividends
        let holdingCompanyDividends = Utils.parseCurrency(this.props.commonState.holdingCompanyDividends) *  ONE_MILLION;

        // get out...
        return (equity - holdingCompanyDividends) * 100 / (totalAssets - holdingCompanyDividends);
    }


    /**
     * This method prints the merged bank level ratio.
     *
     * @param {*} team1 The first team.
     * @param {*} team2 The second team.
     * @param {*} team3 The third team.
     * @return The formatted merged bank level ratio.
     */
    printMergedBankLevelRatio(team1, team2, team3)
    {
        let mergedBankLevelRatio = this.calculateMergedBankLevelRatio(team1, team2, team3);

        // Format the percentage
        return formatPercentage(mergedBankLevelRatio);
    }


    /**
     * This method handles form changes.
     * @param {*} event The event.
     */
    onFieldChange = (name, value) =>
    {
        this.props.onFieldChange(name, value);
    }


    /**
     * This method formats the number.
     *
     * @param {*} number The number.
     * @return The formatted number.
     */
    formatNumber(number)
    {
        // Leave empty if infinite...
        if (!isFinite(number))
            return "";

        // Format the string
        return number.toLocaleString("en-US");
    }


    /**
     * This method rounds the value to 3 decimals.
     *
     * @param {*} value The value.
     * @returns The rounded value.
     */
    roundTo3Decimals(value)
    {
        return Math.round(value * 1000) / 1000;
    }


    /**
     * This method parses the percentage.
     *
     *- @param {*} percentage The percentage.
     * @returns The parsed percentage.
     */
    parsePercentage(percentage)
    {
        return parsePercentage(percentage) / 100.0;
    }
}

// Export the disclosure...
export default BaseMergersAndAcquisitionsTab;