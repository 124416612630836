import { createSlice } from '@reduxjs/toolkit';

// Create the initial state
const initialState = 
{
    messages: [],
    cleared: false,
    lastId: 0
};

// Create the slice
export const messageSlice = createSlice(
{
    name: 'message',
    initialState,

    // The `reducers` field lets us define reducers and generate associated actions
    reducers: 
    {
        /**
         * This method sets the message.
         * 
         * @param {*} state The state.
         * @param {*} action The action.
         */
        setMessages: (state, action) => 
        {
            // Create the message map
            let messageMap = {};

            // Build the message map
            state.messages.forEach(message => messageMap[message.message_id] = message.message);

            // Initialize the messages
            let messages = [];

            // Initialize the last ID
            let lastId = state.lastId;

            // Add the existing messages
            for (let message of state.messages)
                messages.push(message);

            // Check the existing payload
            for (let message of action.payload)
            {
                // Don't add it if it's not in the map and it's less than the last ID...
                if (messageMap[message.message_id] ||  (message.message_id <= lastId))
                    continue;

                // Add the message
                messages.push(message);

                // Update the last ID if appropriate...
                if (message.message_id > state.lastId)
                    state.lastId = message.message_id;
            }

            // Sort the messages
            messages.sort((message1, message2) => { return message1.message_id > message2.message_id ? -1 : 1 });

            // Set the updated messages
            state.messages = messages;
        },


        /**
         * This method clears the message.
         *
         * @param {*} state The state.
         * @param {*} action The action.
         */
        clearMessage: (state, action) =>
        {
            // Initialize the messages
            let messages = [];

            for (let counter = 0; counter < state.messages.length; counter++)
            {
                // Get the message
                let message = state.messages[counter];

                // Add the other messages
                if (message.message_id !== action.payload)
                    messages.push(message);
            }

            // Update the state
            state.messages = messages;
        }
    }
});

// Export the actions
export const { setMessages, clearMessage } = messageSlice.actions;

// Get out...
export default messageSlice.reducer;
