/**
 * Export the modes.
 */
export const FISIM = "FISIM";
export const CUSO = "CUSO";

/**
 * This method sets the mode.
 * 
 * @param {*} newMode The new mode. 
 */
export function setMode(newMode)
{
    mode.mode = newMode;
}


/**
 * This method gets the mode.
 * 
 * @returns The mode.
 */
export function getMode()
{
    return mode.mode;
}

// Initialize the mode
const mode = { mode: FISIM };
