import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


/**
 * The submit what-if modal component.
 *
 * @param {*} props The properties.
 * @returns The rendered component.
 */
const SubmitWhatIfModal = (props) =>
{
    return (
        <Modal show={ props.visible } onHide={() => props.onHide() } dialogClassName="submit-decision-modal">
            <Modal.Header closeButton>
                <Modal.Title>Submit Forecast</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            You have just selected to submit a forecast. This may take several minutes, press Continue to run the forecast, or press Cancel to return to the Main Screen.
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={() => props.onSubmitWhatIf() }>Continue</Button>
                <Button variant="secondary" onClick={() => props.onHide() }>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
}

// Export the submit what-if modal
export default SubmitWhatIfModal;