import { Component, Fragment, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import TurnChangesModal from '../TurnChangesModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodeCompare } from '@fortawesome/free-solid-svg-icons';

/**
 * The submit button renderer class.
 */
export class SubmitButtonFormatter extends Component
{
    /**
     * The on change event handler.
     *
     * @param {*} value The value.
     */
    onChange = () =>
    {
        // Grab the column ID
        let colId = this.props.column.colId;

        // Set the data value
        this.props.node.setDataValue(colId, "submit");
    }
 
    /**
     * This method renders the component.
     *
     * @returns The JSX.
     */
    render()
    {
        return <Button className="grid-button" onClick={ () => this.onChange() }  disabled={ this.props.disabled }><i class="fa fa-youtube-play"></i></Button>;
    }
}

/**
 * The changes button renderer class.
 */
export class ChangesButtonFormatter extends Component
{
    /**
     * The on change event handler.
     *
     * @param {*} value The value.
     */
    onChange = () =>
    {
        // Grab the column ID
        let colId = this.props.column.colId;

        // Set the data value
        this.props.node.setDataValue(colId, this.props.data[colId] ? this.props.data[colId] + 1 : 1);
    }
 
    /**
     * This method renders the component.
     *
     * @returns The JSX.
     */
    render()
    {
        return <Button className="grid-button" onClick={ () => this.onChange() }><FontAwesomeIcon title="Compare" icon={ faCodeCompare }></FontAwesomeIcon></Button>;
    }
}


/**
 * The columns.
 */
const COLUMNS =
[
    { 
        field: 'intraturn_num', 
        headerName: 'Decision Set',
        width: 90,
        minWidth: 90
    },

    { 
        field: 'name', 
        headerName: 'Scenario',
        width: 110,
        minWidth: 110
    },

    { 
        field: 'time_run',
        headerName: 'Time Run',
        width: 100,
        minWidth: 100
    },

    { 
        field: 'time_submit', 
        headerName: 'Time Submit', 
        width: 90,
        minWidth: 100
    },

    {
        field: 'changes',
        headerName: 'Changes',
        width: 75,
        minWidth: 75,
        cellRenderer: ChangesButtonFormatter
    },

    {
        field: 'submit',
        headerName: 'Submit',
        width: 75,
        minWidth: 75,
        cellRenderer: SubmitButtonFormatter
    },

    {
        field: 'last_submitted',
        headerName: 'Last Submitted',
        width: 100,
        minWidth: 100
    },
];


/**
 * The cell change event handler.
 * 
 * @param {*} params The parameters.
 * @param {*} props The properties.
 * @param {*} setShowTurnChanges The set show turn changes method.
 * @param {*} setIntraturnId The set intraturn ID method.
 */
function onCellChange(params, props, setShowTurnChanges, setIntraturnId)
{
    switch (params.colDef.field)
    {
        case "changes":
            setIntraturnId(params.data.intraturn_id);
            setShowTurnChanges(true);
            break;

        case "submit":
            submitTurn(params, props.onSubmitDecision);
            break;
    }
}


/**
 * The submit turn method.
 * 
 * @param {*} params The parameters.
 * @param {*} submitFn The submit function.
 */
function submitTurn(params, submitFn)
{
    submitFn(params.data);
}


/**
 * The submit decision modal component.
 *
 * @param {*} props The properties.
 * @returns The rendered component.
 */
const SubmitDecisionModal = (props) =>
{
    // Create the state properties
    let [showTurnChanges, setShowTurnChanges] = useState(false);
    let [intraturnId, setIntraturnId] = useState(0);

    return (
        <Fragment>
            <TurnChangesModal visible={ props.visible && showTurnChanges } onClose={ () => { setShowTurnChanges(false); } } intraturnId={ intraturnId } />

            <Modal show={ props.visible } onHide={() => props.onHide() } dialogClassName="submit-decision-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Submit Decision</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <div className = "ag-theme-alpine" style= {{height: '250px', width: '100%', minHeight: "200px" }}>
                                    <AgGridReact onGridReady = { (params) => { params.api.sizeColumnsToFit();  } } columnDefs = { COLUMNS } rowData = { props.intraturns } onCellValueChanged={ (params) => { onCellChange(params, props, setShowTurnChanges, setIntraturnId); }} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.onHide() }>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}

// Export the submit decision modal
export default SubmitDecisionModal;