import { Fragment } from 'react';
import { withRouter, Prompt } from "react-router";
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import BaseDecisionGridView from "./BaseDecisionGridView";
import { DDA_COLUMNS, NMD_COLUMNS, LITE_NMD_COLUMNS } from "./grid/NMDColumns";
import DecisionGrid from "./components/DecisionGrid";
import TurnSelect from "./components/TurnSelect";
import ScenarioName from "./components/ScenarioName";

import DecisionBody from "./DecisionBody";
import gameService from "../../../services/GameService";
import intraturnService from "../../../services/IntraturnService";
import SelectControl from '../../../controls/form/SelectControl';
import NumberControl from '../../../controls/form/NumberControl';
import { parsePercentage } from '../../../controls/Percentage';
import Utils from "../../../utils/Utils";


/**
 * The NMDView class.
 */
class NMDView extends BaseDecisionGridView
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the loaded flag
        this.loaded = false;

        // Initialize the state
        this.state =
        {
            rows: [],
            dirtyRows: {},
            sortColumns: [],
            selectedNMDType: "DDA",
            selectedTurn: -1,
            basisPoints: 1
        };
    }

    /**
     * This method loads the NMDs.
     *
     * @param turn The turn.
     * @param nmdType The nmd type.
     */
    loadNMDs(turn, nmdType)
    {
        // Get out if the state is loaded
        if (!this.props.game)
            return;

        // Only load once...
        if (this.loadingNMDs)
            return;

        // Get out if nothing to do...
        if ((this.state.selectedTurn === turn) && (this.state.selectedNMDType === nmdType))
            return;

        // Warn the user if there are changes...
        if (this.isDirty())
        {
            if (!window.confirm("You have unsaved changes.  Do you wish to proceed?"))
                return;
        }

        // Mark us as loading...
        this.loadingNMDs = true;

        // Load the loan administrations
        gameService.loadNMDs(this.props.match.params.team_id, turn, nmdType, this.onNMDsLoaded);
    }


    /**
     * The NMDs loaded event handler.
     *
     * @param {*} nmds The NMDs.
     * @param {*} lastUpdated The last updated time.
     * @param {*} turn The turn.
     * @param {*} nmdType The nmd type.
     */
    onNMDsLoaded = (nmds, lastUpdated, turn, nmdType) =>
    {
        // Make sure we have something...
        if (!nmds)
            nmds = [];

        // Initialize the state
        let state =
        { 
            rows: nmds, 
            selectedTurn: turn, 
            selectedNMDType: nmdType,
            lastUpdated: lastUpdated
        }

        // Set the intraturn Id if appropriate
        if (nmds.length > 0)
            state.intraturnId = nmds[0].intraturn_id;

        // Set the state
        this.setState(state);

        // Clear the dirty rows
        this.clearDirtyRows();

        // Reset the loading NMDs flag
        this.loadingNMDs = false;

        // Set the loaded flag
        this.loaded = true;
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Call mom...
        let result = super.isComponentReady();

        // Load the NMDs if necessary...
        if (this.props.game && !this.loaded)
        {
            // Load the loan administrations
            this.loadNMDs(this.props.game.turns_completed + 1, this.state.selectedNMDType);

            // Not ready...
            return false;
        }

        // Get out...
        return result;
    }


    /**
     * This method updates the state when the cell value has been modified.
     *
     * @param {*} params The params.
     */
    onCellValueChanged = (params) =>
    {
        // Get the dirty rows map...
        let dirtyRows = this.state.dirtyRows;

        // Mark it dirty
        dirtyRows[params.data.nmd_id] = true;

        // Parse lag
        params.data.lag = parseInt(params.data.lag);

        // Save the state
        this.setState(
        {
            dirtyRows: dirtyRows
        });

        // Displays should most recent up to date values.
        params.api.refreshCells();
    }

    /**
     * This method handles view saves.
     */
    onSave = () =>
    {
        // Get out if this is not actually dirty (shouldn't happen)
        if (!this.isDirty())
            return;
        
        // Update the dirty rows
        this.updateDirtyRows(false);
    }


    /**
     * This method updates the row.
     *
     * @param {*} modifiedBy The modified by user.
     * @param {*} lastUpdated The last updated time.
     * @param {*} force The force flag.
     */
    updateDirtyRows(force)
    {
        // Initialize the updated rows
        let updatedRows = [];

        // Check each row...
        for (let row of this.state.rows)
        {
            // See if it is dirty...
            if (!this.state.dirtyRows[row.nmd_id])
                continue;

            // Convert fees and balances
            row.monthly_fee         =  Utils.parseCurrency(row.monthly_fee);
            row.nsf_fee             =  Utils.parseCurrency(row.nsf_fee);
            row.minimum_balance     =  Utils.parseCurrency(row.minimum_balance);

            // Converts rates
            row.beta         = parsePercentage(row.beta).toFixed(3);
            row.beta_down    = parsePercentage(row.beta_down).toFixed(3);
            row.rate         = parsePercentage(row.rate).toFixed(3);

            // Create a new row
            let updatedRow = { ...row };

            // Push the updated row
            updatedRows.push(updatedRow);
        }

        // Update the NMDs
        intraturnService.updateNMDs(this.state.intraturnId, updatedRows, this.state.lastUpdated, force, this.onSaveComplete);

        // Get out...
        return true;
    }


    /**
     * The save complete event handler.
     */
    onSaveComplete = ({ modifiedBy, lastUpdated }) =>
    {
        // See if it was modified since we loaded the data...
        if (modifiedBy)
        {
            // See if the user wants to force the matter...
            if (!window.confirm("Non-Maturity Deposits were modified by " + modifiedBy + " at " + new Date(lastUpdated).toLocaleTimeString() + ".\n\nWould you like save your changes anyway?"))
                return;

            // Force the update
            this.updateDirtyRows(true);

            // Get out...
            return;
        }

        // Update the state
        this.setState({ lastUpdated: lastUpdated });

        // Clear the dirty flag
        this.clearDirtyRows();

        // let the user know
        alert("Saved.");
    }


    /**
     * This method clears the dirty rows.
     */
    clearDirtyRows()
    {
        // Reset the dirty IDs
        this.setState(
        {
            dirtyRows: {}
        });
    }


    /**
     * This method handles turn changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onTurnChange = (name, value) =>
    {
        // Load the NMDs
        this.loadNMDs(parseInt(value), this.state.selectedNMDType);
    }


    /**
     * This method handles NMD type changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onNMDTypeChange = (name, value) =>
    {
        // Load the NMDs
        this.loadNMDs(this.state.selectedTurn, value);
    }


    /**
     * This method handles field changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onFieldChange = (name, value) =>
    {
        // Get out if no change...
        if ((this.state[name] === value) || (!this.state[name] && !value))
            return;

        // Set the name/value pair...
        let state = {};
        state[name] = value;

        // Set the state
        this.setState(state);
    }


     /**
     * This method increments the basis points.
     *
     * @param {*} direction The direction.
     */
    incrementBasisPoints(direction)
    {
        // Create the delta
        let delta = direction * parseInt(this.state.basisPoints) / 100.0;

        // Get out if nothing to do...
        if (delta === 0)
            return;

        // Create the new rows
        let newRows = [ ...this.state.rows ];

        // Create the new dirty rows
        let dirtyRows = { };

        // Update each row
        for (let row of newRows)
        {
            // Update the basis points
            this.updateBasisPoints(row, delta);

            // Mark it as dirty
            dirtyRows[row.nmd_id] = true;
        }

        // Set the state
        this.setState({ rows: newRows, dirtyRows: dirtyRows });

        // Refresh the cells
        this.api.refreshCells();
    }


    /**
     * This method updates the basis points for the row.
     *
     * @param {*} row The row.
     * @param {*} delta The delta.
     */
    updateBasisPoints(row, delta)
    {
        // Update the rates
        row.rate = parsePercentage(row.rate) + delta;

        // Limit the values...
        if (row.rate < .001)
            row.rate = .001
    }


    /**
     * This method determines if the view is editable.
     *
     * @returns true if the view is editable, else false.
     */
    isEditable()
    {
        return !this.props.team.frozen && (this.state.selectedTurn === this.props.game.turns_completed + 1) ? true : false;
    }


   /**
     * This method renders the view.
     */
    renderGridView()
    {
        // Initialize the nmd types
        let nmdTypes = this.props.gameDetails.nmd_types.map(type => ( { key: type, value: type }));

        // Determine if we are editable
        let editable = this.isEditable();

        return (
            <Fragment>
                <Prompt when={ this.isDirty() } message="There are unsaved changes on this screen.  Are you sure you want to leave?" />

                <DecisionBody name="Non-Maturity Deposits" noteType="D&CD" game={ this.props.game } intraturnId={ this.state.intraturnId } onSave={ this.onSave } dirty={ this.isDirty() }>

                    <Row>
                        <Col xs={6} sm={6} md={4} lg={4}>
                            <TurnSelect name="turns" onChange={ this.onTurnChange } value= { this.state.selectedTurn } />
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4}>
                            <SelectControl items={ nmdTypes } name="loanTypes"  onChange={ this.onNMDTypeChange } value= { this.state.selectedNMDType } />
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="right col-padding-right bold col-middle-align">
                            <ScenarioName intraturnId={ this.state.intraturnId } />
                        </Col>
                    </Row>

                    <div class="grid-body">
                        <DecisionGrid 
                                ref={ (dataGrid) => { this.dataGrid = dataGrid; }} 
                                turn={ this.state.selectedTurn }
                                columns={ this.state.selectedNMDType === "DDA" ? DDA_COLUMNS : (this.props.game.type === "Lite" ? LITE_NMD_COLUMNS : NMD_COLUMNS)  } 
                                sortColumns={ this.state.sortColumns }
                                rowData={ this.state.rows } 
                                onCellValueChanged={ this.onCellValueChanged } 
                                onSortColumnsChange={ this.onSortColumnsChange }
                                onGridReady = { (params) => { this.api = params.api }}
                                minColumnWidth={120}
                        />
                    </div>

                    {
                        this.state.selectedNMDType !== "DDA" ?
                            <Row>
                                <Col xs={12} sm={12} md={ { span: 6, offset: 6 } } lg={ { span: 6, offset: 6 } } className="vertical-spacing right">
                                    <Button variant="primary" onClick={ () => this.incrementBasisPoints(-1) } disabled={ !editable }>- BP</Button>
                                    <NumberControl name="basisPoints" value={ this.state.basisPoints } min={1} onChange={ this.onFieldChange } style={{ width: "75px", display: "inline" }} className="auto-input form-control" disabled={ !editable } />
                                    <Button variant="primary" onClick={ () => this.incrementBasisPoints(1) } disabled={ !editable }>+ BP</Button>
                                </Col>
                            </Row>
                        : ""
                    }
                </DecisionBody>
            </Fragment>
        );
    }
}

// Export the decisions view...
export default withRouter(connect(BaseDecisionGridView.mapStateToProps)(NMDView));