import { currencyRenderer, percentageRenderer } from "../../../../controls/grid/GridRenderers";
import { CurrencyCellEditor } from "../../../../controls/grid/GridEditors";
import SortFunctions from "../../../../controls/grid/SortFunctions";

/**
 * The columns.
 */
const COLUMNS =
[
    { 
        field: 'description', 
        headerName: 'Description',
        minWidth: 265,
        comparator: SortFunctions.alphaNumeric,
    },

    { 
        field: 'yield', 
        headerName: 'Yield', 
        minWidth: 80,
        cellRenderer: percentageRenderer,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'amount', 
        headerName: 'Amount (000)s', 
        cellEditor: CurrencyCellEditor,
        maxValue: 5000,
        minWidth: 150,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric
    },
];


export default COLUMNS;