import {CheckBoxRenderer,  DisabledCheckBoxRenderer, currencyRenderer, percentageRenderer } from "../../../../controls/grid/GridRenderers";
import { PercentageCellEditor } from "../../../../controls/grid/GridEditors";
import SortFunctions from "../../../../controls/grid/SortFunctions";

/**
 * The columns.
 */
const COLUMNS =
[
    { 
        field: 'invest_account', 
        headerName: 'Invest Account',
        minWidth: 260,
        comparator: SortFunctions.alphaNumeric,
    },

    { 
        field: 'cusip', 
        headerName: 'CUSIP',
        minWidth: 180,
        comparator: SortFunctions.alphaNumeric
    },

    { 
        field: 'amount', 
        headerName: 'Amount', 
        minWidth: 100,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'gain_or_loss', 
        headerName: 'Estimated Gain / Loss', 
        minWidth: 175,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'term', 
        headerName: 'Remaining Term', 
        minWidth: 140,
        comparator: SortFunctions.numeric
    },

    {
        field: 'yield',
        headerName: 'Yield',
        minWidth: 80,
        cellRenderer: percentageRenderer,
        comparator: SortFunctions.numeric
    },

    {
        field: 'sale_percent',
        headerName: 'Sale Percent',
        minWidth: 120,
        cellRenderer: percentageRenderer,
        comparator: SortFunctions.numeric,
        cellEditor: PercentageCellEditor
    },
    
    { 
        field: 'sale_amount', 
        headerName: 'Sale Amount', 
        minWidth: 150,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'sale_gain_or_loss', 
        headerName: 'Sale Gain/Loss Amount', 
        minWidth: 200,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'sell_100_percent', 
        headerName: 'Sell 100%',
        width: 100,
        minWidth: 100,
        cellClass: 'center',
        cellRenderer: DisabledCheckBoxRenderer,
        editableCellRenderer: CheckBoxRenderer
    }
];

export default COLUMNS;