import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import Select from 'react-select';

import Currency from "../Currency";
import Percentage from "../Percentage";

/**
 * The checkbox renderer.
 */
export class CheckBoxRenderer extends Component 
{
    /**
     * The checked handler.
     */
    checkedHandler = (event) => 
    {
        // Determine if it is checked
        let checked = event.target.checked;

        // Grab the column ID
        let colId = this.props.column.colId;

        // Set the data value
        this.props.node.setDataValue(colId, checked);
    }

    /**
     * The render method.
     *
     * @returns The rendered component.
     */
    render() 
    {
        if(!this.props.node.rowPinned)
            return (
                <input type="checkbox" onClick={ this.checkedHandler } checked={ this.props.value } />
            );
        else
            return " ";
    }
}

/**
 * The disabled checkbox renderer.
 *
 * @param {*} props The properties.
 * @returns The disabled checkbox formatter.
 */
export const DisabledCheckBoxRenderer = (params) => 
{
    return (
        <input type="checkbox" onClick={ () => {} } disabled={ true } checked={ params.value } />
    );
}


/**
 * The button renderer class.
 */
export class ButtonRenderer extends Component
{
    /**
     * The on change event handler.
     *
     * @param {*} value The value.
     */
    onChange = () =>
    {
        // Grab the column ID
        let colId = this.props.column.colId;

        // Set the data value
        this.props.node.setDataValue(colId, "click");
    }

 
    /**
     * This method renders the component.
     *
     * @returns The JSX.
     */
    render()
    {
       return <Button className="grid-button" onClick={ this.onChange } >{ this.props.value }</Button>;
    }
}

/**
 * The disabled button renderer.
 *
 * @returns The disabled button renderer.
 */
export const DisabledButtonRenderer = function(props)
{
    return <ButtonRenderer disabled={ true } { ...props } />;
}


/**
 * The MultiSelect renderer class.
 */
 export class MultiSelectRenderer extends Component
 {
    /**
    * The on change event handler.
    *
    * @param {*} value The value.
    */
    onChange = (value) =>
    {
        if (this.props.value === value)
            return;

        // Grab the column ID
        let colId = this.props.column.colId;
       
        // Set the data value
        this.props.node.setDataValue(colId, value.map(role => role.value).join(","));
    }


    /**
     * This function finds the value.
     * 
     * @param {*} values The values.
     * @return The value/label pair.
     */
    findValue(values)
    {
        // Get out if nothing to do...
        if (!values)
            return [];

        // Initialize the map
        let itemMap = {};

        // Populate the map
        this.props.options.forEach(item => itemMap[item.value] = item);

        // Get the array of values
        values = values.split(",");

        // Initialize the result
        let result = [];

        // For each item
        for (let value of values)
        {
            // Get it from the map
            let item = itemMap[value];

            // If not found, skip...
            if (!item)
                continue;

            // Add it to the list
            result.push(item);
        }

        // Get out...
        return result;
    }

      
    /**
     * This method renders the component.
     *
     * @returns The JSX.
    */
    render()
    {
        // Get the selected value
        let selectedValue = this.findValue(this.props.value);

        const customStyles = {
            control: styles => ({ ...styles,                 
            width: '700px',
            right: '10px'
            }),
            option: styles => ({ ...styles,    
         
            }),
            menu: styles => ({ ...styles,                 

            })                 

        };
        return <Select 
            options={this.props.options} 
            isMulti={true}
            onChange={ this.onChange } 
            menuPortalTarget={ document.body }  
            value={ selectedValue }
            styles={customStyles}
        />;
    }
}


/**
 * The number renderer.
 *
 * @param {*} props The properties.
 */
export const NumberRenderer = function(props)
{
    // Get the value...
    let value = props.value.toString();

    // Strip trailing zeros after decimal points...
    let periodIndex = value.indexOf(".");

    if (periodIndex >= 0)
    {
        // Remove all trailing spaces (and the period if need be...)
        for (let counter = value.length - 1; counter >= periodIndex; counter--)
        {
            // Get out if not a zero or period...
            if ((value[counter] !== "0") && (value[counter] !== "."))
                break;

            // Trim the string
            value = value.substring(0, value.length - 1);
        }
    }

    // Get out if nothing to do...
    if (value === "")
        value = 0;


    // Get the last digit
    let lastDigit = (periodIndex >= 0) ? periodIndex : value.length;

    // Add the commas...
    while (lastDigit > 3)
    {
        // Move the last digit
        lastDigit -= 3;

        // Create the value
        value = value.substring(0, lastDigit) + "," + value.substring(lastDigit);
    }

    // Handle additional properties...
    let additionalProps = {};

    // Handle read-only rows...
    if (props.column.cellEditor && (!props.column.readOnlyProperty || !props.value.readOnlyProperty))
        additionalProps.className = "editable-cell";
    else
        additionalProps.className = "read-only-cell";

    return <div { ...additionalProps }>{ value }</div>;
}
 
/**
 * The currency renderer.
 *
 * @param {*} props The properties.
 */
 export const currencyRenderer= function(props)
 {
     let additionalProps = {};
 
     // Handle read-only rows...
     if (props.column.cellEditor && (!props.column.readOnlyProperty || !props.value.readOnlyProperty))
         additionalProps.className = "editable-cell";
     else
         additionalProps.className = "read-only-cell";
   
    // Handle Editability 
    props.column.userProvidedColDef.editable = (o) => !o.node.isRowPinned();
   
    // Handle Summary Rows and Prevent rendering on cells that don't need it
    if (props.node.rowPinned)
    {
        // Summary cell initialized to nothing
        if (props.value == "" || props.value === undefined)
            return "";
    }
       
    return <div { ...additionalProps }><Currency value={props.value} /></div>;
 }
 
 
 /**
  * The percentage renderer.
  */
 export const percentageRenderer= function(props) 
 {
     let additionalProps = {};
 
     // Handle read-only rows...
     if (props.column.cellEditor && (!props.column.userProvidedColDef.readOnlyProperty || !props.value.readOnlyProperty))
         additionalProps.className = "editable-cell";
     else
         additionalProps.className = "read-only-cell";

    // Handle Editability 
    props.column.userProvidedColDef.editable = (o) => !o.node.isRowPinned();
   
    // Handle Summary Rows and Prevent rendering on cells that don't need it
    if (props.node.rowPinned)
    {
        // Summary cell initialized to nothing
        if (props.value == "" || props.value === undefined)
            return "";
    }
       
    return <div { ...additionalProps }><Percentage value={props.value} /></div>;
 }