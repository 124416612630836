import SortFunctions from "../../../../controls/grid/SortFunctions";
import { dateTimeFormatter } from "../../../../controls/grid/GridFormatters";
import DownloadReportLink from "../components/DownloadReportLink";

/**
 * The columns.
 */
const COLUMNS =
[
    { 
        field: 'report_name', 
        headerName: 'Report Name',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        minWidth: 400,
        comparator: SortFunctions.alphaNumeric
    },

    { 
        field: 'description', 
        headerName: 'Report Description', 
        minWidth: 600,
        comparator: SortFunctions.alphaNumeric
    },

    { 
        field: 'updated_tzts', 
        headerName: 'Upload Date', 
        minWidth: 120,
        cellRenderer: dateTimeFormatter,
        comparator: SortFunctions.date
    },

    { 
        field: 'status', 
        headerName: 'Open',
        minWidth: 80,
        width: 80,
        cellRenderer: (props) => {return <DownloadReportLink report={ props.data }/>}
    }
];


export default COLUMNS;