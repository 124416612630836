import ajax from "../utils/Ajax";


/**
 * The help service class.
 */
class HelpService
{
    /**
     * This method sets the active page.
     *
     * @param {*} page The page.
     * @param {*} gameId The game ID.
     */
    setActivePage(page, gameId)
    {
        // Save the page & game ID
        this.page   = page;
        this.gameId = gameId;
    }


    /**
     * This method gets the active page.
     *
     * @return {*} page The page.
     */
    getActivePage()
    {
        // Get the page
        return this.page;
    }



    /**
     * This method loads the help.
     *
     * @param {*} onHelpLoaded The help loaded event handler.
     */
    load(onHelpLoaded)
    {
        // Load the help
        ajax.post("/help", { page: this.page, gameId: this.gameId }, onHelpLoaded, "Loading Help...", "An error occurred while loading the help.");
    }



    /**
     * This method saves the text.
     *
     * @param {*} text The text.
     * @param {*} onSaveComplete The save complete event handler.
     */
    save(text, onSaveComplete)
    {
        // Load the help
        ajax.post("/help/save", { page: this.page, gameId: this.gameId, text: text }, onSaveComplete, "Saving Help...", "An error occurred while saving the help.");
    }
}

// Export the help service
export default new HelpService();