import { Fragment } from 'react';
import { withRouter, Prompt } from "react-router";
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import BaseDecisionGridView from "./BaseDecisionGridView";
import { IT_COLUMNS, IT_MAINTENANCE_COLUMNS } from "./grid/ITColumns";
import TurnSelect from "./components/TurnSelect";
import DecisionGrid from "./components/DecisionGrid";

import gameService from "../../../services/GameService";
import intraturnService from "../../../services/IntraturnService";
import BDMViewTemplate from './BDMViewTemplate';


/**
 * The ITView class.
 */
class ITView extends BaseDecisionGridView
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the loaded flag
        this.loaded = false;

        // Initialize the state
        this.state =
        {
            itRows: [],
            itMaintenanceRows: [],
            dirtyRows: {},
            sortColumns: [],
            maintenanceSortColumns: [],
            selectedTurn: -1
        };
    }


    /**
     * This method loads the IT.
     *
     * @param turn The turn.
     */
    loadIT(turn)
    {
        // Get out if the state is loaded
        if (!this.props.game)
            return;

        // Only load once...
        if (this.loadingIT)
            return;

        // Get out if nothing to do...
        if (this.state.selectedTurn === turn)
            return;

        // Warn the user if there are changes...
        if (this.isDirty())
        {
            if (!window.confirm("You have unsaved changes.  Do you wish to proceed?"))
                return;
        }

        // Mark us as loading...
        this.loadingIT = true;

        // Load the IT
        gameService.loadGeneralAdministration(this.props.match.params.team_id, turn, "IT", this.onITLoaded);
    }


    /**
     * The IT loaded event handler.
     *
     * @param {*} it The IT records.
     * @param {*} maintenanceIT The maintenance records.
     * @param {*} lastUpdated The last updated time.
     * @param {*} turn The turn.
     */
    onITLoaded = (it, maintenanceIT, lastUpdated, turn) =>
    {
        // Make sure we have something...
        if (!it)
            it = [];

        // Make sure we have something...
        if (!maintenanceIT)
            maintenanceIT = [];

        // Determine if the spend is being made
        for (let row of it)
        {
            if (parseFloat(row.amount) > 0)
                row.spend = true;
            else
                row.spend = false;
        }

        // Initialize the state
        let state =
        { 
            itRows: it,
            maintenanceITRows: maintenanceIT,
            selectedTurn: turn,
            lastUpdated: lastUpdated
        }

        // Set the intraturn Id if appropriate
        if (it.length > 0)
            state.intraturnId = it[0].intraturn_id;
        else
        if (maintenanceIT.length > 0)
            state.intraturnId = maintenanceIT[0].intraturn_id;

        // Set the state
        this.setState(state);

        // Clear the dirty rows
        this.clearDirtyRows();

        // Reset the loading IT flag
        this.loadingIT = false;

        // Set the loaded flag
        this.loaded = true;
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Call mom...
        let result = super.isComponentReady();

        // Load the facilities if necessary...
        if (this.props.game && !this.loaded)
        {
            // Load the IT
            this.loadIT(this.props.game.turns_completed + 1);

            // Not ready...
            return false;
        }

        // Get out...
        return result;
    }


    /**
     * This method updates the state when an IT row has been modified.
     *
     * @param {*} rows The rows.
     * @param {*} data The data.
     */
    setITRows = (params) =>
    {
        // Get the dirty rows map...
        let dirtyRows = this.state.dirtyRows;

        dirtyRows[params.data.gen_admin_id] = true;

        // Determine if a value is actually changing...
        if (params.data.spend)
        {
            // Set the amount...
            params.data.amount = params.data.proposed_spending;
        }
        else
        {
            // Clear the amount
            params.data.amount = 0;
        }

        // Mark the row as dirty
        dirtyRows[params.data.gen_admin_id] = true;

        // Save the state
        this.setState(
        {
            itRows: this.state.itRows,
            dirtyRows: dirtyRows
        });
    }


    /**
     * This method handles view saves.
     */
    onSave = () =>
    {
        // Get out if this is not actually dirty (shouldn't happen)
        if (!this.isDirty())
            return;

        // Handle the save...
        this.updateDirtyRows(false);
    }


    /**
     * This method updates the dirty rows.
     *
     * @param force The force flag.
     * @return true if there are dirty rows, else false...
     */
    updateDirtyRows(force)
    {
        // Get out if nothing to do...
        if (Object.keys(this.state.dirtyRows).length === 0)
            return false;

        // Initialize the updated rows
        let updatedRows = [];

        // Check each row...
        for (let row of this.state.itRows)
        {
            // See if it is dirty...
            if (!this.state.dirtyRows[row.gen_admin_id])
                continue;

            // Push the updated row
            updatedRows.push(row);
        }

        // Update the IT
        intraturnService.updateIT(this.state.intraturnId, updatedRows, this.state.lastUpdated, force, this.onSaveComplete);

        // Get out...
        return true;
    }


    /**
     * The save complete event handler.
     *
     * @param {*} modifiedBy The modified by user.
     * @param {*} lastUpdated The last updated time.
     */
    onSaveComplete = ({ modifiedBy, lastUpdated }) =>
    {
        // See if it was modified since we loaded the data...
        if (modifiedBy)
        {
            // See if the user wants to force the matter...
            if (!window.confirm("General Administration records have modified by " + modifiedBy + " at " + new Date(lastUpdated).toLocaleTimeString() + ".\n\nWould you like save your changes anyway?"))
                return;

            // Force the update
            this.updateDirtyRows(true);

            // Get out...
            return;
        }

        // Update the state
        this.setState({ lastUpdated: lastUpdated });

        // Clear the dirty flag
        this.clearDirtyRows();

        // let the user know
        alert("Saved.");
    }


    /**
     * This method clears the dirty rows.
     */
    clearDirtyRows()
    {
        // Reset the dirty IDs
        this.setState(
        {
            dirtyRows: {}
        });
    }


    /**
     * This method handles turn changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onTurnChange = (name, value) =>
    {
        // Load the IT
        this.loadIT(parseInt(value));
    }


     /**
     * This method handles sort column changes.
     *
     * @param {*} sortColumns The sorted columns.
     */
    onSortColumnsChange = (sortColumns) =>
    {
        // Sort the rows
        let rows = this.sortRowData(this.state.itRows, IT_COLUMNS, sortColumns);

        // Update the state
        this.setState(
        {
            itRows: rows,
            sortColumns: sortColumns
        })
    }


     /**
     * This method handles sort column changes.
     *
     * @param {*} sortColumns The sorted columns.
     */
    onMaintenanceSortColumnsChange = (sortColumns) =>
    {
        // Sort the rows
        let rows = this.sortRowData(this.state.maintenanceITRows, IT_MAINTENANCE_COLUMNS, sortColumns);

        // Update the state
        this.setState(
        {
            maintenanceITRows: rows,
            maintenanceSortColumns: sortColumns
        })
    }




    /**
     * This method determines if the state is dirty.
     *
     * @returns true if the state is dirty, else false.
     */
    isDirty()
    {
        // Are we dirty?
        if (this.hasDirtyFields() || (Object.keys(this.state.dirtyRows).length > 0))
            return true;

        return false;
    }



   /**
     * This method renders the view.
     */
    renderGridView()
    {
        return (
            <Fragment>
                <Prompt when={ this.isDirty() } message="There are unsaved changes on this screen.  Are you sure you want to leave?" />

                <BDMViewTemplate name="IT" game={ this.props.game } onSave={ this.onSave } dirty={ this.isDirty() } intraturnId={ this.state.intraturnId } >

                    <Row>
                        <Col xs={6} sm={6} md={4} lg={4}>
                            <TurnSelect name="turns" onChange={ this.onTurnChange } value= { this.state.selectedTurn } />
                        </Col>
                    </Row>

                    <div class="grid-body it">
                        <DecisionGrid 
                                columns={ IT_COLUMNS } 
                                turn={ this.state.selectedTurn }
                                sortColumns={ this.state.sortColumns }
                                rowData={ this.state.itRows } 
                                onCellValueChanged={ this.setITRows } 
                                onSortColumnsChange={ this.onSortColumnsChange }
                        />
                    </div>
                    <div class="grid-body it-maintenance">
                        <DecisionGrid 
                                columns={ IT_MAINTENANCE_COLUMNS } 
                                turn={ this.state.selectedTurn }
                                sortColumns={ this.state.maintenanceSortColumns }
                                rowData={ this.state.maintenanceITRows } 
                                onSortColumnsChange={ this.onMaintenanceSortColumnsChange }
                        />
                    </div>
                </BDMViewTemplate>
            </Fragment>
        );
    }
}

// Export the decisions view...
export default withRouter(connect(BaseDecisionGridView.mapStateToProps)(ITView));