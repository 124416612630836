import React from 'react';
import CurrencyInput from '../CurrencyInput';

/**
 * The CurrencyControl component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
class CurrencyControl extends React.Component
{
    /**
     * Object Constructor.
     * 
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Call mom...
        if ((props.value === null) && (props.value === undefined))
            props.onChange(props.name, 0);
        else
            props.onChange(props.name, props.value);

        // Create the reference
        this.inputRef = React.createRef();
    }

    /**
     * This method places focus on the component.
     */
    focus()
    {
        this.inputRef.current.focus();
    }

    /**
     * The render method.
     * 
     * @returns The object model.
     */
    render()
    {
        // Validate the fields
        if (this.props.name == null)
            throw new Error("CurrencyControl is missing name!");

        if (this.props.onChange == null)
            throw new Error("CurrencyControl is missing change event: " + this.props.name);

        // Initialize the class name
        let className = "form-control";
            
        // Add the class name
        if (this.props.className)
        {
            if (className !== "")
                className += " ";
                
            className += this.props.className;
        }

        // Clone the properties
        let newProps = { ...this.props };

        // Update the value if necessary...
        if ((this.props.value === undefined) || (this.props.value === null))
            newProps.value = 0; 

        // Delete the unwanted properties...
        delete newProps.name;
        delete newProps.className;
        delete newProps.parent;
        delete newProps.onChange;


        // Get out...
        return (
            <CurrencyInput ref={ this.inputRef} name={ this.props.name } className={ className } 
                           onFocus={ (event) => event.target.select() }
                           onChange={ (event) => this.props.onChange(event.target.name, event.target.value) } { ...newProps } />
        );
    }
}

// Get out...
export default CurrencyControl;