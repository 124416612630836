import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import InputControl from '../../../controls/form/InputControl';
import ajax from "../../../utils/Ajax";


/**
 * The send message modal component.
 *
 * @param {*} this.props The properties.
 * @returns The rendered component.
 */
class SendMessageModal extends React.Component
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the state
        this.state = 
        {
            message: ""
        }
    }


    /**
     * This method sends the message.
     */
    onSendMessage = () =>
    {
        // Make sure there is a message...
        if (this.state.message.length === 0)
        {
            alert("You must specify a message.");
            return;
        }

        // Send the message
        if (this.props.admin)
            ajax.post("/message/send/admin", { message: this.state.message }, this.onSendMessageComplete, "Sending message...", "An error occurred while sending the message.");
        else
            ajax.post("/message/send/" + this.props.gameId + "/" + this.props.teamId, { message: this.state.message }, this.onSendMessageComplete, "Sending message...", "An error occurred while sending the message.");
    }


    /**
     * This method handles the send complete event.
     */
    onSendMessageComplete = () =>
    {
        // Notify the user
        alert("The message was successfully sent.");

        // Reset the state
        this.setState({ message: "" });

        // Hide the window...
        this.props.onHide();
    }


    /**
     * This method handles field changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onFieldChange = (name, value) =>
    {
        // Get out if no change...
        if ((this.state[name] === value) || (!this.state[name] && !value))
            return;

        // Set the name/value pair...
        let state = {};
        state[name] = value;

        // Set the state
        this.setState(state);
    }


    /**
     * This method renders the component.
     *
     * @returns The rendered component.
     */
    render()
    {
        return (
            <Modal show={ this.props.visible } onHide={() => this.props.onHide() } dialogClassName="submit-decision-modal">
                <Modal.Header closeButton>
                    <Modal.Title>{ this.props.admin ? "Send Administrative Message to Everyone" : "Send Message to Team"}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xs={4} sm={4} md={2} lg={2}><label for="message">Message</label></Col>
                            <Col xs={8} sm={8} md={10} lg={10}><InputControl name="message" value={ this.state.message } onChange={ this.onFieldChange } /></Col>
                        </Row>
                    </Container>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={() => this.onSendMessage() }>Send</Button>
                    <Button variant="secondary" onClick={() => this.props.onHide() }>Cancel</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

// Export the send message modal
export default SendMessageModal;