import React from 'react';

import ajax from "../../utils/Ajax";
import Utils from "../../utils/Utils";

/**
 * The loading item.
 */
const LOADING_ITEM = { name: "Loading...", value: "", disabled: true };

/**
 * The empty list item.
 */
const EMPTY_LIST_ITEM = { name: "None Present", value: "" };

/**
 * The none-selected list item.
 */
const NONE_SELECTED_LIST_ITEM = { name: "None Selected", value: "" };


/**
 * The SelectControl class.
 */
class SelectControl extends React.Component
{
    /**
     * Object Constructor.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the statem
        this.state = {};

        // Initialize the items
        if (props.items)
        {
            // Save the items
            this.state.items = [ ...props.items ];

            // Add the none-selected item if necessary
            if ((props.required !== undefined) && !props.required)
                this.state.items.unshift(NONE_SELECTED_LIST_ITEM);
        }
        else
        {
            // Default the items
            this.state.items = [ LOADING_ITEM ];
        }   
    }


    /**
     * The component did mount event.
     */
    componentDidMount()
    {
        // Post the URL
        if (this.props.url)
            ajax.post(this.props.url, this.props.urlParms, this.onItemsLoaded, null, "An error occurred while populating form fields");
    }


    /**
     * Handle item changes.
     *
     * @param {*} prevProps The previous properties.
     */
    componentDidUpdate(prevProps)
    {
        if (!this.props.items && !prevProps.items)
            return;

        if (!prevProps.items || !Utils.arrayEquals(prevProps.items, this.props.items))
        {
            // Save the items
            let items = [ ...this.props.items ];

            // Add the none-selected item if necessary
            if ((this.props.required !== undefined) && !this.props.required)
                items.unshift(NONE_SELECTED_LIST_ITEM);

            this.setState({ items: items })
            return;
        }
    }


    /**
     * The change handler.
     * 
     * @param {*} event The event.
     */
    onChange = (event) =>
    {
        // Call the provided handler
        this.props.onChange(event.target.name, event.target.value);
    }


    /**
     * This method handles the items loaded event.
     * 
     * @param {*} response The response.
     */
    onItemsLoaded = (response) =>
    {
        // Get the items
        let items = response.items;

        // Push it onto the list
        if (items.length === 0)
            items.push(EMPTY_LIST_ITEM);
        else
        {
            // Add the none-selected item if necessary
            if (this.props.required !== true)
                items.unshift(NONE_SELECTED_LIST_ITEM);
        }

        // Get the value
        let value = this.props.value ? this.props.value : items[0].value;

        // Set the default value
        this.props.onChange(this.props.name, value === '' ? null : value);

        // Set the state
        this.setState({ items: items, itemsLoaded: true });
    }


    /**
     * This method renders the component.
     */
    render()
    {
        let newProps = { ...this.props };
        delete newProps.name;
        delete newProps.onChange;
        delete newProps.className;
        delete newProps.notAForm;

        // Determine the default class name
        let defaultClassName = (!this.props.notAForm) ? "form-select" : "";

        // Set the class name
        let className = (this.props.className ? this.props.className + " " : "") + defaultClassName;

        return (
            <select name={ this.props.name } className={ className } onChange={ this.onChange } { ...newProps }>
                {
                    this.state.items.map((e, key) =>
                    {
                        return <option key={ key } value={ e.value } disabled={ e.disabled } selected={ e.value === this.props.value } >{ e.name ? e.name : e.value }</option>;
                    })
                }
            </select>
        );
    }
}

export default SelectControl;