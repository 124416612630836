import { withRouter } from "react-router";
import { connect } from 'react-redux';

import gameService from '../../services/GameService';
import BaseDashboardView from './BaseDashboardView';


/**
 * The KPI types.
 */
export const KPI_TYPES = [ "Performance", "Regulator", "Earnings & Profitability", "Margin Analysis", "Loan & Lease Analysis", "Liquidity", "Capitalization", "Balance Sheet Ratios" ];


/**
 * The Management Ratios View class.
 */
class ManagementRatiosView extends BaseDashboardView
{
    /**
     * Object Constructor.
     * 
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom
        super(props, "Management Ratios", KPI_TYPES);

        // Initialize the loaded flag
        this.loaded= false;

        // Get the dashboard details
        if (props.game && props.team)
        {
            // Get the teanm performamce
            gameService.getTeamPerformance(props.game.game_id, props.team.team_id);

            // Set the requested flag
            this.state.requested = true;
        }
    }


    /**
    * This method maps the state to the properties.
    * 
    * @param {*} state The state.
    * @param {*} ownProps The own properties.
    * @returns The mapping.
    */
    static mapStateToProps(state, ownProps)
    {
        // Get the dashboard details
        let dashboardDetails = state.games.teamPerformanceMap[ownProps.match.params.team_id];

        // Call the base class...
        return BaseDashboardView.mapStateToProps(state, ownProps, dashboardDetails);
    }


    /**
     * This method handles component updates.
     *
     * @param {*} prevProps The previous properties.
     */
    componentDidUpdate(prevProps)
    {
        // Get out if there is nothing to do...
        if (!this.props.game || !this.props.team)
            return;

        // Get the previous team ID
        let previousTeamId = prevProps.team ? prevProps.team.team_id : null;

        // See if the team ID changed...
        if (!this.state.requested || (this.props.team.team_id !== previousTeamId))
        {
            // Get the team performance
            gameService.getTeamPerformance(this.props.game.game_id, this.props.team.team_id);

            // Update the state
            this.setState({ requested: true });
        }
    }
}

// Export the game view...
export default withRouter(connect(ManagementRatiosView.mapStateToProps)(ManagementRatiosView));