import { Fragment, Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';

import Utils from '../../../../utils/Utils';
import { COLUMNS, PREPAY_COLUMNS, TERM_ITEMS } from "../grid/BorrowingsColumns";
import DecisionGrid from "../components/DecisionGrid";
import CurrencyControl from '../../../../controls/form/CurrencyControl';
import { parsePercentage } from '../../../../controls/Percentage';

import gameService from "../../../../services/GameService";
import intraturnService from "../../../../services/IntraturnService";
import SelectControl from '../../../../controls/form/SelectControl';
import DraggableModalDialog from '../../../../controls/DraggableModalDialog';


// The default modal data...
let DEFAULT_MODAL_DATA = 
{
    amortizing_or_bullet: "Amortizing",
    fixed_or_variable: "Fixed",
    term: 24,
    maturities: 0
};



/**
 * The Borrowings control class.
 */
class BorrowingsControl extends Component
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the loaded flag
        this.loaded = false;

        // Initialize the state
        this.state =
        {
            rows: [],
            dirtyRows: {},
            sortColumns: [],
            selectedRows: new Set(),

            previousRows: [],
            previousDirtyRows: {},
            previousSortColumns: [],

            deletedRows: [],
            showAddModal: false,
            addedRowCount: 0,
            modalData: {},
        };

        // Initialize the columns
        this.columns = COLUMNS;
    }

    /**
     * This method gets the intraturn ID.
     * 
     * @returns The intraturn ID.
     */
    getIntraturnId()
    {
        return this.state.intraturnId;
    }


    /**
     * This method loads the borrowings.
     *
     * @param turn The turn.
     */
    loadBorrowings(turn)
    {
        // Get out if the state is loaded
        if (!this.props.game)
            return;

        // Only load once...
        if (this.loadingBorrowings)
            return;

        // Mark us as loading...
        this.loadingBorrowings = true;

        // Load the borrowings
        gameService.loadBorrowings(this.props.team.team_id, turn, this.onBorrowingsLoaded);
    }


    /**
     * The borrowings loaded event handler.
     *
     * @param {*} borrowings The borrowings.
     * @param {*} previousBorrowings The previous borrowings.
     * @param {*} rates The rates.
     * @param {*} lastUpdated The last updated time.
     * @param {*} turn The turn.
     * @param {*} intraturnId The intraturn ID.
     */
    onBorrowingsLoaded = (borrowings, previousBorrowings, rates, lastUpdated, turn, intraturnId) =>
    {
        // Make sure we have something...
        if (!borrowings)
            borrowings = [];

        if (!previousBorrowings)
            previousBorrowings = [];

        // Initialize the rate map
        let rateMap = {};

        // Populate the map
        for (let rate of rates)
            rateMap[rate.months] = rate.rate;


        // Initialize the state
        let state =
        { 
            rows: borrowings, 
            previousRows: previousBorrowings,
            rateMap: rateMap,
            selectedTurn: turn,
            intraturnId: intraturnId,
            lastUpdated: lastUpdated,
            summary: this.calculateSummary(borrowings),
            previousSummary: this.calculateSummary(previousBorrowings)
        }

        // Set the state
        this.setState(state);

        // Clear the dirty rows
        this.clearDirtyRows();

        // Reset the loading borrowings flag
        this.loadingBorrowings = false;

        // Set the loaded flag
        this.loaded = true;
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Load the CDs if necessary...
        if (this.props.game && !this.loaded)
        {
            // Load the borrowings
            this.loadBorrowings(this.props.game.turns_completed + 1);

            // Not ready...
            return false;
        }

        // Get out...
        return true;
    }


    /**
     * This method updates the state when the cell value has been modified.
     *
     * @param {*} params The params.
     */
    onCellValueChanged = (params) =>
    {
        // Get the dirty rows map...
        let dirtyRows = this.state.dirtyRows;

        // Update the rate
        params.data.rate = parsePercentage(this.state.rateMap[params.data.term]).toFixed(3);

        // Mark it dirty
        dirtyRows[params.data.borrowing_id] = true;

        // Calculate summary
        let summary = this.calculateSummary(this.state.rows);

        // Save the state
        this.setState(
        {
            dirtyRows: dirtyRows,
            summary: summary
        });

        // Displays should most recent up to date values.
        params.api.refreshCells();
    }
    

    /**
     * This method updates the state when a previous cell value has been modified.
     *
     * @param {*} params The params.
     */
    onPreviousCellValueChanged = (params) =>
    {
        // Get the dirty rows map...
        let previousDirtyRows = this.state.previousDirtyRows;

        // Mark it as dirty
        previousDirtyRows[params.data.borrowing_id] = true;
        
        // Set the prepay turn number
        params.data.prepay_turn_num = this.props.selectedTurn;

        // Calculate summary
        let prevSummary = this.calculateSummary(this.state.previousRows);
        
        // Save the state
        this.setState(
        {
            previousDirtyRows: previousDirtyRows,
            previousSummary: prevSummary
        });

        // Displays should most recent up to date values.
        params.api.refreshCells();
    }


    /**
     * This method handles selected row changes.
     *
     * @param {*} selectedRows The selected rows.
     */
    onSelectedRowsChange = (selectedRows) =>
    {
        if (!this.isEditable())
            return;

        this.setState({ selectedRows: selectedRows });
    }


    /**
     * This method handles view saves.
     * 
     * @param {*} callbackFn The callback function.
     */
    save(callbackFn)
    {
        // Get out if this is not actually dirty (shouldn't happen)
        if (!this.isDirty())
            return;

        // Handle the save...
        this.updateDirtyRows(false, callbackFn);
    }


    /**
     * This method determines if there are dirty fields.
     *
     * @returns true if there are dirty fields, else false.
     */
    hasDirtyFields()
    {
        if (this.state.deletedRows.length)
            return true;

        return false;
    }


    /**
     * This method updates the dirty rows.
     *
     * @param {*} force The force flag.
     * @param callbackFn The callback function.
     * @return true if there are dirty rows, else false...
     */
    updateDirtyRows(force, callbackFn)
    {
        // Get out if nothing to do...
        if ((Object.keys(this.state.dirtyRows).length === 0) && !this.state.deletedRows.length && (Object.keys(this.state.previousDirtyRows).length === 0))
            return false;

        // Initialize the added & updated rows
        let addedRows   = [];
        let updatedRows = [];

        // Check each row...
        for (let row of this.state.rows)
        {
            // See if it is dirty...
            if (!this.state.dirtyRows[row.borrowing_id])
                continue;

            // Put it in the correct bucket...
            if (row.borrowing_id < 0)
                addedRows.push(row);
            else
                updatedRows.push(row);

            // Update the rate
            row.rate = parsePercentage(this.state.rateMap[row.term]).toFixed(3);

            // Convert the amount
            row.maturities = Utils.parseCurrency(row.maturities);
        }


        // Initialize the updated previous rows
        let updatedPrevious = [];

        // Check each row...
        for (let row of this.state.previousRows)
        {
            // See if it is dirty...
            if (!this.state.previousDirtyRows[row.borrowing_id])
                continue;

            // Push the row
            updatedPrevious.push(row);
        }

        // Update borrowings CDs
        intraturnService.updateBorrowings(this.state.intraturnId, this.props.selectedTurn, 
                                          addedRows, updatedRows, this.state.deletedRows, 
                                          updatedPrevious, this.state.lastUpdated, force, this.onSaveComplete.bind(this, callbackFn));

        // Get out...
        return true;
    }


    /**
     * The save complete event handler.
     *
     * @param {*} callbackFn The callback funciton.
     * @param {*} modifiedBy The modified by user.
     * @param {*} lastUpdated The last updated time.
     * @param {*} borrowings The borrowings.
     * @param {*} previousBorrowings The previous borrowings.
     * @param {*} lastUpdated The last updated time.
     * @param {*} turn The turn.
     * @param {*} intraturnId The intraturn ID.
     */
    onSaveComplete(callbackFn, modifiedBy, lastUpdated, borrowings, previousBorrowings, rates, turn, intraturnId)
    {
        // See if it was modified since we loaded the data...
        if (modifiedBy)
        {
            // See if the user wants to force the matter...
            if (!window.confirm("Borrowing records have modified by " + modifiedBy + " at " + new Date(lastUpdated).toLocaleTimeString() + ".\n\nWould you like save your changes anyway?"))
                return;

            // Force the update
            this.updateDirtyRows(true);

            // Get out...
            return;
        }

        // Update the data
        this.onBorrowingsLoaded(borrowings, previousBorrowings, rates, lastUpdated, turn, intraturnId);

        // Clear the dirty flag
        this.clearDirtyRows();

        // let the caller know
        callbackFn();
    }


    /**
     * This method clears the dirty rows.
     */
    clearDirtyRows()
    {
        // Reset the dirty IDs
        this.setState(
        {
            dirtyRows: {},
            deletedRows: [],
            previousDirtyRows: {},
            addedRowCount: 0
        });
    }


    /**
     * This method handles property updates.
     *
     * @param {*} prevProps The previous properties.
     */
    componentDidUpdate(prevProps)
    {
        if ((prevProps.team.team_id !== this.props.team.team_id) || 
            (prevProps.selectedTurn !== this.props.selectedTurn))
        {
            // Load the borrowings
            this.loadBorrowings(this.props.selectedTurn);
        }
    }


    /**
     * This method handles delete row clicks.
     */
    onClickDeleteRows = () =>
    {
        // Initialize the rows & deleted rows
        let rows        = [];
        let deletedRows = [ ...this.state.deletedRows ];
        let dirtyRows   = { ...this.state.dirtyRows };

        // Get the selected rows
        let selectedRows = this.api.getSelectedRows();

        // Initialize the map
        let selectedRowMap = {};

        // Populate it...
        for (let row of selectedRows)
            selectedRowMap[row.borrowing_id] = true;

        // Add it to the appropriate list
        for (let row of this.state.rows)
        {
            if (selectedRowMap[row.borrowing_id])
            {
                if (row.borrowing_id > 0)
                    deletedRows.push({ borrowing_id: row.borrowing_id, intraturn_id: row.intraturn_id });
                else
                    delete dirtyRows[row.borrowing_id];
            }
            else
                rows.push(row);
        }

        // Set the state
        this.setState({ rows: rows, deletedRows: deletedRows, dirtyRows: dirtyRows, summary: this.calculateSummary(rows) });
    }


    /**
     * This method adds the row.
     */
    addRow = () =>
    {
        // Get the added row count
        let addedRowCount = this.state.addedRowCount + 1;

        // Create the new row
        let newRow = 
        { 
            borrowing_id: -addedRowCount,
            intraturn_id: this.state.intraturnId,
                
            ...this.state.modalData 
        };

        // Format the percentages
        newRow.rate       = parsePercentage(this.state.rateMap[newRow.term]).toFixed(3);
        newRow.maturities = Utils.parseCurrency(newRow.maturities);

        // Set the rows
        let rows = [...this.state.rows, newRow];

        // Set the dirty rows
        let dirtyRows = { ...this.state.dirtyRows };
        dirtyRows[newRow.borrowing_id] = true;

        // Hide the modal
        this.setState({ rows: rows, dirtyRows: dirtyRows, addedRowCount: addedRowCount, summary: this.calculateSummary(rows), showAddModal: false });
    }


    /**
    * This method calculates the summary.
    *
    * @param {*} rows The rows.
    * @returns The summary.
    */
    calculateSummary(rows)
    {
        // Initialize the summary
        let summary =
        {
            amortizing_or_bullet: "Total",
            maturities: 0
        }

        // Calculate the summary
        for (let row of rows)
            summary.maturities += Utils.parseCurrency(row.maturities);

        // Get out...
        return summary;
    }


    /**
     * The modal data change event handler.
     *
     * @param {*} key The key.
     * @param {*} value The vale.
     */
    onChangeModalData = (key, value) =>
    {
        // Get the modal data
        let modalData = { ...this.state.modalData };

        // Set the value
        modalData[key] = value;

        // Update the state
        this.setState({ modalData: modalData});
    }


    /**
     * This method determines if the view is editable.
     *
     * @returns true if the view is editable, else false.
     */
    isEditable()
    {
        return !this.props.team.frozen && (this.props.selectedTurn === this.props.game.turns_completed + 1) ? true : false;
    }


    /**
     * This method handles sort column changes.
     *
     * @param {*} sortColumns The sorted columns.
     */
    onSortPreviousColumnsChange = (sortColumns) =>
    {
        // Create the adjusted sort columns array
        let adjustedSortColumns = [];

        // Only add columns that can be sorted
        for (let sortColumn of sortColumns)
        {
            if (PREPAY_COLUMNS.find(column => column.key === sortColumn.columnKey).comparator)
                adjustedSortColumns.push(sortColumn);
        }

        // Sort the rows
        let previousRows = this.sortRowData(this.state.previousRows, PREPAY_COLUMNS, adjustedSortColumns);

        // Update the state
        this.setState(
        {
            previousRows: previousRows,
            previousSortColumns: adjustedSortColumns
        })
    }



    /**
     * This method determines if the state is dirty.
     *
     * @returns true if the state is dirty, else false.
     */
    isDirty()
    {
        // Are we dirty?
        if ((this.state.addedRowCount > 0) || (Object.keys(this.state.dirtyRows).length > 0) || (this.state.deletedRows.length > 0))
            return true;

        return false;
    }


    /**
     * This method renders the component.
     */
    render()
    {
        // Get out if nothing to do...
        if (!this.isComponentReady())
            return <Fragment />;

        // Initialize the editable flag
        let editable = this.isEditable();

        return (
            <Fragment>
                <Row className="icon-buttons" >
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <a onClick={ () => { if (editable) this.setState({ showAddModal: true, modalData: { ...DEFAULT_MODAL_DATA } }) } }><i class="fa fa-plus"></i></a>
                        &nbsp;&nbsp;&nbsp;
                        <a onClick={ () => { if (editable) this.onClickDeleteRows() } } disabled={ !editable }><i class="fa fa-trash"></i></a>
                    </Col>
                </Row>

                <div className={"grid-body" + ((this.props.game.type === "Lite") ? " small" : "") }>
                    <DecisionGrid 
                        rowKeyGetter={ (row) => { return row.borrowing_id; } }
                        turn={ this.props.selectedTurn }
                        columns={ COLUMNS } 
                        sortColumns={ this.state.sortColumns }
                        rowData={ this.state.rows } 
                        pinnedBottomRowData={ [ this.state.summary ] }
                        selectedRows={ this.state.selectedRows }
                        onCellValueChanged={ this.onCellValueChanged } 
                        onSelectedRowsChange={ this.onSelectedRowsChange }
                        onSortColumnsChange={ this.onSortColumnsChange }
                        rowSelection="single"
                        onGridReady = { (params) => { this.api = params.api }}
                        height={ (this.props.game.type === "Lite") ? " 30vh" : ""}
                        />
                </div>

                <div className={"grid-body previous-borrowings" + ((this.props.game.type === "Lite") ? " small" : "") }>
                    <DecisionGrid
                        rowKeyGetter={ (row) => { return row.borrowing_id; } }
                        turn={ this.props.selectedTurn }
                        columns={ PREPAY_COLUMNS } 
                        sortColumns={ this.state.previousSortColumns }
                        rowData={ this.state.previousRows } 
                        pinnedBottomRowData={ [ this.state.previousSummary ] }
                        onCellValueChanged={ this.onPreviousCellValueChanged } 
                        onSortColumnsChange={ this.onSortPreviousColumnsChange }
                        height={ (this.props.game.type === "Lite") ? " 30vh" : ""}
                        />
                </div>

                <Modal show={ this.state.showAddModal } onHide={() => this.setState({ showAddModal: false }) } dialogAs={ DraggableModalDialog } dialogClassName="borrowing-modal">
                    <Modal.Header closeButton>
                    <Modal.Title>New Borrowing</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col xs={6} sm={6} md={4} lg={4}><label>Ammortizing or Bullet</label></Col>
                                <Col xs={12} sm={12} md={8} lg={8}><SelectControl items={ [ { key: "Amortizing", value: "Amortizing", }, { key: "Bullet", value: "Bullet" } ] } name="amortizing_or_bullet"  onChange={ this.onChangeModalData } value= { this.state.modalData.v } /></Col>
                            </Row>
                            <Row>
                                <Col xs={6} sm={6} md={4} lg={4}><label>Fixed or Variable</label></Col>
                                <Col xs={12} sm={12} md={8} lg={8}><SelectControl items={ [ { key: "Fixed", value: "Fixed", }, { key: "Variable", value: "Variable" } ] } name="fixed_or_variable"  onChange={ this.onChangeModalData } value= { this.state.modalData.v } /></Col>
                            </Row>
                            <Row>
                                <Col xs={6} sm={6} md={4} lg={4}><label>Term (Months)</label></Col>
                                <Col xs={12} sm={12} md={8} lg={8}><SelectControl name="term" items={ TERM_ITEMS } value={ this.state.modalData.term } onChange={ this.onChangeModalData } /></Col>
                            </Row>

                            <Row>
                                <Col xs={6} sm={6} md={4} lg={4}><label>Amount (000s)</label></Col>
                                <Col xs={12} sm={12} md={8} lg={8}><CurrencyControl name="maturities" value={ this.state.modalData.maturities } onChange={ this.onChangeModalData } /></Col>
                            </Row>
                            <Row>
                                <Col xs={6} sm={6} md={4} lg={4}><label>Rate</label></Col>
                                <Col xs={12} sm={12} md={8} lg={8}><label>{ this.state.rateMap[this.state.modalData.term] }%</label></Col>
                            </Row>
                        </Container>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="primary" onClick={ () => this.addRow() }>Add</Button>
                        <Button variant="secondary" onClick={() => this.setState({ showAddModal: false}) }>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}

// Export the decisions view...
export default BorrowingsControl;

