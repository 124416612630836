import { Fragment } from 'react';
import { withRouter, Prompt } from "react-router";
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Utils from "../../../utils/Utils";

import BaseDecisionGridView from "./BaseDecisionGridView";
import COLUMNS from "./grid/BDMColumns";
import TurnSelect from "./components/TurnSelect";
import DecisionGrid from "./components/DecisionGrid";
import ScenarioName from "./components/ScenarioName";

import DecisionBody from "./DecisionBody";
import gameService from "../../../services/GameService";
import intraturnService from "../../../services/IntraturnService";
import BDMViewTemplate from './BDMViewTemplate';


/**
 * The BDMView class.
 */
class BDMView extends BaseDecisionGridView
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the loaded flag
        this.loaded = false;

        // Initialize the state
        this.state =
        {
            rows: [],
            dirtyRows: {},
            sortColumns: [],
            selectedRows: new Set(),
            selectedTurn: -1,
            modalData: {},
        };

        // Initialize the columns
        this.columns = COLUMNS;
    }


    /**
     * This method loads the business development & marketing items.
     *
     * @param turn The turn.
     */
    loadBDMs(turn)
    {
        // Get out if the state is loaded
        if (!this.props.game)
            return;

        // Only load once...
        if (this.loadingBDMs)
            return;

        // Get out if nothing to do...
        if (this.state.selectedTurn === turn)
            return;

        // Warn the user if there are changes...
        if (this.isDirty())
        {
            if (!window.confirm("You have unsaved changes.  Do you wish to proceed?"))
                return;
        }

        // Mark us as loading...
        this.loadingBDMs = true;

        // Load the BDM items
        gameService.loadBDMs(this.props.match.params.team_id, turn, this.onBDMsLoaded);
    }


    /**
     * The business development & marketing loaded event handler.
     *
     * @param {*} bdms The BDMs.
     * @param {*} lastUpdated The last updated time.
     * @param {*} turn The turn.
     */
    onBDMsLoaded = (bdms, lastUpdated, turn) =>
    {
        // Make sure we have something...
        if (!bdms)
            bdms = [];

        // Initialize the state
        let state =
        { 
            rows: bdms, 
            selectedTurn: turn,
            lastUpdated: lastUpdated,
            summary: this.calculateSummary(bdms)
        }

        // Set the intraturn Id if appropriate
        if (bdms.length > 0)
            state.intraturnId = bdms[0].intraturn_id;

        // Set the state
        this.setState(state);

        // Clear the dirty rows
        this.clearDirtyRows();

        // Reset the loading BDMs flag
        this.loadingBDMs = false;

        // Set the loaded flag
        this.loaded = true;
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Call mom...
        let result = super.isComponentReady();

        // Load the CDs if necessary...
        if (this.props.game && !this.loaded)
        {
            // Load the BDMs
            this.loadBDMs(this.props.game.turns_completed + 1);

            // Not ready...
            return false;
        }

        // Get out...
        return result;
    }

    /**
     * This method updates the state when the cell value has been modified.
     *
     * @param {*} params The params.
     */
    onCellValueChanged = (params) =>
    {
        // Get the dirty rows map...
        let dirtyRows = this.state.dirtyRows;

        // Mark it dirty
        dirtyRows[params.data.bus_dev_id] = true;

        // Update Summary
        let summary = this.calculateSummary(this.state.rows);

        // Save the state
        this.setState(
        {
            dirtyRows: dirtyRows,
            summary: summary
        });

        // Displays should most recent up to date values.
        params.api.refreshCells();
    }


    /**
     * This method handles selected row changes.
     *
     * @param {*} selectedRows The selected rows.
     */
    onSelectedRowsChange = (selectedRows) =>
    {
        if (this.isEditable())
            this.setState({ selectedRows: selectedRows });
    }

    /**
     * This method handles view saves.
     */
    onSave = () =>
    {
        // Get out if this is not actually dirty (shouldn't happen)
        if (!this.isDirty())
            return;

        // Handle the save...
        this.updateDirtyRows(false);
    }


    /**
     * This method updates the dirty rows.
     *
     * @param force The force flag.
     * @return true if there are dirty rows, else false...
     */
    updateDirtyRows(force)
    {
        // Get out if nothing to do...
        if (Object.keys(this.state.dirtyRows).length === 0)
            return false;

        // Initialize the updated rows
        let updatedRows = [];

        // Check each row...
        for (let row of this.state.rows)
        {
            // See if it is dirty...
            if (!this.state.dirtyRows[row.bus_dev_id])
                continue;
            
            // Convert the amount
            row.amount = Utils.parseCurrency(row.amount);

            // Add the updated row..
            updatedRows.push(row);
        }

        // Update BDMs
        intraturnService.updateBDMs(this.state.intraturnId, this.state.selectedTurn, updatedRows, this.state.lastUpdated, force, this.onSaveComplete);

        // Get out...
        return true;
    }


    /**
     * The save complete event handler.
     *
     * @param {*} modifiedBy The modified by user.
     * @param {*} lastUpdated The last updated time.
     * @param {*} bdms The BDMs.
     * @param {*} turn The turn.
     */
    onSaveComplete = (modifiedBy, lastUpdated, bdms, turn) =>
    {
        // See if it was modified since we loaded the data...
        if (modifiedBy)
        {
            // See if the user wants to force the matter...
            if (!window.confirm("Business Development & Marketing records have modified by " + modifiedBy + " at " + new Date(lastUpdated).toLocaleTimeString() + ".\n\nWould you like save your changes anyway?"))
                return;

            // Force the update
            this.updateDirtyRows(true);

            // Get out...
            return;
        }

        // Update the data
        this.setState({ lastUpdated: lastUpdated });

        // Clear the dirty flag
        this.clearDirtyRows();

        // let the user know
        alert("Saved.");
    }


    /**
     * This method clears the dirty rows.
     */
    clearDirtyRows()
    {
        // Reset the dirty IDs
        this.setState(
        {
            dirtyRows: {}
        });
    }


    /**
     * This method handles turn changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onTurnChange = (name, value) =>
    {
        // Load the BDMs
        this.loadBDMs(parseInt(value));
    }


    /**
    * This method calculates the summary.
    *
    * @param {*} rows The rows.
    * @returns The summary.
    */
    calculateSummary(rows)
    {
        // Initialize the summary
        let summary =
        {
            type: "Total",
            amount: 0
        }

        // Calculate the summary
        for (let row of rows)
            summary.amount += Utils.parseCurrency(row.amount);

        // Get out...
        return summary;
    }


    /**
     * The modal data change event handler.
     *
     * @param {*} key The key.
     * @param {*} value The vale.
     */
    onChangeModalData = (key, value) =>
    {
        // Get the modal data
        let modalData = { ...this.state.modalData };

        // Set the value
        modalData[key] = value;

        // Update the state
        this.setState({ modalData: modalData});
    }


    /**
     * This method determines if the view is editable.
     *
     * @returns true if the view is editable, else false.
     */
    isEditable()
    {
        return !this.props.team.frozen && (this.state.selectedTurn === this.props.game.turns_completed + 1) ? true : false;
    }


    /**
     * This method renders the view.
     */
    renderView()
    {
        return (
            <Fragment>
                <Prompt when={ this.isDirty() } message="There are unsaved changes on this screen.  Are you sure you want to leave?" />

                <BDMViewTemplate name="Business Development" onSave={ this.onSave } dirty={ this.isDirty() } intraturnId={ this.state.intraturnId } >
                    <Row>
                        <Col xs={6} sm={6} md={4} lg={4}>
                            <TurnSelect name="turns" onChange={ this.onTurnChange } value= { this.state.selectedTurn } />
                        </Col>
                    </Row>

                    <div className="grid-body">
                        <DecisionGrid 
                            rowKeyGetter={ (row) => { return row.bus_dev_id; } }
                            turn={ this.state.selectedTurn }
                            columns={ this.columns } 
                            sortColumns={ this.state.sortColumns }
                            rowData={ this.state.rows } 
                            pinnedBottomRowData={ [ this.state.summary ] }
                            selectedRows={ this.state.selectedRows }
                            onCellValueChanged={ this.onCellValueChanged }
                            onSelectedRowsChange={ this.onSelectedRowsChange }
                            onSortColumnsChange={ this.onSortColumnsChange }
                        />
                    </div>
                </BDMViewTemplate>
            </Fragment>
        );
    }
}

// Export the decisions view...
export default withRouter(connect(BaseDecisionGridView.mapStateToProps)(BDMView));