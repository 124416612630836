import { Component, Fragment } from "react";
import { withRouter } from 'react-router';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { store } from "../store";
import { logout } from "../../features/authentication/AuthenticationSlice";

import Utils from "../utils/Utils";
import Menu from "./Menu";
import SystemMessage from "./SystemMessage";
import HelpLink from "./HelpLink";

import ajax from "../utils/Ajax";

import { FISIM, getMode } from "../../Modes";



/**
 * This method maps the state to the properties.
 * 
 * @param {*} state The state.
 * @param {*} ownProps The own properties.
 * @returns The mapping.
 */
const mapStateToProps = (state, ownProps) =>
{
    let result = 
    {
         userId: state.authentication.userId,
         fullName: state.authentication.fullName,
         game: state
    };
 
    return result;
}
 

 


/**
 * The application template.
 */
class AppTemplate extends Component
{
    /**
     * This method handles logout click events.
     */
    onClickLogOut = () =>
    {
        ajax.post("/logout", null, this.onLogOutComplete);
    }
    

    /**
     * This method handles the logout complete event.
     */
    onLogOutComplete = () =>
    {
        // Perform the logout...
        store.dispatch(logout());
    }



    /**
     * The render method.
     * 
     * @returns The rendered object.
     */
    render()
    {
        // Set the active route...
        Utils.setActiveRoute(this.props.location.pathname);

        return (
            <Fragment>
                <div class="container">
                    <div class="row">
                        <div class="offset-sm-1 offset-md-1 offset-lg-1 col-sm-5 col-md-5 col-lg-5">
                        {
                            getMode() === FISIM ?
                                <Link to="/">
                                    <img class="nav-image" src={ process.env.PUBLIC_URL + "/images/fisim_logo.png" } alt="FISIM logo" />
                                    <span class="option-tm">powered by Abrigo</span>
                                </Link> 
                                : 
                                <Link to="/">
                                    <img class="nav-image" src={ process.env.PUBLIC_URL + "/images/af-logo-64x64.png" } alt="Arctic Fox logo" />
                                    <h1 class="af-nav-title">Arctic Fox</h1>
                                </Link> 
                        }
                        </div>
                        <div class="offset-sm-2 offset-md-2 offset-lg-2 col-sm-4 col-md-4 col-lg-4">
                        {
                            getMode() === FISIM ?
                                <img class="nav-image" src={ process.env.PUBLIC_URL + "/images/gsb_logo.png" } alt="GSB logo" />
                            : ""
                        }
                        </div>
                    </div>
                </div>

                <div class="navbar-gradient">
                    {
                        this.props.userId ? <Menu /> : 
                            <div class="user-options-cell">
                                <div class="user-table">
                                    <div class="fp-cell"><Link to="/new-user">New User</Link></div>
                                    <div class="fp-cell"><Link to="/request-password">Forgot Password</Link></div> 
                                </div>
                            </div>
                    }

                    {
                        this.props.userId ?
                            <div class="user-table">
                                <div class="user-name-cell">

                                    <div class="user-name-welcome">
                                        <i class="fa fa-user"></i>
                                        &nbsp;Welcome, {this.props.fullName}
                                    </div>
                                </div>


                                <div class="user-options-cell">
                                    <div class="user-options-table">

                                        <div class="option-1-name">
                                            <HelpLink />
                                        </div>

                                        <div class="option-2-name">
                                            <Link to="/user-settings"><i class="fa fa-cog pad-right-5"></i>User Settings</Link>
                                        </div> 

                                        <div class="option-3-name">
                                            <Link to="/login" onClick={ this.onClickLogOut }><i class="fa fa-sign-out pad-right-5"></i>Sign Out</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ""
                    }

                    <div className="logged-in-content">
                        <SystemMessage />
                        {this.props.children}
                    </div>

                </div>
            </Fragment>
            );
    }
}

// Export the template
export default connect(mapStateToProps)(withRouter(AppTemplate));