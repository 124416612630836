import { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AgChartsReact } from 'ag-charts-react';
import { formatCurrency } from "../../../controls/Currency";

/**
 * The default currency options.
 */
const CURRENCY_OPTIONS =
{
    subtitle: 
    {
        text: "",
    },

    legend:
    {
        enabled: false,
        position: "bottom"
    },

    axes: 
    [
        {
            type: "category",
            position: "bottom",

            title:
            {
                text: "Quarters Behind",
                enabled: true
            }
        },

        {
            type: "number",
            position: "left",

            label: 
            {
                formatter: function (params) 
                {
                    return formatCurrency(params.value);
                },
            },
        },
    ],    
};


/**
 * The default percentage options.
 */
const PERCENTAGE_OPTIONS =
{
    subtitle: 
    {
        text: "",
    },

    legend:
    {
        enabled: false,
        position: "bottom"
    },

    axes: 
    [
        {
            type: "category",
            position: "bottom",

            title:
            {
                text: "Quarters Behind",
                enabled: true
            }
        },

        {
            type: "number",
            position: "left",

            label: 
            {
                formatter: function (params) 
                {
                    return params.value + "%";
                },
            },
        },
    ],    
};


/**
 * This method builds the data.
 *
 * @param {*} kpi The KPI.
 * @param {*} field The field
 * @returns The data.
 */
export function buildData(kpi, name, field)
{
    // Get out if nothing to do...
    if (!kpi || !kpi.kpis)
        return [];

    // Default the parameters
    if (!field)
        field = "quarters";

    // Initialize the data
    var data = [];

    // Process the KPIs
    for (let kpiDetails of kpi.kpis)
    {
        // Initialize the KPI data
        let kpiData = [];

        // Push the data
        data.push(kpiData);

        // Process each KPI
        for (let counter = 0; counter < kpiDetails[field].length; counter++)
        {
            // Initialize the details
            let details = { quarter: kpiDetails[field].length - counter - 1 };

            // Set the value
            details[name ? name : kpiDetails.name] = parseFloat(kpiDetails[field][counter]);

            // Push the details
            kpiData.push(details);
        }
    }

    // Get out...
    return data;
}


/**
 * This method builds the series.
 *
 * @param {*} kpi The KPI.
 * @param {*} name The name.
 * @returns The series entries.
 */
export function buildSeries(kpi, name)
{
    // Get out if nothing to do...
    if (!kpi || !kpi.kpis)
        return [];

    // Initialize the series
    let series = [];

    // Process each series....
    for (let kpiDetails of kpi.kpis)
    {
        let entry =
        {
            xKey: "quarter",
            xName: "Quarter",
            yKey: name ? name : kpiDetails.name,
            label: 
            {
                enabled: false
            },

            format: kpiDetails.format
        }

        // Add the series
        series.push(entry);
    }

    // Get out...
    return series;
}


/**
 * This method creates the graph details.
 *
 * @param {*} data The data.
 * @param {*} series The series.
 */
 export function createGraphDetails(data, series)
{
    let depositGraphDetails;

    // Initialize the graph details
    let graphDetails = [];

    for (let counter = 0; counter < data.length; counter++)
    {
        if ((series[counter].yKey === "Core Deposits") || (series[counter].yKey === "Total Deposits"))
        {
            // Initialize the deposit graph details if necessary
            if (!depositGraphDetails)
            {
                // It gets additional details...
                depositGraphDetails = 
                {
                    ...CURRENCY_OPTIONS,

                    title: 
                    {
                        text: "Deposits"
                    },
                    
                    legend:
                    {
                        enabled: true,
                        position: "bottom"
                    },

                    data: [ ...data[counter] ],
                    series: [ series[counter] ]
                };

                // Push it
                graphDetails.push(depositGraphDetails);
            }
            else
            {
                // Merge the data points
                for (var innerCounter = 0; innerCounter < data[counter].length; innerCounter++)
                    depositGraphDetails.data[innerCounter] = { ...depositGraphDetails.data[innerCounter], ...data[counter][innerCounter] }

                // Add the series
                depositGraphDetails.series.push(series[counter]);
            }

            // Skip normal processing
            continue;
        }


        // Determine the default options
        let defaultOptions = series[counter].format === "Percentage" ? PERCENTAGE_OPTIONS : CURRENCY_OPTIONS;

        // Create the details
        let details =
        {
            ...defaultOptions,

            data: data[counter],
            series: [ series[counter] ]
        };

        // Push them
        graphDetails.push(details);
    }

    // Get out...
    return graphDetails;
}


/**
 * The team key indicators graph component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
const TeamKeyIndicatorsGraphGroup = (props) =>
{
    // Get out if nothing to do...
    if (!props.kpi)
        return <Fragment />;

    // Create the data & series
    let data   = buildData(props.kpi);
    let series = buildSeries(props.kpi);

    // Create the graph details
    let graphDetails = createGraphDetails(data, series);

    // Create the options
    let options = [];

    // Generate the options
    for (let counter = 0; counter < graphDetails.length; counter++)
    {
        // Save the name
        let name = graphDetails[counter].series[0].yKey;

        options.push(
        {
            title: 
            {
                text: name,
            },

            ...graphDetails[counter]
        });
    }

    // Get out...
    return (
        <Container>
            <Row className="key-indicator-graph-group">
                <Col xs={12} sm={12} md={12} lg={12} className="key-indicator">
                    <b>{ props.name }</b>
                </Col>
            </Row>

            <Row className="kpi-indicator-graph-row">
            {
                options.map((option, key) =>
                {
                    return (
                        <Col xs={12} sm={12} md={ 12 / props.maxGraphCols } lg={12 / props.maxGraphCols } className="key-indicator-graph">
                            <AgChartsReact key={ "chart_" + key } options={ option } style={ { width: "100%", height: "500px" } }/>
                        </Col>
                    )
                })
            }
            </Row>
        </Container>
    );
}

// Get out...
export default TeamKeyIndicatorsGraphGroup;


