import { Fragment } from 'react';
import { withRouter, Prompt } from "react-router";
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import BaseDecisionGridView from "./BaseDecisionGridView";
import { FACILITIES_COLUMNS, MAINTENANCE_FACILITIES_COLUMNS } from "./grid/FacilitiesColumns";
import TurnSelect from "./components/TurnSelect";
import DecisionGrid from "./components/DecisionGrid";

import gameService from "../../../services/GameService";
import intraturnService from "../../../services/IntraturnService";
import Utils from "../../../utils/Utils";
import BDMViewTemplate from './BDMViewTemplate';


/**
 * The FacilitiesView class.
 */
class FacilitiesView extends BaseDecisionGridView
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the loaded flag
        this.loaded = false;

        // Initialize the state
        this.state =
        {
            facilityRows: [],
            maintenanceFacilityRows: [],
            dirtyRows: {},
            maintenanceDirtyRows: {},
            sortColumns: [],
            maintenanceSortColumns: [],
            selectedTurn: -1
        };
    }


    /**
     * This method loads the facilities.
     *
     * @param turn The turn.
     */
    loadFacilities(turn)
    {
        // Get out if the state is loaded
        if (!this.props.game)
            return;

        // Only load once...
        if (this.loadingFacilities)
            return;

        // Get out if nothing to do...
        if (this.state.selectedTurn === turn)
            return;

        // Warn the user if there are changes...
        if (this.isDirty())
        {
            if (!window.confirm("You have unsaved changes.  Do you wish to proceed?"))
                return;
        }

        // Mark us as loading...
        this.loadingFacilities = true;

        // Load the facilities
        gameService.loadFacilities(this.props.match.params.team_id, turn, this.onFacilitiesLoaded);
    }


    /**
     * The facilities loaded event handler.
     *
     * @param {*} facilities The facilities.
     * @param {*} maintenanceFacilities The maintenance facilities.
     * @param {*} lastUpdated The last updated time.
     * @param {*} turn The turn.
     */
    onFacilitiesLoaded = (facilities, maintenanceFacilities, lastUpdated, turn) =>
    {
        // Make sure we have something...
        if (!facilities)
            facilities = [];

        // Make sure we have something...
        if (!maintenanceFacilities)
            maintenanceFacilities = [];

        // Determine if the spend is being made
        for (let facility of facilities)
        {
            // Set the spend flag
            if (parseFloat(facility.spend_amount) > 0)
                facility.spend = true;
            else
                facility.spend = false;

            // Set the read-only flag
            if (facility.type === "Close Old Branch")
                facility.read_only = true;
            else
                facility.read_only = false;
        }

        // Determine if the spend is being made
        for (let facility of maintenanceFacilities)
        {
            // Set the read-only flag
            if (facility.type === "Close Old Branch")
                facility.read_only = true;
            else
                facility.read_only = false;
        }

        // Initialize the state
        let state =
        { 
            facilityRows: facilities,
            maintenanceFacilityRows: maintenanceFacilities,
            selectedTurn: turn,
            lastUpdated: lastUpdated
        }

        // Set the intraturn Id if appropriate
        if (facilities.length > 0)
            state.intraturnId = facilities[0].intraturn_id;
        else
        if (maintenanceFacilities.length > 0)
            state.intraturnId = maintenanceFacilities[0].intraturn_id;

        // Set the state
        this.setState(state);

        // Clear the dirty rows
        this.clearDirtyRows();

        // Reset the loading facilities flag
        this.loadingFacilities = false;

        // Set the loaded flag
        this.loaded = true;
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Call mom...
        let result = super.isComponentReady();

        // Load the facilities if necessary...
        if (this.props.game && !this.loaded)
        {
            // Load the facilities
            this.loadFacilities(this.props.game.turns_completed + 1);

            // Not ready...
            return false;
        }

        // Get out...
        return result;
    }


    /**
     * This method updates the state when a facility row has been modified.
     *
     * @param {*} rows The rows.
     * @param {*} data The data.
     */
    setFacilityRows = (params) =>
    {
        // Get the dirty rows map...
        let dirtyRows = this.state.dirtyRows;

        // Get the maintenance facility rows
        let maintenanceFacilityRows = [ ...this.state.maintenanceFacilityRows ];

        // Determine if a value is actually changing...
        if (params.data.spend)
        {
            // Set the spend amounts...
            params.data.spend_amount = Utils.parseCurrency(params.data.estimated_spending);

            // Push it to the maintenance list...
            maintenanceFacilityRows.push(params.data);
        }

        else
        {
            // Clear the spend amount
            params.data.spend_amount = 0;

            // Remove it from the maintenance list...
            for (let counter = 0; counter < maintenanceFacilityRows.length; counter++)
            {
                // See if we found the row
                if (maintenanceFacilityRows[counter].facilities_id !== params.data.facilities_id)
                    continue;

                // Remove the item...
                maintenanceFacilityRows.splice(counter, 1);

                // Get out...
                break;
            }
        }

        // Mark the row as dirty
        dirtyRows[params.data.facilities_id] = true;

        // Save the state
        this.setState(
        {
            dirtyRows: dirtyRows,
            maintenanceFacilityRows: maintenanceFacilityRows
        });

        // Displays should most recent up to date values.
        params.api.refreshCells();
    }


    /**
     * This method updates the state when an maintenance facility row has been modified.
     *
     * @param {*} rows The rows.
     * @param {*} data The data.
     */
    setMaintenanceFacilityRows =  (params) =>
    {
        // Get the dirty rows map...
        let maintenanceDirtyRows = this.state.maintenanceDirtyRows;

        // Mark the row as dirty
        maintenanceDirtyRows[params.data.facilities_id] = true;

        // Set the spend amounts...
        params.data.spend_amount = Utils.parseCurrency(params.data.spend_amount);

        // Save the state
        this.setState(
        {
            maintenanceDirtyRows: maintenanceDirtyRows
        });

        // Displays should most recent up to date values.
        params.api.refreshCells();
    }

    /**
     * This method handles view saves.
     */
    onSave = () =>
    {
        // Get out if this is not actually dirty (shouldn't happen)
        if (!this.isDirty())
            return;

        // Handle the save...
        this.updateDirtyRows(false);
    }


    /**
     * This method updates the dirty rows.
     *
     * @param force The force flag.
     * @return true if there are dirty rows, else false...
     */
    updateDirtyRows(force)
    {
        // Get out if nothing to do...
        if ((Object.keys(this.state.dirtyRows).length === 0) && (Object.keys(this.state.maintenanceDirtyRows).length === 0))
            return false;

        // Initialize the updated rows
        let updatedRows = [];

        // Check each row...
        for (let row of this.state.facilityRows)
        {
            // See if it is dirty...
            if (!this.state.dirtyRows[row.facilities_id])
                continue;

            // Push the updated row
            updatedRows.push(row);
        }

        // Initialize the maintenance rows
        let maintenanceRows = [];

        // Check each row...
        for (let row of this.state.maintenanceFacilityRows)
        {
            // See if it is dirty...
            if (!this.state.maintenanceDirtyRows[row.facilities_id])
                continue;

            row.spend_amount = Utils.parseCurrency(row.spend_amount);

            // Push the updated row
            maintenanceRows.push(row);
        }

        // Update the facilities
        intraturnService.updateFacilities(this.state.intraturnId, updatedRows, maintenanceRows, this.state.lastUpdated, force, this.onSaveComplete);

        // Get out...
        return true;
    }


    /**
     * The save complete event handler.
     *
     * @param {*} modifiedBy The modified by user.
     * @param {*} lastUpdated The last updated time.
     */
    onSaveComplete = ({ modifiedBy, lastUpdated }) =>
    {
        // See if it was modified since we loaded the data...
        if (modifiedBy)
        {
            // See if the user wants to force the matter...
            if (!window.confirm("General Administration records have modified by " + modifiedBy + " at " + new Date(lastUpdated).toLocaleTimeString() + ".\n\nWould you like save your changes anyway?"))
                return;

            // Force the update
            this.updateDirtyRows(true);

            // Get out...
            return;
        }

        // Update the state
        this.setState({ lastUpdated: lastUpdated });

        // Clear the dirty flag
        this.clearDirtyRows();

        // let the user know
        alert("Saved.");
    }


    /**
     * This method clears the dirty rows.
     */
    clearDirtyRows()
    {
        // Reset the dirty IDs
        this.setState(
        {
            dirtyRows: {},
            maintenanceDirtyRows: {}
        });
    }


    /**
     * This method handles turn changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onTurnChange = (name, value) =>
    {
        // Load the facilities
        this.loadFacilities(parseInt(value));
    }


     /**
     * This method handles sort column changes.
     *
     * @param {*} sortColumns The sorted columns.
     */
    onSortColumnsChange = (sortColumns) =>
    {
        // Sort the rows
        let rows = this.sortRowData(this.state.facilityRows, FACILITIES_COLUMNS, sortColumns);

        // Update the state
        this.setState(
        {
            facilityRows: rows,
            sortColumns: sortColumns
        })
    }


     /**
     * This method handles sort column changes.
     *
     * @param {*} sortColumns The sorted columns.
     */
    onMaintenanceSortColumnsChange = (sortColumns) =>
    {
        // Sort the rows
        let rows = this.sortRowData(this.state.maintenanceFacilityRows, MAINTENANCE_FACILITIES_COLUMNS, sortColumns);

        // Update the state
        this.setState(
        {
            maintenanceFacilityRows: rows,
            maintenanceSortColumns: sortColumns
        })
    }


    /**
     * This method determines if the state is dirty.
     *
     * @returns true if the state is dirty, else false.
     */
    isDirty()
    {
        // Are we dirty?
        if (this.hasDirtyFields() || (Object.keys(this.state.dirtyRows).length > 0) || (Object.keys(this.state.maintenanceDirtyRows).length > 0))
            return true;

        return false;
    }


   /**
     * This method renders the view.
     */
    renderGridView()
    {
        return (
            <Fragment>
                <Prompt when={ this.isDirty() } message="There are unsaved changes on this screen.  Are you sure you want to leave?" />

                <BDMViewTemplate name="Facilities" game={ this.props.game } onSave={ this.onSave } dirty={ this.isDirty() } intraturnId={ this.state.intraturnId } >

                    <Row>
                        <Col xs={6} sm={6} md={4} lg={4}>
                            <TurnSelect name="turns" onChange={ this.onTurnChange } value= { this.state.selectedTurn } />
                        </Col>
                    </Row>

                    <div class="grid-body facilities">
                        <DecisionGrid 
                                columns={ FACILITIES_COLUMNS } 
                                turn={ this.state.selectedTurn }
                                sortColumns={ this.state.sortColumns }
                                rowData={ this.state.facilityRows } 
                                onCellValueChanged={ this.setFacilityRows } 
                                onSortColumnsChange={ this.onSortColumnsChange }
                        />
                    </div>
                    <div class="grid-body maintenance-facilities">
                        <DecisionGrid 
                                columns={ MAINTENANCE_FACILITIES_COLUMNS } 
                                turn={ this.state.selectedTurn }
                                sortColumns={ this.state.maintenanceSortColumns }
                                rowData={ this.state.maintenanceFacilityRows } 
                                onCellValueChanged={ this.setMaintenanceFacilityRows } 
                                onSortColumnsChange={ this.onMaintenanceSortColumnsChange }
                        />
                    </div>
                </BDMViewTemplate>
            </Fragment>
        );
    }
}

// Export the decisions view...
export default withRouter(connect(BaseDecisionGridView.mapStateToProps)(FacilitiesView));