import React  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload  } from "@fortawesome/free-solid-svg-icons";
import gameService from '../../../../services/GameService';
import Utils from '../../../../utils/Utils';


/**
 * The download report link class.
 */
class DownloadReportLink extends React.Component
{
    /**
     * The download report click event handler.
     */
    onClickDownloadReport = () =>
    {
        gameService.downloadReport(this.props.report.report_id, this.onReportDownloaded);
    }


    /**
     * The report downloaded event handler.
     *
     * @param {*} data The data.
     */
    onReportDownloaded = (data) =>
    {
        // Download the report
        Utils.download(this.props.report.file_name, data.data);
    }


    /**
     * This method renders the component.
     */
    render()
    {
        return (
            <span class = "fa-buttons" onClick={ this.onClickDownloadReport }><FontAwesomeIcon icon = {faDownload} /></span>
        );
    }
}

export default DownloadReportLink;