import { Fragment } from "react";
import SelectControl from "../../../controls/form/SelectControl";

/**
 * The team selector component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
const TeamSelector = (props) =>
{
    // Get out if nothing to do...
    if (!props.teams)
        return <Fragment />;

    // Get the teams
    let teams = props.teams;
    
    // Filter the teams
    if (props.ignoreTeams)
    {
        // Create the ignore team map
        let ignoreTeamMap = {};

        // Build it...
        for (let teamId of props.ignoreTeams)
            ignoreTeamMap[teamId] = true;

        // Filter the teams
        teams = teams.filter(team => !ignoreTeamMap[team.team_id]);
    }

    // Map the teams
    teams = teams.map(team => ({ name: team.team_name, value: team.team_id }));

    // Sort the teams
    teams.sort((team1, team2) => team1.name.localeCompare(team2.name, undefined, { sensitivity: 'accent' }));


    // Return the view...
    return (
        <SelectControl items={ teams } { ...props } />
    );
}

// Export the component
export default TeamSelector;