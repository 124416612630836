import { withRouter } from "react-router";
import { connect } from 'react-redux';

import BaseGameView from "./BaseGameView";
import FormControl from "../../controls/form/FormControl";
import InputControl from "../../controls/form/InputControl";
import CheckBoxControl from "../../controls/form/CheckBoxControl";
import SelectControl from "../../controls/form/SelectControl";

import gameService from "../../services/GameService";

/**
 * The RunTurnView class.
 */
class RunTurnView extends BaseGameView
{
    /**
     * Object Constructor.
     * 
     * @param {*} props The properties.
     */
    constructor(props)
    {
        super(props);

        // Initialize the state
        this.state =
        {
            reportNamePrefix: "",
            startingBank: 0,
            endingBank: 0,
            turn: 0,
            doNotUpdateTables: false,
            runMCM: false,
            runPeerReports: false,
            runLeaderReports: false,
            runSummaryReports: false,
            runDetailReports: false,
            runChartReports: false,
            runIndividualBankReports: false,
            runSummaryAndDetailReports: false,
            detailReportsToExcel: false,
            runFutureMaturitiesReports: false,
            runAnalysisReports: false,
            runRegulatoryReports: false,
            runGapReport: false,
            runInterestRateRiskReports: false
        }
    }


    /**
     * The component did update event handler.
     *
     * @param {*} prevProps The previous properties.
     */
    componentDidUpdate(prevProps)
    {
        // Make sure we have a game...
        if (!this.props.game || (this.state.gameId === this.props.game.game_id))
            return;

        // Set the starting & ending banks...
        this.setState(
        {
            gameId: this.props.game.game_id,
            turn: this.props.game.turns_completed + 1,
            startingBank: 1,
            endingBank: this.props.game.number_of_teams
        });
    }


    /**
     * The submit event handler.
     */
    onSubmit = () =>
    {
        // Run the turn
        gameService.runTurn(this.state.gameId, this.state, this.onComplete);
    }

    /**
     * The complete event handler.
     * 
     * @param {*} response The response.
     */
    onComplete = (response) =>
    {
        // Show the message
        alert("The turn will begin shortly.");

        // Navigate back
        this.props.history.goBack();
    }


    /**
     * The checkbox change event handler.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onCheckBoxChange = (name, value) =>
    {
        // Empty string = false...
        if (value == "")
            value = false;

        this.onFieldChange(name, value);
    }


    /**
     * This method handles peer report field changes.

     * @param {*} event The event.
     */
    onPeerReportFieldChange = (name, value) =>
    {
        // Update the value...
        this.onFieldChange(name, value);

        // Make the others match the peer report when it's clicked
        if (name == "runPeerReports")
        {
            this.onFieldChange("runLeaderReports", value);
            this.onFieldChange("runSummaryReports", value);
            this.onFieldChange("runDetailReports", value);
            this.onFieldChange("runChartReports", value);
        }
        else
        {
            // Initialize the state
            let state =
            {
                runLeaderReports: this.state.runLeaderReports,
                runSummaryReports: this.state.runSummaryReports,
                runDetailReports: this.state.runDetailReports,
                runChartReports: this.state.runChartReports
            }

            // Change the updated one...
            state[name] = value;

            // Update the peer report field based on it's "children"...
            this.onFieldChange("runPeerReports", state.runLeaderReports || state.runSummaryReports || state.runDetailReports || state.runChartReports);
        }
    }




    /**
     * This method handles bank report field changes.

     * @param {*} event The event.
     */
    onBankReportFieldChange = (name, value) =>
    {
        // Update the value...
        this.onFieldChange(name, value);

        // Make the others match the peer report when it's clicked
        if (name == "runIndividualBankReports")
        {
            this.onFieldChange("runSummaryAndDetailReports", value);
            this.onFieldChange("detailReportsToExcel", value);
            this.onFieldChange("runFutureMaturitiesReports", value);
            this.onFieldChange("runAnalysisReports", value);
            this.onFieldChange("runRegulatoryReports", value);
            this.onFieldChange("runGapReport", value);
            this.onFieldChange("runInterestRateRiskReports", value);
        }
        else
        {
            // Initialize the state
            let state =
            {
                runSummaryAndDetailReports: this.state.runSummaryAndDetailReports,
                detailReportsToExcel: this.state.detailReportsToExcel,
                runFutureMaturitiesReports: this.state.runFutureMaturitiesReports,
                runAnalysisReports: this.state.runAnalysisReports,
                runRegulatoryReports: this.state.runRegulatoryReports,
                runGapReport: this.state.runGapReport,
                runInterestRateRiskReports: this.state.runInterestRateRiskReports
            }

            // Change the updated one...
            state[name] = value;

            // Update the run individual bank reports field based on it's "children"...
            this.onFieldChange("runIndividualBankReports", state.runSummaryAndDetailReports || state.detailReportsToExcel || state.runFutureMaturitiesReports || 
                                                           state.runAnalysisReports || state.runRegulatoryReports || state.runGapReport ||
                                                           state.runInterestRateRiskReports);
        }
    }



    /**
     * This method renders the view.
     */
    renderView()
    {
        // Create the team options
        let teamOptions = [];
        for (let counter = 1; counter <= this.props.game.number_of_teams; counter++)
            teamOptions.push({ name: "" + counter, value: "" + counter });

        // Create the turn options
        let turnOptions = [];
        for (let counter = 1; counter <= Math.max(this.props.game.turns_completed + 1, this.props.game.turns_allowed); counter++)
            turnOptions.push({ name: "" + counter, value: "" + counter });

        return (
            <FormControl name="Run Turn">
                <fieldset className="form">
                    <legend>Turn Details</legend>
                    <div className="row">
                        <div className="left offset-sm-1 offset-md-1 offset-lg-1 col-sm-2 col-md-2 col-lg-2 d-flex"><label for="turn" className="form-check-label">Turn</label>&nbsp;&nbsp;<SelectControl name="turn" items={ turnOptions } value={ this.state.turn } onChange={ this.onFieldChange } /></div>
                        <div className="left col-sm-4 col-md-4 col-lg-4 d-flex"><label for="reportNamePrefix" className="form-check-label">Report&nbsp;Prefix</label>&nbsp;&nbsp;<InputControl name="reportNamePrefix" onChange={ this.onFieldChange } value= { this.state.reportNamePrefix } /></div>
                    </div>
                </fieldset>
                <fieldset className="form">
                    <legend>MCM</legend>
                    <div className="row">
                        <div className="left offset-sm-1 offset-md-1 offset-lg-1 col-sm-2 col-md-2 col-lg-2"><CheckBoxControl name="runMCM" onChange={ this.onCheckBoxChange } checked={ this.state.runMCM ? "checked" : "" } />&nbsp;&nbsp;<label for="runMCM" className="form-check-label">Run MCM</label></div>
                        <div className="left col-sm-2 col-md-2 col-lg-2"><CheckBoxControl name="doNotUpdateTables" onChange={ this.onCheckBoxChange } checked={ this.state.doNotUpdateTables ? "checked" : "" } />&nbsp;&nbsp;<label for="doNotUpdateTables" className="form-check-label">Do Not Update Tables</label></div>
                    </div>
                </fieldset>
                <fieldset className="form">
                    <legend>Peer Reports</legend>
                    <div className="row">
                        <div className="left offset-sm-1 offset-md-1 offset-lg-1 col-sm-2 col-md-2 col-lg-2"><CheckBoxControl name="runPeerReports" onChange={ this.onPeerReportFieldChange } checked={ this.state.runPeerReports ? "checked" : "" } />&nbsp;&nbsp;<label for="runPeerReports" className="form-check-label">Peer Reports</label></div>
                    </div>
                    <div className="row">
                        <div className="left offset-sm-2 offset-md-2 offset-lg-2 offset-lg-2 col-sm-2 col-md-2 col-lg-2"><CheckBoxControl name="runLeaderReports" onChange={ this.onPeerReportFieldChange } checked={ this.state.runLeaderReports ? "checked" : "" } />&nbsp;&nbsp;<label for="runLeaderReports" className="form-check-label">Leader Reports</label></div>
                        <div className="left col-sm-2 col-md-2 col-lg-2"><CheckBoxControl name="runSummaryReports" onChange={ this.onPeerReportFieldChange } checked={ this.state.runSummaryReports ? "checked" : "" } />&nbsp;&nbsp;<label for="runSummaryReports" className="form-check-label">Summary Reports</label></div>
                        <div className="left col-sm-2 col-md-2 col-lg-2"><CheckBoxControl name="runDetailReports" onChange={ this.onPeerReportFieldChange } checked={ this.state.runDetailReports ? "checked" : "" } />&nbsp;&nbsp;<label for="runDetailReports" className="form-check-label">Detail Reports</label></div>
                        <div className="left col-sm-2 col-md-2 col-lg-2"><CheckBoxControl name="runChartReports" onChange={ this.onPeerReportFieldChange } checked={ this.state.runChartReports ? "checked" : "" } />&nbsp;&nbsp;<label for="runChartReports" className="form-check-label">Charts Reports</label></div>
                    </div>
                </fieldset>
                <fieldset className="form">
                    <legend>Bank Reports</legend>
                    <div className="row">
                        <div className="left offset-sm-1 offset-md-1 offset-lg-1 col-sm-2 col-md-2 col-lg-2"><CheckBoxControl name="runIndividualBankReports" onChange={ this.onBankReportFieldChange } checked={ this.state.runIndividualBankReports ? "checked" : "" } />&nbsp;&nbsp;<label for="runMCM" className="form-check-label">Individual Bank Reports</label></div>
                        <div className="left col-sm-2 col-md-2 col-lg-2"><SelectControl name="startingBank" items={ teamOptions } value={ this.state.startingBank } onChange={ this.onFieldChange } disabled={ !this.state.runIndividualBankReports } /></div>
                        <div className="left col-sm-2 col-md-2 col-lg-2"><SelectControl name="EndingBank" items={ teamOptions } value={ this.state.endingBank } onChange={ this.onFieldChange } disabled={ !this.state.runIndividualBankReports } /></div>
                    </div>
                    <div className="row">
                        <div className="left offset-sm-2 offset-md-2 offset-lg-2 col-sm-2 col-md-2 col-lg-2"><CheckBoxControl name="runSummaryAndDetailReports" onChange={ this.onBankReportFieldChange } checked={ this.state.runSummaryAndDetailReports ? "checked" : "" } />&nbsp;&nbsp;<label for="runSummaryAndDetailReports" className="form-check-label">Summary and Detailed Reports</label></div>
                        <div className="left col-sm-2 col-md-2 col-lg-2"><CheckBoxControl name="detailReportsToExcel" onChange={ this.onBankReportFieldChange } checked={ this.state.detailReportsToExcel ? "checked" : "" } />&nbsp;&nbsp;<label for="detailReportsToExcel" className="form-check-label">Detail Reports to Excel</label></div>
                        <div className="left col-sm-2 col-md-2 col-lg-2"><CheckBoxControl name="runFutureMaturitiesReports" onChange={ this.onBankReportFieldChange } checked={ this.state.runFutureMaturitiesReports ? "checked" : "" } />&nbsp;&nbsp;<label for="detailReportsToExcel" className="form-check-label">Future Maturities Reports</label></div>
                    </div>
                    <div className="row">
                        <div className="left offset-sm-2 offset-md-2 offset-lg-2 col-sm-2 col-md-2 col-lg-2"><CheckBoxControl name="runAnalysisReports" onChange={ this.onBankReportFieldChange } checked={ this.state.runAnalysisReports ? "checked" : "" } />&nbsp;&nbsp;<label for="runAnalysisReports" className="form-check-label">Analysis Reports</label></div>
                        <div className="left col-sm-2 col-md-2 col-lg-2"><CheckBoxControl name="runRegulatoryReports" onChange={ this.onBankReportFieldChange } checked={ this.state.runRegulatoryReports ? "checked" : "" } />&nbsp;&nbsp;<label for="runRegulatoryReports" className="form-check-label">Regulatory Report</label></div>
                        <div className="left col-sm-2 col-md-2 col-lg-2"><CheckBoxControl name="runGapReport" onChange={ this.onBankReportFieldChange } checked={ this.state.runGapReport ? "checked" : "" } />&nbsp;&nbsp;<label for="runGapReport" className="form-check-label">Gap Report</label></div>
                    </div>
                    <div className="row">
                        <div className="left offset-sm-2 offset-md-2 offset-lg-2 col-sm-3 col-md-3 col-lg-3"><CheckBoxControl name="runInterestRateRiskReports" onChange={ this.onBankReportFieldChange } checked={ this.state.runInterestRateRiskReports ? "checked" : "" } />&nbsp;&nbsp;<label for="runInterestRateRiskReports" className="form-check-label">Interest Rate Risk Reports (Gap, MV, &amp; EAR)</label></div>
                    </div>
                </fieldset>
                <div className="row">
                    <div className="left col-sm-4 col-md-4 col-lg-4" style={{ marginLeft: "20px" }}><button className="btn btn-primary" onClick={ (event) => this.onSubmit(event) } >Run Turn</button></div>
                </div>
            </FormControl>
        );
    }
}

export default withRouter(connect(BaseGameView.mapStateToProps)(RunTurnView));