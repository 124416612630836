import { formatPercentage } from '../../../controls/Percentage';


/**
 * The calculation helpers class.
 */
class CalculationHelpers
{
    /**
     * The calculation helpers.
     */
    constructor()
    {
        // Create the dollar formatter
        this.dollarFormatter = new Intl.NumberFormat(navigator.language, 
        {
            style: 'currency',
            currency: 'usd',
            minimumFractionDigits: 0,
            maximumFractionDigits: 3
        });


        // Create the dollar formatter with 2 decimals
        this.dollarFormatter2Decimals = new Intl.NumberFormat(navigator.language, 
        {
            style: 'currency',
            currency: 'usd',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        });

        // Initialize the tax rate
        this.TAX_RATE = .25;
    }


    /**
     * This method gets the dollar formatter. 
     *
     * @returns The dollar formatter.
     */
    getDollarFormatter()
    {
        return this.dollarFormatter;
    }


    /**
     * This method gets the dollar formatter with 2 decimals.
     *
     * @returns The dollar formatter with 2 decimals.
     */
    getDollarFormatter2Decimals()
    {
        return this.dollarFormatter2Decimals;
    }


    /**
     * This method gets the value.
     *
     * @param {*} team The team.
     * @param {*} property The property name.
     * @returns The value.
     */
    getValue(team, property)
    {
        if (!team || !team[property])
            return 0;

        return parseFloat(team[property].value);
    }

    /**
     * This method prints the value.
     *
     * @param {*} team The team.
     * @param {*} property The property name.
     * @returns The value.
     */
    printCurrencyInMillions(team, property)
    {
        if (!team || !team[property])
            return "";

        return this.formatCurrencyInMillions(team[property].value);
    }


    /**
     * This method formats the currency in millions of dollars.
     *
     * @param {*} value The value.
     * @returns The formatted value.
     */
    formatCurrencyInMillions(value)
    {
        return this.dollarFormatter.format(value / 1000000);
    }


    /**
     * This method sums the values.
     *
     * @param {*} team1 The first team.
     * @param {*} team2 The second team.
     * @param {*} team3 The third team.
     * @param {*} property The property name.
     * @returns The value.
     */
    printSumValuesInMillions(team1, team2, team3, property)
    {
        return this.dollarFormatter.format(this.sumValues(team1, team2, team3, property) / 1000000);
    }


    /**
     * This method sums the values.
     *
     * @param {*} team1 The first team.
     * @param {*} team2 The second team.
     * @param {*} team3 The third team.
     * @param {*} property The property name.
     * @returns The value.
     */
    sumValues(team1, team2, team3, property)
    {
        if (!team1 && !team2 && !team3)
            return "";

        let value = 0;
        
        if (team1 && team1[property]) 
            value += parseFloat(team1[property].value);

        if (team2 && team2[property])
            value += parseFloat(team2[property].value);

        if (team3 && team3[property])
            value += parseFloat(team3[property].value);

        return value;
    }


    /**
     * This method gets the value in millions.
     *
     * @param {*} team The team.
     * @param {*} property The property name.
     * @returns The value.
     */
    getValueInMillions(team, property)
    {
        return this.getValue(team, property) / 1000000;
    }


    /**
     * This method sums the values.
     *
     * @param {*} team1 The first team.
     * @param {*} team2 The second team.
     * @param {*} team3 The third team.
     * @param {*} property The property name.
     * @returns The value.
     */
    sumValues(team1, team2, team3, property)
    {
        if (!team1 && !team2 && !team3)
            return "";

        let value = 0;
        
        if (team1 && team1[property]) 
            value += parseFloat(team1[property].value);

        if (team2 && team2[property])
            value += parseFloat(team2[property].value);

        if (team3 && team3[property])
            value += parseFloat(team3[property].value);

        return value;
    }


    /**
     * This method sums the values.
     *
     * @param {*} team1 The first team.
     * @param {*} team2 The second team.
     * @param {*} team3 The third team.
     * @param {*} property The property name.
     * @returns The value.
     */
    printSumValuesInMillions(team1, team2, team3, property)
    {
        return this.dollarFormatter.format(this.sumValues(team1, team2, team3, property) / 1000000);
    }


    /**
     * This method formats the number.
     *
     * @param {*} number The number.
     * @return The formatted number.
     */
    formatNumber(number)
    {
        // Leave empty if infinite...
        if (!isFinite(number))
            return "";

        // Format the string
        return number.toLocaleString("en-US");
    }


    /**
     * This method prints the percentage.
     *
     * @param {*} team The team.
     * @param {*} property The property.
     * @returns The value.
     */
    printPercentage(team, property)
    {
        if (!team || !team[property])
            return "";

        return formatPercentage(team[property].value * 100);
    }


    /**
     * This method prints the ratio.
     *
     * @param {*} team The team.
     * @param {*} property The property.
     * @returns The ratio.
     */
    printRatio(team, property)
    {
        if (!team || !team[property])
            return "";

        return team[property].value.toFixed(3);
    }
}

// Create & export the singleton...
export default new CalculationHelpers();