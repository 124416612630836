
import { CheckBoxRenderer, DisabledCheckBoxRenderer, currencyRenderer } from "../../../../controls/grid/GridRenderers";
import { CurrencyCellEditor } from "../../../../controls/grid/GridEditors";
import SortFunctions from "../../../../controls/grid/SortFunctions";


/**
 * The potential expense columns.
 */
export const POTENTIAL_EXPENSE_COLUMNS =
[
    { 
        field: 'type', 
        headerName: 'Type',
        minWidth: 200,
        comparator: SortFunctions.alphaNumeric
    },

    { 
        field: 'proposed_spending',
        headerName: 'Proposed Spending', 
        minWidth: 200,
        comparator: SortFunctions.numeric,
        cellRenderer: currencyRenderer
    },

    { 
        field: 'amount', 
        headerName: 'Amount', 
        minWidth: 200,
        comparator: SortFunctions.numeric,
        cellRenderer: currencyRenderer
    },

    { 
        field: 'spend', 
        headerName: 'Spend',
        minWidth: 80,
        width: 80,
        cellRenderer: DisabledCheckBoxRenderer,
        editableCellRenderer: CheckBoxRenderer
    }
];


/**
 * The incurred-expenses columns.
 */
export const INCURRED_EXPENSE_COLUMNS =
[
    { 
        field: 'type', 
        headerName: 'Type',
        minWidth: 200,
        comparator: SortFunctions.alphaNumeric
    },

    { 
        field: 'proposed_spending',
        headerName: 'Proposed Spending', 
        minWidth: 200,
        comparator: SortFunctions.numeric,
        cellRenderer: currencyRenderer
    },

    { 
        field: 'amount', 
        headerName: 'Amount',
        minWidth: 200, 
        editable: (params) => params.data.readOnly ? false : true,
        comparator: SortFunctions.numeric,
        cellRenderer: currencyRenderer,
        cellEditor: CurrencyCellEditor
    },
];