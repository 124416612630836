import ajax from './Ajax';

/**
 * The log class.
 */
class Log
{
    /**
     * Object constructor.
     */
    constructor()
    {
        this.originalConsole = {};
        this.currentlyLogging = false;
    }


    /**
     * The initialization method.
     */
    initialize()
    {
        // Save the original console
        this.originalConsole =
        {
            log: console.log,
            error: console.error,
        };

        // Redefine the console log methods...
        console.log   = this.log.bind(this);
        console.error = this.error.bind(this);
    }


    /**
     * This method logs the message.
     * 
     * @param {*} message The message.
     */
    log(message)
    {
        this.doLog(message, "Log", this.originalConsole.log);
    }

    /**
     * This method logs the error.
     * 
     * @param {*} message The message.
     */
    error(message)
    {
        this.doLog(message, "Error", this.originalConsole.error);
    }


    /**
     * This method logs the message to the console only.
     * 
     * @param {*} message The message.
     */
    logMessageToConsoleOnly(message)
    {
        // Convert the message
        message = this.convertError(message);

        // Log the message
        this.originalConsole.log.call(console, message);
    }

    /**
     * This method logs the error to the console only.
     * 
     * @param {*} message The message.
     */
    logErrorToConsoleOnly(message)
    {
        // Convert the message
        message = this.convertError(message);

        // Log the message
        this.originalConsole.error.call(console, message);
    }

    /**
     * This method handles the new logging mechanism.
     * 
     * @param {*} message The message.
     * @param {*} level The level.
     * @param {*} originalFn The original function.
     */
    doLog(message, level, originalFn)
    {
        // Convert the message
        message = this.convertError(message);

        // Don't log if this method generates an error
        if (this.currentlyLogging)
        {
            // Log to the original console
            originalFn.call(console, message);

            // Get out...
            return;
        }

        // Set the currently logging flag
        this.currentlyLogging = true;

        try
        {
            // Get the route
            let route = window.location.pathname;

            // Add the hash if appropriate
            if (window.location.hash)
                route += "#" + window.location.hash;

            // Construct th epacket
            let data =
            {
                level: level,
                route: route,
                message: message
            }

            // Send the data to the server
            ajax.postSilent("/log", data);

            // Log to the original console...
            originalFn.call(console, message);
        }

        catch (error)
        {
            // Log the error
            this.originalConsole.error(this.convertError());
        }

        // Clear the currently logging flag...
        this.currentlyLogging = false;
    }


    /**
     * This method converts the error.
     * 
     * @param {*} message The message.
     * @returns The converted message if it was an error, otherwise the message.
     */
    convertError(message)
    {
        if (message instanceof Error)
            return message.name + "\n" + message.message + "\n" + message.stack;

        return message;
    }
}

// Export the log
export default new Log();
