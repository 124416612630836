import { createSlice } from '@reduxjs/toolkit';

// Create the initial state
const initialState = 
{
    gamesLoaded: false,
    gameMap: {},
    gameDetailsMap: {},
    games: [],
    gameTeamsMap: {},
    allGameTeamsMap: {},
    teamMap: {},
    allTeamsMap: {},
    dashboardDetailsMap: {},
    teamPerformanceMap: {},
    regulatoryReportMap: {},
    mergersAndAcquisitionsMap: {},
    mAndAScenariosMap: {}
};

// Create the slice
export const gamesSlice = createSlice(
{
    name: 'games',
    initialState,

    // The `reducers` field lets us define reducers and generate associated actions
    reducers: 
    {
        /**
         * This method updates the state for the set games operation.
         * 
         * @param {*} state The state.
         * @param {*} action The action.
         */
        setGames: (state, action) => 
        {
            // Set the games-loaded flag
            state.gamesLoaded = true;

            // Set the state
            state.games = action.payload;

            // Reset the game map
            state.gameMap = {};

            // Set the game map
            for (let game of state.games)
                state.gameMap[game.game_id] = game;
        },


        /**
         * This method updates the state for the set game operation.
         *
         * @param {*} state The state.
         * @param {*} action The action.
         */
        setGameDetails: (state, action) =>
        {
            // Update the state
            state.gameDetailsMap[action.payload.gameId] = action.payload.gameDetails;

            // Update the game in the map...
            if (state.gameMap[action.payload.gameId])
            {
                state.gameMap[action.payload.gameId].frozen             = action.payload.gameDetails.frozen;
                state.gameMap[action.payload.gameId].turns_completed    = action.payload.gameDetails.turns_completed;
                state.gameMap[action.payload.gameId].manda_enabled      = action.payload.gameDetails.manda_enabled;
                state.gameMap[action.payload.gameId].lockdown_time      = action.payload.gameDetails.lockdown_time;
            }

            // Update the game...
            for (let game of state.games)
            {
                // Find our game...
                if (game.game_id != action.payload.gameId)
                    continue;

                // Update the frozen field
                game.frozen            = action.payload.gameDetails.frozen;
                game.turns_completed   = action.payload.gameDetails.turns_completed;
                game.manda_enabled     = action.payload.gameDetails.manda_enabled;
                game.lockdown_time     = action.payload.gameDetails.lockdown_time;
                break;
            }
        },


        /**
         * This method sets the game's frozen flag.
         *
         * @param {*} state The state.
         * @param {*} action The action.
         */
        setGameFrozen: (state, action) =>
        {
            // Set the game map
            for (let game of state.games)
            {
                // Look for our game...
                if (game.game_id != action.payload.game_id)
                    continue;

                // Set the frozen flag...
                game.frozen = action.frozen;

                // Break out...
                break;
            }

            // Update the map as well...
            if (state.gameMap[action.payload.game_id])
                state.gameMap[action.payload.game_id].frozen = action.payload.frozen;
        },


        /**
         * This method updates the state for the set teams operation.
         *
         * @param {*} state The state.
         * @param {*} action The action.
         */
        setTeams: (state, action) =>
        {
            // Initialize the teams
            let teams = [];

            // Only add the teams for which the user is a member...
            for (let team of action.payload.teams)
            {
                if (team.is_member)
                    teams.push(team);
            }

            // Save the game teams
            state.gameTeamsMap[action.payload.game_id] = teams;

            // Set all the game teams
            state.allGameTeamsMap[action.payload.game_id] = action.payload.teams;

            // Populate the team map
            for (let team of action.payload.teams)
            {
                // Set the regulator report into the map
                if (!state.regulatoryReportMap[team.team_id])
                    state.regulatoryReportMap[team.team_id] = {};

                // Save the turns
                state.regulatoryReportMap[team.team_id].turns = team.regulatory_report_turns;

                // Set the dashboard details if necessary...
                if (!team.dashboardDetails)
                    team.dashboardDetails = state.teamMap[team.teamId] ? state.teamMap[team.teamId].dashboardDetails : null;

                // Place it into the team map if the user is a member
                if (team.is_member)
                    state.teamMap[team.team_id] = team;

                // Put it into the all teams map
                state.allTeamsMap[team.team_id] = team;
            }
        },


        /**
         * This method sets the dashboard details.
         *
         * @param {*} state The state.
         * @param {*} action The action.
         */
        setDashboardDetails: (state, action) =>
        {
            // Set the dashboard details into the map
            state.dashboardDetailsMap[action.payload.team_id] = action.payload.dashboardDetails;
        },


        /**
         * This method sets the team performance.
         * 
         * @param {*} state The state.
         * @param {*} action The action.
         */
        setTeamPerformance: (state, action) =>
        {
            // Set the team performance into the map
            state.teamPerformanceMap[action.payload.team_id] = action.payload.teamPerformance;
        },


        /**
         * This method sets the regulatory report.
         * 
         * @param {*} state The state.
         * @param {*} action The action.
         */
        setRegulatoryReport: (state, action) =>
        {
            // Set the regulator report into the map
            if (!state.regulatoryReportMap[action.payload.team_id])
                state.regulatoryReportMap[action.payload.team_id] = {};

            // Save the regulator report...
            state.regulatoryReportMap[action.payload.team_id].regulatoryReport = action.payload.regulatoryReport;
            state.regulatoryReportMap[action.payload.team_id].turnNum          = action.payload.turnNum;
        },


        /**
         * This method sets the mergers & acquisitions.
         *
         * @param {*} state The state.
         * @param {*} action The action.
         */
        setMergersAndAcquisitions: (state, action) =>
        {
            // Set the dashboard details into the map
            state.mergersAndAcquisitionsMap[action.payload.team_id] = action.payload.mergersAndAcquisitions;

            // Save the scenarios
            state.mAndAScenariosMap[action.payload.team_id] = action.payload.scenarios;
        },


        /**
         * This method sets the M&A scenarios.
         *
         * @param {*} state The state.
         * @param {*} action The action.
         */
        setMergersAndAcquisitionsScenarios: (state, action) =>
        {
            // Save the scenarios
            state.mAndAScenariosMap[action.payload.team_id] = action.payload.scenarios;
        },


        /**
         * This method sets the team's frozen flag.
         *
         * @param {*} state The state.
         * @param {*} action The action.
         */
        setTeamFrozen: (state, action) =>
        {
            let teams = state.gameTeamsMap[action.payload.game_id];
            if (teams)
            {
                for (let team of teams)
                {
                    if (team.team_id !== action.payload.team_id)
                        continue;

                    team.frozen = action.payload.frozen;
                    break;
                }
            }

            let team = state.teamMap[action.payload.team_id];
            if (team)
                team.frozen = action.payload.frozen;
        },


        /**
         * This method sets the team's disable whatifs flag.
         *
         * @param {*} state The state.
         * @param {*} action The action.
         */
        setTeamDisableWhatIfs: (state, action) =>
        {
            let teams = state.gameTeamsMap[action.payload.game_id];
            if (teams)
            {
                for (let team of teams)
                {
                    if (team.team_id !== action.payload.team_id)
                        continue;

                    team.disable_whatifs = action.payload.disable_whatifs;
                    break;
                }
            }

            let team = state.teamMap[action.payload.team_id];
            if (team)
                team.disable_whatifs = action.payload.disable_whatifs;
        },


        /**
         * This method sets the team's name.
         *
         * @param {*} state The state.
         * @param {*} action The action.
         */
        setTeamName: (state, action) =>
        {
            let teams = state.gameTeamsMap[action.payload.game_id];
            if (teams)
            {
                for (let team of teams)
                {
                    if (team.team_id !== action.payload.team_id)
                        continue;

                    team.team_name = action.payload.team_name;
                    break;
                }
            }

            let team = state.teamMap[action.payload.team_id];
            if (team)
                team.team_name = action.payload.team_name;
        },


        /**
         * This method disables the whatifs for the team.
         *
         * @param {*} state The state.
         * @param {*} action The action.
         */
        disableWhatIfs: (state, action) =>
        {
            let teams = state.gameTeamsMap[action.payload.game_id];
            if (teams)
            {
                for (let team of teams)
                {
                    if (team.team_id !== action.payload.team_id)
                        continue;

                    team.disable_whatifs = true;
                    break;
                }
            }

            let team = state.teamMap[action.payload.team_id];
            if (team)
                team.disable_whatifs = true;
        },


        /**
         * This method updates the measures of success.
         * 
         * @param {*} state The state.
         * @param {*} action The action.
         */
        updateMeasuresOfSuccess: (state, action) =>
        {
            let teams = state.gameTeamsMap[action.payload.game_id];
            if (teams)
            {
                for (let team of teams)
                {
                    if (team.team_id !== action.payload.team_id)
                        continue;

                    team.measures_of_success = action.payload.measures_of_success;
                    break;
                }
            }

            let team = state.teamMap[action.payload.team_id];
            if (team)
                team.measures_of_success = action.payload.measures_of_success;
        }
    }
});

// Export the games actions
export const { setGames, setGameDetails, setTeams, setDashboardDetails, setTeamPerformance, setRegulatoryReport,
               setMergersAndAcquisitions, setMergersAndAcquisitionsScenarios, setTeamFrozen, setTeamName,
               disableWhatIfs, setGameFrozen, setTeamDisableWhatIfs, updateMeasuresOfSuccess } = gamesSlice.actions;

/**
 * This method selects the user ID.
 * 
 * @param {*} state The state.
 * @param {*} id The game ID.
 * @returns The game ID.
 */
export const selectGame = (state, id) => state.gameMap[id];

/**
 * This method selects the games.
 * 
 * @param {*} state The state.
 * @returns The role.
 */
export const selectGames = (state) => state.games;

/**
 * This method selects the game map.

 * @param {*} state The state.
 * @returns The game map.
 */
export const selectGameMap = (state) => state.gameMap;

/**
 * This method selects the games-loaded flag.
 *
 * @param {*} state The state.
 * @returns The loaded flag.
 */
export const selectAreGamesLoaded = (state) => state.gamesLoaded;

// Get out...
export default gamesSlice.reducer;
