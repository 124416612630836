/** 
    * The alphanumeric comparison method.
    *
    * @param text1 The first text.
    * @param text2 The second text.
    * @returns The comparison result.
    */
export const alphaNumeric = function (text1, text2)
{
    return text1.localeCompare(text2);
}

/**
    * The numeric comparison method.
    *
    * @param {*} number1 The first number.
    * @param {*} number2 The second number.
    * @returns The comparison result.
    */
export const numeric = function(number1, number2)
{
    // Make sure they are actually numbers...
    number1 = parseFloat(number1);
    number2 = parseFloat(number2);

    // Perform the comparison...
    return number1 === number2 ? 0 : number1 > number2 ? 1 : -1;
}


/**
 * The date comparison method.
 *
 * @param {*} date1 The first date.
 * @param {*} date2 The second date.
 * @returns The comparison result.
 */
export const date = function(date1, date2)
{
    date1 = new Date(date1).getTime();
    date2 = new Date(date2).getTime();

    return date1 == date2 ? 0 : date1 > date2 ? 1 : -1;
}


/**
 * The sort functions.
 */
const SortFunctions =
{
    alphaNumeric: alphaNumeric,
    numeric: numeric,
    date: date
}

// Export them...
export default SortFunctions;