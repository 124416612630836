import Utils from "../utils/Utils";


/**
 * The formatter.
 */
const formatter = new Intl.NumberFormat(navigator.language, 
{
    style: 'currency',
    currency: 'usd',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});


/**
 * The pennies formatter.
 */
const penniesFormatter = new Intl.NumberFormat(navigator.language, 
{
    style: 'currency',
    currency: 'usd',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});


/**
 * This method formats the currency.
 *
 * @param {*} currency The currency.
 * @returns The formatted currency.
 */
export function formatCurrency(currency)
{
    return formatter.format(Utils.parseCurrency(currency));
}


/**
 * This method formats the currency with pennies.
 *
 * @param {*} currency The currency.
 * @returns The formatted currency.
 */
export function formatCurrencyWithPennies(currency)
{
    return penniesFormatter.format(Utils.parseCurrency(currency));
}


/**
 * The currency control.
 *
 * @param {*} props The properties
 * @returns The formatted currency
 */
export default function Currency(props) 
{
    return formatCurrency(props.value);
}