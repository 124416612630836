import { configureStore } from '@reduxjs/toolkit';
import authenticationReducer, { fromSessionStorage } from '../features/authentication/AuthenticationSlice';
import gamesReducer from '../features/games/GamesSlice';
import intraturnsReducer from '../features/intraturns/IntraturnsSlice';
import messageReducer from '../features/message/MessageSlice';
import blockReducer from '../features/block/BlockSlice';
import staticReducer from '../features/static/StaticSlice';
import redirectReducer from '../features/redirect/RedirectSlice';

// Export the store
export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    games: gamesReducer,
    intraturns: intraturnsReducer,
    message: messageReducer,
    block: blockReducer,
    static: staticReducer,
    redirect: redirectReducer
  },
});


// Export the populate the store from session storage method
export const populateStoreFromSessionStorage = () =>
{
    store.dispatch(fromSessionStorage());
};
