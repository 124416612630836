import { Fragment } from 'react';
import { Row, Col } from "react-bootstrap";

import FormControl from "../../../controls/form/FormControl";
import TeamSelector from "../../game/components/TeamSelector";
import PercentageControl from '../../../controls/form/PercentageControl';
import CurrencyControl from '../../../controls/form/CurrencyControl';
import { formatPercentage } from '../../../controls/Percentage';
import BaseMergersAndAcquisitionsTab from './BaseMergersAndAcquisitionsTab';
import calculationHelpers from './CalculationHelpers';


/**
 * The Statistics class.
 */
class Statistics extends BaseMergersAndAcquisitionsTab
{
    /**
     * The team change event handler.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onTeamChange = (name, value) =>
    {
        // Convert empty string to zero...
        if (value == '') 
            value = 0;
        else
            value = parseInt(value); 
            
        // Call the field change handler...
        this.props.onFieldChange(name, value);
    }


    /**
     * This method renders the component.
     */
    render()
    {
        // Set the teams
        let team1 = (this.props.mergersAndAcquisitionsMap && this.props.commonState.team1) ? this.props.mergersAndAcquisitionsMap[this.props.commonState.team1] : null;
        let team2 = (this.props.mergersAndAcquisitionsMap && this.props.commonState.team2) ? this.props.mergersAndAcquisitionsMap[this.props.commonState.team2] : null;
        let team3 = (this.props.mergersAndAcquisitionsMap && this.props.commonState.team3) ? this.props.mergersAndAcquisitionsMap[this.props.commonState.team3] : null;

        // Calculate the statistics
        let statistics = this.calculateStatistics();

        // Calculate the disclsure
        let disclosure = this.calculateDisclosure();

        // Initialize the tab index
        let tabIndex2 = 2;
        let tabIndex3 = 10;

        return(
            <Fragment>
                <FormControl name="Bank Statistics">
                    <div className="manda-grid panel-body">
                        <Row className="header g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><TeamSelector teams={ this.props.teams } name="team1" onChange={ this.onTeamChange } value={ this.props.commonState.team1 } required={ false } ignoreTeams={ [ this.props.commonState.team2, this.props.commonState.team3 ] } tabIndex={ 1 } /></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><TeamSelector teams={ this.props.teams } name="team2" onChange={ this.onTeamChange } value={ this.props.commonState.team2 } required={ false } ignoreTeams={ [ this.props.commonState.team1, this.props.commonState.team3 ] } tabIndex={ tabIndex2++ } /></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><TeamSelector teams={ this.props.teams } name="team3" onChange={ this.onTeamChange } value={ this.props.commonState.team3 } required={ false } ignoreTeams={ [ this.props.commonState.team1, this.props.commonState.team2 ] } tabIndex={ tabIndex2++ }  /></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right padded-header"><b>Combined Bank</b></Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Total Assets</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team1, "Total Assets") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team2, "Total Assets") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team3, "Total Assets") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printSumValuesInMillions(team1, team2, team3, "Total Assets") }</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Owner's Equity</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team1, "Equity") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team2, "Equity") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team3, "Equity") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printSumValuesInMillions(team1, team2, team3, "Equity") }</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Tier One (Leverage) Ratio</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printPercentage(team1, "Leverage Ratio") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printPercentage(team2, "Leverage Ratio") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printPercentage(team3, "Leverage Ratio") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className={ "right value " + (this.calculateCombinedLevelRatio(team1, team2, team3) < 6 ? "bad" : "good") }>{ this.printCombinedLevelRatio(team1, team2, team3) }</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Dividend to Holding Company (millions)</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2}></Col>
                            <Col xs={6} sm={6} md={3} lg={2}></Col>
                            <Col xs={6} sm={6} md={3} lg={2}></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><CurrencyControl name="holdingCompanyDividends" value={ this.props.commonState.holdingCompanyDividends } onChange={ this.onFieldChange } style={ { textAlign: "right" } } tabIndex={ tabIndex3++ }/></Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Merged Bank's New Tier One (Leverage) Ratio</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2}></Col>
                            <Col xs={6} sm={6} md={3} lg={2}></Col>
                            <Col xs={6} sm={6} md={3} lg={2}></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ this.printMergedBankLevelRatio(team1, team2, team3) }</Col>
                        </Row>
                    </div>
                </FormControl>

                <div className="manda-spacer"></div>

                <FormControl name="Memo Statistics">
                    <div className = "manda-grid panel-body">
                        <Row className="header g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><b>{ team1 ? this.props.teamMap[this.props.commonState.team1].team_name : "None Selected" }</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><b>{ team2 ? this.props.teamMap[this.props.commonState.team2].team_name : "None Selected" }</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><b>{ team3 ? this.props.teamMap[this.props.commonState.team3].team_name : "None Selected" }</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><b>Combined Bank</b></Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Loan Loss Reserve</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team1, "Reserve for Loan Loss") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team2, "Reserve for Loan Loss") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team3, "Reserve for Loan Loss") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printSumValuesInMillions(team1, team2, team3, "Reserve for Loan Loss") }</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Annualized Net Income</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team1, "Net Income after Tax") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team2, "Net Income after Tax") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team3, "Net Income after Tax") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printSumValuesInMillions(team1, team2, team3, "Net Income after Tax") }</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Annualized Net Interest Income</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team1, "Annualized Net Interest Income") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team2, "Annualized Net Interest Income") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team3, "Annualized Net Interest Income") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printSumValuesInMillions(team1, team2, team3, "Annualized Net Interest Income") }</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Annualized Fees and Service Charges</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team1, "Non-Interest Income") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team2, "Non-Interest Income") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team3, "Non-Interest Income") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printSumValuesInMillions(team1, team2, team3, "Non-Interest Income") }</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Annualized Operating Expenses</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team1, "Non-Interest Expense") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team2, "Non-Interest Expense") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team3, "Non-Interest Expense") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printSumValuesInMillions(team1, team2, team3, "Non-Interest Expense") }</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Stock Price Per Share</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter2Decimals().format(calculationHelpers.getValue(team1, "Stock Price")) }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? calculationHelpers.getDollarFormatter2Decimals().format(calculationHelpers.getValue(team2, "Stock Price")) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? calculationHelpers.getDollarFormatter2Decimals().format(calculationHelpers.getValue(team3, "Stock Price")) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter2Decimals().format(calculationHelpers.getValue(team1, "Stock Price") + calculationHelpers.getValue(team2, "Stock Price") + calculationHelpers.getValue(team3, "Stock Price")) }</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Number of Shares Outstanding</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ this.formatNumber(calculationHelpers.getValue(team1, "Common Shares")) }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? this.formatNumber(calculationHelpers.getValue(team2, "Common Shares")) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? this.formatNumber(calculationHelpers.getValue(team3, "Common Shares")) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ this.formatNumber(calculationHelpers.sumValues(team1, team2, team3, "Common Shares")) }</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>EPS</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team1, "EPS") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team2, "EPS") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team3, "EPS") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2}>&nbsp;</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>PE</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printRatio(team1, "PE") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printRatio(team2, "PE") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printRatio(team3, "PE") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2}>&nbsp;</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Market Cap</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter2Decimals().format(calculationHelpers.getValue(team1, "Market Cap")) }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter2Decimals().format(calculationHelpers.getValue(team2, "Market Cap")) }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter2Decimals().format(calculationHelpers.getValue(team3, "Market Cap")) }</Col>
                            <Col xs={6} sm={6} md={3} lg={2}>&nbsp;</Col>
                        </Row>                                                
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Market / Book</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printRatio(team1, "Market Book") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printRatio(team2, "Market Book") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printRatio(team3, "Market Book") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2}>&nbsp;</Col>
                        </Row>                                                
                    </div>
                </FormControl>

                <div className="manda-spacer"></div>

                <FormControl name="Stock Offer Based Upon Desired EPS">
                    <div className = "manda-grid panel-body">
                        <Row className="header g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><b>{ team1 ? this.props.teamMap[this.props.commonState.team1].team_name : "None Selected" }</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><b>{ team2 ? this.props.teamMap[this.props.commonState.team2].team_name : "None Selected" }</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><b>{ team3 ? this.props.teamMap[this.props.commonState.team3].team_name : "None Selected" }</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">&nbsp;</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Annual Earnings Per Share</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter().format(calculationHelpers.getValue(team1, "Annual Earnings Per Share")) }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? calculationHelpers.getDollarFormatter().format(calculationHelpers.getValue(team2, "Annual Earnings Per Share")) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? calculationHelpers.getDollarFormatter().format(calculationHelpers.getValue(team3, "Annual Earnings Per Share")) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">&nbsp;</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Cost Savings Rate (% of annual operating expense)</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">N/A</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? <PercentageControl name="costSavingsPercentage2" value={ this.props.commonState.costSavingsPercentage2 } onChange={ this.onFieldChange } style={ { textAlign: "right" } } tabIndex={ tabIndex2++ }/> : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? <PercentageControl name="costSavingsPercentage3" value={ this.props.commonState.costSavingsPercentage3 } onChange={ this.onFieldChange } style={ { textAlign: "right" } } tabIndex={ tabIndex3++ } /> : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">&nbsp;</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Cost Savings</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">N/A</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? calculationHelpers.getDollarFormatter().format(statistics.costSavings2) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? calculationHelpers.getDollarFormatter().format(statistics.costSavings3) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">&nbsp;</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Int Inc Enhancement Rates</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">N/A</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? <PercentageControl name="enhancementRate2" value={ this.props.commonState.enhancementRate2 } onChange={ this.onFieldChange } style={ { textAlign: "right" } } tabIndex={ tabIndex2++ } /> : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? <PercentageControl name="enhancementRate3" value={ this.props.commonState.enhancementRate3 } onChange={ this.onFieldChange } style={ { textAlign: "right" } } tabIndex={ tabIndex3++ } /> : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">&nbsp;</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Int Inc Enhancement</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">N/A</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? calculationHelpers.getDollarFormatter().format(statistics.enhancementAmount2) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? calculationHelpers.getDollarFormatter().format(statistics.enhancementAmount3) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">&nbsp;</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Fee Inc Enhancement Rates</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">N/A</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? <PercentageControl name="feeEnhancementRate2" value={ this.props.commonState.feeEnhancementRate2 } onChange={ this.onFieldChange } style={ { textAlign: "right" } } tabIndex={ tabIndex2++ } /> : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? <PercentageControl name="feeEnhancementRate3" value={ this.props.commonState.feeEnhancementRate3 } onChange={ this.onFieldChange } style={ { textAlign: "right" } } tabIndex={ tabIndex3++ } /> : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">&nbsp;</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Fee Inc Enhancement</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">N/A</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? calculationHelpers.getDollarFormatter().format(statistics.feeEnhancementAmount2) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? calculationHelpers.getDollarFormatter().format(statistics.feeEnhancementAmount3) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">&nbsp;</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Pro Forma Annual Earnings</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.printCurrencyInMillions(team1, "Net Income after Tax") }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? calculationHelpers.getDollarFormatter().format(statistics.proFormaEarnings2) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? calculationHelpers.getDollarFormatter().format(statistics.proFormaEarnings3) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">&nbsp;</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Desired Annual Earnings Per Share</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">N/A</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className={ "right" + (team2 ? " value good" : "") }>{ team2 ? calculationHelpers.getDollarFormatter().format(calculationHelpers.getValue(team1, "Annual Earnings Per Share")) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className={ "right" + (team3 ? " value good" : "") }>{ team3 ? calculationHelpers.getDollarFormatter().format(calculationHelpers.getValue(team1, "Annual Earnings Per Share")) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">&nbsp;</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Holding Company Shares Offered</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ this.formatNumber(calculationHelpers.getValue(team1, "Common Shares")) }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? this.formatNumber(disclosure.bankSharesTendered2) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? this.formatNumber(disclosure.bankSharesTendered3) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">&nbsp;</Col>
                        </Row>
                    </div>
                </FormControl>                                        
            </Fragment>
        );
    }
}

// Export the statistics...
export default Statistics;