import { redirect, redirectWithReturn  } from "../../features/redirect/RedirectSlice";
import { block, unblock } from "../../features/block/BlockSlice";
import { store } from '../store';

/**
 * The utilities class.
 */
class Utils
{
    /**
     * The active route.
     */
    static activeRoute;

    /**
     * The active parameters.
     */
    static activeParams = {};

    /**
     * The block count.
     */
    static blockCount = 0;

    /**
     * The list of block messages.
     */
    static blockList = [];



    /**
     * This method sets the active route.
     * 
     * @param {*} route The route.
     */
    static setActiveRoute(route)
    {
        Utils.activeRoute = route;
    }


    /**
     * This method sets the route params.
     * 
     * @param {*} routeParams The route parameters.
     */
    static setRouteParams(routeParams)
    {
        Utils.activeParams = routeParams;
    }


    /**
     * This method gets the active route.
     * 
     * @returns The active route.
     */
    static getActiveRoute()
    {
        return Utils.activeRoute;
    }


    /**
     * This method gets the active parameters.
     *
     * @returns The active parameters.
     */
    static getActiveParams()
    {
        return Utils.activeParams;
    }


    /**
     * This method redirects to the route.
     * 
     * @param {*} route The route.
     */
    static redirect(route)
    {
        store.dispatch(redirect(route));
    }

    /**
     * This method redirects to the route and sets the return URL.
     * 
     * @param {*} redirectRoute The redirect route.
     * @param {*} returnRoute The return route.
     */
     static redirectWithReturnRoute(redirectRoute, returnRoute)
     {
         store.dispatch(redirectWithReturn({ redirectRoute: redirectRoute, returnRoute: returnRoute }));
     }
 
     /**
     * This method blocks the UI.
     * 
     * @param {*} message The message.
     */
    static blockUI(message)
    {
        // Get the message
        let blockObject =
        {
            id: Utils.blockCount++,
            message: message
        }

        // Add the object to the list
        Utils.blockList.push(blockObject);

        // Block the text
        store.dispatch(block(message));

        // Get out...
        return blockObject;
    }

    /**
     * This method unblocks the UI.
     *
     * @param {*} blockObject The block object.
     */
    static unblockUI(blockObject)
    {
        // Get the index of the object
        let index = Utils.blockList.findIndex((otherObject) => otherObject.id === blockObject.id);

        // Get out if it has already been unblocked
        if (index === -1)
            return;

        // Remove the index from the list
        Utils.blockList.splice(index, 1);
        
        // Unblock if this is the last message, otherwise revert to the previous message...
        if (Utils.blockList.length === 0)
            store.dispatch(unblock());
        else
            store.dispatch(block(Utils.blockList[Utils.blockList.length - 1].message));
    }


    /**
     * This method parses the currency value.
     *
     * @param {*} currency.
     * @returns The float value. 
     */
    static parseCurrency(value)
    {
        // Null or undefined go to zero...
        if (!value)
            return 0;

        // Clean the value
        value = value.toString().replace(/[$,]/g, '');
        
        // Convert it to a float
        return parseFloat(value);
    }


    /**
     * This method formats the text.
     *
     * @param {*} format The format.
     * @param  {...any} args The arguments.
     * @returns The formatted text.
     */
    static formatText(format, ...args)
    {
        // Replace the formatted text...
        return format.replace(/{(\d+)}/g, function(match, number) 
        { 
            // Return the argument...
            return typeof args[number] != 'undefined' ? args[number] : match;
        });
    }


    /**
     * This method downloads the data to the file name.
     *
     * @param {*} fileName The file name.
     * @param {*} data The data.
     */
    static download(fileName, data)
    {
        let url = window.URL.createObjectURL(new Blob([ Buffer.from(data, 'base64') ]));
        let a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();    
    }


    /**
     * This method compares two arrays.
     *
     * @param {*} a The first array.
     * @param {*} b The second array.
     * @returns true if they are the same, otherwise false.
     */
    static arrayEquals(a, b) 
    {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            JSON.stringify(a) === JSON.stringify(b);
    }


    /**
     * This method executes the method.
     *
     * @param {*} later The later flag.
     * @param {*} fn The function.
     */
    static execute(later, fn)
    {
        if (!later)
        {
            fn();
            return;
        }

        setTimeout(fn, 100);
    }
}

// Export the utilities...
export default Utils;
