import { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";

import KPI from "./KPI";


/**
 * The team key indicators component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
const TeamKeyIndicators = (props) =>
{
    // Get out if nothing to do...
    if (!props.kpiMap)
        return <Fragment />;


    // Get out...
    return (
        <Container>
            <Row>
                <Col xs={6} sm={6} md={4} lg={4} className="key-indicator">
                </Col>
                <Col xs={4} sm={4} md={2} lg={2} className="key-indicator">
                    Current QTR
                </Col>
                <Col xs={4} sm={4} md={2} lg={2} className="key-indicator">
                    Previous QTR
                </Col>
                <Col xs={4} sm={4} md={2} lg={2} className="key-indicator">
                    Variance
                </Col>
            </Row>

            {
                props.kpiTypes.map((kpiType, index) =>
                {
                    return (
                        <Fragment>
                            { index > 0 ? <div className="kpi-spacer" /> : "" }
                            <KPI kpi={ props.kpiMap[kpiType] } />
                        </Fragment>
                    )
                })
            }
        </Container>
    );
}

// Get out...
export default TeamKeyIndicators;


