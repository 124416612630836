import { Fragment } from 'react';
import { withRouter, Prompt } from "react-router";
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Utils from "../../utils/Utils";

import gameService from "../../services/GameService";
import { COLUMNS, LITE_COLUMNS } from "./columns/AdjustmentsColumns";
import BaseGameView from "./BaseGameView";
import TurnSelect from "./decisions/components/TurnSelect";
import ErrorBox from "../../components/ErrorBox";
import SelectControl from "../../controls/form/SelectControl";
import DecisionGrid from "./decisions/components/DecisionGrid";


/**
 * The AdjustmentsView class.
 */
class AdjustmentsView extends BaseGameView
{
    /**
     * The object constructor
     */
    constructor(props)
    {
        // Call mom
        super(props);

        // Initialize the loaded flag
        this.loaded= false;

        // Initialize the state
        this.state =
        {
            dirtyRows: {},
            dirtyEvents: {},
            rows: [],
            events: []
        }
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Call mom...
        let result = super.isComponentReady();

        // Load the adjustments if necessary...
        if (this.props.game && !this.loaded)
        {
            // Load the adjustments
            this.loadAdjustments(this.props.game.turns_completed + 1);

            // Not ready...
            return false;
        }

        // Get out...
        return result;
    }


    /**
     * This method loads the adjustments.
     *
     * @param {*} turnNum The turn number.
     */
    loadAdjustments(turnNum)
    {
        // Get out if the state is loaded
        if (!this.props.game)
            return;

        // Only load once...
        if (this.loadingAdjustments)
            return;

        // Get out if nothing to do...
        if (this.state.selectedTurn === turnNum)
            return;

        // Warn the user if there are changes...
        if (this.isDirty())
        {
            if (!window.confirm("You have unsaved changes.  Do you wish to proceed?"))
                return;
        }

        // Mark us as loading...
        this.loadingAdjustments = true;

        // Load the adjustments
        gameService.loadAdjustments(this.props.game.game_id, turnNum, this.onAdjustmentsLoaded);
    }


    /**
     * The adjustments loaded event handler.
     *
     * @param {*} adjustments The adjustments.
     * @param {*} events The events.
     * @param {*} turnNum The turn number.
     */
    onAdjustmentsLoaded = (adjustments, events, turnNum) =>
    {
        // Initialize the selected event
        let selectedEvent = this.state.selectedEvent;

        // Handle events if appropriate
        if (events)
        {
            // Set the events
            events = events.map(event => ({ name: event.name, value: event.event_id }));

            // Default to the first event if necessary
            if (!selectedEvent && events && events.length)
            {
                // Select the first event
                selectedEvent = events[0].value;

                // Set the state
                this.setState({ selectedEvent: selectedEvent, events: events });
            }
        }


        // Set the filtered adjustments
        this.setFilteredAdjustments(selectedEvent, adjustments);

        // Set the state
        this.setState({ adjustments: adjustments, selectedTurn: turnNum, dirtyRows: {}, dirtyEvents: {} });

        // Clear the loading flag
        this.loadingAdjustments = false;

        // Set the loaded flag
        this.loaded = true;
    }


    /**
     * This method sets the filtered adjustments.
     *
     * @param {*} selectedEvent The selected event.
     * @param {*} adjustments The adjustments.
     */
    setFilteredAdjustments(selectedEvent, adjustments)
    {
        // Filter the adjustments
        let filteredAdjustments = adjustments.filter(adjustment => adjustment.event_id == selectedEvent);

        // Update the state
        this.setState({ selectedEvent: selectedEvent, filteredAdjustments: filteredAdjustments });
    }


    /**
     * This method handles event changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onChangeEvent = (name, value) =>
    {
        this.setFilteredAdjustments(parseInt(value), this.state.adjustments);
    }


    /**
     * The turn change event handler.
     */
    onTurnChange = (name, value) =>
    {
        this.loadAdjustments(parseInt(value));
    }


    /**
     * The is-dirty method.
     *
     * @returns true if there is unsaved data, else false.
     */
    isDirty()
    {
        return (Object.keys(this.state.dirtyRows).length + Object.keys(this.state.dirtyEvents).length) > 0 ? true : false;
    }

    /**
     * This method updates the state when the cell value has been modified.
     *
     * @param {*} params The params.
     */
    onCellValueChanged = (params) =>
    {
        // Get the dirty rows map...
        let dirtyRows = this.state.dirtyRows;

        // Get the dirty events map...
        let dirtyEvents = this.state.dirtyEvents;

        // Make a copy of the adjustments...
        let adjustments = [ ...this.state.adjustments ];

        // Mark it dirty
        dirtyRows[params.data.invest_purchase_id] = true;

     
        // Mark it as dirty
        if (params.data.team_turn_adjustment_id)
            dirtyRows[params.data.team_turn_adjustment_id] = true;
        else
            dirtyEvents[params.data.event_id] = true;

        // Find the master adjustment
        for (let adjustment of adjustments)
        {
            // See if we found our adjustment...
            if ((adjustment.team_turn_id !== params.data.team_turn_id) ||
                (adjustment.event_id !== this.state.selectedEvent))
            {
                continue;
            }

            // Update it...
            adjustment.income  = Utils.parseCurrency(params.data.income);
            adjustment.expense = Utils.parseCurrency(params.data.expense);

            // Get out...
            break;
        }
                
        // Displays should most recent up to date values.
        params.api.refreshCells();

        // Save the state
        this.setState(
        {
            adjustments: adjustments,
            dirtyRows: dirtyRows,
            dirtyEvents: dirtyEvents
        });

        // Save the state
        this.setState(
        {
            dirtyRows: dirtyRows
        });
    }
    

    /**
     * This method saves the changes.
     */
    onSave = () =>
    {
        // Get out if this is not actually dirty (shouldn't happen)
        if (!this.isDirty())
            return;


        // Get out if nothing to do...
        if (!this.isDirty())
            return false;

        // Initialize the updated adjustments
        let updatedAdjustments = [];

        // Check each row...
        for (let adjustment of this.state.adjustments)
        {
            // See if it is dirty...
            if (!this.state.dirtyRows[adjustment.team_turn_adjustment_id] &&
                !this.state.dirtyEvents[adjustment.event_id])
            {
                continue;
            }

            // Add the updated row..
            updatedAdjustments.push(adjustment);
        }

        // Update the adjustments
        gameService.updateAdjustments(this.props.game.game_id, this.state.selectedTurn, updatedAdjustments, this.onSaveComplete);

        // Get out...
        return true;
    }


    /**
     * The save complete event handler.
     *
     * @param {*} adjustments The adjustments.
     * @param {*} events The events.
     * @param {*} turnNum The turn number.
     */
    onSaveComplete = (adjustments, events, turnNum) =>
    {
        // Clear the dirty flag
        this.clearDirtyRows();

        // Update the adjustments
        this.onAdjustmentsLoaded(adjustments, events, turnNum);

        // Let the user know
        alert("Saved.");
    }




    /**
     * This method clears the dirty rows.
     */
    clearDirtyRows()
    {
        // Reset the dirty IDs
        this.setState(
        {
            dirtyRows: {},
            dirtyEvents: {}
        });
    }


    /**
     * This method renders the view.
     */
    renderView()
    {
        // Get the game
        let game = this.props.game;
        if (game == null)
        {
            return (
                <ErrorBox>The game was not found.</ErrorBox>
            );
        }

        return(
            <Fragment>
                <Prompt when={ this.isDirty() } message="There are unsaved changes on this screen.  Are you sure you want to leave?" />

                <div className="container left">
                    <div className="decision-container">
                        <div className="fisim-header">
                            <div className="fisim-header-left">{ game.game_name + " - Adjustments" }</div>
                            <div className="fisim-header-right">
                                <div className="decision-header-icon">
                                    {
                                        this.isDirty() ?
                                            <span onClick={ this.onSave }><i title="Save" className="decision-icon-color fa fa-floppy-o" ></i></span>
                                            :
                                            <span><i title="Save" className="disabled decision-icon-color fa fa-floppy-o" ></i></span>
                                    }
                                    
                                </div>
                            </div>
                        </div>

                        <div className = "panel-body">
                            <Row>
                                <Col xs={6} sm={6} md={4} lg={4}>
                                    <TurnSelect name="turns" onChange={ this.onTurnChange } value= { this.state.selectedTurn } />
                                </Col>
                                <Col xs={6} sm={6} md={4} lg={4}>
                                </Col>
                                <Col xs={6} sm={6} md={4} lg={4} className="right col-padding-right bold col-middle-align">
                                    <SelectControl items={ this.state.events } name="event" onChange={ this.onChangeEvent } value= { this.state.selectedEvent } />
                                </Col>
                            </Row>

                            <DecisionGrid 
                                columns = { this.props.game.type === "Lite" ? LITE_COLUMNS : COLUMNS }
                                rowData = { this.state.filteredAdjustments }
                                turn = { this.state.selectedTurn }
                                onCellValueChanged={ this.onCellValueChanged } 
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// Export the game view...
export default withRouter(connect(BaseGameView.mapStateToProps)(AdjustmentsView));