import { withRouter } from "react-router";
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReactDOM from 'react-dom';
import $ from "jquery";

import COLUMNS from "./columns/TeamNotesColumns";
import BaseGameView from "./BaseGameView";
import DecisionBody from "./decisions/DecisionBody";
import ErrorBox from "../../components/ErrorBox";
import SelectControl from "../../controls/form/SelectControl";
import { Container, Row, Col, Button } from "react-bootstrap";

import noteService from "../../services/NoteService";
import gameService from "../../services/GameService";

import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

/**
 * The TeamNotesView class.
 */
class TeamNotesView extends BaseGameView
{
    /**
     * The object constructor
     */
    constructor(props)
    {
        // Call mom
        super(props);

        // Initialize the loaded flag
        this.loaded= false;

        // Set the columns
        this.columns = [ ...COLUMNS ];

        // Load the note sections
        noteService.loadNoteSections();

        // Initialize the state
        this.state =
        {
            sectionType: props.match.params.section_type ? props.match.params.section_type : "None",
            selectedRows: new Set(),
            rows: [],
            dirtyRows: {},
            deletedRows: [],
            showModal: false,
            modalText: "",
            addedRows: [],
            gridKey: 1,
            modalTurn: 0
        };
    }


    /**
     * This method maps the state to the properties.
     * 
     * @param {*} state The state.
     * @param {*} ownProps The own properties.
     * @returns The mapping.
     */
    static mapStateToProps(state, ownProps)
    {
        // Call mom...
        let props = BaseGameView.mapStateToProps(state, ownProps);

        // Check the note sections
        props.noteSections = state.static.noteSections;
    
        // Get out...
        return props;
    }


    /**
     * This method loads the notes.
     *
     * @param {*} sectionType The section type.
     */
    loadNotes(sectionType)
    {
        // Only load once...
        if (this.loadingNotes)
            return;

        // Get out if nothing to do...
        if (this.loaded && (this.state.sectionType == sectionType))
            return;

        // Warn the user if there are changes...
        if (this.isDirty())
        {
            if (!window.confirm("You have unsaved changes.  Do you wish to proceed?"))
                return;
        }

        // Mark us as loading...
        this.loadingNotes = true;

        // Load the notes
        noteService.loadTeamNotes(this.props.match.params.team_id, sectionType, this.onNotesLoaded);
    }


    /**
     * The notes loaded event handler.
     *
     * @param {*} sectionType The section type.
     * @param {*} teamNotes The team notes.
     */
    onNotesLoaded = (sectionType, teamNotes) =>
    {
        // Initialize the rows
        let rows = [];

        // Push the rows
        for (let teamNote of teamNotes)
            this.addRows(teamNote, rows);

        // Set the state
        this.setState({ sectionType: sectionType, rows: rows, gridKey: this.state.gridKey + 1 });

        // Update the flags
        this.loadingNotes = false;
        this.notesLoaded  = true;
    }


    /**
     * This method makes the row.
     *
     * @param {*} teamNote The team note.
     * @param {*} rows The rows.
     * @returns The row.
     */
    addRows(teamNote, rows)
    {
        let row1 = { ...teamNote, type: "primary"  };
        let row2 = { ...teamNote, type: "secondary" };

        rows.push(row1);
        rows.push(row2);
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Call mom...
        let result = super.isComponentReady();

        if (this.scenarioLoaded && this.notesLoaded)
            return result;

        // Load the notes if necessary...
        if (this.props.game)
        {
            // Load the notes if appropriate
            if (!this.notesLoaded)
                this.loadNotes(this.state.sectionType);

            // Load the active scenario
            if (!this.scenarioLoaded && !this.scenarioLoading)
            {
                this.scenarioLoading = true;
                gameService.loadActiveIntraturn(this.props.match.params.team_id, this.onScenarioLoaded);
            }

            // Not ready...
            return false;
        }

        // Get out...
        return result;
    }


    /**
     * This method handles the scenario-loaded event.
     *
     * @param {*} intraturn The intraturn.
     */
    onScenarioLoaded = (intraturn) =>
    {
        // Set the state
        this.setState({ scenarioName: intraturn.name, intraturnId: intraturn.intraturn_id, turnId: intraturn.turn_id });

        // Set the flags
        this.scenarioLoading = false;
        this.scenarioLoaded  = true;
    }


    /**
     * This method determines if the view is dirty.
     *
     * @returns true if the view is dirty, else false.
     */
    isDirty()
    {
        let dirty = (Object.keys(this.state.dirtyRows).length > 0) || (this.state.addedRows.length > 0) || (this.state.deletedRows.length > 0);

        return dirty;
    }


    /**
     * The note section change.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onNoteSectionChange = (name, value) =>
    {
        // Get the current columns
        let newColumns = [];
        let currentColumns = this.gridColumnApi.getAllColumns();
        
        // Create map of this.columns
        let columnMap = {};
        // Populate it...
        for (let masterColumn of this.columns) 
            columnMap[masterColumn.field] = masterColumn;
        // Populate the list and preserve order
        for (let column of currentColumns)
        {
            let masterColumn = columnMap[column.colId];
            masterColumn.width = column.actualWidth;
            
            newColumns.push(masterColumn);
        }
        // Set the new columns as the columns
        this.columns = newColumns;

        this.setState(
        {
            sectionType: value,
            gridKey: this.state.gridKey + 1
        })
    }


    /**
     * This method handles selected row changes.
     *
     * @param {*} selectedRows The selected rows.
     */
    onSelectedRowsChange = (selectedRows) =>
    {
        this.setState({ selectedRows: selectedRows });
    }


    /**
     * This method handles delete row clicks.
     */
    onClickDeleteRows = () =>
    {
        // Initialize the rows & deleted rows
        let rows        = [];
        let selectedRows = this.gridApi.getSelectedRows();
        let deletedRows = [ ...this.state.deletedRows ];

        // Create the selected row map
        let selectedRowMap = {};
        for (let selectedRow of selectedRows)
            selectedRowMap[selectedRow.note_id] = true;

        // Add it to the appropriate list
        for (let row of this.state.rows)
        {
            if (selectedRowMap[row.note_id])
            {
                if (row.note_id > 0)
                    deletedRows.push(row.note_id);
            }
            else
                rows.push(row);
        }


        // Initialize the added rows
        let addedRows = [];

        // Add it to the appropriate list
        for (let row of this.state.addedRows)
        {
            if (!selectedRowMap[row.note_id])
                addedRows.push(row);
        }

        // Set the state
        this.setState({ rows: rows, addedRows: addedRows, deletedRows: deletedRows, selectedRows: new Set() });
    }


    /**
     * This method saves the note.
     */
    saveNote()
    {
        if (this.state.modalNoteId === 0)
            this.addNote();
        else
            this.updateNote();
    }


    /**
     * This method adds the note.
     */
    addNote()
    {
        // Get thenote section
        let noteSection = "";
        for (let section of this.props.noteSections)
        {
            if (section.value !== this.state.modalSectionType)
                continue;

            noteSection = section.name;
            break;
        }

        // Create the note
        let note =
        {
            note_id: -(this.state.addedRows.length + 1),
            note_type_cd: "Team",
            note_section_cd: this.state.modalSectionType,
            game_id: this.props.game.game_id,
            turn_id: this.state.turnId,
            intraturn_id: this.state.intraturnId,
            text: this.state.modalText,
            updated_tzts: new Date(), 

            scenario_name: this.state.scenarioName,
            note_section: noteSection,
            turn_name: "Qtr " + (this.props.game.turns_completed + 1)
        };

        // Create the rows
        let rows = [];
        
        // Add the rows to the top of the list
        this.addRows(note, rows);

        // Push the rest of the rows
        for (let row of this.state.rows)
            rows.push(row);

        // Create the added rows
        let addedRows = [...this.state.addedRows, note];

        // Add the row & hide the modal...
        this.setState({ showModal: false, rows: rows, addedRows: addedRows, gridKey: this.state.gridKey + 1 });
    }


    /**
     * This method updates the note.
     */
    updateNote()
    {
        let rows      = [ ...this.state.rows ];
        let dirtyRows = { ...this.state.dirtyRows };

        // Check each row...
        for (let counter = 0; counter < rows.length; counter++)
        {
            // Get the row...
            let row = rows[counter];

            // Look for a match
            if (row.note_id !== this.state.modalNoteId)
                continue;

            // Set the text
            row.text = this.state.modalText;

            // Get our DOM node
            let domNode = $(ReactDOM.findDOMNode(this));

            // Get the item
            if (counter % 2 === 1)
            {
                // Get the div
                let div = domNode.find(".grid-text-holder[data-note-id=" + row.note_id + "]");

                // Update it so we don't have to click off of it to make it update on it's own (which it will do eventually anyway...)
                $(div).html(row.text);
            }

            // Mark it dirty if necessary
            if (row.note_id > 0)
                dirtyRows[row.note_id] = true;
        }

        // Initialize the state
        let state =
        {
            showModal: false, 
            rows: rows, 
            dirtyRows: dirtyRows
        }

        // Update the added row if necessary...
        if (this.state.modalNoteId < 0)
        {
            // Get the added rows
            let addedRows = this.state.addedRows;

            // Check each one...
            for (let addedRow of addedRows)
            {
                // Look for a match
                if (addedRow.note_id !== this.state.modalNoteId)
                    continue;

                // Set the text
                addedRow.text = this.state.modalText;

                // Break out...
                break;
            }

            // Update the state
            state.addedRows = addedRows;
        }

        // Update the state...
        this.setState(state);
    }


    /**
     * This method handles the save event.
     */
    onSave = () =>
    {
        // Create a map of the rows
        let rowMap = {};
        for (var row of this.state.rows)
            rowMap[row.note_id] = row;

        // Initialize the updated row array
        let updatedRows = [];

        // Create the updated row array
        for (let noteId in this.state.dirtyRows)
        {
            // It's a new note - we'll catch it when we build the add list
            if (noteId < 0)
                continue;

            // Get the note
            let note = rowMap[noteId];

            // Make sure it wasn't deleted...
            if (!note)
                continue;

            // Push the rows
            updatedRows.push({ note_id: note.note_id, text: note.text });
        }

        // Save the updates
        noteService.saveTeamNotes(this.props.match.params.team_id, this.state.addedRows, updatedRows, this.state.deletedRows, this.onSaveComplete);
    }


    /**
     * This method handles the save complete event.
     *
     * @param {*} teamNotes The team notes.
     */
    onSaveComplete = (teamNotes) =>
    {
        // Initialize the rows
        let rows = [];

        // Push the rows
        for (let teamNote of teamNotes)
            this.addRows(teamNote, rows);

        // Clear the state
        this.setState({ rows: rows, dirtyRows: {}, deletedRows: [], addedRows: [], gridKey: this.state.gridKey + 1 });
    }

    /**
     * This method updates the state when a row has been modified.
     *
     * @param {*} params The params.
     */
    editRow = (params) =>
    {
        // Get the row...
        let row = params.data;

        // Set the state
        this.setState(
        {
            showModal: true,
            modalTurn: row.turn_name,
            modalText: row.text,
            modalNoteId: row.note_id,
            modalSectionType: row.note_section,
            modalScenarioName: row.scenario_name
        });    
    }

    /**
     * This method creates a new note.
     */
    newNote()
    {
        this.setState(
        {
            showModal: true,
            modalTurn: this.props.game.turns_completed + 1,
            modalText: "",
            modalNoteId: 0,
            modalSectionType: this.state.sectionType === "None" ? "Smry" : this.state.sectionType,
            modalScenarioName: this.state.scenarioName
        });    
    }

    /**
     * The grid ready event handler.
     *
     * @param {*} params The parameters.
     */
    onGridReady = (params) => 
    {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
    }

    /**
     * This method renders the view.
     */
    renderView()
    {
        // Get the game
        let game = this.props.game;
        if (game == null)
        {
            return (
                <ErrorBox>The game was not found.</ErrorBox>
            );
        }

        // Get the rows...
        let rows = this.state.rows;

        // Filter the rows...
        if (this.state.sectionType !== "None")
            rows = rows.filter((row) => row.note_section_cd === this.state.sectionType);

        return(
            <DecisionBody name="Team Notes" hideNotes="true" game={ game } intraturnId={ this.state.intraturnId } onSave={ this.onSave } dirty={ this.isDirty() }>

                <Modal show={ this.state.showModal } onHide={() => this.setState({ showModal: false }) } dialogClassName="borrowing-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>New Note</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col xs={6} sm={6} md={3} lg={3}><label>{ (this.state.modalNoteId === 0 ? "Qtr " : "") + this.state.modalTurn }</label></Col>
                                <Col xs={6} sm={6} md={3} lg={3}><label>{ this.state.modalScenarioName }</label></Col>
                                <Col xs={8} sm={8} md={6} lg={6}>
                                { 
                                    this.state.modalNoteId === 0 ?
                                        <SelectControl items={ this.props.noteSections.filter((noteSection) => noteSection.value !== "None") } name="noteSections"  onChange={ (name, value) => this.setState({ modalSectionType: value }) } value= { this.state.modalSectionType } /> 
                                    :
                                    <label>{ this.state.modalSectionType }</label>
                                }
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <ReactQuill theme="snow" className="rich-text-editor" value={ this.state.modalText } onChange={ (value) => this.setState({ modalText: value }) }/>
                                </Col>
                            </Row>

                        </Container>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="primary" onClick={ () => this.saveNote() }>{ this.state.modalNoteId === 0 ? "Add" : "Update" }</Button>
                        <Button variant="secondary" onClick={() => this.setState({ showModal: false}) }>Cancel</Button>
                    </Modal.Footer>
                </Modal>

                <div className = "panel-body">

                    <Row>
                        <Col xs={6} sm={6} md={4} lg={3}>
                            <SelectControl items={ this.props.noteSections } name="noteSections"  onChange={ this.onNoteSectionChange } value= { this.state.sectionType } />                
                        </Col>
                    </Row>

                    <Row className="icon-buttons" >
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <a onClick={ () => this.newNote() }><i class="fa fa-plus"></i></a>
                            &nbsp;&nbsp;&nbsp;
                            <a onClick={ this.onClickDeleteRows }><i class="fa fa-trash"></i></a>
                        </Col>
                    </Row>

                    <div className="grid-body">
                        <div className = "ag-theme-alpine" style= {{height: 'calc(100vh - 200px)', width: '100%', minHeight: "200px"}}>
                            <AgGridReact 
                                className="notes-grid"
                                onGridReady = { this.onGridReady }  
                                key={ this.state.sectionType ? this.state.sectionType : "None" } 
                                rowKeyGetter={ (row) => { return row.note_id; } }
                                columnDefs = { this.columns } 
                                rowData = { rows } 
                                rowSelection="multiple"
                                onSelectionChanged={ this.onSelectedRowsChange }
                                selectedRows={ this.state.selectedRows }
                                onCellValueChanged = { this.editRow }
                                getRowHeight={(args) => (args.data.type === 'primary') ? 45 : 150 }
                            />
                        </div>
                    </div>
                </div>
            </DecisionBody>
        );
    }
}

// Export the game view...
export default withRouter(connect(TeamNotesView.mapStateToProps)(TeamNotesView));