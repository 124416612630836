import React, { Fragment } from 'react';
import { withRouter, Prompt } from "react-router";
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import BaseDecisionGridView from "./BaseDecisionGridView";
import TurnSelect from "./components/TurnSelect";
import ScenarioName from "./components/ScenarioName";

import DecisionBody from "./DecisionBody";
import BorrowingsControl from './components/BorrowingsControl';



/**
 * The BorrowingsView class.
 */
class BorrowingsView extends BaseDecisionGridView
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Create the reference
        this.borrowingsControlRef = React.createRef();

        // Initialize the state
        this.state =
        {
            selectedTurn: -1,
        };
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Call mom...
        let result = super.isComponentReady();

        // Load the CDs if necessary...
        if (this.props.game && (this.state.selectedTurn === -1))
        {
            // Load the borrowings
            this.setState({ selectedTurn: this.props.game.turns_completed + 1 });

            // Not ready...
            return false;
        }

        // Get out...
        return result;
    }
    
    
    /**
     * This method handles view saves.
     */
    onSave = () =>
    {
        // Get out if this is not actually dirty (shouldn't happen)
        if (!this.isDirty())
            return;

        // Save the changes
        if (this.borrowingsControlRef.current)
            this.borrowingsControlRef.current.save(this.onSaveComplete);
    }


    /**
     * This method determines if there are dirty fields.
     *
     * @returns true if there are dirty fields, else false.
     */
    hasDirtyFields()
    {
       return this.borrowingsControlRef.current && this.borrowingsControlRef.current.hasDirtyFields();
    }


    /**
     * The save complete event handler.
     */
    onSaveComplete = () =>
    {
        // let the user know
        alert("Saved.");
    }



    /**
     * This method handles turn changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onTurnChange = (name, value) =>
    {
        // See if it's dirty...
        if (this.isDirty())
        {
            if (!window.confirm("You have unsaved changes.  Do you wish to proceed?"))
                return;
        }

        // Update the state
        this.setState({ selectedTurn: parseInt(value) });
    }


    /**
     * This method determines if the state is dirty.
     *
     * @returns true if the state is dirty, else false.
     */
    isDirty()
    {
        return this.borrowingsControlRef.current && this.borrowingsControlRef.current.isDirty();
    }


    /**
     * This method renders the view.
     */
    renderView()
    {
        let intraturnId = this.borrowingsControlRef.current ? this.borrowingsControlRef.current.getIntraturnId() : 0;

        return (
            <Fragment>
                <Prompt when={ this.isDirty() } message="There are unsaved changes on this screen.  Are you sure you want to leave?" />

                <DecisionBody name="Borrowings" noteType="Tres" game={ this.props.game } intraturnId={ intraturnId } onSave={ this.onSave } dirty={ this.isDirty() }>

                    <Row>
                        <Col xs={6} sm={6} md={4} lg={4}>
                            <TurnSelect name="turns" onChange={ this.onTurnChange } value= { this.state.selectedTurn } />
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4}>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="right col-padding-right bold col-middle-align">
                            <ScenarioName intraturnId={ intraturnId } />
                        </Col>
                    </Row>

                    <BorrowingsControl selectedTurn={ this.state.selectedTurn }
                                       team={ this.props.team } game={ this.props.game } 
                                       ref={ this.borrowingsControlRef } />
            </DecisionBody>

            </Fragment>
        );
    }
}

// Export the decisions view...
export default withRouter(connect(BaseDecisionGridView.mapStateToProps)(BorrowingsView));