import React from 'react';


/**
 * The base view.
 */
class BaseView extends React.Component
{
    /**
     * Object Constructor.
     * 
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the state
        this.state = 
        {
            validator: null
        };
    }


    /**
     * This method handles form changes.
     * @param {*} event The event.
     */
    onFieldChange = (name, value) =>
    {
        let state = {};
        state[name] = value;
 
        this.setState(state);
    }
 }

 // Export the base view
 export default BaseView;