import { Fragment } from 'react';
import { withRouter, Prompt } from "react-router";
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import BaseDecisionView from "./BaseDecisionView";
import CurrencyInput from "../../../controls/CurrencyInput";
import { formatCurrencyWithPennies } from "../../../controls/Currency";

import GeneralAdminTemplate from "./GeneralAdminTemplate";
import gameService from "../../../services/GameService";
import intraturnService from "../../../services/IntraturnService";

import Utils from "../../../utils/Utils";


/**
 * The DividendsView class.
 */
class DividendsView extends BaseDecisionView
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the loaded flag
        this.loaded = false;

        // Initialize the state
        this.state =
        {
            dividends: formatCurrencyWithPennies(1),
            originalDividends: 1
        };
    }


    /**
     * This method loads the dividends.
     *
     * @param turn The turn.
     */
    loadDividends(turn)
    {
        // Get out if the state is loaded
        if (!this.props.game)
            return;

        // Only load once...
        if (this.loadingDividends)
            return;

        // Get out if nothing to do...
        if (this.state.selectedTurn === turn)
            return;

        // Warn the user if there are changes...
        if (this.isDirty())
        {
            if (!window.confirm("You have unsaved changes.  Do you wish to proceed?"))
                return;
        }

        // Mark us as loading...
        this.loadingDividends = true;

        // Load the dividends
        gameService.loadDividends(this.props.match.params.team_id, turn, this.onDividendsLoaded);
    }


    /**
     * The dividends loaded event handler.
     *
     * @param {*} dividends The dividends.
     * @param {*} lastUpdated The last updated time.
     * @param {*} intraturnId The intraturn ID.
     */
    onDividendsLoaded = (dividends, lastUpdated, intraturnId) =>
    {
        // Make sure we have something...
        if (!dividends)
            dividends = 0.5;

        let state =
        { 
            dividends: formatCurrencyWithPennies(dividends),
            originalDividends: dividends,
            intraturnId: intraturnId,
            lastUpdated: lastUpdated
        }

        // Set the state
        this.setState(state);

        // Clear the loading dividends flag
        this.loadingDividends = false;

        // Set the loaded flag
        this.loaded = true;
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Call mom...
        let result = super.isComponentReady();

        // Load the investment sales if necessary...
        if (this.props.game && !this.loaded)
        {
            // Load the investment sales
            this.loadDividends(this.props.game.turns_completed + 1);

            // Not ready...
            return false;
        }

        // Get out...
        return result;
    }


    /**
     * This method handles view saves.
     */
    onSave = () =>
    {
        // Get out if this is not actually dirty (shouldn't happen)
        if (!this.isDirty())
            return;

        // Update the dirty fields
        this.updateDirtyFields(false);
    }


    /**
     * This method updates the dirty fields.
     *
     * @param {*} force The force flag.
     */
    updateDirtyFields(force)
    {
        // Update the investment sales
        intraturnService.updateDividends(this.state.intraturnId, Utils.parseCurrency(this.state.dividends).toFixed(2), this.state.lastUpdated, force, this.onSaveComplete);
    }


    /**
     * The save complete event handler.
     *
     * @param {*} modifiedBy The modified by user.
     * @param {*} lastUpdated The last updated time.
     * @param {*} dividends The dividends.
     */
    onSaveComplete = (modifiedBy, lastUpdated, dividends) =>
    {
        // See if it was modified since we loaded the data...
        if (modifiedBy)
        {
            // See if the user wants to force the matter...
            if (!window.confirm("General Administration records have modified by " + modifiedBy + " at " + new Date(lastUpdated).toLocaleTimeString() + ".\n\nWould you like save your changes anyway?"))
                return;

            // Force the update
            this.updateDirtyFields(true);

            // Get out...
            return;
        }

        // Clear the dirty flag
        this.setState({ dividends: dividends, originalDividends: dividends, lastUpdated: lastUpdated });

        // let the user know
        alert("Saved.");
    }


    /**
     * This method handles turn changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onTurnChange = (name, value) =>
    {
        // Load the dividends
        this.loadDividends(parseInt(value));
    }


    /**
     * This method handles dividend changes.
     *
     * @param {*} event The event.
     */
    onChangeDividends = (event) =>
    {
        // save the value
        this.setState({ dividends: event.target.value });
    }


    /**
     * This method determines if the state is dirty.
     *
     * @returns true if the state is dirty, else false.
     */
    isDirty()
    {
        // Are we dirty?
        if (Utils.parseCurrency(this.state.dividends) === parseFloat(this.state.originalDividends))
            return false;

        return true;
    }



    /**
     * This method renders the view.
     */
    renderView()
    {
        return (
            <Fragment>
                <Prompt when={ this.isDirty() } message="There are unsaved changes on this screen.  Are you sure you want to leave?" />

                <GeneralAdminTemplate name="Dividends" game={ this.props.game } onSave={ this.onSave } dirty={ this.isDirty() } intraturnId={ this.state.intraturnId }>

                    <Row>
                        <Col xs={12} sm={12} md={{ offset: 1, span: 7 }} lg={{ offset: 1, span: 5}}>
                            Dividends per Share:  <CurrencyInput value={ this.state.dividends } onChange={ this.onChangeDividends } allowDecimal="true" disabled={ this.props.team.frozen } min={0} style={{ width: "90px", display: "inline" }} className="auto-input form-control" />
                        </Col>
                    </Row>
                </GeneralAdminTemplate>
            </Fragment>
        );
    }
}

// Export the decisions view...
export default withRouter(connect(BaseDecisionView.mapStateToProps)(DividendsView));