import { MultiSelectRenderer } from "../../../../controls/grid/GridRenderers";
import SortFunctions from "../../../../controls/grid/SortFunctions";

/**
 * The Role options.
 */
const ROLE_OPTIONS = 
[
    { value: 'ceo', label: 'CEO' },
    { value: 'cfo/treasurer', label: 'CFO/Treasurer' },
    { value: 'credit officer', label: 'Credit Officer' },
    { value: 'deposit officer', label: 'Deposit Officer' },
    { value: 'lending officer', label: 'Lending Officer' }
];

/**
 * The housekeeping columns.
 */
export const HOUSEKEEPING_COLUMNS =
[
    { 
        field: 'full_name', 
        headerName: 'Team Members',
        comparator: SortFunctions.alphaNumeric,
        width:640,
        minWidth:640
    },

    { 
        field: 'roles',
        headerName: 'Roles', 
        width: 640,
        minWidth: 640,
        cellRenderer: (params) => { return <MultiSelectRenderer options={ ROLE_OPTIONS } { ...params } /> },
        editableCellRenderer: (params) => { return <MultiSelectRenderer options={ ROLE_OPTIONS } { ...params } /> },
    }
];

