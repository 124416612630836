import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MeasuresOfSuccessView from '../MeasuresOfSuccessView';
import MeasuresOfSuccessControl from './MeasuresOfSuccessControl';
import { useRef, useState } from 'react';


/**
 * The measures of success modal component.
 *
 * @param {*} props The properties.
 * @returns The rendered component.
 */
const MeasuresOfSuccessModal = (props) =>
{
    // Create the on change event handler...
    let onChange = (value, dirty) =>
    {
        setDirty(dirty);
    }

    // Create the reference
    let measuresOfSuccessRef = useRef();

    // Initialize the state
    let [ dirty, setDirty] = useState(false);

    return (
        <Modal show={ props.visible } onHide={() => props.onHide() } dialogClassName="submit-decision-modal">
            <Modal.Header closeButton>
                <Modal.Title>Measures of Success</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="decision-header-icon right full-width">
                    {
                        !props.team.frozen && dirty ?
                            <span onClick={ () => measuresOfSuccessRef.current.save() }><i title="Save" className="decision-icon-color fa fa-floppy-o" ></i></span>
                            :
                            <span><i title="Save" className="disabled decision-icon-color fa fa-floppy-o" ></i></span>
                    }
                </div>

                <MeasuresOfSuccessControl team={ props.team } ref={ measuresOfSuccessRef } onChange={ onChange } />
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={() => props.onHide() }>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

// Export the measures of success modal
export default MeasuresOfSuccessModal;