import React, { Fragment } from 'react';

import gameService from "../../../../services/GameService";



/**
 * The scenario name component.
 */
class ScenarioName extends React.Component
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        super(props);

        // Initialize an empty state...
        this.state = {};

        /// Load the intraturn if necessary...
        if (props.intraturnId)
            this.loadIntraturn();

        // Set it to be constructed...
        this.constructed = true;
    }


    /**
     * The component-did-update event handler.
     *
     * @param {*} prevProps The previous properties.
     */
    componentDidUpdate(prevProps) 
    {
        // Reload the intraturn if necessary...
        if (prevProps.intraturn !== this.props.intraturnId) 
            this.loadIntraturn();
    }


    /**
     * This method loads the intraturn.
     *
     * @param {*} intraturnId The intraturn ID.
     */
    loadIntraturn()
    {
        // Only load once...
        if (this.loadingIntraturn || this.state.scenarioName)
            return;

        // Mark us as loading...
        this.loadingIntraturn = true;

        // Load the investment purchases
        gameService.loadIntraturn(this.props.intraturnId, this.onIntraturnLoaded);
    }

    /**
     * The intraturn loaded event handler.
     *
     * @param {*} intraturn The intraturn.
     */
    onIntraturnLoaded = (intraturn) =>
    {
        // Make sure it exists (it shouldn't be null though...)
        if (!intraturn)
            return;

        // Initialize the state
        let state = { scenario_intraturn_id: intraturn.intraturn_id };

        // Set the scenario name
        if (!this.state.scenarioName)
        {
            // Save the scenario name
            state.scenarioName = intraturn.name;

            // Call the callback if necessary...
            if (this.props.onScenarioLoaded)
                this.props.onScenarioLoaded(state.scenarioName);
        }

        // Set the state appropriately...
        if (this.constructed)
            this.setState(state);
        else
            this.state = state;

        // Reset the loading intraturn flag
        this.loadingIntraturn = false;
    }

    /**
     * The render method.
     *
     * @returns The rendered component.
     */
    render()
    { 
        return (
            <Fragment>
                {
                    this.state.scenarioName ? <div> Scenario Name: { this.state.scenarioName } </div>: ""
                }
            </Fragment>
        );
    }
}

// Export the component
export default ScenarioName;