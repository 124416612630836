import { Fragment } from "react";

import { store } from "../../store";
import BaseView from "../BaseView";
import gameService from "../../services/GameService";
import ErrorBox from "../../components/ErrorBox";


/**
 * The BaseGameView class.
 */
class BaseGameView extends BaseView
{
    /**
     * The component did mount method.
     */
    componentDidMount()
    {
        // Load the data
        this.loadData();
    }

    
    /**
     * This method handles component did update events.
     *
     * @param {*} prevProps The previous props.
     */
    componentDidUpdate(prevProps)
    {
        // Get out if nothing to do...
        if (!prevProps || !prevProps.game || !this.props.game || (this.props.game.frozen === prevProps.game.frozen))
            return;

        // Reload if unfrozen...
        if (!this.state.frozen && prevProps.game.frozen)
            window.location.reload();
    }

    /**
     * This method maps the state to the properties.
     * 
     * @param {*} state The state.
     * @param {*} ownProps The own properties.
     * @returns The mapping.
     */
    static mapStateToProps(state, ownProps)
    {
        let result =
        {
            game: state.games.gameMap[ownProps.match.params.game_id],
            gameDetails: state.games.gameDetailsMap[ownProps.match.params.game_id],
            teams: state.games.gameTeamsMap[ownProps.match.params.game_id]
        };
    
        return result;
    }


    /**
     * This method loads the data.
     */
    loadData()
    {
        // Load the game
        gameService.loadGames();

        // Load the game details
        gameService.loadGameDetails(this.props.match.params.game_id);

        // Load the games
        gameService.loadTeams(this.props.match.params.game_id);
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Determine if we are ready...
        if (gameService.areGamesLoaded() && this.props.teams && this.props.gameDetails)
            return true;

        // Not ready...
        return false;
    }


    /**
     * This method renders the component.
     */
    render()
    {
        // Don't render the game until the component is ready...
        if (!this.isComponentReady())
            return <Fragment />;

        // Handle frozen games...
        if (this.props.game.frozen && (store.getState().authentication.role == "Student"))
            return <ErrorBox title="Game Frozen">This game is currently frozen.  It will be unlocked 30 minutes prior to the start of your session.  This screen will automatically refresh when the game is unfrozen.</ErrorBox>

        // Render the game...
        return this.renderView();
    }


    /**
     * This method renders the game.
     */
    renderView()
    {
        return <Fragment />;
    }
}

// Export the view
export default BaseGameView;

