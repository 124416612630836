import { disableWhatIfs } from "../../features/games/GamesSlice";

import { store } from "../store";
import ajax from "../utils/Ajax";
import gameService from "./GameService";


/**
 * The intraturn service class.
 */
class IntraturnService
{
    /**
     * This method creates the scenario.
     *
     * @param {*} name The name.
     * @param {*} copyFromIntraturnId The copy-from intraturn ID.
     * @param {*} onSaveComplete The save complete event handler.
     */
    createScenario(name, copyFromIntraturnId, onSaveComplete)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onSaveComplete(response.intraturn);
        };

        // Create the scenario
        ajax.post("/intraturn/create-scenario", { name: name, intraturn_id: copyFromIntraturnId }, responseFn, "Creating new scenario...", "An error occurred while creating the scenario.");
    }

    /**
     * This method deletes the scenario.
     *
     * @param {*} intraturnId The intraturn ID.
     * @param {*} teamId The team ID.
     * @param {*} onDeleteComplete The delete complete event handler.
     */
    deleteScenario(intraturnId, teamId, onDeleteComplete)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onDeleteComplete(response.intraturns);
        };

        // Create the scenario
        ajax.post("/intraturn/delete-scenario", { intraturn_id: intraturnId, team_id: teamId }, responseFn, "Deleting the scenario...", "An error occurred while deleting the scenario.");
    }


    /**
     * This method updates the investment purchases.
     *
     * @param {*} intraturnId The intraturn ID.
     * @param {*} updatedRows The updated rows.
     * @param {*} lastUpdated The last updated time.
     * @param {*} force The force flag.
     * @param {*} onSaveComplete The save complete handler.
     */
    updateInvestmentPurchases(intraturnId, updatedRows, lastUpdated, force, onSaveComplete)
    {
        // Update the investment purchases
        ajax.post("/intraturn/" + intraturnId + "/investment-purchases/save", { updated: updatedRows, lastUpdated: lastUpdated, force: force }, onSaveComplete, "Saving Investment Purchases...", "An error occurred while saving the investment purchases.");
    }


    /**
     * This method updates the swaps and maturity.
     *
     * @param {*} intraturn_id The intraturn ID.
     * @param {*} swaps The swaps.
     * @param {*} maturity The maturity.
     * @param {*} lastUpdated The last updated time.
     * @param {*} force The force flag.
     * @param {*} onSaveComplete The save complete handler.
     */
    updateSwapsAndMaturity(intraturn_id, swaps, maturity, lastUpdated, force, onSaveComplete)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onSaveComplete(response.modifiedBy, response.lastUpdated, swaps, parseInt(maturity));
        }

        // Update the swaps & maturities
        ajax.post("/intraturn/swaps-and-maturities/save/" + intraturn_id, { swaps: swaps, maturity: maturity, lastUpdated: lastUpdated, force: force }, responseFn, "Saving Swaps & Maturities...", "An error occurred while saving the swaps and maturities.");
    }
 

    /**
     * This method updates the investment sales.
     *
     * @param {*} teamId The team ID.
     * @param {*} updatedRows The updated rows.
     * @param {*} lastUpdated The last updated time.
     * @param {*} force The force flag.
     * @param {*} onSaveComplete The save complete handler.
     */
    updateInvestmentSales(intraturnId, updatedRows, lastUpdated, force, onSaveComplete)
    {
        // Update the investment sales
        ajax.post("/intraturn/" + intraturnId + "/investment-sales/save", { updated: updatedRows, lastUpdated: lastUpdated, force: force }, onSaveComplete, "Saving Investment Sales...", "An error occurred while saving the investment sales.");
    }
 

    /**
     * This method updates the loan administrations.
     *
     * @param {*} intraturnId The intraturn ID.
     * @param {*} updatedRows The updated rows.
     * @param {*} lastUpdated The last updated flag.
     * @param {*} force The force flag.
     * @param {*} onSaveComplete The save complete handler.
     */
    updateLoanAdministrations(intraturnId, updatedRows, lastUpdated, force, onSaveComplete)
    {
        // Update the loan administrations
        ajax.post("/intraturn/" + intraturnId + "/loan-administrations/save", { updated: updatedRows, lastUpdated: lastUpdated, force: force }, onSaveComplete, "Saving Loan Administrations...", "An error occurred while saving the loan administrations.");
    }


    /**
     * This method updates the loan loss adjustment.
     *
     * @param {*} intraturn_id The intraturn ID.
     * @param {*} loanLossAdjustment The loan loss adjustment, if one occurred.
     * @param {*} lastUpdated The last updated time.
     * @param {*} force The force flag.
     * @param {*} onSaveComplete The save complete handler.
     */
    updateLoanLossAdjustment(intraturn_id, loanLossAdjustment, lastUpdated, force, onSaveComplete)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onSaveComplete(response.modifiedBy, response.lastUpdated, loanLossAdjustment);
        }

        // Update the loan loss adjustments
        ajax.post("/intraturn/loan-loss-adjustment/save/" + intraturn_id, { loanLossAdjustment: loanLossAdjustment, lastUpdated: lastUpdated, force: force }, responseFn, "Saving Loan Loss Adjustment...", "An error occurred while saving the loan loss adjustment.");
    }
 

    /**
     * This method updates the loan originations.
     *
     * @param {*} intraturnId The intraturn ID.
     * @param {*} updatedRows The updated rows.
     * @param {*} lastUpdated The last updated value.
     * @param {*} force The force flag.
     * @param {*} onSaveComplete The save complete handler.
     */
    updateLoanOriginations(intraturnId, updatedRows, lastUpdated, force, onSaveComplete)
    {
        // Update the loan originations
        ajax.post("/intraturn/" + intraturnId + "/loan-originations/save", { updated: updatedRows, lastUpdated: lastUpdated, force: force }, onSaveComplete, "Saving Loan Originations...", "An error occurred while saving the loan originations.");
    }


    /**
     * This method updates the mortgages pipelined.
     *
     * @param {*} intraturn_id The intraturn ID.
     * @param {*} mortgagesPipelined The mortgages pipelined, if one occurred.
     * @param {*} lastUpdated The last updated time.
     * @param {*} force The force flag.
     * @param {*} onSaveComplete The save complete handler.
     */
    updateMortgagesPipelined(intraturn_id, mortgagesPipelined, lastUpdated, force, onSaveComplete)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onSaveComplete(response.modifiedBy, response.lastUpdated, mortgagesPipelined);
        }

        // Update the mortgages pipelined
        ajax.post("/intraturn/mortgages-pipelined/save/" + intraturn_id, { mortgagesPipelined: mortgagesPipelined, lastUpdated: lastUpdated, force: force }, responseFn, "Saving mortgages pipelined...", "An error occurred while saving the mortgages pipelined.");
    }
 

    /**
     * This method updates the loan sales.
     *
     * @param {*} intraturnId The intraturn ID.
     * @param {*} turnNum The turn number.
     * @param {*} loanCategory The loan category.
     * @param {*} addedRows The added rows.
     * @param {*} updatedRows The updated rows.
     * @param {*} deletedRows The deleted rows.
     * @param {*} lastUpdated The last updated time.
     * @param {*} force The force flag.
     * @param {*} onSaveComplete The save complete handler.
     */
    updateLoanSales(intraturnId, turnNum, loanCategory, addedRows, updatedRows, deletedRows, lastUpdated, force, onSaveComplete)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onSaveComplete(response.modifiedBy, response.lastUpdated, response.loanSales, turnNum, loanCategory);
        };

        // Update the loan sales
        ajax.post("/intraturn/" + intraturnId + "/loan-sales/save", { turnNum: turnNum, loanCategory: loanCategory, added: addedRows, updated: updatedRows, deleted: deletedRows, lastUpdated: lastUpdated, force: force }, responseFn, "Saving Loan Sales...", "An error occurred while saving the loan sales.");
    }
 

    /**
     * This method updates the non-maturity deposits.
     *
     * @param {*} intraturnId The intraturn ID.
     * @param {*} updatedRows The updated rows.
     * @param {*} lastUpdated The last updated time.
     * @param {*} force The force flag.
     * @param {*} onSaveComplete The save complete handler.
     */
    updateNMDs(intraturnId, updatedRows, lastUpdated, force, onSaveComplete)
    {
        // Update the NMDs.
        ajax.post("/intraturn/" + intraturnId + "/nmds/save", { updated: updatedRows, lastUpdated: lastUpdated, force: force }, onSaveComplete, "Saving Non-Maturity Deposits...", "An error occurred while saving the non-maturity deposits.");
    }
 

    /**
     * This method updates the CDs.
     *
     * @param {*} intraturnId The intraturn ID.
     * @param {*} turnNum The turn number.
     * @param {*} cdType The CD type.
     * @param {*} addedRows The added rows.
     * @param {*} updatedRows The updated rows.
     * @param {*} deletedRows The deleted rows.
     * @param {*} lastUpdated The last updated time.
     * @param {*} force The force flag.
     * @param {*} onSaveComplete The save complete handler.
     */
    updateCDs(intraturnId, turnNum, cdType, addedRows, updatedRows, deletedRows, lastUpdated, force, onSaveComplete)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onSaveComplete(response.modifiedBy, response.lastUpdated, response.cds, turnNum, cdType);
        };

        // Update the CDs
        ajax.post("/intraturn/" + intraturnId + "/cds/save", { turnNum: turnNum, cdType: cdType, added: addedRows, updated: updatedRows, deleted: deletedRows, lastUpdated: lastUpdated, force: force }, responseFn, "Saving CDs...", "An error occurred while saving the CDs.");
    }
 

    /**
     * This method updates the CDs.
     *
     * @param {*} intraturnId The intraturn ID.
     * @param {*} turnNum The turn number.
     * @param {*} addedRows The added rows.
     * @param {*} updatedRows The updated rows.
     * @param {*} deletedRows The deleted rows.
     * @param {*} updatedPrevious The updated previous rows.
     * @param {*} lastUpdated The last updated time.
     * @param {*} force The force flag.
     * @param {*} onSaveComplete The save complete handler.
     */
    updateBorrowings(intraturnId, turnNum, addedRows, updatedRows, deletedRows, updatedPrevious, lastUpdated, force, onSaveComplete)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onSaveComplete(response.modifiedBy, response.lastUpdated, response.borrowings, response.previousBorrowings, response.rates, turnNum, intraturnId);
        };

        // Update the borrowings
        ajax.post("/intraturn/" + intraturnId + "/borrowings/save", { turnNum: turnNum, added: addedRows, updated: updatedRows, deleted: deletedRows, updatedPrevious: updatedPrevious, lastUpdated: lastUpdated, force: force }, responseFn, "Saving Borrowings...", "An error occurred while saving the Borrowings.");
    }
 

    /**
     * This method updates the long-term debts.
     *
     * @param {*} intraturnId The intraturn ID.
     * @param {*} updatedRows The updated rows.
     * @param {*} lastUpdated The last updated time.
     * @param {*} force The force flag.
     * @param {*} onSaveComplete The save complete handler.
     */
    updateLongTermDebts(intraturnId, updatedRows, lastUpdated, force, onSaveComplete)
    {
        // Update the long-term debts
        ajax.post("/intraturn/" + intraturnId + "/long-term-debts/save", { updated: updatedRows, lastUpdated: lastUpdated, force: force }, onSaveComplete, "Saving Long-Term Debts...", "An error occurred while saving the long-term debts.");
    }
 

    /**
     * This method updates the equity.
     *
     * @param {*} intraturnId The intraturn ID.
     * @param {*} updatedRows The updated rows.
     * @param {*} lastUpdated The last updated time.
     * @param {*} force The force flag.
     * @param {*} onSaveComplete The save complete handler.
     */
    updateEquity(intraturnId, updatedRows, lastUpdated, force, onSaveComplete)
    {
        // Update the equity
        ajax.post("/intraturn/" + intraturnId + "/equity/save", { updated: updatedRows, lastUpdated: lastUpdated, force: force }, onSaveComplete, "Saving Equity...", "An error occurred while saving the equity.");
    }
 

    /**
     * This method updates the BDMs.
     *
     * @param {*} intraturnId The intraturn ID.
     * @param {*} turnNum The turn number.
     * @param {*} updatedRows The updated rows.
     * @param {*} lastUpdated The last updated time.
     * @param {*} force The force flag.
     * @param {*} onSaveComplete The save complete handler.
     */
    updateBDMs(intraturnId, turnNum, updatedRows, lastUpdated, force, onSaveComplete)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onSaveComplete(response.modifiedBy, response.lastUpdated, response.bdms, turnNum);
        };

        // Update the BDMs
        ajax.post("/intraturn/" + intraturnId + "/bdms/save", { turnNum: turnNum, updated: updatedRows, lastUpdated: lastUpdated, force: force }, responseFn, "Saving Business Development and Marketing...", "An error occurred while saving the business development and marketing.");
    }


    /**
     * This method sets the active intraturn.
     *
     * @param {*} intraturn The intraturn.
     * @param {*} onSaveComplete The save complete event handler.
     */
    setActiveIntraturn(intraturn, onSaveComplete)
    {
        // Wrap the call...
        let responseFn = () =>
        {
            onSaveComplete(intraturn);
        }

        // Set the active intraturn.
        ajax.post("/intraturn/activate/" + intraturn.intraturn_id, null, responseFn, "Setting the active scenario...", "An error occurred while setting the active scenario.");
    }
 

    /**
     * This method updates the facilities.
     *
     * @param {*} intraturnId The intraturn ID.
     * @param {*} updatedRows The updated rows.
     * @param {*} maintenanceRows The maintenance rows.
     * @param {*} lastUpdated The last updated time.
     * @param {*} force The force flag.
     * @param {*} onSaveComplete The save complete handler.
     */
    updateFacilities(intraturnId, updatedRows, maintenanceRows, lastUpdated, force, onSaveComplete)
    {
        // Upate the facilities
        ajax.post("/intraturn/" + intraturnId + "/facilities/save", { updated: updatedRows, maintenance: maintenanceRows, lastUpdated: lastUpdated, force: force }, onSaveComplete, "Saving Facilities...", "An error occurred while saving the facilities.");
    }


    /**
     * This method updates the general administration.
     *
     * @param {*} intraturnId The intraturn ID.
     * @param {*} updatedRows The updated rows.
     * @param {*} account The account.
     * @param {*} lastUpdated The last updated time.
     * @param {*} force The force flag.
     * @param {*} onSaveComplete The save complete handler.
     */
    updateGeneralAdministration(intraturnId, updatedRows, account, lastUpdated, force,onSaveComplete)
    {
        // Update the general administration
        ajax.post("/intraturn/" + intraturnId + "/general-administration/save", { updated: updatedRows, lastUpdated: lastUpdated, force: force }, onSaveComplete, "Saving " + account + "...", "An error occurred while saving the " + account + ".");
    }


    /**
     * This method updates the IT
     *
     * @param {*} intraturnId The intraturn ID.
     * @param {*} updatedRows The updated rows.
     * @param {*} lastUpdated The last updated time.
     * @param {*} force The force flag.
     * @param {*} onSaveComplete The save complete handler.
     */
    updateIT(intraturnId, updatedRows, lastUpdated, force,onSaveComplete)
    {
        // Update the IT
        ajax.post("/intraturn/" + intraturnId + "/it/save", { updated: updatedRows, lastUpdated: lastUpdated, force: force }, onSaveComplete, "Saving IT...", "An error occurred while saving IT.");
    }


    /**
     * This method updates the dividends.
     *
     * @param {*} intraturnId The intraturn ID.
     * @param {*} dividends The dividends.
     * @param {*} lastUpdated The last updated time.
     * @param {*} force The force flag.
     * @param {*} onSaveComplete The save complete handler.
     */
    updateDividends(intraturnId, dividends, lastUpdated, force, onSaveComplete)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onSaveComplete(response.modifiedBy, response.lastUpdated, dividends);
        }

        // Update the dividends
        ajax.post("/intraturn/dividends/save/" + intraturnId, { dividends: dividends, lastUpdated: lastUpdated, force: force }, responseFn, "Saving Dividends...", "An error occurred while saving the dividends.");
    }


    /**
     * This method submits the turn.
     *
     * @param {*} intraturnId The intraturn ID.
     * @param {*} gameId The game Id.
     * @param {*} teamId The team Id.
     * @param {*} onSubmitComplete The submit complete handler.
     */
    submitTurn(intraturnId, gameId, teamId, onSubmitComplete)
    {
        // Wrap the call...
        let responseFn = () =>
        {
            // Freeze the team
            gameService.teamIsFrozen(gameId, teamId, true);

            // Call the submit complete event handler
            onSubmitComplete();
        }

        // Submit the turn
        ajax.post("/intraturn/submit/" + intraturnId, null, responseFn, "Submitting the turn...", "An error occurred while submitting the turn.");
    }


    /**
     * This method submits the what-if.
     *
     * @param {*} intraturnId The intraturn ID.
     * @param {*} gameId The game ID.
     * @param {*} teamId The team ID.
     * @param {*} onSubmitComplete The submit complete handler.
     */
    submitWhatIf(intraturnId, gameId, teamId, onSubmitComplete)
    {
        // Create the response function
        let responseFn = (response) =>
        {
            // Disable the whatifs
            store.dispatch(disableWhatIfs({ game_id: gameId, team_id: teamId } ));

            // Call the event handler
            onSubmitComplete(response);
        };

        // Submit the turn
        ajax.post("/intraturn/whatif/" + intraturnId, null, responseFn, "Submitting the forecast...", "An error occurred while submitting the forecast.");
    }


    /**
     * This method gets the turn changes.
     * 
     * @param {*} intraturnId The intraturn ID.
     * @param {*} onComplete The complete handler.
     */
    getTurnChanges(intraturnId, onComplete)
    {
        // Submit the turn
        ajax.post("/intraturn/changes/" + intraturnId, null, onComplete, "Retrieving turn changes...", "An error occurred while retrieving the turn changes.");
    }
}

// Export the game service.
export default new IntraturnService();