import BaseGameView from "../BaseGameView";


/**
 * The BaseDecisionView class.
 */
class BaseDecisionView extends BaseGameView
{
    /**
    * This method maps the state to the properties.
    * 
    * @param {*} state The state.
    * @param {*} ownProps The own properties.
    * @returns The mapping.
    */
    static mapStateToProps(state, ownProps)
    {
        // Call mom...
        let result = BaseGameView.mapStateToProps(state, ownProps);

        // Add the team
        result.team = state.games.teamMap[ownProps.match.params.team_id];

        // Get out...
        return result;
    }
}

// Export the base decision view...
export default BaseDecisionView;