import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { Fragment } from "react";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { COLUMNS, STUDENT_COLUMNS } from "./HomeColumns";
import BaseView from "./BaseView";
import Message from "../components/Message";
import gameService from "../services/GameService";
import FormControl from '../controls/form/FormControl';

import { FISIM, getMode } from "../../Modes";

/**
 * The HomeView class.
 */
class HomeView extends BaseView
{
    /**
     * Object constructor
     */
    constructor(props)
    {
        // Call mom ..
        super(props);

        // Initialize the loaded flag
        this.loaded = false;
    }

    /**
    * This method maps the state to the properties.
    * 
    * @param {*} state The state.
    * @param {*} ownProps The own properties.
    * @returns The mapping.
    */
    static mapStateToProps(state, ownProps)
    {
        let result = 
        {
            games: state.games.games,
            authentication: state.authentication
        };
    
        return result;
    }


    /**
     * The component did mount event.
     */
    componentDidMount()
    {
        gameService.loadGames();
    }



    /**
     * This method renders the component.
     */
    render()
    {
        // Do nothing if we are loading...
        if (!gameService.areGamesLoaded())
            return <Fragment />;

        // Display a message if there are no games...
        if (this.props.games.length === 0)
            return <Message title="Games">There are currently no games assigned to your user.</Message>;
        
        // No need to make them click if there is only one game...
        if (this.props.games.length === 1)
            this.props.history.replace("/game/" + this.props.games[0].game_id);

        return (
        
           <FormControl name = { (getMode() === FISIM) ? "Existing Games" : "Credit Unions" }>
                <div className = "panel-body">
                    {
                        this.props.authentication.role === "Administrator" ?
                            <p>Since you are an administrator, this is a list of all of the games in the FiSim database. </p>
                        : ""
                    }
                    <div className = "ag-theme-alpine" style= {{height: 'calc(100vh - 300px)', width: '100%'}}>
                        <AgGridReact  
                            onGridReady = { (params) => { params.api.sizeColumnsToFit();  } } 
                            rowData = { this.props.games }
                            columnDefs = { this.props.authentication.role === "Student" ? STUDENT_COLUMNS : COLUMNS }
                            defaultColDef =
                            {{
                                sortable: true,
                                resizable: true
                            }}
                        />
                    </div>
                </div>
            </FormControl>

        );
    }
}

export default withRouter(connect(HomeView.mapStateToProps)(HomeView));