import { Fragment } from 'react';
import { withRouter, Prompt } from "react-router";
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';

import BaseDecisionGridView from "./BaseDecisionGridView";
import { RETAIL_COLUMNS, BROKERED_COLUMNS, DEFAULT_COLUMNS } from "./grid/CDsColumns";

import DecisionGrid from "./components/DecisionGrid";
import TurnSelect from "./components/TurnSelect";
import ScenarioName from "./components/ScenarioName";
import { ValidationSummary, Validator } from "../../../components/ValidationSummary";
import InputControl from '../../../controls/form/InputControl';
import NumberControl from '../../../controls/form/NumberControl';
import PercentageControl from '../../../controls/form/PercentageControl';
import { parsePercentage } from '../../../controls/Percentage';
import Utils from "../../../utils/Utils";

import DecisionBody from "./DecisionBody";
import gameService from "../../../services/GameService";
import intraturnService from "../../../services/IntraturnService";
import SelectControl from '../../../controls/form/SelectControl';
import DraggableModalDialog from '../../../controls/DraggableModalDialog';


// The default modal data...
let DEFAULT_MODAL_DATA = 
{
    cd_type: "Retail CDs",
    cd_account: "",
    term: 3,
    fixed_or_variable: "Fixed",
    market_rate: 0,
    rate: 0,
    index_rate: 0,
    total_balance: 0,
    maturities: 0,
    last_originations: 0,
    cap: "999999.999",
    out_of_market: false
};



/**
 * The CDsView class.
 */
class CDsView extends BaseDecisionGridView
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the loaded flag
        this.loaded = false;

        // Initialize the state
        this.state =
        {
            rows: [],
            dirtyRows: {},
            sortColumns: [],
            selectedRows: new Set(),
            deletedRows: [],
            selectedCDType: "Retail CDs",
            selectedTurn: -1,
            fillPercent: "0.00%",
            showAddModal: false,
            addedRowCount: 0,
            modalData: {},
            cdTypes: [],
            basisPoints: 1
        };
    }


    /**
     * This method loads the CDs.
     *
     * @param turn The turn.
     * @param cdType The CD type.
     */
    loadCDs(turn, cdType)
    {
        // Get out if the state is loaded
        if (!this.props.game)
            return;

        // Only load once...
        if (this.loadingCDs)
            return;

        // Get out if nothing to do...
        if ((this.state.selectedTurn === turn) && (this.state.selectedCDType === cdType))
            return;

        // Warn the user if there are changes...
        if (this.isDirty())
        {
            if (!window.confirm("You have unsaved changes.  Do you wish to proceed?"))
                return;
        }

        // Mark us as loading...
        this.loadingCDs = true;

        // Load the CDs
        gameService.loadCDs(this.props.match.params.team_id, turn, cdType, this.onCDsLoaded);
    }


    /**
     * The CDs loaded event handler.
     *
     * @param {*} CDs The CDs.
     * @param {*} lastUpdated The last updated time.
     * @param {*} turn The turn.
     * @param {*} cdType The CD type.
     */
    onCDsLoaded = (cds, lastUpdated, turn, cdType) =>
    {
        // Make sure we have something...
        if (!cds)
            cds = [];

        // Calculate the properties
        for (let cd of cds)
            this.calculate(cd);

        // Initialize the state
        let state =
        { 
            rows: cds, 
            summary: this.calculateSummary(cds),
            selectedTurn: turn, 
            selectedCDType: cdType,
            lastUpdated: lastUpdated
        }

        // Set the intraturn Id if appropriate
        if (cds.length > 0)
            state.intraturnId = cds[0].intraturn_id;

        // Set the state
        this.setState(state);

        // Clear the dirty rows
        this.clearDirtyRows();

        // Reset the loading CDs flag
        this.loadingCDs = false;

        // Set the loaded flag
        this.loaded = true;
    }


    /**
     * This method performs the calculations.
     *
     * @param {*} cd The CD.
     */
    calculate(cd)
    {
        // Calculate the spread
        cd.spread = parsePercentage(cd.rate) - parsePercentage(cd.index_rate);

        // Format the spread
        cd.spread = parsePercentage(cd.spread).toFixed(3);
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Call mom...
        let result = super.isComponentReady();

        // Load the CDs if necessary...
        if (this.props.game && !this.loaded)
        {
            // Load the CDs
            this.loadCDs(this.props.game.turns_completed + 1, this.state.selectedCDType);

            // Not ready...
            return false;
        }

        // Get out...
        return result;
    }

    /**
     * This method updates the state when the cell value has been modified.
     *
     * @param {*} params The params.
     */
     onCellValueChanged = (params) =>
    {
        // Get the dirty rows map...
        let dirtyRows = this.state.dirtyRows;

        // Mark it dirty
        dirtyRows[params.data.cd_id] = true;

        // Perform calculations
        this.calculate(params.data); 

        // Calculate Summary
        let summary = this.calculateSummary(this.state.rows);
    
        // Save the state
        this.setState(
        {
            dirtyRows: dirtyRows,
            summary: summary
        });

        // Displays should most recent up to date values.
        params.api.refreshCells();
    }


    /**
     * This method handles selected row changes.
     *
     * @param {*} selectedRows The selected rows.
     */
    onSelectedRowsChange = (selectedRows) =>
    {
        if (this.isEditable())
            this.setState({ selectedRows: selectedRows });
    }


    /**
     * This method handles view saves.
     */
    onSave = () =>
    {
        // Get out if this is not actually dirty (shouldn't happen)
        if (!this.isDirty())
            return;

        // Handle the save...
        this.updateDirtyRows(false);
    }


    /**
     * This method determines if there are dirty fields.
     *
     * @returns true if there are dirty fields, else false.
     */
    hasDirtyFields()
    {
        if (this.state.deletedRows.length)
            return true;

        return false;
    }


    /**
     * This method updates the dirty rows.
     *
     * @param force The force flag.
     * @return true if there are dirty rows, else false...
     */
    updateDirtyRows(force)
    {
        // Get out if nothing to do...
        if ((Object.keys(this.state.dirtyRows).length === 0) && !this.state.deletedRows.length)
            return false;

        // Initialize the added & updated rows
        let addedRows   = [];
        let updatedRows = [];

        // Check each row...
        for (let row of this.state.rows)
        {
            // See if it is dirty...
            if (!this.state.dirtyRows[row.cd_id])
                continue;

            // Convert cap
            row.cap = Utils.parseCurrency(row.cap);

            // Converts rates
            row.rate = parsePercentage(row.rate).toFixed(3);

            // Put it in the correct bucket...
            if (row.cd_id < 0)
                addedRows.push(row);
            else
                updatedRows.push(row);
        }

        // Update the CDs
        intraturnService.updateCDs(this.state.intraturnId, this.state.selectedTurn, this.state.selectedCDType, 
                                   addedRows, updatedRows, this.state.deletedRows, this.state.lastUpdated, force, 
                                   this.onSaveComplete);

        // Get out...
        return true;
    }


    /**
     * The save complete event handler.
     *
     * @param {*} modifiedBy The modified by user.
     * @param {*} lastUpdated The last updated time.
     * @param {*} cds The CDs.
     * @param {*} turn The turn.
     * @param {*} cdType The CD type.
     */
    onSaveComplete = (modifiedBy, lastUpdated, cds, turn, cdType) =>
    {
        // See if it was modified since we loaded the data...
        if (modifiedBy)
        {
            // See if the user wants to force the matter...
            if (!window.confirm("CDs have modified by " + modifiedBy + " at " + new Date(lastUpdated).toLocaleTimeString() + ".\n\nWould you like save your changes anyway?"))
                return;

            // Force the update
            this.updateDirtyRows(true);

            // Get out...
            return;
        }

        // Update the data
        this.onCDsLoaded(cds, lastUpdated, turn, cdType);

        // Clear the dirty flag
        this.clearDirtyRows();

        // let the user know
        alert("Saved.");
    }


    /**
     * This method clears the dirty rows.
     */
    clearDirtyRows()
    {
        // Reset the dirty IDs
        this.setState(
        {
            dirtyRows: {},
            deletedRows: []
        });
    }


    /**
     * This method handles turn changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onTurnChange = (name, value) =>
    {
        // Load the CDs
        this.loadCDs(parseInt(value), this.state.selectedCDType);
    }


    /**
     * This method handles cd type changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onCDTypeChange = (name, value) =>
    {
        // Load the cds
        this.loadCDs(this.state.selectedTurn, value);
    }


    /**
     * This method calculates the summary.
     *
     * @param {*} rows The rows.
     * @returns The summary row.
     */
    calculateSummary(rows)
    {
        // Initialize the summary
        let summary =
        {
            cd_account: "Total",
            total_balance: 0,
            maturities: 0,
            last_originations: 0,
            //spread: 0,
            cap: 0
        }

        // Calculate the summary...
        for (let row of rows)
        {
            summary.total_balance     += Utils.parseCurrency(row.total_balance);
            summary.maturities        += Utils.parseCurrency(row.maturities);
            summary.last_originations += Utils.parseCurrency(row.last_originations);
            //summary.spread            += parsePercentage(row.spread);
            summary.cap               += Utils.parseCurrency(row.cap);
        }

        // Get out...
        return summary;
    }


    /**
     * This method increments the basis points.
     *
     * @param {*} direction The direction.
     */
    incrementBasisPoints(direction)
    {
        // Create the delta
        let delta = direction * parseInt(this.state.basisPoints) / 100.0;

        // Get out if nothing to do...
        if (delta === 0)
            return;

        // Create the new rows
        let newRows = [ ...this.state.rows ];

        // Create the new dirty rows
        let dirtyRows = { };

        // Update each row
        for (let row of newRows)
        {
            // Update the basis points
            this.updateBasisPoints(row, delta);

            // Mark it as dirty
            dirtyRows[row.cd_id] = true;
        }

        // Set the state
        this.setState({ rows: newRows, dirtyRows: dirtyRows });

        // Refresh the cells
        this.api.refreshCells();
    }


    /**
     * This method updates the basis points for the row.
     *
     * @param {*} row The row.
     * @param {*} delta The delta.
     */
    updateBasisPoints(row, delta)
    {
        // Update the rates
        row.rate = parsePercentage(row.rate) + delta;

        // Limit the values...
        if (row.rate < .001)
            row.rate = .001

        // Perform calculations
        this.calculate(row);
    }


    /**
     * This method handles delete row clicks.
     */
    onClickDeleteRows = () =>
    {
        // Initialize the rows & deleted rows
        let rows        = [];
        let deletedRows = [ ...this.state.deletedRows ];
        let dirtyRows   = { ...this.state.dirtyRows };

        // Get the selected rows
        let selectedRows = this.api.getSelectedRows();

        // Initialize the map
        let selectedRowMap = {};

        // Populate it...
        for (let row of selectedRows)
            selectedRowMap[row.cd_id] = true;

        // Add it to the appropriate list
        for (let row of this.state.rows)
        {
            if (selectedRowMap[row.cd_id])
            {
                if (row.cd_id > 0)
                    deletedRows.push({ cd_id: row.cd_id, intraturn_id: row.intraturn_id });
                else
                    delete dirtyRows[row.cd_id];
            }
            else
                rows.push(row);
        }

        // Set the state
        this.setState({ rows: rows, dirtyRows: dirtyRows, deletedRows: deletedRows });
    }


    /**
     * This method adds the row.
     */
    addRow = () =>
    {
        let higherRow;
        let lowerRow;

        // Validate the form
        let validation = Validator.validate(this.state.modalData, "addCD");
        if (!validation.result)
        {
            // Set the state
            this.setState({ validation: validation});

            // Get out...
            return;
        }

        // Get the added row count
        let addedRowCount = this.state.addedRowCount + 1;

        // Create the new row
        let newRow = 
        { 
            cd_id: -addedRowCount,
            intraturn_id: this.state.intraturnId,
                
            ...this.state.modalData 
        };

        // Format the percentages
        newRow.market_rate = parsePercentage(newRow.market_rate).toFixed(3);
        newRow.rate        = parsePercentage(newRow.rate).toFixed(3);

        // Find the closest term
        let closestRow = this.findClosestTerm(newRow.term);
        if (!closestRow)
        {
            alert("Unable to set the FHLB Index Rate.");
            return;
        }

        // Set the value if it's an exact match...
        if (closestRow.term === newRow.term)
            newRow.index_rate = closestRow.index_rate;
        else
        {
            // Determine the closest surrounding rows...
            if (closestRow.term > newRow.term)
            {
                lowerRow = this.findClosestTermLess(newRow.term);
                higherRow = closestRow;                
            }
            else
            {
                higherRow = this.findClosestTermGreater(newRow.term);
                lowerRow = closestRow;
            }

            // if both are available, interpolate the value
            if (higherRow && lowerRow)
            {
                // Determine the spread
                let spread = (parseFloat(higherRow.index_rate) - parseFloat(lowerRow.index_rate)) / (higherRow.term - lowerRow.term);
                
                // Move it by the number of months difference...
                newRow.index_rate = parseFloat(lowerRow.index_rate) + (spread * (newRow.term - lowerRow.term));

                // Convert it to a string...
                newRow.index_rate = newRow.index_rate.toFixed(3);
            }
            else
            {
                // There is only one rate.  This is the best we can do :(
                newRow.index_rate = closestRow.index_rate;
            }
        }

        // Perform calculations...
        this.calculate(newRow);

        // Set the rows
        let rows = [...this.state.rows, newRow];

        // Set the dirty rows
        let dirtyRows = { ...this.state.dirtyRows };
        dirtyRows[newRow.cd_id] = true;

        // Hide the modal
        this.setState({ rows: rows, dirtyRows: dirtyRows, addedRowCount: addedRowCount, showAddModal: false });
    }


    /**
     * This method finds the closest term.
     *
     * @param {*} term The term.
     * @returns The closest row to the term.
     */
    findClosestTerm(term)
    {
        var closestTerm = null;

        for (var counter = 0; counter < this.state.rows.length; counter++)
        {
            // Get the row
            let row = this.state.rows[counter];

            // Look for the closest term...
            if ((counter === 0) || (Math.abs(row.term - term) < term - closestTerm.term))
                closestTerm = row;
        }

        // Get out...
        return closestTerm;
    }


    /**
     * This method finds the closest term less than the term.
     *
     * @param {*} term The term.
     * @returns The closest row to the term.
     */
    findClosestTermLess(term)
    {
        var closestTerm = null;

        for (var counter = 0; counter < this.state.rows.length; counter++)
        {
            // Get the row
            let row = this.state.rows[counter];

            // Look for the closest term...
            if ((row.term < term) && (!closestTerm || (Math.abs(row.term - term) < term - closestTerm.term)))
                closestTerm = row;
        }

        // Get out...
        return closestTerm;
    }


    /**
     * This method finds the closest term greater than the term.
     *
     * @param {*} term The term.
     * @returns The closest row to the term.
     */
    findClosestTermGreater(term)
    {
        var closestTerm = null;

        for (var counter = 0; counter < this.state.rows.length; counter++)
        {
            // Get the row
            let row = this.state.rows[counter];

            // Look for the closest term...
            if ((row.term > term) && (!closestTerm || (Math.abs(row.term - term) < term - closestTerm.term)))
                closestTerm = row;
        }

        // Get out...
        return closestTerm;
    }


    /**
     * The modal data change event handler.
     *
     * @param {*} key The key.
     * @param {*} value The vale.
     */
    onChangeModalData = (key, value) =>
    {
        // Get the modal data
        let modalData = { ...this.state.modalData };

        // Set the value
        modalData[key] = value;

        // Update the state
        this.setState({ modalData: modalData});
    }


    /**
     * This method determines if the view is editable.
     *
     * @returns true if the view is editable, else false.
     */
    isEditable()
    {
        return !this.props.team.frozen && (this.state.selectedTurn === this.props.game.turns_completed + 1) ? true : false;
    }


    /**
     * This method gets the columns.
     *
     * @returns The columns.
     */
    getColumns()
    {
        switch (this.state.selectedCDType)
        {
            case "Retail CDs":
                return RETAIL_COLUMNS;

            case "Brokered CDs":
                return BROKERED_COLUMNS;

            default:
                return DEFAULT_COLUMNS;
        }
    } 


    /**
     * This method renders the view.
     */
    renderView()
    {
        // Initialize the editable flag
        let editable = this.isEditable();

        // Initialize the CD types
        let cdTypes = this.props.gameDetails.cd_types.map(type => ( { key: type, value: type } ));

        return (
            <Fragment>
                <Prompt when={ this.isDirty() } message="There are unsaved changes on this screen.  Are you sure you want to leave?" />
                
                <DecisionBody name="CDs" noteType="D&CD" game={ this.props.game } intraturnId={ this.state.intraturnId } onSave={ this.onSave } dirty={ this.isDirty() }>

                    <Row>
                        <Col xs={6} sm={6} md={4} lg={4}>
                            <TurnSelect name="turns" onChange={ this.onTurnChange } value= { this.state.selectedTurn } />
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4}>
                            <SelectControl items={ cdTypes } name="cdType"  onChange={ this.onCDTypeChange } value= { this.state.selectedCDType } />
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="right col-padding-right bold col-middle-align">
                            <ScenarioName intraturnId={ this.state.intraturnId } />
                        </Col>
                    </Row>

                    {
                        this.state.selectedCDType === "Retail CDs" ?

                            <Row className="icon-buttons" >
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <a onClick={ () => { if (editable) { this.setState({ showAddModal: true, modalData: { ...DEFAULT_MODAL_DATA, cdType: this.props.gameDetails.cd_types[0].value } }) } } }><i class="fa fa-plus"></i></a>
                                    &nbsp;&nbsp;&nbsp;
                                    <a onClick={ () => { if (editable) { this.onClickDeleteRows() } } }><i class="fa fa-trash"></i></a>
                                </Col>
                            </Row>

                        : ""
                    }

                    <div className="grid-body">                    
                        <DecisionGrid 
                                rowKeyGetter={ (row) => { return row.cd_id; } }
                                turn={ this.state.selectedTurn }
                                columns={ this.getColumns() } 
                                sortColumns={ this.state.sortColumns }
                                rowData={ this.state.rows } 
                                selectedRows={ this.state.selectedRows }
                                onCellValueChanged={ this.onCellValueChanged }    
                                onSelectedRowsChange={ this.onSelectedRowsChange }
                                onSortColumnsChange={ this.onSortColumnsChange }
                                pinnedBottomRowData={[ this.state.summary ]}
                                rowSelection="single"
                                onGridReady = { (params) => { this.api = params.api }}
                        />
                    </div>

                    {
                    this.state.selectedCDType != "Brokered CDs" ?
                        <Row>
                            <Col xs={12} sm={12} md={ { span: 6, offset: 6 } } lg={ { span: 6, offset: 6 } } className="vertical-spacing right">
                                <Button variant="primary" onClick={ () => this.incrementBasisPoints(-1) } disabled={ !editable }>-&nbsp;BP</Button>
                                <NumberControl name="basisPoints" value={ this.state.basisPoints } min={1} onChange={ this.onFieldChange } style={{ width: "75px", display: "inline" }} className="auto-input form-control" disabled={ !editable } />
                                <Button variant="primary" onClick={ () => this.incrementBasisPoints(1) } disabled={ !editable }>+&nbsp;BP</Button>
                            </Col>
                        </Row> : ""
                    }
                </DecisionBody>

                <Modal show={ this.state.showAddModal } onHide={() => this.setState({ showAddModal: false }) } dialogAs={ DraggableModalDialog } dialogClassName="cd-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>New Retail CD</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}><ValidationSummary validation={ this.state.validation } /></Col>
                            </Row>
                        
                            <Row>
                                <Col xs={6} sm={6} md={4} lg={4}><label>CD Account</label></Col>
                                <Col xs={12} sm={12} md={8} lg={8}><InputControl name="cd_account" value={ this.state.modalData.cd_account } onChange={ this.onChangeModalData } /></Col>
                            </Row>
                            <Row>
                                <Col xs={6} sm={6} md={4} lg={4}><label>Term (Months)</label></Col>
                                <Col xs={12} sm={12} md={8} lg={8}><NumberControl name="term" value={ this.state.modalData.term } onChange={ this.onChangeModalData } /></Col>
                            </Row>
                            <Row>
                                <Col xs={6} sm={6} md={4} lg={4}><label>Fixed or Variable</label></Col>
                                <Col xs={12} sm={12} md={8} lg={8}><SelectControl items={ [ { key: "Fixed", value: "Fixed" }, { key: "Variable", value: "Variable" } ] } name="fixed_or_variable"  onChange={ this.onChangeModalData } value= { this.state.modalData.fixed_or_variable } /></Col>
                            </Row>
                            <Row>
                                <Col xs={6} sm={6} md={4} lg={4}><label>Offer Rate</label></Col>
                                <Col xs={12} sm={12} md={8} lg={8}><PercentageControl name="rate" value={ this.state.modalData.rate } onChange={ this.onChangeModalData } /></Col>
                            </Row>
                        </Container>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="primary" onClick={ () => this.addRow() }>Add</Button>
                        <Button variant="secondary" onClick={() => this.setState({ showAddModal: false}) }>Cancel</Button>
                    </Modal.Footer>
                </Modal>

            </Fragment>
        );
    }
}

// Export the decisions view...
export default withRouter(connect(BaseDecisionGridView.mapStateToProps)(CDsView));