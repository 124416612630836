import { createSlice } from '@reduxjs/toolkit';

// Create the initial state
const initialState = 
{
    fullName: null,
    userId: null,
    role: null
};

// Create the slice
export const authenticationSlice = createSlice(
{
    name: 'authentication',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: 
    {
        /**
         * This method updates the state for the login operation.
         * 
         * @param {*} state The state.
         * @param {*} action The action.
         */
        login: (state, action) => 
        {
            // Set the state
            state.fullName = action.payload.fullName;
            state.email = action.payload.email;
            state.userId = action.payload.userId;
            state.role = action.payload.role;

            // Set the user Id, name, email and role 
            window.sessionStorage.setItem("authentication.fullName", action.payload.fullName);
            window.sessionStorage.setItem("authentication.email", action.payload.email);
            window.sessionStorage.setItem("authentication.userId", action.payload.userId);
            window.sessionStorage.setItem("authentication.role" , action.payload.role);
        },


        /**
         * This method updates the state for the logout operation.
         * 
         * @param {*} state The state.
         */
        logout: (state) =>
        {
            // Save them in the session storage
            window.sessionStorage.removeItem("authentication.fullName");
            window.sessionStorage.removeItem("authentication.email");
            window.sessionStorage.removeItem("authentication.userId");
            window.sessionStorage.removeItem("authentication.role");

            // Clear the state...
            window.location.reload();
        },


        /**
         * This method sets the authentication from session storage.
         * 
         * @param {*} state The state.
         */
        fromSessionStorage: (state) =>
        {
            // Set the user Id & role
            let fullName = window.sessionStorage.getItem("authentication.fullName");
            let email    = window.sessionStorage.getItem("authentication.email");
            let userId   = window.sessionStorage.getItem("authentication.userId");
            let role     = window.sessionStorage.getItem("authentication.role");

            // Get out if nothing to do...
            if (!userId)
                return;

            // Update the state
            state.fullName = fullName;
            state.email    = email;
            state.userId   = parseInt(userId);
            state.role     = role;
        },

        /**
         * This method changes the user information.
         * 
         * @param {*} state The state.
         * @param {*} action The action.
         */
        changeUserInfo: (state, action) =>
        {
            // Set the state
            state.fullName = action.payload.fullName;
            state.email    = action.payload.email;

            // Set the user information
            window.sessionStorage.setItem("authentication.fullName", action.payload.fullName);
            window.sessionStorage.setItem("authentication.email", action.payload.email);
        }
    }
});

// Export the login action
export const { login, logout, fromSessionStorage, changeUserInfo } = authenticationSlice.actions;


/**
 * This method selects the full name.
 * 
 * @param {*} state The state.
 * @returns The full name.
 */
export const selectFullName = (state) => state.authentication.fullName;

/**
 * This method selects the user ID.
 * 
 * @param {*} state The state.
 * @returns The user ID.
 */
export const selectUserId = (state) => state.authentication.userId;

/**
 * This method selects the role.
 * 
 * @param {*} state The state.
 * @returns The role.
 */
export const selectRole = (state) => state.authentication.role;

// Get out...
export default authenticationSlice.reducer;
