import { ButtonRenderer, DisabledButtonRenderer, percentageRenderer, currencyRenderer } from "../../../../controls/grid/GridRenderers";
import { CurrencyCellEditor, PercentageCellEditor } from "../../../../controls/grid/GridEditors";
import SortFunctions from "../../../../controls/grid/SortFunctions";
import { formatPercentage } from "../../../../controls/Percentage";


/**
 * The columns.
 */
export const COLUMNS = 
[
    { 
        field: 'loan_account', 
        headerName: 'Loan Account',
        minWidth: 220,
        comparator: SortFunctions.alphaNumeric,
    },

    { 
        field: 'market_rate', 
        headerName: 'Market Rate', 
        minWidth: 115,
        cellRenderer: percentageRenderer
    },

    {
        headerName: "Summary Rates for Credit Categories",
        width: 500,
        minWidth: 500,
        children:
        [
            { 
                field: 'rate_1', 
                headerName: '1',
                width: 90,
                minWidth: 90,
                cellEditor: PercentageCellEditor,
                cellRenderer: percentageRenderer,
                comparator: SortFunctions.numeric,
            },

            { 
                field: 'rate_3', 
                headerName: '3',
                width: 90,
                minWidth: 90,
                cellEditor: PercentageCellEditor,
                cellRenderer: percentageRenderer,
                comparator: SortFunctions.numeric,
            },

            { 
                field: 'rate_6', 
                headerName: '6', 
                width: 90,
                minWidth: 90,
                cellEditor: PercentageCellEditor,
                cellRenderer: percentageRenderer,
                comparator: SortFunctions.numeric,
            }
        ]
    },

    {
        headerName: "Rate for Portfolio Target Credit Categories",
        children:
        [
            { 
                field: 'target_1', 
                headerName: '1', 
                width: 80,
                minWidth: 80,
                cellEditor: PercentageCellEditor,
                cellRenderer: percentageRenderer,
                comparator: SortFunctions.numeric,
            },

            { 
                field: 'target_2', 
                headerName: '2', 
                width: 80,
                minWidth: 80,
                cellEditor: PercentageCellEditor,
                cellRenderer: percentageRenderer,
                comparator: SortFunctions.numeric,
            },

            { 
                field: 'target_3', 
                headerName: '3', 
                width: 80,
                minWidth: 80,
                cellEditor: PercentageCellEditor,
                cellRenderer: percentageRenderer,
                comparator: SortFunctions.numeric,
            },

            { 
                field: 'target_4', 
                headerName: '4', 
                width: 80,
                minWidth: 80,
                cellEditor: PercentageCellEditor,
                cellRenderer: percentageRenderer,
                comparator: SortFunctions.numeric,
            },

            { 
                field: 'target_5', 
                headerName: '5', 
                width: 80,
                minWidth: 80,
                cellEditor: PercentageCellEditor,
                cellRenderer: percentageRenderer,
                comparator: SortFunctions.numeric,
            },

            { 
                field: 'target_6', 
                headerName: '6', 
                width: 80,
                minWidth: 80,
                cellEditor: PercentageCellEditor,
                cellRenderer: percentageRenderer,
                comparator: SortFunctions.numeric,
            }
        ]
    },

    { 
        field: 'org_fee', 
        headerName: 'Origination Fee', 
        minWidth: 135,
        cellEditor: PercentageCellEditor,
        cellRenderer: (props) => { return <b> {formatPercentage(props.value)} </b> },
        comparator: SortFunctions.numeric,
    },

    { 
        field: 'annual_fee', 
        headerName: 'Annual Fee', 
        minWidth: 110,
        cellEditor: CurrencyCellEditor,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric,
    },

    {
        headerName: "BP",
        children:
        [
            { 
                field: 'basis_point_up', 
                headerName: '+', 
                width: 80,
                minWidth: 80,
                cellClass: 'center',
                cellRenderer: DisabledButtonRenderer,
                editableCellRenderer: ButtonRenderer
            },

            { 
                field: 'basis_point_down', 
                headerName: '-', 
                width: 80,
                minWidth: 80,
                cellClass: 'center',
                cellRenderer: DisabledButtonRenderer,
                editableCellRenderer: ButtonRenderer
            }
        ]
    }
];


/**
 * The columns.
 */
export const LITE_COLUMNS = 
[
    { 
        field: 'loan_account', 
        headerName: 'Loan Account',
        minWidth: 220,
        comparator: SortFunctions.alphaNumeric,
    },

    { 
        field: 'market_rate', 
        headerName: 'Market Rate', 
        minWidth: 115,
        cellRenderer: percentageRenderer
    },

    {
        headerName: "Rates for Credit Categories",
        width: 500,
        minWidth: 500,
        children:
        [
            { 
                field: 'rate_1', 
                headerName: 'Low',
                width: 90,
                minWidth: 90,
                cellEditor: PercentageCellEditor,
                cellRenderer: percentageRenderer,
                comparator: SortFunctions.numeric,
            },

            { 
                field: 'rate_3', 
                headerName: 'Medium',
                width: 90,
                minWidth: 90,
                cellEditor: PercentageCellEditor,
                cellRenderer: percentageRenderer,
                comparator: SortFunctions.numeric,
            },

            { 
                field: 'rate_6', 
                headerName: 'High', 
                width: 90,
                minWidth: 90,
                cellEditor: PercentageCellEditor,
                cellRenderer: percentageRenderer,
                comparator: SortFunctions.numeric,
            }
        ]
    },

    { 
        field: 'org_fee', 
        headerName: 'Origination Fee', 
        minWidth: 135,
        cellEditor: PercentageCellEditor,
        cellRenderer: (props) => { return <b> {formatPercentage(props.value)} </b> },
        comparator: SortFunctions.numeric,
    },

    { 
        field: 'annual_fee', 
        headerName: 'Annual Fee', 
        minWidth: 110,
        cellEditor: CurrencyCellEditor,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric,
    },

    {
        headerName: "BP",
        children:
        [
            { 
                field: 'basis_point_up', 
                headerName: '+', 
                width: 80,
                minWidth: 80,
                cellClass: 'center',
                cellRenderer: DisabledButtonRenderer,
                editableCellRenderer: ButtonRenderer
            },

            { 
                field: 'basis_point_down', 
                headerName: '-', 
                width: 80,
                minWidth: 80,
                cellClass: 'center',
                cellRenderer: DisabledButtonRenderer,
                editableCellRenderer: ButtonRenderer
            }
        ]
    }
];


export default COLUMNS;