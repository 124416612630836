import ajax from "../utils/Ajax";
import Utils from "../utils/Utils";
import gameService from "./GameService";
import { setMessages } from "../../features/message/MessageSlice";
import { store } from "../store";


/**
 * The watch interval.
 */
let WATCH_INTERVAL = 10000;




/**
 * The watch service class.
 */
class WatchService
{
    /**
     * This method initializes the watch service.
     */
    initialize()
    {
        // Set the timer...
        window.setInterval(this.watch, WATCH_INTERVAL);
    }


    /**
     * This method watches the server for updates.
     */
    watch = () =>
    {
        // Get the parameters
        let params = Utils.getActiveParams();

        // Add the games if it's the home route...
        if (Utils.getActiveRoute() === "/")
        {
            if (!params)
                params = {};

            params.games = true;
        }

        // Get out if nothing to do...
        if (!store.getState().authentication.userId || !params || (Object.keys(params).length == 0))
            return;

        // Post the watch
        ajax.postSilent("/watch", params, this.onWatchComplete, null, null, params);
    }


    /** 
     * The watch complete event handler.
     *
     * @param {*} response The response.
     * @param {*} params The parameters.
     */
    onWatchComplete = (response, params) =>
    {
        // Get the game ID...
        let gameId = params.game_id ? parseInt(params.game_id) : 0;

        // Update the games if necessary
        if (response.games)
            gameService.updateGames(response.games);

        // Update the game details if necessary
        if (response.gameDetails)
            gameService.updateGameDetails(gameId, response.gameDetails);

        // Update the teams if necessary
        if (response.teams)
            gameService.updateTeams(gameId, response.teams);

        // Update the dashboard details if necessary
        if (response.dashboardDetails)
            gameService.updateDashboardDetails(params.team_id, response.dashboardDetails);

        // Set the message
        if (response.messages)
        {
            store.dispatch(setMessages(response.messages));
        }
    }
}

// Export the watch service.
export default new WatchService();