import { createSlice } from '@reduxjs/toolkit';

// Create the initial state
const initialState = 
{
    block: false,
    blockMessage: null
};

// Create the slice
export const blockSlice = createSlice(
{
    name: 'block',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: 
    {
        /**
         * The block method.
         * 
         * @param {*} state The state.
         * @param {*} action The action.
         */
        block: (state, action) => 
        {
            state.blocking = true;
            state.blockMessage = action.payload;
        },

        /**
         * The unblock method.
         * 
         * @param {*} state The state.
         */
        unblock: (state) =>
        {
            state.blocking = false;
            state.blockMessage = null;
        }
    }
});

// Export the block action
export const { block, unblock } = blockSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectBlocked = (state) => state.block.block;
export const selectBlockMessage = (state) => state.block.blockMessage;

// Get out...
export default blockSlice.reducer;
