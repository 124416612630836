import { withRouter } from "react-router";
import { connect } from 'react-redux';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import BaseDecisionGridView from "./BaseDecisionGridView";
import DecisionBody from "./DecisionBody";
import ScenarioName from "./components/ScenarioName";


/**
 * The BDMViewTemplate class.
 */
class BDMViewTemplate extends BaseDecisionGridView
{
    /**
     * This method renders the view.
     */
    renderGridView()
    {
        // Get the last slash
        let lastSlash = this.props.location.pathname.lastIndexOf("/");

        // Get the base path
        let basePath = this.props.location.pathname.substring(0, lastSlash + 1);

        // Get the active tab
        let activeTab = this.props.location.pathname.substring(lastSlash + 1);


        return (
            <DecisionBody name="Marketing & General Administration" noteType="Admn" game={ this.props.game } intraturnId={ this.props.intraturnId } onSave={ this.props.onSave } dirty={ this.props.dirty }>
                <div className="general-admin-scenario-name" >
                    <ScenarioName intraturnId={ this.props.intraturnId } />
                </div>

                <Tabs activeKey={ activeTab } onSelect={ (key) => { this.props.history.push(basePath + key) } }>
                    <Tab eventKey="business-development" title="Business Development">
                        {activeTab == "business-development" ? this.props.children : "" }
                    </Tab>
                    {
                        (this.props.game.type !== "Lite") ?
                            <Tab eventKey="facilities" title="Facilities">
                                {activeTab == "facilities" ? this.props.children : "" }
                            </Tab>
                        : ""
                    }

                    {
                        (this.props.game.type !== "Lite") ?
                            <Tab eventKey="it" title="IT">
                                {activeTab == "it" ? this.props.children : "" }
                            </Tab>
                        : ""
                    }
                    <Tab eventKey="learning-development" title="Learning / Development">
                        {activeTab == "learning-development" ? this.props.children : "" }
                    </Tab>
                    <Tab eventKey="staffing" title="Staffing">
                        {activeTab == "staffing" ? this.props.children : "" }
                    </Tab>
                </Tabs>
            </DecisionBody>
        );
    }
}

// Export the decisions view...
export default withRouter(connect(BaseDecisionGridView.mapStateToProps)(BDMViewTemplate));