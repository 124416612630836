/**
 * The form validation.
 */
const FormValidation =
{
    createGame:
    [
        {
            property: "game_name",
            name: "Game Name",
            required: true
        },
    
        {
            property: "interest_rate_code",
            name: "Interest Rate Environment",
            required: true
        },

        {
            property: "template_details_id",
            name: "Template",
            required: true
        }
    ],

    updateGame:
    [
        {
            property: "game_name",
            name: "Game Name",
            required: true
        },
    
        {
            property: "interest_rate_code",
            name: "Interest Rate Environment",
            required: true
        }
    ],

    addCD:
    [
        {
            property: "cd_account",
            name: "CD Account",
            required: true
        },

        {
            property: "term",
            name: "Term",
            required: true,
            min: 1
        },

        {
            property: "rate",
            name: "Offer Rate",
            required: true,
            min: 0
        }
    ]
}

export default FormValidation;