import React from 'react';
import { useSelector } from 'react-redux';

import 
{
    Route,
    Redirect
} from "react-router";
import HelpService from '../app/services/HelpService';
  
import { selectUserId, selectRole } from '../features/authentication/AuthenticationSlice';
  

/**
 * The admin route function.
 * 
 * @param {*} children
 * @param {*} The rest. 
 * @returns The route.
 */
function AdminRoute ({ children, help, ...rest }) 
{
    let gameId;

    let userId = useSelector(selectUserId);
    let role = useSelector(selectRole);

    // Get the game ID if one exists...
    if (rest && rest.computedMatch && rest.computedMatch.params)
        gameId = parseInt(rest.computedMatch.params.game_id);

    // Set the active page
    HelpService.setActivePage(help, gameId);

    return (
        <Route {...rest} render={() => 
        {
            return ((userId != null) && (role === "Administrator"))
                ? children
                : <Redirect to='/login' />
            }
        } />
    )
}

export default AdminRoute;