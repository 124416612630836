import { Fragment } from 'react';
import { withRouter, Prompt } from "react-router";
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import BaseDecisionGridView from "./BaseDecisionGridView";
import COLUMNS from "./grid/LoanAdministrationColumns";
import DecisionGrid from "./components/DecisionGrid";
import TurnSelect from "./components/TurnSelect";
import ScenarioName from "./components/ScenarioName";
import Utils from "../../../utils/Utils";

import DecisionBody from "./DecisionBody";
import gameService from "../../../services/GameService";
import intraturnService from "../../../services/IntraturnService";
import SelectControl from '../../../controls/form/SelectControl';
import NumberControl from '../../../controls/form/NumberControl';
import { parsePercentage } from "../../../controls/Percentage";

/**
 * The maximum loan loss value...
 */
let MAX_LOAN_LOSS = 5000;


/**
 * The LoanAdministrationView class.
 */
class LoanAdministrationView extends BaseDecisionGridView
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the loaded flag
        this.loaded = false;

        // Initialize the state
        this.state =
        {
            rows: [],
            dirtyRows: {},
            sortColumns: [],
            selectedLoanType: "Loans",
            selectedTurn: -1,
            loanLossAdjustment: 0,
            originalLoanLossAdjustment: 0,
            balanceCategory: 6,
            grid:
            {
                columns: 11,
                extraHeader:
                [
                    [ { start: 3, span: 6,  text: "Portfolio Target Categories for New Loans"} ]
                ]
            }
        };

        // Initialize the columns
        this.columns = COLUMNS;
    }

    /**
     * This method loads the loan administrations.
     *
     * @param turn The turn.
     * @param loanType The loan type.
     */
    loadLoanAdministrations(turn, loanType)
    {
        // Get out if the state is loaded
        if (!this.props.game)
            return;

        // Only load once...
        if (this.loadingLoanAdministrations)
            return;

        // Get out if nothing to do...
        if ((this.state.selectedTurn === turn) && (this.state.selectedLoanType === loanType))
            return;

        // Warn the user if there are changes...
        if (this.isDirty())
        {
            if (!window.confirm("You have unsaved changes.  Do you wish to proceed?"))
                return;
        }

        // Mark us as loading...
        this.loadingLoanAdministrations = true;

        // Load the loan administrations
        gameService.loadLoanAdministrations(this.props.match.params.team_id, turn, loanType, this.onLoanAdministrationsLoaded);
    }


    /**
     * The loan administrations loaded event handler.
     *
     * @param {*} loanAdministrations The loan administrations.
     * @param {*} loanLossAdjustment The loan loss adjustment.
     * @param {*} lastUpdated The last updated time.
     * @param {*} turn The turn.
     * @param {*} loanType The loan type.
     */
    onLoanAdministrationsLoaded = (loanAdministrations, loanLossAdjustment, lastUpdated, turn, loanType) =>
    {
        // Make sure we have something...
        if (!loanAdministrations)
            loanAdministrations = [];

        // Calculate the total for the row...
        for (let loadAdministration of loanAdministrations)
        {
            // Set the out of market flag
            if (parseFloat(loadAdministration.cap) !== 0)
                loadAdministration.out_of_market = false;
            else
                loadAdministration.out_of_market = true;

            // Calculate the total
            this.calculateTotal(loadAdministration);
        }

        // Initialize the state
        let state =
        { 
            rows: loanAdministrations, 
            selectedTurn: turn, 
            selectedLoanType: loanType, 
            loanLossAdjustment: loanLossAdjustment,
            originalLoanLossAdjustment: loanLossAdjustment,
            lastUpdated: lastUpdated,
            summary: this.calculateSummary(loanAdministrations)
        }

        // Set the intraturn Id if appropriate
        if (loanAdministrations.length > 0)
            state.intraturnId = loanAdministrations[0].intraturn_id;

        // Set the state
        this.setState(state);

        // Clear the dirty rows
        this.clearDirtyRows();

        // Reset the loading loan administrations flag
        this.loadingLoanAdministrations = false;

        // Set the loaded flag
        this.loaded = true;
    }


    /**
     * This method calculates the total.
     *
     * @param {*} loanAdministration The loan administration.
     */
    calculateTotal(loanAdministration)
    {
        // Calculate the total
        loanAdministration.target_total = (parseFloat(loanAdministration.target_1) * 1000) + (parseFloat(loanAdministration.target_2) * 1000) + 
                                          (parseFloat(loanAdministration.target_3) * 1000) + (parseFloat(loanAdministration.target_4) * 1000) + 
                                          (parseFloat(loanAdministration.target_5) * 1000) + (parseFloat(loanAdministration.target_6) * 1000);


        // Convert it back to a decimal...
        loanAdministration.target_total /= 1000;
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Call mom...
        let result = super.isComponentReady();

        // Load the loan administrations if necessary...
        if (this.props.game && !this.loaded)
        {
            // Load the loan administrations
            this.loadLoanAdministrations(this.props.game.turns_completed + 1, this.state.selectedLoanType);

            // Not ready...
            return false;
        }

        // Get out...
        return result;
    }


    /**
     * This method determines if any fields are dirty.
     *
     * @returns true if any fields are dirty, else false.
     */
    hasDirtyFields()
    {
        // See if any fields are dirty...
        if (parseFloat(this.state.originalLoanLossAdjustment) !== parseFloat(this.state.loanLossAdjustment))
            return true;
            
        return false;
    }

    /**
     * This method updates the state when the cell value has been modified.
     *
     * @param {*} params The params.
     */
    onCellValueChanged = (params) =>
    {
        // Get the dirty rows map...
        let dirtyRows = this.state.dirtyRows;

        // Mark it dirty
        dirtyRows[params.data.loan_admin_id] = true;

        // Update the exit market situation
        if ((params.column.colId  === "out_of_market") && params.data.out_of_market)
            params.data.cap = "0.00";

        // Handle cap updates...
        if (params.column.colId === "cap")
        {
            if (parseFloat(params.data.cap) !== 0)
                params.data.out_of_market = false;
            else
                params.data.out_of_market = true;
        }

        // Update the total
        this.calculateTotal(params.data);

        // Update Summary
        let summary = this.calculateSummary(this.state.rows);

        // Save the state
        this.setState(
        {
            dirtyRows: dirtyRows,
            summary: summary
        });
        
        // Displays should most recent up to date values.
        this.api.refreshCells();
    }


    /**
     * This method handles view saves.
     */
    onSave = () =>
    {
        // Get out if this is not actually dirty (shouldn't happen)
        if (!this.isDirty())
            return;

        // Validate the table
        if (!this.validateTable())
            return;

        // Handle the save...
        if (this.hasDirtyFields())
            this.updateDirtyFields(false);
        else
            this.updateDirtyRows(false);
    }


    /**
     * This method validates the table.
     */
    validateTable()
    {
        // Initialize the bad rows
        let badRows = [];

        // Find the bad rows
        for (let counter = 0; counter < this.state.rows.length; counter++)
        {
            // Add it if it is bad
            if (this.state.rows[counter].target_total !== 100)
                badRows.push(counter + 1);
        }

        // Get out if there were none...
        if (badRows.length === 0)
            return true;

        // Handle single row scenario...
        if (badRows.length === 1)
        {
            alert ("Row " + badRows[0] + " has targets that don't total 100%.");
            return;
        }


        // Initialize the row text
        let rowText = "";

        for (let counter = 0; counter < badRows.length; counter++)
        {
            if (counter === badRows.length - 1)
            {
                if (counter > 1)
                    rowText += ", and ";
                else
                if (counter > 0)
                    rowText += " and ";
            }
            else
            if (counter > 0)
                rowText += ", ";

            // Add the bad rows
            rowText += badRows[counter];
        }

        // Create the message
        let message = "Rows " + rowText + " have targets that don't total 100%.";

        // Alert the message
        alert(message);

        // Invalid table...
        return false;
    }


    /**
     * This method updates the dirty fields.
     *
     * @param force The force flag.
     */
    updateDirtyFields(force)
    {
        // Update the loan loss adjustment
        intraturnService.updateLoanLossAdjustment(this.state.intraturnId, this.state.loanLossAdjustment, this.state.lastUpdated, force, this.onUpdateLoanLossAdjustmentComplete);
    }


    /**
     * This method handles the update loan loss adjustment complete event.
     *
     * @param {*} modifiedBy The modified by user.
     * @param {*} lastUpdated The last updated time.
     * @param loanLossAdjustment The loan loss adjustment.
     */
    onUpdateLoanLossAdjustmentComplete = (modifiedBy, lastUpdated, loanLossAdjustment) =>
    {
        // See if it was modified since we loaded the data...
        if (modifiedBy)
        {
            // See if the user wants to force the matter...
            if (!window.confirm("Loan Adminstration records were modified by " + modifiedBy + " at " + new Date(lastUpdated).toLocaleTimeString() + ".\n\nWould you like save your changes anyway?"))
                return;

            // Force the update
            this.updateDirtyFields(true);

            // Get out...
            return;
        }

        // Set the state
        this.setState(
        {
            loanLossAdjustment: loanLossAdjustment, 
            originalLoanLossAdjustment: loanLossAdjustment,
            lastUpdated: lastUpdated
        });

        // Show the alert if there are no dirty rows
        if (!this.updateDirtyRows(false))
            alert("Saved");
    }


    /**
     * This method updates the dirty rows.
     *
     * @param {*} force The force flag.
     * @return true if there are dirty rows, else false...
     */
    updateDirtyRows(force)
    {
        // Get out if nothing to do...
        if (Object.keys(this.state.dirtyRows).length === 0)
            return false;

        // Initialize the updated rows
        let updatedRows = [];

        // Check each row...
        for (let row of this.state.rows)
        {
            // See if it is dirty...
            if (!this.state.dirtyRows[row.loan_admin_id])
                continue;

            // Converts targets
            row.target_1 = parsePercentage(row.target_1).toFixed(3);
            row.target_2 = parsePercentage(row.target_2).toFixed(3);
            row.target_3 = parsePercentage(row.target_3).toFixed(3);
            row.target_4 = parsePercentage(row.target_4).toFixed(3);
            row.target_5 = parsePercentage(row.target_5).toFixed(3);
            row.target_6 = parsePercentage(row.target_6).toFixed(3);

            // create a new row
            let updatedRow = { ...row };

            // Parse the cap value...
            updatedRow.cap = Utils.parseCurrency(updatedRow.cap);

            // Push the updated row
            updatedRows.push(updatedRow);
        }

        // Update the loan administrations
        intraturnService.updateLoanAdministrations(this.state.intraturnId, updatedRows, this.state.lastUpdated, force, this.onSaveComplete);

        // Get out...
        return true;
    }


    /**
     * The save complete event handler.
     *
     * @param {*} modifiedBy The modified by user.
     * @param {*} lastUpdated The last updated time.
     */
    onSaveComplete = ({modifiedBy, lastUpdated }) =>
    {
        // See if it was modified since we loaded the data...
        if (modifiedBy)
        {
            // See if the user wants to force the matter...
            if (!window.confirm("Loan Adminstration records were modified by " + modifiedBy + " at " + new Date(lastUpdated).toLocaleTimeString() + ".\n\nWould you like save your changes anyway?"))
                return;

            // Force the update
            this.updateDirtyRows(true);

            // Get out...
            return;
        }

        // Set the last updated time
        this.setState({ lastUpdated: lastUpdated });

        // Clear the dirty flag
        this.clearDirtyRows();

        // let the user know
        alert("Saved.");
    }


    /**
     * This method clears the dirty rows.
     */
    clearDirtyRows()
    {
        // Reset the dirty IDs
        this.setState(
        {
            dirtyRows: {}
        });
    }


    /**
     * This method handles turn changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onTurnChange = (name, value) =>
    {
        // Load the loan administrations
        this.loadLoanAdministrations(parseInt(value), this.state.selectedLoanType);
    }


    /**
     * This method handles loan type changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onLoanTypeChange = (name, value) =>
    {
        // Load the loan administrations
        this.loadLoanAdministrations(this.state.selectedTurn, value);
    }


    /**
     * THe loan loss field change.
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onFieldChangeLoanLoss = (name, value) =>
    {
        // Validate the value
        if (parseInt(value) > MAX_LOAN_LOSS)
        {
            // Alert the user
            alert("The maximum loan loss value is " + MAX_LOAN_LOSS);

            // Get out...
            return;
        }

        // Update the value
        this.onFieldChange(name, value);
    }


    /**
     * This method handles field changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onFieldChange = (name, value) =>
    {
        // Get out if no change...
        if ((this.state[name] === value) || (!this.state[name] && !value))
            return;

        // Set the name/value pair...
        let state = {};
        state[name] = value;

        // Set the state
        this.setState(state);
    }


    /**
     * This method places the balance in a category.
     */
    balanceToCategory()
    {
        // Get category to balance
        let category = "target_" + this.state.balanceCategory;

        // Create the new rows
        let newRows = [ ...this.state.rows ];

        // Create the dirty rows
        let dirtyRows = { ...this.state.dirtyRows };

        // Update the rows
        for (let row of newRows)
        {
            // Skip if there is nothing to do...
            if (row.target_total === 100)
                continue;

            // Mark the orw as dirty
            dirtyRows[row.loan_admin_id] = true;

            // Update the category field...
            if (row.target_total > 100)
            {
                // Determine the amount to update the row
                let updateAmount = Math.min(parseFloat(row[category]), row.target_total - 100);
                row[category] = parseFloat(row[category]) - updateAmount;
            }
            else
            {
                // Determine the amount to update the row
                let updateAmount = 100 - row.target_total;
                row[category] = parseFloat(row[category]) + updateAmount;
            }

            // Calculate the total
            this.calculateTotal(row);
        }

        // Recalculate the summary
        let summary = this.calculateSummary(newRows);

        // Set the state
        this.setState({ rows: newRows, summary: summary, dirtyRows: dirtyRows });

        // Displays should most recent up to date values.
        this.api.refreshCells();
    }


    /**
     * This method calculates the summary.
     *
     * @param {*} rows The rows.
     * @returns The summary.
     */
    calculateSummary(rows)
    {
        // Initialize the summary
        let summary =
        {
            loan_account: "Total",
            max_out: 0
        }
        let total = 0;

        // Calculate it
        rows.forEach(row => total += Utils.parseCurrency(row.max_out));
        summary.max_out = total;

        // Get out...
        return summary ;
    }


    /**
     * This method renders the view.
     */
    renderGridView()
    {
        // Initialize the editable flag
        let editable = !this.props.team.frozen && (this.state.selectedTurn === this.props.game.turns_completed + 1) ? true : false;
        
        // Initialize the loan types
        let loanTypes = [ { key: "All", value: "All" }, ...this.props.gameDetails.loan_admin_types.map(type => ( { key: type, value: type })) ];

        // Initialize the total
        let total = 0;

        // Calculate it
        this.state.rows.forEach(row => total += parseFloat(row.amount));

        return (
            <Fragment>
                <Prompt when={ this.isDirty() } message="There are unsaved changes on this screen.  Are you sure you want to leave?" />

                <DecisionBody name="Loan Administration" game={ this.props.game } noteType="Loan" intraturnId={ this.state.intraturnId } onSave={ this.onSave } dirty={ this.isDirty() }>

                    <Row>
                        <Col xs={6} sm={6} md={4} lg={4}>
                            <TurnSelect name="turns" onChange={ this.onTurnChange } value= { this.state.selectedTurn } />
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4}>
                        { 
                            true ? 
                                <SelectControl items={ loanTypes } name="loanTypes"  onChange={ this.onLoanTypeChange } value= { this.state.selectedLoanType } />
                            : ""
                        }
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="right col-padding-right bold col-middle-align">
                            <ScenarioName intraturnId={ this.state.intraturnId } />
                        </Col>
                    </Row>

                    <div class="grid-body">
                        <DecisionGrid 
                                turn={ this.state.selectedTurn }
                                columns={ this.columns } 
                                sortColumns={ this.state.sortColumns }
                                rowData={ this.state.rows } 
                                onCellValueChanged={ this.onCellValueChanged } 
                                onSortColumnsChange={ this.onSortColumnsChange }
                                pinnedBottomRowData={[ this.state.summary ]}
                                onGridReady = { (params) => { this.api = params.api }}
                        />
                    </div>

                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className="vertical-spacing">
                            Q&amp;E&nbsp;Loan&nbsp;Loss&nbsp;Adjustment&nbsp;(000)s<NumberControl name="loanLossAdjustment" value={ this.state.loanLossAdjustment } onChange={ this.onFieldChangeLoanLoss } style={{ width: "75px", display: "inline" }} className="auto-input" disabled={ !editable } />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className="vertical-spacing right">
                            <NumberControl name="balanceCategory" value={ this.state.balanceCategory } min={1} max={6} onChange={ this.onFieldChange } style={{ width: "75px", display: "inline" }} className="auto-input form-control" disabled={ !editable } />
                            <Button variant="primary" onClick={ () => this.balanceToCategory() } disabled={ !editable }>Balance&nbsp;to&nbsp;Category</Button>
                        </Col>
                    </Row>
                </DecisionBody>
            </Fragment>
        );
    }
}

// Export the decisions view...
export default withRouter(connect(BaseDecisionGridView.mapStateToProps)(LoanAdministrationView));