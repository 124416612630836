import React, { Fragment } from 'react';
import FormValidation from "../resources/FormValidation";



/**
 * The validator class.
 */
export class Validator
{
    /**
     * This method creates a success result.
     * 
     * @returns The success result.
     */
    static createSuccess()
    {
        let result =
        {
            success: true,
            errors: []
        }

        return result;
    }


    /**
     * This method validates the data in the component.
     * 
     * @param state The state that is being validated.
     * @param formName The form name.
     * @returns The validation result.
     */
    static validate(state, formName)
    {
        // Get the validation list
        let validationList = FormValidation[formName];

        // Initialize the errors.
        let errors = [];

        // Check each field that is being validated.
        for (let validator of validationList)
            this.validateField(state, validator, errors);

        // Create the result
        let result =
        {
            result: errors.length === 0 ? true : false,
            errors: errors
        }

        // Get out...
        return result;
    }


    /**
     * This method validates the field.
     * 
     * @param {*} state The state.
     * @param {*} validator The validator.
     * @param {*} errors The errors.
     */
    static validateField(state, validator, errors)
    {
        // Handle required fields.
        if (validator.required)
        {
            if (("" + state[validator.property]).length === 0)
            {
                errors.push(validator.name + " is a required field.");
                return;
            }
        }

        if (validator.min || validator.max)
        {
            // Get the minimum value...
            let minVal = validator.min;
            if (minVal)
            {
                if (isNaN(minVal) && (minVal.indexOf('$') === 0))
                    minVal = state[minVal.substring(1)];
            }

            // Get the maximum value...
            let maxVal = validator.max;
            if (maxVal)
            {
                if (isNaN(maxVal) && (maxVal.indexOf('$') === 0))
                    maxVal = state[maxVal.substring(1)];
            }

            // Determine if it is below the minimum or above the maximum value
            let belowMin = (minVal && (parseInt(state[validator.property]) < parseInt(minVal)));
            let aboveMax = (maxVal && (parseInt(state[validator.property]) > parseInt(maxVal)));

            // Check to see if it is out of range...
            if (belowMin || aboveMax)
            {
                // Construct the error
                if (minVal && maxVal)
                    errors.push(validator.name + " must be between " + minVal + " and " + maxVal);
                else
                if (minVal)
                    errors.push(validator.name + " must be at least " + minVal);
                else
                    errors.push(validator.name + " cannot be greater than " + maxVal);

                // Get out...
                return;
            }
        }
    }
}



/**
 * The validation summary.
 * 
 * @param {*} props The propertie.
 * @returns The rendered object model.
 */
export function ValidationSummary(props) 
{
    // Get out if nothing to do...
    if (!props.validation || !props.validation.errors.length)
        return <Fragment />;

    return (
        <div class="validation-summary">
            <div className="validation-summary-title">Validation Errors</div>
        {
            props.validation.errors.map((error, key) =>
            {
                return <div className="validation-error" key={ key } >{ error }</div>;
            })
        }
        </div>
    );
} 