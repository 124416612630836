import { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import TeamKeyIndicatorsGraphGroup from "./TeamKeyIndicatorsGraphGroup";


/**
 * The team key indicators graph component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
const TeamKeyIndicatorsGraph = (props) =>
{
    // Get out if nothing to do...
    if (!props.kpiMap)
        return <Fragment />;

    // Get out...
    return (
        <Container>
        {
            props.kpiTypes.map((kpiType) =>
            {
                return (
                    <Row className="key-indicator-graph-row">
                        <Col xs={12} sm={12} md={12} lg={12} className="key-indicator">
                            <TeamKeyIndicatorsGraphGroup name={ kpiType } kpi={ props.kpiMap[kpiType] } maxGraphCols={ props.maxGraphCols } />
                        </Col>
                    </Row>
                )
            })
        }
        </Container>
    );
}

// Get out...
export default TeamKeyIndicatorsGraph;


