import {CheckBoxRenderer, DisabledCheckBoxRenderer, currencyRenderer, percentageRenderer } from "../../../../controls/grid/GridRenderers";
import { CurrencyCellEditor, PercentageCellEditor } from "../../../../controls/grid/GridEditors";
import SortFunctions from "../../../../controls/grid/SortFunctions";
import { boldFormatter } from "../../../../controls/grid/GridFormatters";


/**
 * The columns.
 */
const COLUMNS = 
[
    { 
        field: 'loan_account', 
        headerName: 'Loan Account',
        minWidth: 270,
        comparator: SortFunctions.alphaNumeric,
    },

    { 
        field: 'max_out', 
        headerName: 'Current Balance (000)s', 
        minWidth: 250,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric,
    },

    {
        headerName: "Portfolio Target Credit Categories for New Loans",
        children:
        [
            { 
                field: 'target_1', 
                headerName: '1', 
                minWidth: 90,
                cellEditor: PercentageCellEditor,
                cellRenderer: percentageRenderer,
                comparator: SortFunctions.numeric,
                editable: (params) => params.data.out_of_market ? false : true
            },

            { 
                field: 'target_2', 
                headerName: '2', 
                minWidth: 90,
                cellEditor: PercentageCellEditor,
                cellRenderer: percentageRenderer,
                comparator: SortFunctions.numeric,
                editable: (params) => params.data.out_of_market ? false : true
            },

            { 
                field: 'target_3', 
                headerName: '3', 
                minWidth: 90,
                cellEditor: PercentageCellEditor,
                cellRenderer: percentageRenderer,
                comparator: SortFunctions.numeric,
                editable: (params) => params.data.out_of_market ? false : true
            },

            { 
                field: 'target_4', 
                headerName: '4', 
                minWidth: 90,
                cellEditor: PercentageCellEditor,
                cellRenderer: percentageRenderer,
                comparator: SortFunctions.numeric,
                editable: (params) => params.data.out_of_market ? false : true
            },

            { 
                field: 'target_5', 
                headerName: '5', 
                minWidth: 90,
                cellEditor: PercentageCellEditor,
                cellRenderer: percentageRenderer,
                comparator: SortFunctions.numeric,
                editable: (params) => params.data.out_of_market ? false : true
            },

            { 
                field: 'target_6', 
                headerName: '6', 
                minWidth: 90,
                cellEditor: PercentageCellEditor,
                cellRenderer: percentageRenderer,
                comparator: SortFunctions.numeric,
                editable: (params) => params.data.out_of_market ? false : true
            }
        ]
    },

    { 
        field: 'target_total', 
        headerName: 'Total',
        minWidth:130, 
        cellRenderer: boldFormatter(percentageRenderer),
        comparator: SortFunctions.numeric
    },

    { 
        field: 'cap', 
        headerName: 'Cap (000)s', 
        minWidth: 130,
        cellEditor: CurrencyCellEditor,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric,
        editable: (params) => params.data.out_of_market ? false : true
    },

    { 
        field: 'out_of_market', 
        headerName: 'Exit Market', 
        minWidth: 120,
        width: 120,
        cellClass: 'center',
        cellRenderer: DisabledCheckBoxRenderer,
        editableCellRenderer: CheckBoxRenderer
    }

];


export default COLUMNS;