import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

/**
 * The default mask options
 */
const DEFAULT_MASK_OPTIONS = 
{
    prefix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    decimalSymbol: '.',
    decimalLimit: 0, // how many digits allowed after the decimal
    allowNegative: false,
    allowLeadingZeroes: false,
}

// Create the currency mask
const INTEGER_MASK = createNumberMask(DEFAULT_MASK_OPTIONS);

/**
 * The integer input component.
 *
 * @param {*} props The properties.
 * @returns The integer input control.
 */
class IntegerInput extends React.Component
{
     /**
      * Object Constructor.
      *
      * @param {*} props The properties.
      */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Create the input reference
        this.inputRef = React.createRef();
    }

    /**
     * This method places focus on the cell.
     */
    focus()
    {
        this.inputRef.current.inputElement.focus();
    }

    /**
     * This method renders the component.
     *
     * @returns The rendered component.
     */
    render()
    {
        // Determine the value
        let value = parseInt(this.props.value);
        if (value === NaN)
            value = "";

        // Update the props
        let props = { ...this.props, value: value };

        // Return the masked input control...
        return <MaskedInput ref={ this.inputRef } mask={ INTEGER_MASK } 
                            onFocus={ (event) => event.target.select() }
                            { ...props } />;
    }
}

/**
 * The default properties.
 */
IntegerInput.defaultProps = 
{
    inputMode: 'numeric',
    maskOptions: {},
}

/**
 * The property types.
 */
IntegerInput.propTypes = 
{
    inputmode: PropTypes.string,
    maskOptions: PropTypes.shape(
    {
        prefix: PropTypes.string,
        suffix: PropTypes.string,
        includeThousandsSeparator: PropTypes.bool,
        thousandsSeparatorSymbol: PropTypes.string,
        allowDecimal: PropTypes.bool,
        decimalSymbol: PropTypes.string,
        decimalLimit: PropTypes.string,
        requireDecimal: PropTypes.bool,
        allowNegative: PropTypes.bool,
        allowLeadingZeroes: PropTypes.bool,
        integerLimit: PropTypes.number,
    })
}


// Export the integer input component
export default IntegerInput;