import { withRouter } from "react-router";
import { connect } from 'react-redux';

import SelectControl from '../../../../controls/form/SelectControl';


/**
  * This method maps the state to the properties.
  * 
  * @param {*} state The state.
  * @param {*} ownProps The own properties.
  * @returns The mapping.
  */
function mapStateToProps(state, ownProps)
{
    let result =
    {
        game: state.games.gameMap[ownProps.match.params.game_id],
        gameDetails: state.games.gameDetailsMap[ownProps.match.params.game_id]
    };

    return result;
}


/**
 * The turn select component.
 *
 * @param {*} props The properties.
 *
 * @returns The turn select component.
 */
const TurnSelect = (props) =>
{
    // Get the turns
    let turns = props.gameDetails.turns;

    // Filter them if necessary
    if (props.availableTurns && props.availableTurns.length)
    {
        // Create the turn map
        let turnMap = {};

        // Populate the map
        for (let turn of props.availableTurns)
            turnMap[turn] = true;

        // Filter the turns
        turns = turns.filter(turn => turnMap[turn.turn_num]);
    }

    // Get the turn items
    let turnItems = turns.filter(turn => turn.turn_num <= props.game.turns_completed + 1)
                                           .map(turn => ({ name: "Qtr " + turn.turn_num, value: turn.turn_num }));

    // Return the select control
    return <SelectControl items={ turnItems } name="turns"  onChange={ props.onChange } value= { props.value } />;
}

export default withRouter(connect(mapStateToProps)(TurnSelect));