import { SelectColumn } from 'react-data-grid';
import { CurrencyCellEditor } from "../../../../controls/grid/GridEditors";
import { currencyRenderer } from '../../../../controls/grid/GridRenderers';
import SortFunctions from "../../../../controls/grid/SortFunctions";

/**
 * The columns.
 */
const COLUMNS =
[
    SelectColumn,

    { 
        field: 'type', 
        headerName: 'Type',
        minWidth: 200,
        comparator: SortFunctions.alphaNumeric,
    },

    { 
        field: 'amount', 
        headerName: 'Amount', 
        minWidth: 200,
        cellEditor: CurrencyCellEditor,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric,
    }
];

export default COLUMNS;