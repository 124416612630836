import ajax from "../utils/Ajax";
import { setGames, setGameDetails, setTeams, setDashboardDetails, setTeamPerformance, 
         setMergersAndAcquisitions, setMergersAndAcquisitionsScenarios, setTeamFrozen, setTeamName, 
         setGameFrozen, setTeamDisableWhatIfs, updateMeasuresOfSuccess, setRegulatoryReport } from "../../features/games/GamesSlice";
import { setIntraturn } from "../../features/intraturns/IntraturnsSlice";
import { store } from "../store";


/**
 * The game service class.
 */
class GameService
{
    /**
     * This method loads the games.
     *
     * @returns true if the games are already loaded, else false.
     */
    loadGames()
    {
        // Get out if nothing to do...
        if (this.areGamesLoaded())
            return true;

        // Load the games
        ajax.post("/game/list", null, this.onGamesLoaded, "Loading Games...", "An error occurred while getting the list of games.");

        // Get out...
        return false;
    }


    /**
     * This method loads the game details.
     *
     * @param {*} gameId The game ID.
     * @returns true if the game details are already loaded, else false.
     */
    loadGameDetails(gameId)
    {
        // Get the game state
        let gameState = this.getState();

        // Get the game
        let game = gameState.gameDetailsMap[gameId];

        // Get out if it has been loaded
        if (game)
            return true;

        // Load the games
        ajax.post("/game/" + gameId, null, this.onGameDetailsLoaded, "Loading Game...", "An error occurred loading the game details.", gameId);

        // Get out...
        return false;
    }


    /**
     * This method loads the teams.
     *
     * @param {*} gameId the game ID.
     * @return true if the teams have been loaded, else false.
     */
    loadTeams(gameId)
    {
        // Get the teams map
        let teamsMap = this.getState().gameTeamsMap[gameId];

        // Get out if nothing to do...
        if (teamsMap && teamsMap[gameId])
            return true;

        // Load the games
        ajax.post("/game/teams/" + gameId, null, this.onTeamsLoaded, "Loading Teams...", "An error occurred while getting the teams.", gameId);
    }


    /**
     * This method gets the dashboard details.
     *
     * @param {*} gameId The game ID.
     * @param {*} teamId The team ID.
     */
    getDashboardDetails(gameId, teamId)
    {
        // Get the dashboard details map
        let dashboardDetailsMap = this.getState().dashboardDetailsMap;
        if (dashboardDetailsMap && dashboardDetailsMap[teamId])
            return;

        // Create the response function
        let responseFn = (response) =>
        {
            // Update the dashboard details
            this.updateDashboardDetails(teamId, response.dashboardDetails);
        };

        // Load the dashboard details
        ajax.post("/game/" + gameId + "/team/" + teamId + "/dashboard-details", null, responseFn, "Loading Dashboard Details", "An error occurred while getting the dashboard details.");
    }


    /**
     * This method gets the team performance data.
     *
     * @param {*} gameId The game ID.
     * @param {*} teamId The team ID.
     */
    getTeamPerformance(gameId, teamId)
    {
        // Get the team performance map
        let teamPerformanceMap = this.getState().teamPerformanceMap;
        if (teamPerformanceMap && teamPerformanceMap[teamId])
            return;

        // Create the response function
        let responseFn = (response) =>
        {
            // Update the team performance
            this.updateTeamPerformance(teamId, response.teamPerformance);
        };

        // Load the management ratios
        ajax.post("/game/" + gameId + "/team/" + teamId + "/team-performance", null, responseFn, "Loading Management Ratios", "An error occurred while getting the management ratios information.");
    }


    /**
     * This method gets the regulatory report data.
     *
     * @param {*} gameId The game ID.
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     */
    getRegulatoryReport(gameId, teamId, turnNum)
    {
        // Get the community performance map
        let regulatoryReportMap = this.getState().regulatoryReportMap;
        if (regulatoryReportMap && regulatoryReportMap[teamId] && regulatoryReportMap[teamId].turnNum === turnNum)
            return;

            // Create the response function
        let responseFn = (response) =>
        {
            // Update the regulatory report
            this.updateRegulatoryReport(teamId, turnNum, response.regulatoryReport, response.turns);
        };

        // Load the regulator report
        ajax.post("/game/" + gameId + "/team/" + teamId + "/regulatory-report/" + turnNum, null, responseFn, "Loading the Regulator Report", "An error occurred while getting the regulator report.");
    }


    /**
     * This method gets the regulator report.
     * 
     * @param {*} gameId The game ID.
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     * @param {*} responseFn The response function.
     */
    getRegulatorReport(gameId, teamId, turnNum, responseFn)
    {
        ajax.post("/game/" + gameId + "/team/" + teamId + "/regulator-report/load/" + turnNum, null, responseFn, "Loading the Regulator Report", "An error occurred while loading the regulator report.");
    }


    /**
     * This method saves the regulator report.
     * 
     * @param {*} gameId The game ID.
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     * @param {*} report The report.
     * @param {*} responseFn The response function.
     */
    saveRegulatorReport(gameId, teamId, turnNum, report, responseFn)
    {
        ajax.post("/game/" + gameId + "/team/" + teamId + "/regulator-report/save/" + turnNum, report, responseFn, "Saving the Regulator Report", "An error occurred while saving the regulator report.");
    }


    /**
     * This method gets the mergers & acquisitions data.
     *
     * @param {*} gameId The game ID.
     */
    getMergersAndAcquisitions(gameId, teamId)
    {
        // Get the mergers & acquisitions 
        let mergersAndAcquisitionsMap = this.getState().mergersAndAcquisitionsMap;
        if (mergersAndAcquisitionsMap && mergersAndAcquisitionsMap[teamId])
            return;

        // Create the response function
        let responseFn = (response) =>
        {
            // Update the mergers & acquisitions
            this.updateMergersAndAcquisitions(teamId, response.mergersAndAcquisitions, response.scenarios);
        };

        // Load the mergers and acquisitions
        ajax.post("/game/" + gameId + "/team/" + teamId + "/mergers-and-acquisitions", null, responseFn, "Loading Mergers & Acquisitions", "An error occurred while loading the mergers & acquisitions.");
    }


    /**
     * This method loads the investment purchases for the team.
     *
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     * @param {*} investmentAccountType The investment account type.
     * @param {*} onInvestmentPurchasesLoaded The response handler.
     */
    loadInvestmentPurchases(teamId, turnNum, investmentAccountType, onInvestmentPurchasesLoaded)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onInvestmentPurchasesLoaded(response.investmentPurchases, response.swaps, parseInt(response.maturity), response.lastUpdated, turnNum, investmentAccountType);
        };

        // Load the games
        ajax.post("/game/team/" + teamId + "/investment-purchases", { turnNum: turnNum, investmentAccountType: investmentAccountType }, responseFn, "Loading Investment Purchases...", "An error occurred while loading the investment purchases.", null, responseFn);
    }


    /**
     * This method loads the investment purchases for the team.
     *
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     * @param {*} investmentCategory The investment category.
     * @param {*} onInvestmentSalesLoaded The response handler.
     */
    loadInvestmentSales(teamId, turnNum, investmentCategory, onInvestmentSalesLoaded)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onInvestmentSalesLoaded(response.investmentSales, response.lastUpdated, turnNum, investmentCategory);
        };

        // Load the games
        ajax.post("/game/team/" + teamId + "/investment-sales", { turnNum: turnNum, investmentCategory: investmentCategory }, responseFn, "Loading Investment Sales...", "An error occurred while loading the investment sales.");
    }


    /**
     * This method loads the loan administrations for the team.
     *
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     * @param {*} loanType The loan type.
     * @param {*} onLoanAdministrationsLoaded The response handler.
     */
    loadLoanAdministrations(teamId, turnNum, loanType, onLoanAdministrationsLoaded)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onLoanAdministrationsLoaded(response.loanAdministrations, response.loanLossAdjustment, response.lastUpdated, turnNum, loanType);
        };

        // Load the games
        ajax.post("/game/team/" + teamId + "/loan-administrations", { turnNum: turnNum, loanType: loanType }, responseFn, "Loading Loan Administrations...", "An error occurred while loading the loan administrations.", null, responseFn);
    }


    /**
     * This method loads the loan originations for the team.
     *
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     * @param {*} loanType The loan type.
     * @param {*} onLoanAdministrationsLoaded The response handler.
     */
    loadLoanOriginations(teamId, turnNum, loanType, onLoanOriginationsLoaded)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onLoanOriginationsLoaded(response.loanOriginations, response.mortagesPipelined, response.lastUpdated, turnNum, loanType);
        };

        // Load the games
        ajax.post("/game/team/" + teamId + "/loan-originations", { turnNum: turnNum, loanType: loanType }, responseFn, "Loading Loan Originations...", "An error occurred while loading the loan originations.", null, responseFn);
    }


    /**
     * This method loads the loan sales for the team.
     *
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     * @param {*} loanCategory The loan category.
     * @param {*} onLoanSalesLoaded The response handler.
     */
    loadLoanSales(teamId, turnNum, loanCategory, onLoanSalesLoaded)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onLoanSalesLoaded(response.loanSales, response.lastUpdated, turnNum, loanCategory);
        };

        // Load the games
        ajax.post("/game/team/" + teamId + "/loan-sales", { turnNum: turnNum, loanCategory: loanCategory }, responseFn, "Loading Loan Sales...", "An error occurred while loading the loan sales.", null, responseFn);
    }


    /**
     * This method loads the NMDs for the team.
     *
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     * @param {*} nmdType The NMD type.
     * @param {*} onNMDLoaded The response handler.
     */
    loadNMDs(teamId, turnNum, nmdType, onNMDLoaded)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onNMDLoaded(response.nmds, response.lastUpdated, turnNum, nmdType);
        };

        // Load the games
        ajax.post("/game/team/" + teamId + "/nmds", { turnNum: turnNum, nmdType: nmdType }, responseFn, "Loading Non-Maturity Deposits...", "An error occurred while loading the loan sales.", null, responseFn);
    }


    /**
     * This method loads the CDs for the team.
     *
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     * @param {*} cdType The CD type.
     * @param {*} onCDsLoaded The response handler.
     */
    loadCDs(teamId, turnNum, cdType, onCDsLoaded)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onCDsLoaded(response.cds, response.lastUpdated, turnNum, cdType);
        };

        // Load the games
        ajax.post("/game/team/" + teamId + "/cds", { turnNum: turnNum, cdType: cdType }, responseFn, "Loading CDs...", "An error occurred while loading the CDs.", null, responseFn);
    }


    /**
     * This method loads the borrowings for the team.
     *
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     * @param {*} onBorrowingsLoaded The response handler.
     */
    loadBorrowings(teamId, turnNum, onBorrowingsLoaded)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onBorrowingsLoaded(response.borrowings, response.previousBorrowings, response.rates, response.lastUpdated, turnNum, response.intraturnId);
        };

        // Load the borrowings
        ajax.post("/game/team/" + teamId + "/borrowings", { turnNum: turnNum }, responseFn, "Loading Borrowings...", "An error occurred while loading the borrowings.", null, responseFn);
    }


    /**
     * This method loads the long-term debts for the team.
     *
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     * @param {*} onLongTermDebtsLoaded The response handler.
     */
    loadLongTermDebts(teamId, turnNum, onLongTermDebtsLoaded)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onLongTermDebtsLoaded(response.longTermDebts, response.lastUpdated, turnNum);
        };

        // Load the borrowings
        ajax.post("/game/team/" + teamId + "/long-term-debts", { turnNum: turnNum }, responseFn, "Loading Long-Term Debts...", "An error occurred while loading the long-term debts.", null, responseFn);
    }


    /**
     * This method loads the equity for the team.
     *
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     * @param {*} onEquityLoaded The response handler.
     */
    loadEquity(teamId, turnNum, onEquityLoaded)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onEquityLoaded(response.equity, response.canRaise, response.lastUpdated, turnNum);
        };

        // Load the borrowings
        ajax.post("/game/team/" + teamId + "/equity", { turnNum: turnNum }, responseFn, "Loading Equity...", "An error occurred while loading the equity.", null, responseFn);
    }


    /**
     * This method loads the BDM items.
     *
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     * @param {*} onBDMsLoaded The response handler.
     */
    loadBDMs(teamId, turnNum, onBDMsLoaded)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onBDMsLoaded(response.bdms, response.lastUpdated, turnNum);
        };

        // Load the borrowings
        ajax.post("/game/team/" + teamId + "/bdms", { turnNum: turnNum }, responseFn, "Loading Business Development and Marketing...", "An error occurred while loading the business development and marketing.", null, responseFn);
    }


    /**
     * This method loads the facilities items.
     *
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     * @param {*} onFacilitiesLoaded The response handler.
     */
    loadFacilities(teamId, turnNum, onFacilitiesLoaded)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onFacilitiesLoaded(response.details.facilities, response.details.maintenanceFacilities, response.lastUpdated, turnNum);
        };

        // Load the borrowings
        ajax.post("/game/team/" + teamId + "/facilities", { turnNum: turnNum }, responseFn, "Loading Facilities...", "An error occurred while loading the facilities.", null, responseFn);
    }

    
    /**
     * This method loads the housekeeping items.
     *
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     * @param {*} onHouseKeepingLoaded The response handler.
     */
     loadHouseKeeping(teamId, turnNum, onHouseKeepingLoaded)
     {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onHouseKeepingLoaded(response.teamRoles, response.intraturnId, turnNum);
        };

        // Load the team roles
        ajax.post("/game/team/" + teamId + "/housekeeping", { turnNum: turnNum }, responseFn, "Loading Housekeeping...", "An error occurred while loading the team roles and name.", null, responseFn);
     }

    /**
     * This method updates the housekeeping.
     * 
     * @param {*} gameId The game ID.
     * @param {*} teamID The team ID.
     * @param {*} teamName The team name.
     * @param {*} updatedRows The updated rows.
     * @param {*} onSaveComplete The save complete handler.
     */
     updateHousekeeping(gameId, teamId, teamName, updatedRows, onSaveComplete)
     {
         // Wrap the call...
        let responseFn = (response) =>
        {
            // Updates value in store
            store.dispatch(setTeamName({ game_id: gameId, team_id: teamId, team_name: teamName } ));

            onSaveComplete(response);
        };

        // Update the housekeeping
        ajax.post("/game/team/"+ teamId + "/housekeeping/save", { updated: updatedRows, teamName: teamName }, responseFn, "Saving Housekeeping...", "An error occurred while saving the housekeeping.");
     }


    /**
     * This method loads the general administration items.
     *
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     * @param {*} account The account.
     * @param {*} onLoaded The response handler.
     */
    loadGeneralAdministration(teamId, turnNum, account, onLoaded)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onLoaded(response.details.potentialExpenses, response.details.incurredExpenses, response.lastUpdated, turnNum, account);
        };

        // Load the borrowings
        ajax.post("/game/team/" + teamId + "/general-administration", { turnNum: turnNum, account: account }, responseFn, "Loading " + account + "...", "An error occurred while loading the " + account + ".", null, responseFn);
    }


    /**
     * This method loads the dividends.
     *
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     * @param {*} onLoaded The response handler.
     */    
    loadDividends(teamId, turnNum, onLoaded)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onLoaded(response.dividends, response.lastUpdated, response.intraturn_id);
        };

        // Load the borrowings
        ajax.post("/game/team/" + teamId + "/dividends", { turnNum: turnNum }, responseFn, "Loading Dividends...", "An error occurred while loading the dividends.", null, responseFn);
    }


    /**
     * This method loads the reports.
     *
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     * @param {*} reportType The report type.
     * @param {*} onReportsLoaded The reports loaded event handler.
     */
    loadReports(teamId, turnNum, reportType, onReportsLoaded)
    {
         // Wrap the call...
        let responseFn = (response) =>
        {
            onReportsLoaded(response ? response.reports : null, turnNum, reportType, response ? response.intraturn_id : 0);
        };

        // Load the reports
        ajax.post("/game/team/" + teamId + "/reports/list", { turnNum: turnNum, reportType: reportType }, responseFn, "Loading Reports...", "An error occurred while loading the reports.");
    }


    /**
     * This method downloads the report.
     *
     * @param {*} reportId The report ID.
     * @param {*} onReportDownloaded The report downloaded event handler.
     */
    downloadReport(reportId, onReportDownloaded)
    {
        // Load the reports
        ajax.post("/game/report/" + reportId, null, onReportDownloaded, "Downloading Report...", "An error occurred while downloading the report.");
    }


    /**
     * This method deletes the reports.
     *
     * @param {*} reportIds The report IDs to delete.
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     * @param {*} reportType The report type.
     * @param {*} onReportsDeleted The reports deleted event handler.
     */
    deleteReports(reportIds, teamId, turnNum, reportType, onReportsDeleted)
    {
         // Wrap the call...
        let responseFn = (response) =>
        {
            onReportsDeleted(response ? response.reports : null);
        };

        // Load the reports
        ajax.post("/game/team/" + teamId + "/reports/delete", { turnNum: turnNum, reportType: reportType, reportIds: reportIds }, responseFn, "Loading Reports...", "An error occurred while loading the reports.");
    }


    /**
     * This method downloads the team reports.
     *
     * @param {*} teamId The tea, ID.
     * @param {*} onReportsDownloaded The reports downloaded event handler.
     */
    downloadTeamReports(teamId, onReportsDownloaded)
    {
        // Load the reports
        ajax.post("/game/team/" + teamId + "/reports/download", null, onReportsDownloaded, "Downloading Reports...", "An error occurred while downloading the reports.");
    }




    /**
     * This method downloads the turn reports.
     *
     * @param {*} gameId The game ID.
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     * @param {*} onDownloadComplete The download complete handler.
     */
    downloadTurnReports(gameId, teamId, turnNum, onDownloadComplete)
    {
        // Submit the turn
        ajax.post("/game/" + gameId + "/" + teamId + "/" + turnNum + "/download-reports", null, onDownloadComplete, "Downloading the reports", "An error occurred while downloading the reports.");
    }


    /**
     * This method loads the active intraturns.
     *
     * @param {*} teamId The team ID.
     * @param {*} onIntraTurnsLoaded The intraturns loaded handler.
     */
    loadActiveIntraturns(teamId, onIntraTurnsLoaded)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onIntraTurnsLoaded(response ? response.intraturns : []);
        };

        // Load the active intraturns
        ajax.post("/game/team/" + teamId + "/active-intraturns", null, responseFn, "Loading Turn Details...", "An error occurred while loading the turn details.");
    }


    /**
     * This method loads the active intraturn.
     *
     * @param {*} teamId The team ID.
     * @param {*} onIntraTurnLoaded The intraturn loaded handler.
     */
    loadActiveIntraturn(teamId, onIntraTurnLoaded)
    {
        // Wrap the call...
        let responseFn = (response) =>
        {
            onIntraTurnLoaded(response ? response.intraturn : null);
        };

        // Load the active intraturns
        ajax.post("/game/team/" + teamId + "/active-intraturn", null, responseFn, "Loading Turn Details...", "An error occurred while loading the turn details.");
    }    


    /**
     * This method loads the intraturn.
     *
     * @param {*} intraturnId The intraturn ID.
     * @param {*} onIntraturnLoaded The intraturn loaded event handler.
     */
    loadIntraturn(intraturnId, onIntraturnLoaded)
    {
        // Get out if it's already in the store
        let intraturn = store.getState().intraturns.intraturnMap[intraturnId];
        if (intraturn)
        {
            onIntraturnLoaded(intraturn);
            return;
        }

        // Wrap the call...
        let responseFn = (response) =>
        {
            // Set the intraturn
            if (response)
            {
                store.dispatch(setIntraturn(response.intraturn));
            }

            // Call the callback...
            onIntraturnLoaded(response ? response.intraturn :  null);
        };

        // Load the active intraturns
        ajax.post("/intraturn/load/" + intraturnId, null, responseFn, "Loading Turn Details...", "An error occurred while loading the turn details.");
    }


    /**
     * This method creates the game.
     *
     * @param {*} game The game.
     * @param {*} onComplete The complete event handler.
     */
    createGame(game, onComplete)
    {
        ajax.post("/game/create", game, onComplete, "Creating Game", "An error occurred while creating the game.");
    }


    /**
     * This method updates the game.
     *
     * @param {*} game The game.
     * @param {*} onComplete The complete event handler.
     */
    updateGame(game, onComplete)
    {
        ajax.post("/game/update", game, onComplete, "Updating Game", "An error occurred while updating the game.");
    }


    /**
     * This method deletes the game.
     *
     * @param {*} gameId The game ID.
     * @param {*} onGameDeleted The game-deleted event handler.
     */
    deleteGame(gameId, onGameDeleted)
    {
        let responseFn = (response) =>
        {
            // Update the games
            this.onGamesLoaded(response);

            // Notify the caller
            onGameDeleted();
        }

        // delete the game
        ajax.post("/game/" + gameId + "/delete", null, responseFn, "Deleting Game...", "An error occurred while deleting the game.");
    }


    /**
     * This method disables whatifs for the team.
     *
     * @param {*} gameId The game ID.
     * @param {*} teamId The team ID.
     * @param {*} frozen The frozen flag.
     */
    disableWhatIfs(gameId, teamId, disable_whatifs)
    {
        let responseFn = () =>
        {
            store.dispatch(setTeamDisableWhatIfs({ game_id: gameId, team_id: teamId, disable_whatifs: disable_whatifs }));
        }

        // Create the message
        let message = disable_whatifs ? "Disabling Forecasts" : "Enabling Forecasts";

        // Freeze the team
        ajax.post("/game/team/" + teamId + "/disable-whatifs", { game_id: gameId, disable_whatifs: disable_whatifs }, responseFn, message, "An error occurred while " + message + ".");
    }



    /**
     * This method freezes the team.
     *
     * @param {*} gameId The game ID.
     * @param {*} teamId The team ID.
     * @param {*} frozen The frozen flag.
     */
    freezeTeam(gameId, teamId, frozen)
    {
        let responseFn = () =>
        {
            store.dispatch(setTeamFrozen({ game_id: gameId, team_id: teamId, frozen: frozen }));
        }

        // Create the message
        let message = frozen ? "Freezing Team" : "Unfreezing Team";

        // Freeze the team
        ajax.post("/game/team/" + teamId + "/freeze", { game_id: gameId, frozen: frozen }, responseFn, message, "An error occurred while " + message + ".");
    }


    /**
     * This method freezes the game.
     *
     * @param {*} gameId The game ID.
     * @param {*} frozen The frozen flag.
     */
    freezeGame(gameId, frozen)
    {
        let responseFn = () =>
        {
            store.dispatch(setGameFrozen({ game_id: gameId, frozen: frozen }));
        }

        // Create the message
        let message = frozen ? "Freezing Game" : "Unfreezing Game";

        // Freeze the team
        ajax.post("/game/" + gameId + "/freeze", { frozen: frozen }, responseFn, message, "An error occurred while " + message + ".");
    }


    /**
     * This method loads the adjustments.
     *
     * @param {*} gameId The game ID.
     * @param {*} turnNum The turn number.
     * @param {*} onComplete The on-complete event handler.
     */
    loadAdjustments(gameId, turnNum, onComplete)
    {
        let responseFn = (response) =>
        {
            onComplete(response.adjustments, response.events, turnNum);
        }
    
        // Load the adjustments
        ajax.post("/game/" + gameId + "/adjustments/get/" + turnNum, { }, responseFn, "Loading Adjustments", "An error occurred while loading the adjustments.");
    }



    /**
     * This method updates the adjustments.
     *
     * @param {*} gameId The game ID.
     * @param {*} turnNum The turn number.
     * @param {*} updatedAdjustments The updated adjustments.
     * @param {*} onComplete The on-complete event handler.
     */
    updateAdjustments(gameId, turnNum, updatedAdjustments, onComplete)
    {
        let responseFn = (response) =>
        {
            onComplete(response.adjustments, response.events, turnNum);
        }

        // Save the adjustments
        ajax.post("/game/" + gameId + "/adjustments/update/" + turnNum, updatedAdjustments, responseFn, "Saving Adjustments", "An error occurred while saving the adjustments.");
    }


    /**
     * This method runs the turn.
     *
     * @param {*} gameId The game ID.
     * @param {*} turnDetails The turn details.
     * @param {*} onComplete The on-complete event handler.
     */
    runTurn(gameId, turnDetails, onComplete)
    {
        // Run the turn
        ajax.post("/game/" + gameId + "/run-turn", turnDetails, onComplete, "Starting Turn", "An error occurred while starting the turn.");
    }


    /**
     * This method updates the measures of success.
     * 
     * @param {*} teamId The team ID.
     * @param {*} measuresOfSuccess The measures of success.
     * @param {*} onComplete THe on-compete event handler.
     */
    updateMeasuresOfSuccess(team, measuresOfSuccess, onComplete)
    {
        // Create the response function
        let responseFn = () =>
        {
            // Set the team
            store.dispatch(updateMeasuresOfSuccess({ game_id: team.game_id, team_id: team.team_id, measures_of_success: measuresOfSuccess }));
            
            // Call the event handler
            onComplete();
        }

        // Run the turn
        ajax.post("/game/team/" + team.team_id + "/measures-of-success", { measuresOfSuccess: measuresOfSuccess }, responseFn, "Updating Measures of Success", "An error occurred while updating the measures of success.");
    }



    /**
     * This method is called when a team has already been frozen.
     *
     * @param {*} gameId The game ID.
     * @param {*} teamId The team ID.
     * @param {*} frozen The frozen flag.
     */
    teamIsFrozen(gameId, teamId, frozen)
    {
        store.dispatch(setTeamFrozen({ game_id: gameId, team_id: teamId, frozen: frozen }));
    }


    /**
     * This method determines if the teams have been loaded.
     *
     * @param {*} gameId The game ID.
     * @returns true if the teams have been loaded, else false.
     */
    areTeamsLoaded(gameId)
    {
        let teams = this.getTeams(gameId);
        if (teams)
            return true;

        return false;
    }


    /**
     * This method gets the teams.
     *
     * @param {*} gameId The game ID.
     * @returns The teams, or undefined if the teams haven't been loaded.
     */
    getTeams(gameId)
    {
        return store.getState().games.gameTeamsMap[gameId];
    }


    /**
     * This method gets the game.

     * @param {*} id The ID.
     * @returns The game.
     */
    getGame(id)
    {
        return store.getState().games.gameMap[id];
    }


    /**
     * This are-games-loaded flag.

     * @returns true if loaded, else false.
     */
    areGamesLoaded()
    {
        return store.getState().games.gamesLoaded;
    }


    /**
     * This method handles the games loaded event.
     * 
     * @param {*} response The response.
     */
    onGamesLoaded = (response) =>
    {
        // Update the games
        this.updateGames(response.games);
    }


    /**
     * This method updates the games.
     *
     * @param {*} games The games.
     */
    updateGames(games)
    {
        // Set them in the store
        store.dispatch(setGames(games));
    }


    /**
     * This method handles the game details loaded event.
     *
     * @param {*} response The response.
     * @param {*} gameId The game ID.
     */
    onGameDetailsLoaded = (response, gameId) =>
    {
        this.updateGameDetails(gameId, response.gameDetails);
    }


    /**
     * This method updates the game details.
     *
     * @param {*} gameId The game ID.
     * @param {*} gameDetails The game details.
     */
    updateGameDetails(gameId, gameDetails)
    {
        store.dispatch(setGameDetails({ gameId: gameId, gameDetails: gameDetails }));
    }


    /**
     * This method handles the teams loaded event.
     *
     * @param {*} response The response.
     * @param {*} gameId The game Id.
     */
    onTeamsLoaded = (response, gameId) =>
    {
        this.updateTeams(gameId, response.teams);
    }


    /**
     * This method updates the teams.
     *
     * @param {*} gameId The game ID.
     * @param {*} teams The teams.
     */
    updateTeams(gameId, teams)
    {
        store.dispatch(setTeams({ game_id: gameId, teams: teams }));
    }


    /**
     * This method updates the dashboard details.
     *
     * @param {*} teamId The team ID.
     * @param {*} dashboardDetails The dashboard details.
     */
    updateDashboardDetails(teamId, dashboardDetails)
    {
        for (let dashboardDetail of dashboardDetails)
        {
            if (dashboardDetail.name === "Total Interest Expenses")
                dashboardDetail.name = "Total Interest Expense";
        }

        store.dispatch(setDashboardDetails({ team_id: teamId, dashboardDetails: dashboardDetails }));
    }


    /**
     * This method updates the team performance.
     *
     * @param {*} teamId The team ID.
     * @param {*} teamPerformance The team performance.
     */
    updateTeamPerformance(teamId, teamPerformance)
    {
        store.dispatch(setTeamPerformance({ team_id: teamId, teamPerformance: teamPerformance }));
    }


    /**
     * This method updates the regulatory report.
     *
     * @param {*} teamId The team ID.
     * @param {*} turnNum The turn number.
     * @param {*} regulatorReport The regulator report data.
     * @param {*} turns The turns.
     */
    updateRegulatoryReport(teamId, turnNum, regulatoryReport, turns)
    {
        store.dispatch(setRegulatoryReport({ team_id: teamId, turnNum: turnNum, regulatoryReport: regulatoryReport, turns: turns }));
    }


    /**
     * This method updates the mergers and acquisitions.
     *
     * @param {*} teamId The team ID.
     * @param {*} mergersAndAcquisitions The mergers and acquisitions.
     * @parma {*} scenarios The scenarios.
     */
    updateMergersAndAcquisitions(teamId, mergersAndAcquisitions, scenarios)
    {
        store.dispatch(setMergersAndAcquisitions({ team_id: teamId, mergersAndAcquisitions: mergersAndAcquisitions, scenarios: scenarios }));
    }


    /**
     * This method loads the scenarios.
     *
     * @param {*} teamId The team ID.
     * @param {*} callbackFn The callback function.
     */
    loadScenarios(teamId, callbackFn)
    {
        // Load the games
        ajax.post("/game/team/" + teamId + "/scenarios/load", null, callbackFn, "Loading the Scenario...", "An error occurred while loading the scenarios.");
    }


    /**
     * This method adds the M&A scenario.
     *
     * @param {*} teamId The team ID.
     * @param {*} name The name.
     * @param {*} scenario The scenario.
     * @parma {*} callbackFn The callback function.
     */
    addMandAScenario(teamId, name, scenario, callbackFn)
    {
        // Create the response method
        let responseFn = (response) =>
        {
            // Set the M&A scenarios...
            store.dispatch(setMergersAndAcquisitionsScenarios({ team_id: teamId, scenarios: response.scenarios }));
            
            // Call the callback function...
            callbackFn(response.m_and_a_id);
        }

        // Save the scenario
        ajax.post("/game/team/" + teamId + "/scenarios/add", { name: name, scenario: scenario }, responseFn, "Adding the Scenario...", "An error occurred while adding the scenario.");
    }


    /**
     * This method updates the M&A scenario.
     *
     * @param {*} teamId The team ID.
     * @param {*} mAndAId The m&A ID.
     * @param {*} scenario The scenario.
     * @parma {*} callbackFn The callback function.
     */
    updateMandAScenario(teamId, mAndAId, scenario, callbackFn)
    {
        // Create the response method
        let responseFn = (response) =>
        {
            // Set the M&A scenarios...
            setMergersAndAcquisitionsScenarios(teamId, response.scenarios);

            // Call the callback function...
            callbackFn(mAndAId);
        }

        // Save the scenario
        ajax.post("/game/team/" + teamId + "/scenarios/update", { m_and_a_id: mAndAId, scenario: scenario }, responseFn, "Updating the Scenario...", "An error occurred while updating the scenario.");
    }



    /**
     * This method gets the state.
     *
     * @returns The state.
     */
    getState()
    {
        // Return the state
        return store.getState().games;
    }
}

// Export the game service.
export default new GameService();