import { Fragment } from 'react';
import { withRouter, Prompt } from "react-router";
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import BaseDecisionGridView from "./BaseDecisionGridView";
import COLUMNS from "./grid/InvestmentPurchaseColumns";
import TurnSelect from "./components/TurnSelect";
import DecisionGrid from "./components/DecisionGrid";
import ScenarioName from "./components/ScenarioName";
import ErrorBox from "../../../components/ErrorBox";
import Utils from "../../../utils/Utils";

import React from 'react';
import DecisionBody from "./DecisionBody";
import gameService from "../../../services/GameService";
import intraturnService from "../../../services/IntraturnService";
import SelectControl from '../../../controls/form/SelectControl';
import NumberControl from '../../../controls/form/NumberControl';

/**
 * The InvestmentPurchasesView class.
 */
class InvestmentPurchasesView extends BaseDecisionGridView
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the loaded flag
        this.loaded = false;

        // Initialize the state
        this.state =
        {
            rows: [],
            dirtyRows: {},
            sortColumns: [],
            selectedInvestmentAccountType: "All",
            selectedTurn: -1,
            swaps: 'Fixed',
            maturity: 0,
            originalSwaps: 'Fixed',
            originalMaturity: 0,
        };

        // Initialize the columns
        this.columns = COLUMNS;
    }


    /**
     * This method loads the investment purchases.
     *
     * @param turn The turn.
     * @param investmentAccountType The investment account type.
     */
    loadInvestmentPurchases(turn, investmentAccountType)
    {
        // Get out if the state is loaded
        if (!this.props.game)
            return;

        // Only load once...
        if (this.loadingInvestmentPurchases)
            return;

        // Get out if nothing to do...
        if ((this.state.selectedTurn === turn) && (this.state.selectedInvestmentAccountType === investmentAccountType))
            return;

        // Warn the user if there are changes...
        if (this.isDirty())
        {
            if (!window.confirm("You have unsaved changes.  Do you wish to proceed?"))
                return;
        }

        // Mark us as loading...
        this.loadingInvestmentPurchases = true;

        // Load the investment purchases
        gameService.loadInvestmentPurchases(this.props.match.params.team_id, turn, investmentAccountType, this.onInvestmentPurchasesLoaded);
    }


    /**
     * The investment purchases loaded event handler.
     *
     * @param {*} investmentPurchases The investment purchases.
     * @param {*} swaps The swaps.
     * @param {*} maturity The maturity.
     * @param {*} lastUpdated The last updated time.
     * @param {*} turn The turn.
     * @param {*} investmentAccountType The investment account type.
     */
    onInvestmentPurchasesLoaded = (investmentPurchases, swaps, maturity, lastUpdated, turn, investmentAccountType) =>
    {
        // Make sure we have something...
        if (!investmentPurchases)
            investmentPurchases = [];

        // Initialize the state
        let state =
        { 
            rows: investmentPurchases, 
            selectedTurn: turn, 
            selectedInvestmentAccountType: investmentAccountType, 
            swaps: swaps, 
            maturity: maturity,
            originalSwaps: swaps,
            originalMaturity: maturity,
            lastUpdated: lastUpdated,
            summary: this.calculateSummary(investmentPurchases),
        }

        // Set the intraturn Id if appropriate
        if (investmentPurchases.length > 0)
            state.intraturnId = investmentPurchases[0].intraturn_id;
            
        // Set the state
        this.setState(state);

        // Clear the dirty rows
        this.clearDirtyRows();

        // Reset the loading investment purchases flag
        this.loadingInvestmentPurchases = false;

        // Set the loaded flag
        this.loaded = true;
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Call mom...
        let result = super.isComponentReady();

        // Load the investment purchases if necessary...
        if (this.props.game && !this.loaded)
        {
            // Load the investment purchases
            this.loadInvestmentPurchases(this.props.game.turns_completed + 1, this.state.selectedInvestmentAccountType);

            // Not ready...
            return false;
        }

        // Get out...
        return result;
    }


    /**
     * This method determines if any fields are dirty.
     *
     * @returns true if any fields are dirty, else false.
     */
    hasDirtyFields()
    {
        // See if any fields are dirty...
        if (this.state.originalSwaps !== this.state.swaps) 
            return true;
            
        if (this.state.originalMaturity !== this.state.maturity)
            return true;

        return false;
    }


    /**
     * This method updates the state when the cell value has been modified.
     *
     * @param {*} params The params.
     */
    onCellValueChanged = (params) =>
    {
        // Get the dirty rows map...
        let dirtyRows = this.state.dirtyRows;

        // Mark it dirty
        dirtyRows[params.data.invest_purchase_id] = true;

        // Update Summary
        let summary = this.calculateSummary(this.state.rows);

        // Save the state
        this.setState(
        {
            dirtyRows: dirtyRows,
            summary: summary
        });

        // Displays should most recent up to date values.
        params.api.refreshCells();
    }


    /**
     * This method handles view saves.
     */
    onSave = () =>
    {
        // Get out if this is not actually dirty (shouldn't happen)
        if (!this.isDirty())
            return;

        // Handle the save...
        if (this.hasDirtyFields())
            this.updateDirtyFields(false);
        else
            this.updateDirtyRows(false);
    }


    /**
     * This method updates the dirty fields.
     *
     * @param force The force flag.
     */
    updateDirtyFields(force)
    {
        // Update the swaps and maturity
        intraturnService.updateSwapsAndMaturity(this.state.intraturnId, this.state.swaps, this.state.maturity, this.state.lastUpdated, force, this.onUpdateSwapsAndMaturityComplete);
    }


    /**
     * This method handles the update swaps and maturity complete event.
     *
     * @param modified The modified by another user flag.
     * @param lastUpdated The last updated time.
     * @param swaps The swaps.
     * @param maturity The maturity.
     */
    onUpdateSwapsAndMaturityComplete = (modifiedBy, lastUpdated, swaps, maturity) =>
    {
        // See if it was modified since we loaded the data...
        if (modifiedBy)
        {
            // See if the user wants to force the matter...
            if (!window.confirm("Investment Purchases were modified by " + modifiedBy + " at " + new Date(lastUpdated).toLocaleTimeString() + ".\n\nWould you like save your changes anyway?"))
                return;

            // Force the update
            this.updateDirtyFields(true);

            // Get out...
            return;
        }

        // Set the state
        this.setState(
        {
            lastUpdated: lastUpdated,
            swaps: swaps, 
            maturity: maturity,
            originalSwaps: swaps,
            originalMaturity: maturity
        });

        // Show the alert if there are no dirty rows
        if (!this.updateDirtyRows(false))
            alert("Saved");
    }


    /**
     * This method updates the dirty rows.
     *
     * @param {*} force The force flag.
     * @return true if there are dirty rows, else false...
     */
    updateDirtyRows(force)
    {
        // Get out if nothing to do...
        if (Object.keys(this.state.dirtyRows).length === 0)
            return false;

        // Initialize the updated rows
        let updatedRows = [];

        // Check each row...
        for (let row of this.state.rows)
        {
            // See if it is dirty...
            if (!this.state.dirtyRows[row.invest_purchase_id])
                continue;

            // Create the updated row
            let updatedRow = { ...row };

            // Parse the amount
            updatedRow.amount = Utils.parseCurrency(updatedRow.amount);

            // Push the updated row
            updatedRows.push(updatedRow);
        }

    
        // Update the investment purchases
        intraturnService.updateInvestmentPurchases(this.state.intraturnId, updatedRows, this.state.lastUpdated, force, this.onSaveComplete);

        // Get out...
        return true;
    }


    /**
     * The save complete event handler.
     *
     * @param {*} modifiedBy The modified by user.
     * @param {*} lastUpdated The last updated time.
     */
    onSaveComplete = ({ modifiedBy, lastUpdated }) =>
    {
        // See if it was modified since we loaded the data...
        if (modifiedBy)
        {
            // See if the user wants to force the matter...
            if (!window.confirm("Investment Purchases were modified by " + modifiedBy + " at " + new Date(lastUpdated).toLocaleTimeString() + ".\n\nWould you like save your changes anyway?"))
                return;

            // Force the update
            this.updateDirtyRows(true);

            // Get out...
            return;
        }

        // Set the last updated time
        this.setState({ lastUpdated: lastUpdated });

        // Clear the dirty flag
        this.clearDirtyRows();

        // let the user know
        alert("Saved.");
    }


    /**
     * This method clears the dirty rows.
     */
    clearDirtyRows()
    {
        // Reset the dirty IDs
        this.setState(
        {
            dirtyRows: {}
        });
    }


    /**
     * This method handles turn changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onTurnChange = (name, value) =>
    {
        // Load the investment purchases
        this.loadInvestmentPurchases(parseInt(value), this.state.selectedInvestmentAccountType);
    }


    /**
     * This method handles investment account changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onInvestmentAccountChange = (name, value) =>
    {
        // Load the investment purchases
        this.loadInvestmentPurchases(this.state.selectedTurn, value);
    }


    /**
     * This method handles field changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onFieldChange = (name, value) =>
    {
        // Get out if no change...
        if ((this.state[name] === value) || (!this.state[name] && !value))
            return;

        // Set the name/value pair...
        let state = {};
        state[name] = value;

        // Set the state
        this.setState(state);
    }

    /**
     * This method calculates the summary.
     *
     * @param {*} rows The rows.
     * @returns The summary row.
     */
    calculateSummary(rows)
    {
        // Initialize the summary
        let summary =
        {
            description: "Total",
            amount: 0,
        }

        // Calculate the summary...
        for (let row of rows)
        {
            summary.amount += Utils.parseCurrency(row.amount);
        }

        // Get out...
        return summary;
    }

    /**
     * This method renders the grid view.
     */
    renderGridView()
    {
        // Get the game
        let team = this.props.team;
        if (team == null)
        {
            return (
                <ErrorBox>The team was not found.</ErrorBox>
            );
        }

        // Initialize the editable flag
        let editable = !this.props.team.frozen && (this.state.selectedTurn === this.props.game.turns_completed + 1) ? true : false;

        // Initialize the investment account types
        let investmentAccountTypes = [ { key: "All", value: "All" }, ...this.props.gameDetails.invest_accounts.map(type => ( { key: type, value: type })) ];

        return (
            <Fragment>
                <Prompt when={ this.isDirty() } message="There are unsaved changes on this screen.  Are you sure you want to leave?" />

                <DecisionBody name="Investment Purchases" game={ this.props.game } noteType="Invs" intraturnId={ this.state.intraturnId } onSave={ this.onSave } dirty={ this.isDirty() }>

                    <Row>
                        <Col xs={6} sm={6} md={4} lg={4}>
                            <TurnSelect name="turns" onChange={ this.onTurnChange } value= { this.state.selectedTurn } />
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4}>
                            <SelectControl items={ investmentAccountTypes } name="investmentAccountTypes"  onChange={ this.onInvestmentAccountChange } value= { this.state.selectedInvestmentAccountType } />
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="right col-padding-right bold col-middle-align">
                            <ScenarioName intraturnId={ this.state.intraturnId } />
                        </Col>
                    </Row>

                    <div class="grid-body">
                        <DecisionGrid 
                                turn= { this.state.selectedTurn }
                                columns={ this.columns } 
                                sortColumns={ this.state.sortColumns }
                                rowData={ this.state.rows } 
                                onCellValueChanged={ this.onCellValueChanged }
                                onSortColumnsChange={ this.onSortColumnsChange }  
                                pinnedBottomRowData={[ this.state.summary ]}
                        />
                    </div>
                { 
                    false ? 
                        <Row>
                            <Col xs={12} sm={12} md={ { span: 7, offset: 5 } } lg={ { span: 5, offset: 7 } } className="vertical-spacing right">
                                Swaps&nbsp;<SelectControl items={ [{ name: "Fixed", value: "Fixed"}, { name: "Floating", value: "Floating" }] } name="swaps"  onChange={ this.onFieldChange } value= { this.state.swaps } disabled={ !editable } className="auto-select" />
                                Maturity&nbsp;(millions)&nbsp;<NumberControl name="maturity" value={ this.state.maturity } onChange={ this.onFieldChange } min={0} style={{ width: "75px", display: "inline" }} className="auto-input" disabled={ !editable } />
                            </Col>
                        </Row> 
                    : ""
                }
                </DecisionBody>
            </Fragment>
        );
    }
}

// Export the decisions view...
export default withRouter(connect(BaseDecisionGridView.mapStateToProps)(InvestmentPurchasesView));