import { CurrencyCellEditor } from "../../../../controls/grid/GridEditors";
import { currencyRenderer, percentageRenderer } from "../../../../controls/grid/GridRenderers";
import SortFunctions from "../../../../controls/grid/SortFunctions";

/**
 * The columns.
 */
const COLUMNS =
[
    { 
        field: 'option', 
        headerName: 'Equity Options',
        minWidth: 300,
        comparator: SortFunctions.alphaNumeric
    },

    { 
        field: 'repurchase', 
        headerName: 'Amount (000s) to Repurchase', 
        minWidth: 220,
        cellRenderer: currencyRenderer,
        cellEditor: CurrencyCellEditor,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'raised', 
        headerName: 'Amount (000s) to Issue', 
        minWidth: 180,
        cellEditor: CurrencyCellEditor,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric,
        readOnlyProperty: "readOnly"
    },

    { 
        field: 'cost_or_raise', 
        headerName: 'Cost to Raise', 
        minWidth: 180,
        cellRenderer: percentageRenderer,
        comparator: SortFunctions.numeric
    }
];


export default COLUMNS;