import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

/**
 * The DecisionLink component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
const DecisionLink = (props) =>
{
    let visited = null;
    
    if (props.intraturn)
    {
        let decisionTracking = props.intraturn.decisionTracking && props.intraturn.decisionTracking[props.type];
        if (decisionTracking)
        {
            if (decisionTracking.last_updated_tzts)
                visited = "green";
            else
                visited = "orange";
        }
    }

    // Get the class name
    let className = props.gameType === "Full" ? "col-lg-2" : "col-lg-4";

    // Get out...
    return (
        <div className={ className + " col-md-3 col-sm-6 col-xs-12" }>
            { visited ?
                    <div class="decision-box-visited">
                         <FontAwesomeIcon color={ visited } icon={ faCheckCircle } />
                    </div>
                : ""
            }
            <Link className="decision-box" to={ "/game/" + props.match.params.game_id + "/decisions/" + props.match.params.team_id + "/" + props.type + (props.subtype ? "/" + props.subtype : "") } >
                <div className="decision-image-box">
                    <img className="nav-image" src={ process.env.PUBLIC_URL + "/images/" + props.type + ".png" } alt={ props.text } />
                </div>
                <div className="decision-text">{ props.text }</div>
            </Link>
        </div>
    );
}

// Get out...
export default withRouter(DecisionLink);



