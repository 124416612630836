import { Component, Fragment } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react'

import ReactQuill from 'react-quill';
import gameService from '../../../../services/GameService';

/**
 * The MeasuresOfSuccessControl class.
 */
class MeasuresOfSuccessControl extends Component
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the loaded flag
        this.loaded = false;

        // Initialize the state
        this.state =
        {
            measuresOfSuccess: "",
            originalMeasuresOfSuccess: ""
        };

        if (this.props.team)
            this.state.measuresOfSuccess = this.props.team.measures_of_success ? this.props.team.measures_of_success : "";
    }


    /**
     * This method handles component mount events.
     */
    componentDidMount()
    {
        this.updateComponent();
    }


    /**
     * The component did update event handler.
     * 
     * @param {*} prevProps The previous properties.
     */
    componentDidUpdate(prevProps)
    {
        this.updateComponent();
    }


    /**
     * This method updates the component.
     */
    updateComponent()
    {
        // Get out if nothing...
        if (!this.props.team || (this.props.team.measures_of_success === this.state.originalMeasuresOfSuccess))
            return;

        // Set the team name
        this.setState(
        { 
            measuresOfSuccess: this.props.team.measures_of_success ? this.props.team.measures_of_success : "",
            originalMeasuresOfSuccess: this.props.team.measures_of_success
        });
    }

    /**
     * This method determines if the state is dirty.
     *
     * @returns true if the state is dirty, else false.
     */
    isDirty()
    {
        // Are we dirty?
        if (this.props.team && (this.state.measuresOfSuccess !== this.props.team.measures_of_success))
        {
            return true;
        }

        return false;
    }


    /**
     * This saves the component.
     */
    save()
    {
        // Get out if this is not actually dirty (shouldn't happen)
        if (!this.isDirty())
            return;

        // Make sure there is enough space
        if (this.state.measuresOfSuccess.length > 5000)
            alert("There maximum length of the text is 5000 characters.  There are currently " + this.state.measuresOfSuccess.length + " characters in the field.");

        // Update the measures of success.
        gameService.updateMeasuresOfSuccess(this.props.team, this.state.measuresOfSuccess, this.onSaveComplete);
    }
     

    /**
     * The save complete event handler.
     */
    onSaveComplete = () =>
    {
        // let the user know
        alert("Saved.");

        // Notify the parent component...
        this.props.onChange(this.state.measuresOfSuccess, this.isDirty());
    }


    /**
     * The change event handler.
     * 
     * @param {*} value The value.
     */
    onChange = (value) => 
    {
        // Set the state
        this.setState({ measuresOfSuccess: value });

        // Call the change event handler...
        this.props.onChange(value, this.isDirty());
    }


    /**
     * This method renders the view.
     */
    render()
    {          
        return (
            <Fragment>
                    <Row>
                        <Col xs={12} sm={12} md={{ offset: 1, span: 11 }} lg={{ offset: 1, span: 11 }}>
                        {
                            this.props.team.frozen ?
                                <div className="rich-text-editor read-only" dangerouslySetInnerHTML={ {__html: this.state.measuresOfSuccess }}></div>
                            :
                                <ReactQuill theme="snow" className="rich-text-editor" value={ this.state.measuresOfSuccess } onChange={ this.onChange }/>
                        }
                        </Col>
                    </Row>
            </Fragment>
        );
    }
}

// Export the decisions view...
export default MeasuresOfSuccessControl;