import React from 'react';

/**
 * The InputControl component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
class InputControl extends React.Component
{
    /**
     * Object Constructor.
     * 
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Call mom...
        if (props.value)
            props.onChange(props.name, this.convert(props.value) );
        else
            props.onChange(props.name, this.convert(""));

        // Create the reference
        this.inputRef = React.createRef();
    }

    /**
     * This method places focus on the component.
     */
    focus()
    {
        this.inputRef.current.focus();
    }

    /**
     * This method converts the value.
     * 
     * @param {*} value The value.
     * @returns The converted value.
     */
    convert(value)
    {
        // Convert numbers...
        if (this.props.number)
        {
            if (value !== "")
            {
                if (this.props.number === 'int')
                    value = parseInt(value);
                else
                    value = parseFloat(value);
            }
            else
                value = 0;
        }
        // Get out...
        return value;
    }


    /**
     * The render method.
     * 
     * @returns The object model.
     */
    render()
    {
        let className;

        // Validate the fields
        if (this.props.name == null)
            throw new Error("InputControl is missing name!");

        if (this.props.onChange == null)
            throw new Error("InputControl is missing change event: " + this.props.name);

        // Set the type (default to text)
        let type = this.props.type ? this.props.type : "text";

        // Adjust for the type
        if (type !== "checkbox") 
            className = "form-control";
            
        // Add the class name
        if (this.props.className)
        {
            if (className !== "")
                className += " ";
                
            className += this.props.className;
        }

        // Clone the properties
        let newProps = { ...this.props };

        // Delete the unwanted properties...
        delete newProps.type;
        delete newProps.name;
        delete newProps.className;
        delete newProps.parent;
        delete newProps.onChange;


        // Get out...
        return (
            <input ref={ this.inputRef} type={ type }  name={ this.props.name } className={ className } onChange={ (event) => this.props.onChange(event.target.name, this.props.checkbox ? event.target.checked : this.convert(event.target.value)) } { ...newProps } />
        );
    }
}

// Get out...
export default InputControl;