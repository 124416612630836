import { Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from "react-bootstrap";

import { clearMessage } from "../../features/message/MessageSlice";
import { store } from "../store";


/**
  * This method maps the state to the properties.
  * 
  * @param {*} state The state.
  * @param {*} ownProps The own properties.
  * @returns The mapping.
  */
function mapStateToProps(state, ownProps)
{
    let result =
    {
        messages: state.message.messages,
        role: state.authentication.role,
        userId: state.authentication.userId
    };

    return result;
}


/**
 * This method clears the message.
 *
 * @param messageId the message ID.
 */
function onClearMessage(messageId)
{
    store.dispatch(clearMessage(messageId));
}


/**
 * This method gets the bold text.
 *
 * @param {*} message The message.
 * @param {*} role The role.
 * @returns The bold text.
 */
function getBoldText(message, role)
{
    let boldText = "";

    if (message.full_name)
        boldText = message.full_name;

    if (role !== "Student")
    {
        if (message.team_name)
        {
            if (boldText !== "")
                boldText += " to ";

            boldText += message.team_name;
        }

        if (message.game_name)
        {
            if (message.team_name)
                boldText += " in ";
            else
            if (boldText !== "")
                boldText += " to ";

            boldText += message.game_name;
        }
    }

    return boldText;
}



/**
 * The SystemMessage component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
const SystemMessage = (props) =>
{
    let messages;

    // // Instructors do nothing...
    // if (props.role === "Instructor")
    //     return <Fragment />;

    // Students are limited to a single message...
    if ((props.role === "Student") && (props.messages.length > 0))
        messages = [ props.messages[0] ];
    else
        messages = props.messages;

    // Get out...
    return (
        (props.userId && (messages.length > 0)) ?
            <div class="system-messages">
            {
                messages.map((message, index) => 
                { 
                    let boldText = getBoldText(message, props.role);

                    return (
                            <div class="system-message">
                                <i class="fa fa-close red padding-right-10" onClick={ () => onClearMessage(message.message_id) }/>
                                {
                                    (boldText !== "") ? <b>{ boldText }:&nbsp;&nbsp;&nbsp;</b> : ""
                                }
                                { message.text }
                        </div>
                    )
                })
            }
            </div>
        : ""
    );
}

// Get out...
export default connect(mapStateToProps)(SystemMessage);
