import { SelectColumn } from 'react-data-grid';

import { CheckBoxRenderer, DisabledCheckBoxRenderer, currencyRenderer, percentageRenderer } from "../../../../controls/grid/GridRenderers";
import { CurrencyCellEditor } from "../../../../controls/grid/GridEditors";
import SortFunctions from "../../../../controls/grid/SortFunctions";


/**
 * The term items...
 */
export const TERM_ITEMS = [24, 36, 48, 60, 72].map((months) => { return { name: months, value: months } } );

/**
 * The columns.
 */
export const COLUMNS =
[
    SelectColumn,

    { 
        field: 'amortizing_or_bullet', 
        headerName: 'Borrowings - Amortizing or Bullet',
        minWidth: 200,
        comparator: SortFunctions.alphaNumeric
    },

    { 
        field: 'fixed_or_variable', 
        headerName: 'Fixed or Variable', 
        minWidth: 200,
        comparator: SortFunctions.alphaNumeric
    },

    { 
        field: 'term',
        headerName: 'Term', 
        minWidth: 150,
        cellRendererSelector: (params) => 
        {
            // Renders on all rows except summary
            if (!params.node.rowPinned)
            {
                return {
                    component: (props) => { return <div className={ props.column.cellEditor && (!props.column.readOnlyProperty || !props.row[props.column.readOnlyProperty]) ? "editable-cell" : "read-only-cell" }>{ props.value + " Months" }</div> },
                };
            }         
            return undefined;
        },
        comparator: SortFunctions.numeric,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams:
        {
            values: TERM_ITEMS.map((item) => item.name),
        }
    },

    { 
        field: 'rate', 
        headerName: 'Rate',
        minWidth: 150,
        cellRenderer: percentageRenderer,
        comparator: SortFunctions.numeric
    },

    {
        field: 'maturities',
        headerName: 'Amount (000s)',
        minWidth: 200,
        cellRenderer: currencyRenderer,
        cellEditor: CurrencyCellEditor,
        comparator: SortFunctions.numeric,
    }
];

/**
 * The columns.
 */
export const PREPAY_COLUMNS =
[
    { 
        field: 'turn', 
        headerName: 'Turn',
        minWidth: 200,
        comparator: SortFunctions.alphaNumeric,
    },

    { 
        field: 'amortizing_or_bullet', 
        headerName: 'Amortizing or Bullet',
        minWidth: 200,
        comparator: SortFunctions.alphaNumeric,
    },

    { 
        field: 'fixed_or_variable', 
        headerName: 'Fixed or Variable', 
        minWidth: 200,
        comparator: SortFunctions.alphaNumeric
    },

    { 
        field: 'term',
        headerName: 'Term',
        minWidth: 150,
        cellRendererSelector: (params) => 
        {
            // Renders on all rows except summary
            if (!params.node.rowPinned)
            {
                return {
                    component: (props) => { return <div className={ props.column.cellEditor && (!props.column.readOnlyProperty || !props.row[props.column.readOnlyProperty]) ? "editable-cell" : "read-only-cell" }>{ props.value + " Months" }</div> },
                };
            }         
            return undefined;
        },
        comparator: SortFunctions.numeric
    },

    { 
        field: 'rate', 
        headerName: 'Rate', 
        minWidth: 120,
        cellRenderer: percentageRenderer,
        comparator: SortFunctions.numeric
    },

  
    {
        field: 'maturities',
        headerName: 'Amount (000s)',
        minWidth: 200,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.alphaNumeric
    },

    {
        field: 'prepay',
        headerName: 'Prepay',
        width: 120,
        minWidth: 120,
        cellRenderer: DisabledCheckBoxRenderer,
        editableCellRenderer: CheckBoxRenderer
    }
];