import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { formatCurrency } from './Currency'
import Utils from '../utils/Utils'

/**
 * The default mask options
 */
const DEFAULT_MASK_OPTIONS = 
{
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    decimalSymbol: '.',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 8, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
}


// Create the currency mask
const CURRENCY_MASK = createNumberMask(DEFAULT_MASK_OPTIONS);

// Create the currency mask with decimal
const CURRENCY_MASK_WITH_DECIMAL = createNumberMask({ ...DEFAULT_MASK_OPTIONS, allowDecimal: true });

// Create the negative currency mask
const NEGATIVE_CURRENCY_MASK = createNumberMask({ ...DEFAULT_MASK_OPTIONS, allowNegative: true });

// Create the negative currency mask with decimal
const NEGATIVE_CURRENCY_MASK_WITH_DECIMAL = createNumberMask({ ...DEFAULT_MASK_OPTIONS, allowDecimal: true, allowNegative: true });



/**
 * The currency input component.
 *
 * @param {*} props The properties.
 * @returns The currency input control.
 */
class CurrencyInput extends React.Component
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the state
        this.state = {};

        // Create the input reference
        this.inputRef = React.createRef();
    }


    /**
     * This method places focus on the cell.
     */
    focus()
    {
        // Set the focus
        this.inputRef.current.inputElement.focus();
    }


    /**
     * This method hanldes the blur event.
     */
    onBlur = () =>
    {
        // Get out if nothing to do...
        if (!this.props.maxValue || (Utils.parseCurrency(this.props.value) <= this.props.maxValue))
            return;

        // Format the maximum value
        let maxValue = formatCurrency(this.props.maxValue);

        // Show the error message
        alert("The maximum value is " + maxValue);

        // Revert the value back to what it was when we received focus...
        this.props.onChange({ target : { name: this.props.name, value: maxValue } });
    }


    /**
     * This method renders the component.
     *
     * @returns The rendered component.
     */
    render()
    {
        let mask;

        let props = { ...this.props };

        if (this.props.allowDecimal)
        {
            if (this.props.allowNegative)
                mask = NEGATIVE_CURRENCY_MASK_WITH_DECIMAL;
            else
                mask = CURRENCY_MASK_WITH_DECIMAL;
        }
        else
        {
            if (this.props.allowNegative)
                mask = NEGATIVE_CURRENCY_MASK;
            else
                mask = CURRENCY_MASK;

            if ((typeof props.value === 'string') || (props.value instanceof String))
            {
                let periodIndex = props.value.indexOf(".");
                if (periodIndex >= 0)
                    props.value = props.value.substring(0, periodIndex);
            }
        }

        // Return the masked input control...
        return <MaskedInput ref={ this.inputRef } mask={ mask } { ...props } onBlur={ this.onBlur } />;
    }
}


/**
 * The default properties.
 */
CurrencyInput.defaultProps = 
{
    inputMode: 'numeric',
    maskOptions: {},
}


/**
 * The property types.
 */
CurrencyInput.propTypes = 
{
    inputmode: PropTypes.string,
    maskOptions: PropTypes.shape(
    {
        prefix: PropTypes.string,
        suffix: PropTypes.string,
        includeThousandsSeparator: PropTypes.bool,
        thousandsSeparatorSymbol: PropTypes.string,
        allowDecimal: PropTypes.bool,
        decimalSymbol: PropTypes.string,
        decimalLimit: PropTypes.string,
        requireDecimal: PropTypes.bool,
        allowNegative: PropTypes.bool,
        allowLeadingZeroes: PropTypes.bool,
        integerLimit: PropTypes.number,
    })
}


// Export the currency input component
export default CurrencyInput;