import {CheckBoxRenderer,  DisabledCheckBoxRenderer, NumberRenderer, currencyRenderer, percentageRenderer} from "../../../../controls/grid/GridRenderers"
import { PercentageCellEditor, CurrencyCellEditor, IntegerEditor } from "../../../../controls/grid/GridEditors";
import SortFunctions from "../../../../controls/grid/SortFunctions";


/**
 * The DDA columns.
 */
export const DDA_COLUMNS = 
[
    { 
        field: 'name', 
        headerName: 'NMD Account',
        minWidth: 310,
        comparator: SortFunctions.alphaNumeric
    },

    { 
        field: 'monthly_fee', 
        headerName: 'Monthly Fee',
        minWidth: 200,
        cellRenderer: currencyRenderer,
        cellEditor: CurrencyCellEditor,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'nsf_fee', 
        headerName: 'NSF Fee', 
        minWidth: 200,
        cellRenderer: currencyRenderer,
        cellEditor: CurrencyCellEditor,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'minimum_balance', 
        headerName: 'Minimum Balance', 
        minWidth: 180,
        cellRenderer: currencyRenderer,
        cellEditor: CurrencyCellEditor,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'no_of_accounts', 
        headerName: 'No. of Accounts', 
        minWidth: 150,
        cellRenderer: NumberRenderer,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'average_balance', 
        headerName: 'Average Balance', 
        minWidth: 150,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'total_balance', 
        headerName: 'Total Balance',
        minWidth: 120,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'out_of_market', 
        headerName: 'Exit Market', 
        width: 120,
        minWidth: 120,
        cellClass: 'center',
        cellRenderer: DisabledCheckBoxRenderer,
        editableCellRenderer: CheckBoxRenderer
    },
];


/**
 * The NMD columns.
 */
export const NMD_COLUMNS = 
[
    { 
        field: 'name', 
        headerName: 'NMD Account',
        minWidth: 250,
        comparator: SortFunctions.alphaNumeric
    },

    { 
        field: 'market_rate', 
        headerName: 'Market Rate',
        minWidth: 120,
        comparator: SortFunctions.alphaNumeric
    },

    {
        field: 'rate',
        headerName: 'Offer Rate',
        minWidth: 100,
        cellRenderer: percentageRenderer,
        comparator: SortFunctions.numeric,
        cellEditor: PercentageCellEditor
    },

    {
        field: 'beta',
        headerName: 'Beta Up',
        minWidth:120,
        cellRenderer: percentageRenderer,
        comparator: SortFunctions.numeric,
        cellEditor: PercentageCellEditor
    },

    {
        field: 'beta_down',
        headerName: 'Beta Down',
        minWidth:120,
        cellRenderer: percentageRenderer,
        cellEditor: PercentageCellEditor,
        comparator: SortFunctions.numeric,  
    },


    {
        field: 'lag',
        headerName: 'Lag',
        minWidth: 80,
        comparator: SortFunctions.numeric,
        cellRenderer: NumberRenderer,
        cellEditor: IntegerEditor
    },


    { 
        field: 'monthly_fee', 
        headerName: 'Monthly Fee',  
        minWidth: 120,
        cellRenderer: currencyRenderer,
        cellEditor: CurrencyCellEditor,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'nsf_fee', 
        headerName: 'NSF Fee',
        minWidth: 120,
        cellRenderer: currencyRenderer,
        cellEditor: CurrencyCellEditor,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'minimum_balance', 
        headerName: 'Min. Balance', 
        cellRenderer: currencyRenderer,
        cellEditor: CurrencyCellEditor,
        minWidth: 180,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'no_of_accounts', 
        headerName: 'No. of Accounts', 
        minWidth: 180,
        cellRenderer: NumberRenderer,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'average_balance', 
        headerName: 'Avg. Balance', 
        minWidth: 160,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'total_balance', 
        headerName: 'Total Balance', 
        minWidth: 120,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'out_of_market', 
        headerName: 'Exit Market', 
        width: 110,
        minWidth: 110,
        cellClass: 'center',
        cellRenderer: DisabledCheckBoxRenderer,
        editableCellRenderer: CheckBoxRenderer
    },
];


/**
 * The Lite NMD columns.
 */
export const LITE_NMD_COLUMNS = 
[
    { 
        field: 'name', 
        headerName: 'NMD Account',
        minWidth: 250,
        comparator: SortFunctions.alphaNumeric
    },

    { 
        field: 'market_rate', 
        headerName: 'Market Rate',
        minWidth: 120,
        comparator: SortFunctions.alphaNumeric
    },

    {
        field: 'rate',
        headerName: 'Offer Rate',
        minWidth: 100,
        cellRenderer: percentageRenderer,
        comparator: SortFunctions.numeric,
        cellEditor: PercentageCellEditor
    },

    { 
        field: 'monthly_fee', 
        headerName: 'Monthly Fee',  
        minWidth: 120,
        cellRenderer: currencyRenderer,
        cellEditor: CurrencyCellEditor,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'nsf_fee', 
        headerName: 'NSF Fee',
        minWidth: 120,
        cellRenderer: currencyRenderer,
        cellEditor: CurrencyCellEditor,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'minimum_balance', 
        headerName: 'Min. Balance', 
        cellRenderer: currencyRenderer,
        cellEditor: CurrencyCellEditor,
        minWidth: 180,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'no_of_accounts', 
        headerName: 'No. of Accounts', 
        minWidth: 180,
        cellRenderer: NumberRenderer,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'average_balance', 
        headerName: 'Avg. Balance', 
        minWidth: 160,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'total_balance', 
        headerName: 'Total Balance', 
        minWidth: 120,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'out_of_market', 
        headerName: 'Exit Market', 
        width: 110,
        minWidth: 110,
        cellClass: 'center',
        cellRenderer: DisabledCheckBoxRenderer,
        editableCellRenderer: CheckBoxRenderer
    },
];
