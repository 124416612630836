import React  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlock, faLock } from "@fortawesome/free-solid-svg-icons";

import gameService from '../../../services/GameService';


/**
 * The freeze game link class.
 */
class FreezeGameLink extends React.Component
{
    /**
     * The toggle frozen event handler.
     */
    onToggleFrozen = () =>
    {
        // Freeze the game
        gameService.freezeGame(this.props.gameId, !this.props.frozen);
    }


    /**
     * This method renders the component.
     */
    render()
    {
        return (
            <span class="fa-buttons" onClick={ this.onToggleFrozen }><FontAwesomeIcon icon = { this.props.frozen ? faLock : faUnlock } /></span>
        );
    }
}

export default FreezeGameLink;