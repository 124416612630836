import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { dateFormatter } from '../controls/grid/GridFormatters';
import DeleteGameLink from "./game/components/DeleteGameLink";
import FreezeGameLink from './game/components/FreezeGameLink';

/**
 * The home columns.
 */
export const COLUMNS = 
[
    {
        field: "edit", 
        headerName: "Edit", 
        headerClass: "panel-header",
        minWidth: 80,
        cellRenderer: (props) => {return <Link to={ "/game/" + props.data.game_id + "/edit" }><span class = "fa-buttons"><FontAwesomeIcon icon = {faEdit}/></span></Link>}
    },

    {
        field: "run", 
        headerName: "Run", 
        width: 80,
        minWidth: 80,
        headerClass: "panel-header",
        cellRenderer: (props) => {return <Link to={"/game/" + props.data.game_id} classname="game-link">
            <span class = "fa-buttons">
                <FontAwesomeIcon icon = {faPlayCircle}/>
            </span>    
        </Link> }
    },

    {
        field: "game_name", 
        headerName: "Game Name",
        minWidth: 300,
        headerClass: "panel-header",
        cellRenderer: (props) => {return <div style={{textAlign: "left"}}>{props.value}</div> }
    },

    {
        field: "abbreviation", 
        headerName: "Abbreviation",
        minWidth: 120,
        headerClass: "panel-header",
        cellRenderer: (props) => {return <div style={{textAlign: "left"}}>{props.value}</div> }
    },

    {
        field: "number_of_teams", 
        headerName: "Number of Teams", 
        minWidth: 150,
        headerClass: "panel-header",
        cellRenderer: (props) => {return <div style={{textAlign: "right"}}>{props.value}</div>}
    },
    
    {
        field: "Freeze",
        headerName: "Freeze",
        minWidth: 90,
        headerClass: "panel-header",
        cellRenderer: (props) =>
            <span class = "fa-buttons">
                <FreezeGameLink gameId={ props.data.game_id } frozen={ props.data.frozen } />
            </span>
    },

    {
        field: "turns_completed",
        headerName: "Completed Decisions",
        minWidth: 180,
        headerClass: "panel-header",
        cellRenderer: (props) => {return <div style={{textAlign: "right"}}>{props.data.turns_completed}</div>}
    },

    {
        field: "decisions_submitted",
        headerName: "Decisions Submitted",
        minWidth: 180,
        headerClass: "panel-header",
        cellRenderer: (props) => {return <div style={{textAlign: "right"}}>{props.data.decisions_submitted}</div>}
    },

    {
        field: "insrt_tzts", 
        headerName: "Created", 
        minWidth: 130,
        cellRenderer: dateFormatter,
        headerClass: "panel-header"
    },

    {
        field: "updated_tzts", 
        headerName: "Last Changed", 
        minWidth: 180,
        cellRenderer: dateFormatter,
        headerClass: "panel-header"
    },

    {
        field: "deleted", 
        headerName: "Delete", 
        minWidth: 80,
        headerClass: "panel-header",
        cellRenderer: (props) => {return <DeleteGameLink gameId={ props.data.game_id } /> }
    }
];



export const STUDENT_COLUMNS =
[
    {
        field: "run", 
        headerName: "Run", 
        headerClass: "panel-header",
        cellRenderer: (props) => {return <Link to={"/game/" + props.data.game_id} classname="game-link">
            <span class = "fa-buttons">
                <FontAwesomeIcon icon = {faPlayCircle}/>
            </span>    
        </Link> }
    },

    {
        field: "game_name", 
        headerName: "Game Name",       
        headerClass: "panel-header",
        cellRenderer: (props) => {return <div style={{textAlign: "left"}}>{props.data.game_name}</div> }
    },

    {
        field: "abbreviation", 
        headerName: "Abbreviation",
        headerClass: "panel-header",
        cellRenderer: (props) => {return <div style={{textAlign: "left"}}>{props.data.abbreviation}</div> }
    },

    {
        field: "turns_completed",
        headerName: "Completed Decisions",
        headerClass: "panel-header",
        cellRenderer: (props) => {return <div style={{textAlign: "right"}}>{props.data.turns_completed}</div>}
    },
];

export default COLUMNS;