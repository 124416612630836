import { faArrowTrendDown, faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react"
import { Col, Row } from "react-bootstrap";
import { formatCurrency } from "../../../controls/Currency";
import { formatPercentage } from "../../../controls/Percentage";

/**
 * The KPI formats.
 */
export const KPI_FORMATS = 
{
    CURRENCY: "Currency",
    PERCENTAGE: "Percentage"
}

/**
 * This method formats the value.
 *
 * @param {*} value The value.
 * @param {*} formatType The format type.
 * @returns The formatted value.
 */
function format(value, formatType)
{
    // Get out if no value...
    if ((value == undefined) || (value == null))
        return "";

    // Format the value...
    if (formatType == KPI_FORMATS.PERCENTAGE)
        return formatPercentage(value);

    return formatCurrency(value);
}


/**
 * The KPI component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
const KPI = (props) =>
{
    // Get out if nothing to do...
    if (!props.kpi)
        return <Fragment />;

    // Return the view...
    return (
        <Fragment>
            <Row>
                <Col xs={6} sm={6} md={4} lg={4} className="key-indicator">
                    { props.kpi.type }
                </Col>
            </Row>
            {
                props.kpi.kpis.map((item) =>
                {
                    let current  = item.quarters && (item.quarters.length > 1) ? item.quarters[item.quarters.length - 1 ] : 0;
                    let previous = item.quarters && (item.quarters.length > 2) ? item.quarters[item.quarters.length - 2 ] : 0;

                    return (
                        <Row>
                            <Col xs={6} sm={6} md={4} lg={4} className="key-indicator-name">
                                { item.name }
                            </Col>
                            <Col xs={4} sm={4} md={2} lg={2}>
                                { format(current, item.format) }
                            </Col>
                            <Col xs={4} sm={4} md={2} lg={2}>
                                { format(previous, item.format) }
                            </Col>
                            <Col xs={4} sm={4} md={2} lg={2}>
                                { current && previous ? format(current - previous, item.format) : "" }
                            </Col>
                            <Col xs={4} sm={4} md={2} lg={2}>
                                { current && previous && (current - previous) ? <FontAwesomeIcon icon={ (current > previous) ? faArrowTrendUp : faArrowTrendDown } color={ (current > previous) ? "green" : "red" } size="2x" /> : "" }
                            </Col>
                        </Row>
                    )
                })
            }
        </Fragment>
    );
}

// Export the component
export default KPI;