import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import { isMobile } from 'react-device-detect';

import BaseGameView from "./BaseGameView";
import TeamDetails from "./components/TeamDetails";
import TeamKeyIndicators from "./components/TeamKeyIndicators";
import ErrorBox from "../../components/ErrorBox";
import TeamKeyIndicatorsGraph from './components/TeamKeyIndicatorsGraph';
import CountDownTimer from './components/CountDownTimer';
import DashboardActions from './components/DashboardActions';


/**
 * The BaseDashboardView class.
 */
class BaseDashboardView extends BaseGameView
{
    /**
     * Object constructor.
     * 
     * @param {*} props The props.
     * @param {*} mode The mode.
     * @param {*] kpiTypes The KPI types.
     */
    constructor(props, mode, kpiTypes)
    {
        // Call mom
        super(props);

        // Save the mode
        this.mode = mode;

        // Save the KPI types
        this.kpiTypes = kpiTypes;

        // The maximum number of graph columns
        this.maxGraphCols = 2;

        // Initialize the state
        this.state =
        {
            showSendMessage: false
        }
    }


    /**
    * This method maps the state to the properties.
    * 
    * @param {*} state The state.
    * @param {*} ownProps The own properties.
    * @param {*} dashboardDetails The dashboard details.
    * @returns The mapping.
    */
    static mapStateToProps(state, ownProps, dashboardDetails)
    {
        // Call mom...
        let result = BaseGameView.mapStateToProps(state, ownProps);

        // Add the team
        result.team = state.games.teamMap[ownProps.match.params.team_id];

        if (dashboardDetails)
        {
            // Initialize the KPI map
            let kpiMap = {};

            // Process the dashboard details
            for (let details of dashboardDetails)
            {
                // Get the KPI list
                let kpi = kpiMap[details.type];

                // Make sure it exists...
                if (!kpi)
                {
                    // Initialize the list
                    kpi = { type: details.type, kpis: [] };

                    // Put it in the map
                    kpiMap[details.type] = kpi;
                }

                // Get the KPI details
                let kpiDetails = kpi.kpis.length  > 0 ? kpi.kpis[kpi.kpis.length - 1] : null;

                // Create a new entry if it's not already in the list
                if (!kpiDetails || (kpiDetails.name !== details.name))
                {
                    // Initialize the KPI details
                    kpiDetails = 
                    {
                        name: details.name,
                        format: details.format,
                        quarters: []
                    };

                    // Add it to the list
                    kpi.kpis.push(kpiDetails);
                }

                // Push the value into the quarter list
                kpiDetails.quarters.push(details.value);
            }

            // Set the KPI map
            result.kpiMap = kpiMap;
        }
        
        // Get out...
        return result;
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        if (!super.isComponentReady())
            return false;

        // Get out if we are not ready...
        if (!this.props.team)
            return false;

        // Ready to go!
        return true;
    }


    /**
     * This method renders the view.
     */
    renderView()
    {
        // Get the game
        let game = this.props.game;
        if (game === null)
        {
            return (
                <ErrorBox>The game was not found.</ErrorBox>
            );
        }

        // Get the team
        let team = this.props.team;
        if (team === null)
        {
            return (
                <ErrorBox>The team was not found.</ErrorBox>
            )
        }

        // Get the dashboard details
        let dashboardDetails = this.props.dashboardDetails;
        if (dashboardDetails === null)
            dashboardDetails = [];

        return(
            <Container>
                <CountDownTimer />
                <Row className="dashboard-action-container g-1">
                    <Col xs={8} sm={8} md={10} lg={10} gu>
                        <Container className="center full-width full-height">
                            <Row className="g-1">
                                <Col xs={12} sm={12} md={12} lg={12} className="left">
                                    <TeamDetails game={ game } team={ team } />
                                </Col>
                            </Row>
                            <Row className="g-1">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    { this.renderDashboard() } 
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col xs={4} sm={4} md={2} lg={2}>
                        <DashboardActions mode={ this.mode } game={ this.props.game } team={ this.props.team } />
                    </Col>
                </Row>
            </Container>
        );
    }


    /**
     * This method renders the dashboard.
     * 
     * @returns The rendered dashboard.
     */
    renderDashboard()
    {
        return (
            <Tab.Container defaultActiveKey="kpis">
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Nav className="nav-tab-underline">
                                <Nav.Item>
                                    <Nav.Link eventKey="kpis" className="tab-underline">KPIs</Nav.Link>
                                </Nav.Item>
                            { 
                                !isMobile ?
                                    <Nav.Item>
                                        <Nav.Link eventKey="graph" className="tab-underline">Graph</Nav.Link>
                                    </Nav.Item>
                                : ""
                            }
                            </Nav>
                        </Col>
                    </Row>
                    <Row>
                        <Tab.Content>
                            <Tab.Pane eventKey="kpis">
                                { this.renderKeyIndicators() }
                            </Tab.Pane>
                            { 
                                !isMobile ?
                                    <Tab.Pane eventKey="graph">
                                        { this.renderKeyIndicatorsGraph() }
                                    </Tab.Pane>
                                : ""
                            }
                        </Tab.Content>
                    </Row>
                </Container>
            </Tab.Container>
        )
    }


    /**
     * This method renders the key indicators.
     * 
     * @returns The key indicators.
     */
    renderKeyIndicators()
    {
        return <TeamKeyIndicators kpiTypes={ this.kpiTypes } kpiMap={ this.props.kpiMap }/>;
    }

    
    /**
     * This method renders the key indicators graph.
     * 
     * @returns The key indicators graph.
     */
    renderKeyIndicatorsGraph()
    {
        return <TeamKeyIndicatorsGraph kpiTypes={ this.kpiTypes } kpiMap={ this.props.kpiMap } maxGraphCols={ this.maxGraphCols }/>
    }
}

// Export the game view...
export default BaseDashboardView;