import { Fragment } from 'react';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import React from 'react'

import BaseDecisionGridView from "./BaseDecisionGridView";

import GeneralAdminTemplate from "./GeneralAdminTemplate";
import BaseDecisionView from './BaseDecisionView';
import MeasuresOfSuccessControl from './components/MeasuresOfSuccessControl';

/**
 * The MeasuresOfSuccessView class.
 */
class MeasuresOfSuccessView extends BaseDecisionView
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the loaded flag
        this.loaded = false;

        // Initialize the state
        this.state =
        {
            dirty: false
        };

        // Create the reference...
        this.measuresOfSuccessRef = React.createRef();
    }

    /**
     * This method determines if the state is dirty.
     *
     * @returns true if the state is dirty, else false.
     */
    isDirty()
    {
        // Are we dirty?
        return this.state.dirty;
    }

    /**
     * The change event handler.
     * 
     * @param {*} value The value.
     * @param {*} dirty The dirty flag.
     */
    onChange = (value, dirty) =>
    {
        this.setState({ dirty: dirty });
    }


    /**
     * This method renders the view.
     */
    renderView()
    {          
        return (
            <Fragment>
                <GeneralAdminTemplate name="Measures of Success" game={ this.props.game } intraturnId={ this.state.intraturnId } onSave={ () => this.measuresOfSuccessRef.current.save() } dirty={ this.isDirty() }>
                    <MeasuresOfSuccessControl team={ this.props.team } ref={ this.measuresOfSuccessRef } onChange={ this.onChange } />
                </GeneralAdminTemplate>
            </Fragment>
        );
    }
}

// Export the decisions view...
export default withRouter(connect(BaseDecisionGridView.mapStateToProps)(MeasuresOfSuccessView));