import { withRouter } from "react-router";
import { connect } from 'react-redux';

import React from 'react';
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { getEditableCellClass } from "../../../../controls/grid/GridEditors";
import { Component } from "react";


/**
  * This method maps the state to the properties.
  * 
  * @param {*} state The state.
  * @param {*} ownProps The own properties.
  * @returns The mapping.
  */
function mapStateToProps(state, ownProps)
{
    let result =
    {
        game: state.games.gameMap[ownProps.match.params.game_id],
        team: state.games.teamMap[ownProps.match.params.team_id]
    };

    return result;
}


/**
 * The size-to-fit event handler.
 *
 * @param {*} params The parameters.
 */
function sizeToFit(params)
{ 
    params.api.sizeColumnsToFit();  
}


/**
 * This method initializes the column.
 *
 * @param {*} column The column.
 * @param {*} isEditable The editable flag.
 * @returns The new column.
 */
function initializeColumn(column, isEditable)
{
    // Copy the column
    let newColumn = { ...column };

    if (isEditable)
    {
        if (newColumn.cellEditor)
        {
            // Handle editability...
            newColumn.cellClass  = getEditableCellClass;

            // Handle Editability on pinned row
            newColumn.editable = (o) => !o.node.isRowPinned();
        }  
        else 
        if (newColumn.editableCellRenderer)
        {
            newColumn.cellRenderer = newColumn.editableCellRenderer;
        }
    }

    // Use the panel header class
    newColumn.headerClass = "panel-header";

    // Get out...
    return newColumn;
}

     


/**
 * The decision grid component.
 *
 * @param {*} props The properties.
 * @returns The rendered component.
 */
class DecisionGrid extends Component
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Set the columns
        this.setColumns();
    }


    /**
     * The component did update event handler.
     *
     * @param {*} prevProps The previous properties.
     */
    componentDidUpdate(prevProps)
    {
        // Get out if nothing to do...
        if (!this.props.team)
            return;

        // Initialize the editable flag
        let isEditable = this.isEditable(this.props);

        // Get out if it is the same team and the editability flag hasn't changed...
        if (prevProps.team && (prevProps.team.team_id === this.props.team.team_id))
        {
            // See if they are equivalent...
            if (isEditable === this.isEditable(prevProps) && this.columnsEqual(this.props.columns, prevProps.columns))
                return;
        }

        // Set the columns
        this.setColumns();
    }


    /**
     * This method determines if the column lists are equivalent.
     *
     * @param {*} columns1 The first list of columns.
     * @param {*} columns2 The second list of columns.
     * @returns true if they are equal, else false.
     */
    columnsEqual(columns1, columns2)
    {
        // Get out if they are the same
        if (columns1 === columns2)
            return true;

        // Get out if we are convinced they are different...
        if (!columns1 || !columns2)
            return false;

        // Get out if the columns are different
        if (columns1.length !== columns2.length)
            return false;

        // Check each column
        for (var counter = 0; counter < columns1.length; counter++)
        {
            if (columns1[counter].field !== columns2[counter].field)
                return false;
        }

        // They are the same...
        return true;
    }


    /**
     * This method sets the columns.
     */
    setColumns()
    {
        // Determine if the table is editable
        let isEditable = this.isEditable(this.props);

        // Handle editability...
        this.columns = this.props.columns.map(column => 
        { 
            let newColumn;

            if (column.children)
            {
                // Clone the column
                newColumn = { ...column, children: [] };

                // Clone the children
                for (let childColumn of column.children)
                {
                    newColumn.children.push(initializeColumn(childColumn, isEditable));
                }

                // Use the panel header class
                newColumn.headerClass = "panel-header";
            }
            else
                newColumn = initializeColumn(column, isEditable);

            // Get out...
            return newColumn;
        });
    }


    /**
     * This method determmines if the properties's team is editable.
     *
     * @param {*} props The properties.
     * @returns true if the team is editable, else false.
     */
    isEditable(props)
    {
        return (!props.team || !props.team.frozen) && (props.turn === props.game.turns_completed + 1) ? true : false;
    }


    /**
     * This method handles cell value changes.
     *
     * @param {*} destinationCellValueChangedFn The destionation cell value changed event handler.
     * @param {*} params The parmeters.
     */
    onCellValueChanged(destinationCellValueChangedFn, params)
    {
        // Ignore nulls (delete key)
        if (params.newValue === null)
            return;

        // Call the destination handler
        destinationCellValueChangedFn(params);
    }


    /**
     * The render method.
     */
    render()
    {
        let additionalProps = { ...this.props };
        delete additionalProps.columns;

        if (additionalProps.onCellValueChanged)
            additionalProps.onCellValueChanged = this.onCellValueChanged.bind(this, additionalProps.onCellValueChanged);

        return (
            <div className = "ag-theme-alpine" style= {{height: 'calc(' + (this.props.height ? this.props.height : '55vh') + ')', width: '100%'}}>
                <AgGridReact  
                    onFirstDataRendered = { sizeToFit }
                    onRowDataUpdated = { sizeToFit }
                    onGridSizeChanged = { sizeToFit }
                    columnDefs = { this.columns }
                    defaultColDef =
                    {{
                        sortable: true,
                        resizable: true,
                        wrapText: true,
                    }}
                    gridOptions = 
                    {{
                        // Pinned Summary Row Style
                        getRowStyle: (params) => { if (params.node.rowPinned) { return { fontWeight: 'bold' }} },
                    }}
                    { ...additionalProps } />
            </div>
        );
    }
}

// Export the decision grid...
export default withRouter(connect(mapStateToProps)(DecisionGrid));