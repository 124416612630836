import { withRouter } from "react-router";

import BaseView from "../BaseView";
import FormControl from "../../controls/form/FormControl";
import InputControl from "../../controls/form/InputControl";
import ajax from "../../utils/Ajax";

 /**
  * The RequestPasswordView class.
  */
 class RequestPasswordView extends BaseView
 {
     /**
      * The submit event handler.
      * 
      * @param {*} event The event.
      */
     onSubmit = (event) =>
     {
        if (this.props.name === "Forgot Password")
        {
            // When the user submits, server should send an email to the user with a link to change password
            ajax.post("/user/request-password", this.state, this.onRequestPasswordComplete, "Requesting Password", "An error occurred during the password request process.");
        }

        if (this.props.name === "New User")
        {
            // When the user submits, server should send an email to the user with a link to set password
            ajax.post("/user/new-user", this.state, this.onRequestPasswordComplete, "Requesting Password", "An error occurred during the password request process.");
        }
    }
     
 
     /**
      * The request password complete event handler.
      * 
      * @param {*} response The response.
      */
     onRequestPasswordComplete = (response) =>
     {
        if (response.passwordSent)
        {
            alert("An email with a link to reset your password has been sent.");
            this.props.history.push("/");
        }
        else if (response.welcomeEmailSent)
        {
            alert("A welcome email with a link to reset your password has been sent.");
            this.props.history.push("/");
        }
        else
            alert("We could not find your email.");
     }
 
     /**
      * This method renders the component.
      */
     render()
     {
         return (
            <FormControl name={ this.props.name }  className="request-password-form" >
                <div className="row">
                    <div className="offset-sm-1 offset-md-1 offset-lg-1 col-sm-3 col-md-2 col-lg-2"><label for="email" className="form-label">Email</label></div>
                    <div className="col-sm-7 col-md-8 col-lg-8"><InputControl name="email" onChange={ this.onFieldChange } /></div>
                </div>

                <div className="row">
                    <div className="offset-sm-4 offset-md-3 offset-lg-3 col-sm-3 col-md-1 col-lg-1"><button className="btn btn-primary" onClick={ (event) => this.onSubmit(event) } >Submit</button></div>
                </div>
            </FormControl>
         );
     }
 }
 
 export default withRouter(RequestPasswordView);