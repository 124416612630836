import {CheckBoxRenderer,  DisabledCheckBoxRenderer, currencyRenderer} from "../../../../controls/grid/GridRenderers";
import { CurrencyCellEditor } from "../../../../controls/grid/GridEditors";
import SortFunctions from "../../../../controls/grid/SortFunctions";

/**
 * The facilities columns.
 */
export const FACILITIES_COLUMNS =
[
    { 
        field: 'type', 
        headerName: 'Facility Type',
        minWidth: 200,
        comparator: SortFunctions.alphaNumeric,
    },

    { 
        field: 'total_cost',
        headerName: 'Total Cost for Build Out', 
        minWidth: 200,
        comparator: SortFunctions.numeric,
        cellRenderer: currencyRenderer,
    },

    { 
        field: 'term', 
        headerName: 'Quarters Cost Amortized', 
        minWidth: 200,
        comparator: SortFunctions.numeric,
    },

    { 
        field: 'estimated_spending',
        headerName: 'Estimated Maintenance', 
        minWidth: 200,
        comparator: SortFunctions.numeric,
        cellRenderer: currencyRenderer,
    },


    { 
        field: 'spend', 
        headerName: 'Spend',
        width: 80,
        minWidth: 80,
        cellClass: 'center',
        cellRenderer: DisabledCheckBoxRenderer,
        editableCellRenderer: CheckBoxRenderer
    }
];


/**
 * The maintenance facilities columns.
 */
export const MAINTENANCE_FACILITIES_COLUMNS =
[
    { 
        field: 'type', 
        headerName: 'Facility Type',
        minWidth: 200,
        comparator: SortFunctions.alphaNumeric,
    },

    { 
        field: 'proposed_spending',
        headerName: 'Proposed Spend',
        minWidth: 200, 
        comparator: SortFunctions.numeric,
        cellRenderer: currencyRenderer,
    },

    { 
        field: 'spend_amount',
        headerName: 'Spend Amount', 
        minWidth: 200,
        comparator: SortFunctions.numeric,
        cellRenderer: currencyRenderer,
        cellEditor: CurrencyCellEditor
    },
];