import React from 'react';
import { useSelector } from 'react-redux';

import 
{
    Route,
    Redirect
} from "react-router";
  
import { selectUserId, selectRole } from '../features/authentication/AuthenticationSlice';
  

/**
 * The instructor route function.
 * 
 * @param {*} children
 * @param {*} The rest. 
 * @returns The route.
 */
function InstructorRoute ({ children, ...rest }) 
{
    let userId = useSelector(selectUserId);
    let role = useSelector(selectRole);

    return (
        <Route {...rest} render={() => 
        {
            return ((userId != null) && ((role === "Instructor") || (role === "Administrator")))
                ? children
                : <Redirect to='/login' />
            }
        } />
    )
}

export default InstructorRoute;