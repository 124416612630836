import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store, populateStoreFromSessionStorage } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import log from "../src/app/utils/Log";
import watchService from "../src/app/services/WatchService";
import { FISIM, CUSO, setMode } from "./Modes";

// Initialize the default mode
let DEFAULT_MODE = FISIM;


(
    function()
    {
        // Force HTTPS...
        if ((window.location.href.indexOf("localhost") === -1) && (window.location.href.indexOf("https://") === -1))
        {
            // Get the URL
            let url = new URL(window.location.href);

            // Now, force it to game.fisim2.com with HTTPS enabled so the cert looks great...
            window.location.href = "https://game.fisim2.com" + url.pathname;
        }

        // Set the default mode
        if (window.location.href.indexOf("fisim") !== -1)
          setMode(FISIM);
        else
          setMode(DEFAULT_MODE);


        // Populate the store from session storage
        populateStoreFromSessionStorage();

        // Initialize the log
        log.initialize();

        // Initialize the watch service
        watchService.initialize();
    }
)();


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
