import React from 'react';

/**
 * The FormControl component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
const FormControl = (props) =>
{
    // Get out...
    return (
        <div className={ "container left" + (props.containerClassName ? " " + props.containerClassName : "") }>
            <div className={ "fisim-form" + (props.className ? " " + props.className : "") }>
                <div className="fisim-header">{ props.name }</div>
                { props.children }
            </div>
        </div>
    );
}

// Get out...
export default FormControl;



