/**
 * The Message component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
const Message = (props) =>
{
    // Get out...
    return (
        <div class="message-summary">
            <div className="message-summary-title">{ props.title ? props.title : "" }</div>
            <div className="message" >{ props.children }</div>
        </div>
    );
}

// Get out...
export default Message;
