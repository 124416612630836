import ajax from "../utils/Ajax";
import { setNoteSections, setNoteSectionsLoading } from "../../features/static/StaticSlice";
import { store } from "../store";


/**
 * The note service class.
 */
class NoteService
{
    /**
     * This method loads the team notes.
     *
     * @param {*} teamId The team ID.
     * @param {*} sectionType The section type.
     * @param {*} callbackFn The callback function.
     */
    loadTeamNotes(teamId, sectionType, callbackFn)
    {
        let responseFn = (response) =>
        {
            callbackFn(sectionType, response.teamNotes);
        }

        // Load the team notes
        ajax.post("/note/team/" + teamId, {  }, responseFn, "Loading Team Notes...", "An error occurred while loading the team notes.");
    }


    /**
     * This method saves the notes.
     *
     * @param {*} teamId The team ID.
     * @param {*} addedNotes The added notes.
     * @param {*} updatedNotes The updated notes.
     * @param {*} deletedNotes The deleted notes.
     * @param {*} callbackFn The callback function.
     */
    saveTeamNotes(teamId, addedNotes, updatedNotes, deletedNotes, callbackFn)
    {
        let responseFn = (response) =>
        {
            callbackFn(response.teamNotes);
        }

        // Save the team notes
        ajax.post("/note/team/" + teamId + "/save", { added: addedNotes, updated: updatedNotes, deleted: deletedNotes }, responseFn, "Saving Notes...", "An error occurred while saving the notes.");
    }



    /**
     * This method loads the note sections.
     */
    loadNoteSections()
    {
        // Get the state
        let state = store.getState();

        // Get out if it's already loaded
        if ((state.static.noteSections.length > 0) || state.static.noteSectionsLoading)
            return;

        // Set the loading flag
        store.dispatch(setNoteSectionsLoading(true));

        // Load the note sections
        ajax.post("/select/note-sections", {}, this.onNoteSectionsLoaded, null, "An error occurred while loading the note sections.", null, this.onNoteSectionsLoaded);
    }


    /**
     * The note section loaded event handler.
     *
     * @param {*} response The response.
     */
    onNoteSectionsLoaded = (response) =>
    {
        // Clear the loading flag
        store.dispatch(setNoteSectionsLoading(false));

        // Get out if an error occurred
        if (!response || !response.items || !response.items.length)
            return;

        // Put none first...
        for (let counter = 0; counter < response.items.length; counter++)
        {
            if (response.items[counter].value !== "None")
                continue;

            // Save the item
            let item = response.items[counter];

            // Remove it
            response.items.splice(counter, 1);
            response.items.unshift(item);
        }

        // Set the note sections
        store.dispatch(setNoteSections(response.items));
    }
}

// Export the note service.
export default new NoteService();