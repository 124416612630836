import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from "react-router";
  
import { selectUserId } from '../features/authentication/AuthenticationSlice';
import helpService from '../app/services/HelpService';
import Watch from './Watch';


/**
 * The private route function.
 * 
 * @param {*} children
 * @param {*} The rest. 
 * @returns The route.
 */
function PrivateRoute ({ children, help, ...rest }) 
{
    let gameId;

    // Get the user ID
    let userId = useSelector(selectUserId);

    // Get the game ID if one exists...
    if (rest && rest.computedMatch && rest.computedMatch.params)
        gameId = parseInt(rest.computedMatch.params.game_id);

    // Set the active page
    helpService.setActivePage(help, gameId);


    return (
        <Route {...rest} render={() => 
        {
            return (
                <Fragment>
                    <Watch />
                    {
                    (userId != null)
                        ? children
                        : <Redirect to='/login' />
                    }
                </Fragment>
            )
        }} />
    )
}

export default PrivateRoute;