import { withRouter } from "react-router";
import { connect } from 'react-redux';

import React from 'react';
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { COLUMNS, STUDENT_COLUMNS } from "./columns/TeamsColumns";
import BaseGameView from "./BaseGameView";
import FormControl from "../../controls/form/FormControl";
import ErrorBox from "../../components/ErrorBox";


/**
 * The GameView class.
 */
class GameView extends BaseGameView
{
    /**
     * The object constructor
     */
    constructor(props)
    {
        // Call mom
        super(props);

        // Initialize the loaded flag
        this.loaded= false;
    }



    /**
    * This method maps the state to the properties.
    * 
    * @param {*} state The state.
    * @param {*} ownProps The own properties.
    * @returns The mapping.
    */
    static mapStateToProps(state, ownProps)
    {
        // Call mom...
        let result = BaseGameView.mapStateToProps(state, ownProps);

        // Add the authentication
        result.authentication = state.authentication;
    
        // Get out...
        return result;
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Make sure everything is loaded
        if (!super.isComponentReady())
            return false;

        // No need to make them click if there is only one team...
        if (this.props.teams.length == 1)
        {
            this.props.history.replace("/game/" + this.props.game.game_id + "/team/" + this.props.teams[0].team_id);
            return false;
        }

        // Get out...
        return true;
    }


    /**
     * This method renders the view.
     */
    renderView()
    {
        // Get the game
        let game = this.props.game;
        if (game == null)
        {
            return (
                <ErrorBox>The game was not found.</ErrorBox>
            );
        }

        // Add the turns completed field
        let teams = this.props.teams.map(team => { return { ...team, turns_completed: game.turns_completed }});

        return(
            <FormControl name={ game.game_name }>
               <div className = "panel-body">
                    <div className = "ag-theme-alpine" style= {{height: 'calc(100vh - 250px)', width: '100%', minHeight: "325px" }}>
                        <AgGridReact
                            onGridReady = { (params) => { params.api.sizeColumnsToFit();  } } 
                            columnDefs = { this.props.authentication.role === "Student" ? STUDENT_COLUMNS : COLUMNS }
                            rowData = { teams }
                            defaultColDef =
                            {{
                                sortable: true,
                                resizable: true
                            }}
                        />
                    </div>
                </div>
            </FormControl>
        );
    }
}

// Export the game view...
export default withRouter(connect(GameView.mapStateToProps)(GameView));