import { withRouter } from "react-router";
import { connect } from 'react-redux';

import BaseView from "../BaseView";
import InputControl from "../../controls/form/InputControl";
import PasswordControl from "../../controls/form/PasswordControl";
import ajax from "../../utils/Ajax";
import { changeUserInfo } from "../../../features/authentication/AuthenticationSlice";
import { store } from "../../store";


/**
 * This method maps the state to the properties.
 * 
 * @param {*} state The state.
 * @param {*} ownProps The own properties.
 * @returns The mapping.
 */
const mapStateToProps = (state, ownProps) =>
{
    let result = 
    {
        fullName: state.authentication.fullName,
        email: state.authentication.email
    };
 
    return result;
}

/**
 * The UserOptionsView class.
 */
class UserOptionsView extends BaseView
{
    /**
     * Object Constructor.
     * 
     * @param {*} props The properties.
     */
    constructor(props)
    {
        super(props);

        this.state = 
        { 
            fullName: props.fullName, 
            email: props.email 
        };
    }


    /**
     * The submit user info event handler.
     * 
     * @param {*} event The event.
     */
    onSubmitUserInfo = (event) =>
    {
        // When the user submits, server will update their information
        ajax.post("/user/user-settings", this.state, this.onUpdateUserInfoComplete, "Updating User Settings", "An error occurred during the user update request process.");
    }


    /**
     * The submit new password event handler.
     * 
     * @param {*} event The event.
     */
    onSubmitNewPassword = (event) =>
    {
        // Prevents user from entering a blank password
        if (!this.state.newPassword || !this.state.confirmPassword)
        {
            alert("These fields cannot be left empty.");
            return;
        }

        // Check if passwords match
        if (this.state.newPassword !== this.state.confirmPassword)
        {
            alert("The passwords do not match.");
            return;
        }

        // When the user submits, server will update their password
        ajax.post("/user/update-password", this.state, this.onUpdateUserPasswordComplete, "Updating password", "An error occurred during the user's password update request process.");
    }
     
 
    /**
     * The update user information complete event handler.
     * 
     * @param {*} response The response.
     */
    onUpdateUserInfoComplete = (response) =>
    {
        // Updates value in store
        store.dispatch(changeUserInfo({ fullName: this.state.fullName, email: this.state.email }));
    
        // Provide feedback
        alert("Your information has been updated.");

        // Go back where we came from
        this.props.history.goBack();
    }


    /**
     * The update user password complete event handler.
     * 
     * @param {*} response The response.
     */
    onUpdateUserPasswordComplete = (response) =>
    {   
        // Provide feedback
        alert("Your password has been updated.");
 
        // Go back where we came from
        this.props.history.goBack();
    } 


    /**
     * This method renders the component.
     */
    render()
    {
        return (
            <div className="container left" style={{ width: "50%", "min-width": "400px" }}>
                <div className="fisim-form">
                    <div className="fisim-header">User Information</div>
                    <div className="row">
                        <div className="offset-sm-1 offset-md-1 offset-lg-1 col-sm-3 col-md-2 col-lg-2"><label for="fullName" className="form-label">Full Name</label></div>
                        <div className="col-sm-7 col-md-8 col-lg-8"><InputControl name="fullName" onChange={this.onFieldChange} value={this.state.fullName} /></div>
                    </div>

                    <div className="row">
                        <div className="offset-sm-1 offset-md-1 offset-lg-1 col-sm-3 col-md-2 col-lg-2"><label for="email" className="form-label">Email</label></div>
                        <div className="col-sm-7 col-md-8 col-lg-8"><InputControl name="email" onChange={this.onFieldChange} value={this.state.email} style={ { "text-transform": "lowercase" }} /></div>
                    </div>

                    <div className="row">
                        <div className="offset-sm-4 offset-md-3 offset-lg-3 col-sm-3 col-md-1 col-lg-1"><button className="btn btn-primary" onClick={(event) => this.onSubmitUserInfo(event)}>Submit</button></div>
                    </div>
                </div>

                <br/><br/><br/>

                <div className="fisim-form">
                    <div className="fisim-header">Change Password</div>
                    <div className="row">
                        <div className="offset-sm-1 offset-md-1 offset-lg-1 col-sm-3 col-md-2 col-lg-2"><label for="currPassword" className="form-label">Current Password</label></div>
                        <div className="col-sm-7 col-md-8 col-lg-8"><PasswordControl name="currPassword" onChange={this.onFieldChange} /></div>
                    </div>

                    <div className="row">
                        <div className="offset-sm-1 offset-md-1 offset-lg-1 col-sm-3 col-md-2 col-lg-2"><label for="newPassword" className="form-label">New Password</label></div>
                        <div className="col-sm-7 col-md-8 col-lg-8"><PasswordControl name="newPassword" onChange={this.onFieldChange} /></div>
                    </div>

                    <div className="row">
                        <div className="offset-sm-1 offset-md-1 offset-lg-1 col-sm-3 col-md-2 col-lg-2"><label for="confirmPassword" className="form-label">Confirm Password</label></div>
                        <div className="col-sm-7 col-md-8 col-lg-8"><PasswordControl name="confirmPassword" onChange={this.onFieldChange} /></div>
                    </div>

                    <div className="row">
                        <div className="offset-sm-4 offset-md-3 offset-lg-3 col-sm-3 col-md-1 col-lg-1"><button className="btn btn-primary" onClick={(event) => this.onSubmitNewPassword(event)}>Submit</button></div>
                    </div>
                </div>
            </div>
        );
    }
}
 
// Export the template
export default connect(mapStateToProps)(withRouter(UserOptionsView));