import React  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import gameService from '../../../services/GameService';


/**
 * The delete game link class.
 */
class DeleteGameLink extends React.Component
{
    /**
     * The delete game click event handler.
     */
    onClickDeleteGame = () =>
    {
        // Ask permission first...
        if (!window.confirm("Are you sure you wish to delete this game?"))
            return;

        // Delete the game
        gameService.deleteGame(this.props.gameId, this.onGameDeleted);
    }


    /**
     * The game deleted event handler.
     *
     * @param {*} data The data.
     */
    onGameDeleted = () =>
    {
        alert("The game has been successfully deleted.");
    }


    /**
     * This method renders the component.
     */
    render()
    {
        return (
            <span class = "fa-buttons" onClick={ this.onClickDeleteGame }><FontAwesomeIcon icon = {faTrashAlt}/></span>
        );
    }
}

export default DeleteGameLink;