import { SelectColumn } from 'react-data-grid';

import {CheckBoxRenderer,  DisabledCheckBoxRenderer, currencyRenderer, percentageRenderer} from "../../../../controls/grid/GridRenderers";
import { PercentageCellEditor, CurrencyCellEditor } from "../../../../controls/grid/GridEditors";

import SortFunctions from "../../../../controls/grid/SortFunctions";


/**
 * The Retail CD columns.
 */
export const RETAIL_COLUMNS =
[
    SelectColumn,

    { 
        field: 'cd_account', 
        headerName: 'CD Account',
        minWidth: 220,
        comparator: SortFunctions.alphaNumeric
    },

    { 
        field: 'term', 
        headerName: 'Term (Months)', 
        minWidth: 150,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'fixed_or_variable',
        headerName: 'Fixed or Variable', 
        minWidth: 200,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'market_rate', 
        headerName: 'Avg Market Rate', 
        minWidth: 180,
        cellRenderer: percentageRenderer,
        comparator: SortFunctions.numeric
    },

    {
        field: 'rate',
        headerName: 'Offer Rate',
        minWidth: 120,
        editable: (params) => params.data.out_of_market ? false : true,
        cellRenderer: percentageRenderer,
        comparator: SortFunctions.numeric,
        cellEditor: PercentageCellEditor
    },

    {
        field: 'index_rate',
        headerName: 'FHLB Index Rate',
        minWidth: 150,
        cellRenderer: percentageRenderer,
        comparator: SortFunctions.numeric,
    },

    {
        field: 'spread',
        minWidth: 200,
        headerName: 'Spread to FHLB Index Rate',
        cellRenderer: percentageRenderer,
        comparator: SortFunctions.numeric,
    },

    { 
        field: 'total_balance', 
        headerName: 'Total Balance (000)s', 
        minWidth: 175,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric

    },

    { 
        field: 'maturities', 
        headerName: 'Matured this Period (000s)', 
        minWidth: 220,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'last_originations', 
        headerName: 'Last Originations (000s)', 
        minWidth: 200,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'out_of_market', 
        headerName: 'Exit Market',
        width: 150,
        minWidth: 150,
        cellClass: 'center',
        cellRenderer: DisabledCheckBoxRenderer,
        editableCellRenderer: CheckBoxRenderer
    }
];


/**
 * The Brokered CD columns.
 */
export const BROKERED_COLUMNS =
[
    SelectColumn,

    { 
        field: 'cd_account', 
        headerName: 'CD Account',
        minWidth: 220,
        comparator: SortFunctions.alphaNumeric
    },

    { 
        field: 'term', 
        headerName: 'Term (Months)', 
        minWidth: 150,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'fixed_or_variable',
        headerName: 'Fixed or Variable',
        minWidth: 200,
        comparator: SortFunctions.numeric
    },

    {
        field: 'rate',
        headerName: 'Offer Rate',
        minWidth: 120,
        cellRenderer: percentageRenderer,
        comparator: SortFunctions.numeric,
    },

    { 
        field: 'total_balance', 
        headerName: 'Total Balance (000)s',
        minWidth: 175, 
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'maturities', 
        headerName: 'Matured this Period (000s)', 
        minWidth: 220,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'last_originations', 
        headerName: 'Last Originations (000s)',
        minWidth: 200, 
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric
    },

    {
        field: 'cap',
        headerName: 'Requested Volume (000s)',
        minWidth: 200,
        comparator: SortFunctions.numeric,
        cellEditor: CurrencyCellEditor,
        cellRenderer: currencyRenderer,
        editable: (params) => params.data.out_of_market ? true : false
    },
    
    { 
        field: 'out_of_market', 
        headerName: 'Exit Market',
        width: 150,
        minWidth: 150,
        cellClass: 'center',
        cellRenderer: DisabledCheckBoxRenderer,
        editableCellRenderer: CheckBoxRenderer
    }
];


/**
 * The Default columns.
 */
export const DEFAULT_COLUMNS =
[
    SelectColumn,

    { 
        field: 'cd_account', 
        headerName: 'CD Account',
        minWidth: 220,
        comparator: SortFunctions.alphaNumeric,
    },

    { 
        field: 'term', 
        headerName: 'Term (Months)', 
        minWidth: 150,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'fixed_or_variable',
        headerName: 'Fixed or Variable',
        minWidth: 200,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'market_rate', 
        headerName: 'Avg Market Rate', 
        minWidth: 180,
        cellRenderer: percentageRenderer,
        comparator: SortFunctions.numeric
    },

    {
        field: 'rate',
        headerName: 'Offer Rate',
        minWidth: 120,
        cellRenderer: percentageRenderer,
        cellEditor: PercentageCellEditor,
        editable: (params) => params.data.out_of_market ? true : false
    },

    {
        field: 'index_rate',
        headerName: 'Treasury',
        minWidth: 150,
        cellRenderer: percentageRenderer,
        comparator: SortFunctions.numeric,
    },

    {
        field: 'spread',
        headerName: 'Spread to Treasury',
        minWidth: 200,
        cellRenderer: percentageRenderer,
        comparator: SortFunctions.numeric,
    },

    { 
        field: 'total_balance', 
        headerName: 'Total Balance (000)s', 
        minWidth: 175,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'maturities', 
        headerName: 'Matured this Period (000s)', 
        minWidth: 220,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric,
    },

    { 
        field: 'last_originations', 
        headerName: 'Last Originations (000s)',
        minWidth: 200,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric
    },

    {
        field: 'cap',
        headerName: 'Period Cap (000s)',
        minWidth: 200,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric,
        cellEditor: CurrencyCellEditor,
        editable: (params) => params.data.out_of_market ? true : false
    },
    
    { 
        field: 'out_of_market', 
        headerName: 'Exit Market',
        width: 150,
        minWidth: 150,
        cellClass: 'center',
        cellRenderer: DisabledCheckBoxRenderer,
        editableCellRenderer: CheckBoxRenderer
    }
];