import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

/**
 * The default mask options
 */
const DEFAULT_MASK_OPTIONS = 
{
    prefix: '',
    suffix: '%',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 3, // how many digits allowed after the decimal
    integerLimit: 3, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
}

// Create the percentage mask
const PERCENTAGE_MASK = createNumberMask(DEFAULT_MASK_OPTIONS);

/**
 * The percentage input component.
 *
 * @param {*} props The properties.
 * @returns The percentage input control.
 */
 class PercentageInput extends React.Component
 {
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Create the input reference
        this.inputRef = React.createRef();
    }


    /**
     * This method places focus on the cell.
     */
    focus()
    {
        this.inputRef.current.inputElement.focus();
    }


    /**
     * This method renders the component.
     *
     * @returns The rendered component.
     */
    render()
    {
        // Return the masked input control...
        return <MaskedInput ref={ this.inputRef } mask={ PERCENTAGE_MASK } { ...this.props } />; 
    }
}
 

/**
 * The default properties.
 */
PercentageInput.defaultProps = 
{
    inputMode: 'numeric',
    maskOptions: {},
}


/**
 * The property types.
 */
PercentageInput.propTypes = 
{
    inputmode: PropTypes.string,
    maskOptions: PropTypes.shape(
    {
        prefix: PropTypes.string,
        suffix: PropTypes.string,
        includeThousandsSeparator: PropTypes.bool,
        thousandsSeparatorSymbol: PropTypes.string,
        allowDecimal: PropTypes.bool,
        decimalSymbol: PropTypes.string,
        decimalLimit: PropTypes.string,
        requireDecimal: PropTypes.bool,
        allowNegative: PropTypes.bool,
        allowLeadingZeroes: PropTypes.bool,
        integerLimit: PropTypes.number,
    })
}


// Export the percentage input component
export default PercentageInput;