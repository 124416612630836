import { CurrencyCellEditor } from "../../../controls/grid/GridEditors";
import { currencyRenderer } from "../../../controls/grid/GridRenderers";
import SortFunctions from "../../../controls/grid/SortFunctions";

/**
 * The adjustments columns.
 */
export const COLUMNS = 
[
    {
        field: "team_name",
        headerName: "Team Name",
        headerClass: "panel-header",
    },
    {
        field: "income",
        headerName: "Income",
        headerClass: "panel-header",
        cellRenderer: currencyRenderer,
        cellEditor: CurrencyCellEditor,
        comparator: SortFunctions.numeric,
    },

    {
        field: "expense",
        headerName: "Expense",
        cellRenderer: currencyRenderer,
        headerClass: "panel-header",
        cellEditor: CurrencyCellEditor,
        comparator: SortFunctions.numeric,
    }
];


/**
 * The Lite adjustments columns.
 */
export const LITE_COLUMNS = 
[
    {
        field: "team_name",
        headerName: "Team Name",
        headerClass: "panel-header",
    },

    {
        field: "expense",
        headerName: "Expense",
        cellRenderer: currencyRenderer,
        headerClass: "panel-header",
        cellEditor: CurrencyCellEditor,
        comparator: SortFunctions.numeric,
    }
];
