import { Fragment } from 'react';
import { Row, Col } from "react-bootstrap";

import FormControl from "../../../controls/form/FormControl";
import CurrencyControl from '../../../controls/form/CurrencyControl';
import BaseMergersAndAcquisitionsTab from './BaseMergersAndAcquisitionsTab';
import calculationHelpers from './CalculationHelpers';


/**
 * The Disclosure class.
 */
class Disclosure extends BaseMergersAndAcquisitionsTab
{
    /**
     * This method renders the component.
     */
    render()
    {
        // Set the teams
        let team1 = (this.props.mergersAndAcquisitionsMap && this.props.commonState.team1) ? this.props.mergersAndAcquisitionsMap[this.props.commonState.team1] : null;
        let team2 = (this.props.mergersAndAcquisitionsMap && this.props.commonState.team2) ? this.props.mergersAndAcquisitionsMap[this.props.commonState.team2] : null;
        let team3 = (this.props.mergersAndAcquisitionsMap && this.props.commonState.team3) ? this.props.mergersAndAcquisitionsMap[this.props.commonState.team3] : null;

        // Calculate the disclosure
        let disclosure = this.calculateDisclosure();

        // Initialize the tab index
        let tabIndex2 = 1;
        let tabIndex3 = 10;

        return(
            <Fragment>
                <FormControl name="Stock Price & Exchange Disclosure">
                    <div className="manda-grid panel-body">
                        <Row className="header g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><b>{ team1 ? this.props.teamMap[this.props.commonState.team1].team_name : "None Selected" }</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><b>{ team2 ? this.props.teamMap[this.props.commonState.team2].team_name : "None Selected" }</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><b>{ team3 ? this.props.teamMap[this.props.commonState.team3].team_name : "None Selected" }</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><b>Combined Bank</b></Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Stock Premium Offered</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right"><CurrencyControl name="stockPremiumOffered1" value={ this.props.commonState.stockPremiumOffered1 } onChange={ this.onFieldChange } style={ { textAlign: "right" } } allowDecimal={ true } tabIndex={ tabIndex2++ } allowDecimal={ true } allowNegative={ true } /></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? <CurrencyControl name="stockPremiumOffered2" value={ this.props.commonState.stockPremiumOffered2 } onChange={ this.onFieldChange } style={ { textAlign: "right" } } allowDecimal={ true } tabIndex={ tabIndex2++ } allowDecimal={ true } allowNegative={ true } /> : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? <CurrencyControl name="stockPremiumOffered3" value={ this.props.commonState.stockPremiumOffered3 } onChange={ this.onFieldChange } style={ { textAlign: "right" } } allowDecimal={ true } tabIndex={ tabIndex3++ } allowDecimal={ true } allowNegative={ true } /> : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">&nbsp;</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Implied Stock Offer</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">N/A</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 && isFinite(disclosure.impliedStockOffer2) ? calculationHelpers.getDollarFormatter().format(disclosure.impliedStockOffer2) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 && isFinite(disclosure.impliedStockOffer3) ? calculationHelpers.getDollarFormatter().format(disclosure.impliedStockOffer3) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">&nbsp;</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Implied Value of the Acquired Bank</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">N/A</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 && isFinite(disclosure.impliedStockOffer2)  ? calculationHelpers.getDollarFormatter().format(disclosure.impliedValue2) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 && isFinite(disclosure.impliedStockOffer2)  ? calculationHelpers.getDollarFormatter().format(disclosure.impliedValue3) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">&nbsp;</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Stock Price Per Share</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter2Decimals().format(calculationHelpers.getValue(team1, "Stock Price")) }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? calculationHelpers.getDollarFormatter2Decimals().format(calculationHelpers.getValue(team2, "Stock Price")) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? calculationHelpers.getDollarFormatter2Decimals().format(calculationHelpers.getValue(team3, "Stock Price")) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">&nbsp;</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Stock Premium Offered</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">$0</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? this.props.commonState.stockPremiumOffered2 : "" } </Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? this.props.commonState.stockPremiumOffered3 : "" } </Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">&nbsp;</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Implied Stock Value</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter().format(disclosure.impliedStockValue1) }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? calculationHelpers.getDollarFormatter().format(disclosure.impliedStockValue2) : "" } </Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? calculationHelpers.getDollarFormatter().format(disclosure.impliedStockValue3) : "" } </Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">&nbsp;</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Number of Bank Shares Tendered</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ this.formatNumber(calculationHelpers.getValue(team1, "Common Shares")) }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? this.formatNumber(calculationHelpers.getValue(team2, "Common Shares")) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? this.formatNumber(calculationHelpers.getValue(team3, "Common Shares")) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ this.formatNumber(calculationHelpers.sumValues(team1, team2, team3, "Common Shares")) }</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Exchange Ratio</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">1.00</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? this.formatNumber(disclosure.exchangeRatio2) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? this.formatNumber(disclosure.exchangeRatio3) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">&nbsp;</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Number of Holding Company Shares Issued</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ this.formatNumber(calculationHelpers.getValue(team1, "Common Shares")) }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? this.formatNumber(disclosure.bankSharesTendered2) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? this.formatNumber(disclosure.bankSharesTendered3) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ this.formatNumber(calculationHelpers.getValue(team1, "Common Shares") + disclosure.bankSharesTendered2 + disclosure.bankSharesTendered3) }</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Cash Paid per Bank Share Tendered</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">N/A</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? <CurrencyControl name="cashPaid2" value={ this.props.commonState.cashPaid2 } onChange={ this.onFieldChange } style={ { textAlign: "right" } } allowDecimal={ true } tabIndex={ tabIndex2++ } allowDecimal={ true } /> : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? <CurrencyControl name="cashPaid3" value={ this.props.commonState.cashPaid3 } onChange={ this.onFieldChange } style={ { textAlign: "right" } } allowDecimal={ true } tabIndex={ tabIndex3++ } allowDecimal={ true }  /> : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">&nbsp;</Col>
                        </Row>
                        <Row className="odd-row g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Total Cash Paid to Bank Stockholders (millions)</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">N/A</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? calculationHelpers.formatCurrencyInMillions(disclosure.totalCashPaid2) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? calculationHelpers.formatCurrencyInMillions(disclosure.totalCashPaid3) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.formatCurrencyInMillions(disclosure.totalCashPaid2 + disclosure.totalCashPaid3) }</Col>
                        </Row>
                        <Row className="g-3">
                            <Col xs={6} sm={6} md={3} lg={4} className="left"><b>Total Value of the Deal</b></Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter().format(disclosure.totalValue1) }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team2 ? calculationHelpers.getDollarFormatter().format(disclosure.totalValue2) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ team3 ? calculationHelpers.getDollarFormatter().format(disclosure.totalValue3) : "" }</Col>
                            <Col xs={6} sm={6} md={3} lg={2} className="right">{ calculationHelpers.getDollarFormatter().format(disclosure.totalValue1 + disclosure.totalValue2 + disclosure.totalValue3) }</Col>
                        </Row>
                    </div>
                </FormControl>
            </Fragment>
        );
    }
}

// Export the disclosure...
export default Disclosure;