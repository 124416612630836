import React  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";

import gameService from '../../../services/GameService';



/**
 * The whatif link class.
 */
class WhatIfLink extends React.Component
{
    /**
     * The toggle what-if event handler.
     */
    onToggleWhatIf = () =>
    {
        // Disable whatifs for the team
        gameService.disableWhatIfs(this.props.gameId, this.props.teamId, !this.props.disable_whatifs);
    }


    /**
     * This method renders the component.
     */
    render()
    {
        return (
            <span class="fa-buttons" onClick={ this.onToggleWhatIf }><FontAwesomeIcon color = { this.props.disable_whatifs ? "red" : "green" } icon = { this.props.disable_whatifs ? faCircleXmark : faCheckCircle  } /></span>
        );
    }
}

export default WhatIfLink;