import { withRouter } from "react-router";

import BaseView from "../BaseView";
import FormControl from "../../controls/form/FormControl";
import PasswordControl from "../../controls/form/PasswordControl";
import ajax from "../../utils/Ajax";

 
 /**
  * The ResetPasswordView class.
  */
 class ResetPasswordView extends BaseView
 {
     /**
      * The submit event handler.
      * 
      * @param {*} event The event.
      */
     onSubmit = (event) =>
     {
        // Check if passwords match
        if (this.state.password !== this.state.confirmPassword)
        {
            alert("Passwords do not match");
            return;
        }

        // When the user submits, server will update the password
        ajax.post("/user/reset-password/" + this.props.match.params.uuid, this.state, this.onNewPasswordComplete, "Updating Password", "An error occurred during the password update process.");
     }
     
     /**
      * The new password complete event handler.
      * 
      * @param {*} response The response.
      */
     onNewPasswordComplete = (response) =>
     {
        if(response.passwordChanged)
        {
            alert("Your password has been changed.");
            this.props.history.push("/");
        }
        else
            alert("We could not change your password. Please try again.");
     }
 
     /**
      * This method renders the component.
      */
     render()
     {
         return (
            <FormControl name="Reset Password" className="change-password-form">
                <div className="row">
                    <div className="offset-sm-1 offset-md-1 offset-lg-1 col-sm-3 col-md-2 col-lg-2"><label for="password" className="form-label">New Password</label></div>
                    <div className="col-sm-7 col-md-8 col-lg-8"><PasswordControl name="password" onChange={ this.onFieldChange } /></div>
                </div>

                <div className="row">
                    <div className="offset-sm-1 offset-md-1 offset-lg-1 col-sm-3 col-md-2 col-lg-2"><label for="confirmPassword" className="form-label">Confirm New Password</label></div>
                    <div className="col-sm-7 col-md-8 col-lg-8"><PasswordControl name="confirmPassword" onChange={ this.onFieldChange } /></div>
                </div>

                <div className="row">
                    <div className="offset-sm-4 offset-md-3 offset-lg-3 col-sm-3 col-md-2 col-lg-2"><button className="btn btn-primary" onClick={ (event) => this.onSubmit(event) } >Submit</button></div>
                </div>
            </FormControl>
         );
     }
 }
 
 export default withRouter(ResetPasswordView);