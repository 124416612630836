import { useState, useEffect, Fragment } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';

import DraggableModalDialog from "../../../controls/DraggableModalDialog"
import intraturnService from '../../../services/IntraturnService';
import { formatCurrency, formatCurrencyWithPennies } from "../../../controls/Currency";
import {formatPercentage } from "../../../controls/Percentage";

import "./turn-changes.css";


/**
 * This method formats the yes/no value.
 * 
 * @param {*} value The value.
 * @returns The yes/no value.
 */
function formatYesNo(value)
{
    return value ? "Yes" : "No";
}


/**
 * This method displays a field change.
 * 
 * @param {*} field1 The first field.
 * @param {*} field2 The second field.
 * @returns The change HTML.
 */
function fieldChange(field1, field2)
{
    if (field1 === field2)
        return field1;

    return (
        <Fragment>
            <div className="before">{ field1 }</div>
            <div className="after">{ field2 }</div>
        </Fragment>
    );
}


/**
 * The turn changes modal component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
const TurnChangesModal = (props) =>
{
    // Create the state
    let [ comparison, setComparison ] = useState(null);

    // Get the changes when visible
    useEffect(() =>
    {
        if (props.visible)
            intraturnService.getTurnChanges(props.intraturnId, setComparison);
    }, [ props.visible ]);

    // Initialize the NMD lists
    let ddas = [];
    let checkingAndSavings = [];
    let moneyMarket = [];

    // Initialize the CD lists
    let brokeredCDs = [];
    let internetCDs = [];
    let publicCDs = [];
    let retailCDs = [];

    // Initialize the general admin lists
    let it = [];
    let learning = [];
    let staffing = [];
    

    if (comparison)
    {
        // Populate the NMD lists
        for (let nmd of comparison.nmds)
        {
            switch (nmd.type)
            {
                case "DDA":
                    ddas.push(nmd);
                    break;

                case "Interest Bearing Checking & Savings":
                    checkingAndSavings.push(nmd);
                    break;

                case "Money Market":
                    moneyMarket.push(nmd);
                    break;
            }
        }

        // Populate the CD lists
        for (let cd of comparison.cds)
        {
            switch (cd.cd_type)
            {
                case "Brokered CDs":
                    brokeredCDs.push(cd);
                    break;

                case "Internet CDs":
                    internetCDs.push(cd);
                    break;

                case "Public\\Gov't CDs":
                    publicCDs.push(cd);
                    break;

                case "Retail CDs":
                    retailCDs.push(cd);
                    break;
            }
        }

        // Populate the general administration lists
        for (let genAdmin of comparison.genAdmin)
        {
            switch (genAdmin.gen_admin_account)
            {
                case "IT":
                    it.push(genAdmin);
                    break;

                case "Learning/Development":
                    learning.push(genAdmin);
                    break;

                case "Staffing":
                    staffing.push(genAdmin);
                    break;
            }
        }
    }

    // Initialize the lite flag...
    let lite = props.gameType === "Lite";

    // Get out...
    return (
        <Modal show={ props.visible } onHide={ props.onClose } dialogAs={ DraggableModalDialog } dialogClassName="turn-changes-modal">
            <Modal.Header closeButton>
                <Modal.Title>{ comparison ? "Changes for Turn #" + comparison.turn_num + " - " + comparison.name : "Changes" }</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {
                    comparison ?
                        <Container className="turn-changes-container">
                            <Row className="turn-difference-table-row">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="turn-difference-heading">Investment Purchases</div>
                                    { 
                                        comparison.investPurchases.length > 0 ?
                                            <div className="turn-difference-table">
                                                <div className="turn-difference-row">
                                                    <div className="turn-difference-column header change-name">Description</div>
                                                    <div className="turn-difference-column header">Amount (000s)</div>
                                                </div>
                                                {
                                                    comparison.investPurchases.map(function(item)
                                                    {
                                                        return (
                                                            <div className="turn-difference-row">
                                                                <div className="turn-difference-column change-name">{ item.description }</div>
                                                                <div className="turn-difference-column">{ item.amount }</div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        : <div className="no-changes">No changes</div>
                                    }
                                </Col>
                            </Row>

                            <Row className="turn-difference-table-row">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="turn-difference-heading">Investment Sales</div>
                                    { 
                                        comparison.investSales.length > 0 ?
                                            <div className="turn-difference-table">
                                                <div className="turn-difference-row">
                                                    <div className="turn-difference-column header change-name">Invest Account</div>
                                                    <div className="turn-difference-column header">CUSIP</div>
                                                    <div className="turn-difference-column header">Percent</div>
                                                    <div className="turn-difference-column header">Amount</div>
                                                    <div className="turn-difference-column header">Gain/Loss</div>
                                                </div>
                                                {
                                                    comparison.investSales.map(function(item)
                                                    {
                                                        return (
                                                            <div className="turn-difference-row">
                                                                <div className="turn-difference-column change-name">{ item.invest_account }</div>
                                                                <div className="turn-difference-column">{ item.cusip }</div>
                                                                <div className="turn-difference-column">{ formatPercentage(item.sale_percent) }</div>
                                                                <div className="turn-difference-column">{ formatCurrency(item.sale_amount) }</div>
                                                                <div className="turn-difference-column">{ formatCurrency(item.gain_or_loss) }</div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        : <div className="no-changes">No changes</div>
                                    }
                                </Col>
                            </Row>

                            {
                                !lite ?
                                <Row className="turn-difference-table-row">
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className="turn-difference-heading">Loan Administration</div>
                                        { 
                                            comparison.loanAdmins.length > 0 ?
                                                <div className="turn-difference-table">
                                                    <div className="turn-difference-row">
                                                        <div className="turn-difference-column header">Account</div>
                                                        <div className="turn-difference-column header">1</div>
                                                        <div className="turn-difference-column header">2</div>
                                                        <div className="turn-difference-column header">3</div>
                                                        <div className="turn-difference-column header">4</div>
                                                        <div className="turn-difference-column header">5</div>
                                                        <div className="turn-difference-column header">6</div>
                                                        <div className="turn-difference-column header">Cap</div>
                                                        <div className="turn-difference-column header">Exit Market</div>
                                                    </div>
                                                    {
                                                        comparison.loanAdmins.map(function(item)
                                                        {
                                                            return (
                                                                <div className="turn-difference-row">
                                                                    <div className="turn-difference-column change-name">{ item.loan_account }</div>
                                                                    <div className="turn-difference-column">
                                                                        { fieldChange(formatPercentage(item.before_1), formatPercentage(item.after_1)) }
                                                                    </div>
                                                                    <div className="turn-difference-column">
                                                                        { fieldChange(formatPercentage(item.before_2), formatPercentage(item.after_2)) }
                                                                    </div>
                                                                    <div className="turn-difference-column">
                                                                        { fieldChange(formatPercentage(item.before_3), formatPercentage(item.after_3)) }
                                                                    </div>
                                                                    <div className="turn-difference-column">
                                                                        { fieldChange(formatPercentage(item.before_4), formatPercentage(item.after_4)) }
                                                                    </div>
                                                                    <div className="turn-difference-column">
                                                                        { fieldChange(formatPercentage(item.before_5), formatPercentage(item.after_5)) }
                                                                    </div>
                                                                    <div className="turn-difference-column">
                                                                        { fieldChange(formatPercentage(item.before_6), formatPercentage(item.after_6)) }
                                                                    </div>
                                                                    <div className="turn-difference-column">
                                                                        { fieldChange(formatCurrency(item.before_cap), formatCurrency(item.after_cap)) }
                                                                    </div>
                                                                    <div className="turn-difference-column">
                                                                    { fieldChange(formatYesNo(item.before_out_of_market), formatYesNo(item.after_out_of_market)) }
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            : <div className="no-changes">No changes</div>
                                        }
                                    </Col>
                                </Row> :
                                ""
                            }

                            <Row className="turn-difference-table-row">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="turn-difference-heading">Loan Origination</div>
                                    { 
                                        comparison.loanOrigins.length > 0 ?
                                            <div className="turn-difference-table">
                                                <div className="turn-difference-row">
                                                    <div className="turn-difference-column header">Account</div>
                                                    <div className="turn-difference-column header">1</div>
                                                    <div className="turn-difference-column header">2</div>
                                                    <div className="turn-difference-column header">3</div>
                                                    <div className="turn-difference-column header">4</div>
                                                    <div className="turn-difference-column header">5</div>
                                                    <div className="turn-difference-column header">6</div>
                                                    <div className="turn-difference-column header">Org Fee</div>
                                                    <div className="turn-difference-column header">Annual Fee</div>
                                                </div>
                                                {
                                                    comparison.loanOrigins.map(function(item)
                                                    {
                                                        return (
                                                            <div className="turn-difference-row">
                                                                <div className="turn-difference-column">{ item.loan_account }</div>
                                                                <div className="turn-difference-column">
                                                                    { fieldChange(formatPercentage(item.before_1), formatPercentage(item.after_1)) }
                                                                </div>
                                                                <div className="turn-difference-column">
                                                                    { fieldChange(formatPercentage(item.before_2), formatPercentage(item.after_2)) }
                                                                </div>
                                                                <div className="turn-difference-column">
                                                                    { fieldChange(formatPercentage(item.before_3), formatPercentage(item.after_3)) }
                                                                </div>
                                                                <div className="turn-difference-column">
                                                                    { fieldChange(formatPercentage(item.before_4), formatPercentage(item.after_4)) }
                                                                </div>
                                                                <div className="turn-difference-column">
                                                                    { fieldChange(formatPercentage(item.before_5), formatPercentage(item.after_5)) }
                                                                </div>
                                                                <div className="turn-difference-column">
                                                                    { fieldChange(formatPercentage(item.before_6), formatPercentage(item.after_6)) }
                                                                </div>
                                                                <div className="turn-difference-column">
                                                                { fieldChange(formatCurrency(item.before_origin_fee), formatCurrency(item.after_origin_fee)) }
                                                                </div>
                                                                <div className="turn-difference-column">
                                                                { fieldChange(formatCurrency(item.before_annual_fee), formatCurrency(item.after_annual_fee)) }
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        : <div className="no-changes">No changes</div>
                                    }
                                </Col>
                            </Row>

                            <Row className="turn-difference-table-row">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="turn-difference-heading">Loan Sales</div>
                                    { 
                                        comparison.loanSales.length > 0 ?
                                            <div className="turn-difference-table">
                                                <div className="turn-difference-row">
                                                    <div className="turn-difference-column header change-name">Loan Account</div>
                                                    <div className="turn-difference-column header fixed-column">Percent</div>
                                                    <div className="turn-difference-column header fixed-column">Amount</div>
                                                    <div className="turn-difference-column header fixed-column">Gain/Loss</div>
                                                </div>
                                                {
                                                    comparison.loanSales.map(function(item)
                                                    {
                                                        return (
                                                            <div className="turn-difference-row">
                                                                <div className="turn-difference-column change-name">{ item.loan_account }</div>
                                                                <div className="turn-difference-column fixed-column">{ formatPercentage(item.sale_percent) }</div>
                                                                <div className="turn-difference-column fixed-column">{ formatCurrency(item.sale_amount) }</div>
                                                                <div className="turn-difference-column fixed-column">{ formatCurrency(item.gain_or_loss) }</div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        : <div className="no-changes">No changes</div>
                                    }
                                </Col>
                            </Row>

                            <Row className="turn-difference-table-row">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="turn-difference-heading">NMD  - DDAs</div>
                                    { 
                                        ddas.length > 0 ?
                                            <div className="turn-difference-table">
                                                <div className="turn-difference-row">
                                                    <div className="turn-difference-column header change-name">Account</div>
                                                    <div className="turn-difference-column header fixed-column">Monthly Fee</div>
                                                    <div className="turn-difference-column header fixed-column">NSF Fee</div>
                                                    <div className="turn-difference-column header fixed-column">Minimum Balance</div>
                                                </div>
                                                {
                                                    ddas.map(function(item)
                                                    {
                                                        return (
                                                            <div className="turn-difference-row">
                                                                <div className="turn-difference-column change-name">{ item.name }</div>
                                                                <div className="turn-difference-column fixed-column">
                                                                    { fieldChange(formatCurrency(item.before_monthly_fee), formatCurrency(item.after_monthly_fee)) }
                                                                </div>
                                                                <div className="turn-difference-column fixed-column">
                                                                    { fieldChange(formatCurrency(item.before_nsf_fee), formatCurrency(item.after_nsf_fee)) }
                                                                </div>
                                                                <div className="turn-difference-column fixed-column">
                                                                    { fieldChange(formatCurrency(item.before_minimum_balance), formatCurrency(item.after_minimum_balance)) }
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        : <div className="no-changes">No changes</div>
                                    }
                                </Col>
                            </Row>

                            <Row className="turn-difference-table-row">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="turn-difference-heading">NMD  - Interest Bearing Checking &amp; Savings</div>
                                    { 
                                        checkingAndSavings.length > 0 ?
                                            <div className="turn-difference-table">
                                                <div className="turn-difference-row">
                                                    <div className="turn-difference-column header change-name">Account</div>
                                                    <div className="turn-difference-column header">Offer Rate</div>
                                                    <div className="turn-difference-column header">Beta Up</div>
                                                    <div className="turn-difference-column header">Beta Down</div>
                                                    <div className="turn-difference-column header">Lag</div>
                                                    <div className="turn-difference-column header">Monthly Fee</div>
                                                    <div className="turn-difference-column header">NSF Fee</div>
                                                    <div className="turn-difference-column header">Minimum Balance</div>
                                                </div>
                                                {
                                                    checkingAndSavings.map(function(item)
                                                    {
                                                        return (
                                                            <div className="turn-difference-row">
                                                                <div className="turn-difference-column change-name">{ item.name }</div>
                                                                <div className="turn-difference-column">
                                                                    { fieldChange(formatPercentage(item.before_rate), formatPercentage(item.after_rate)) }
                                                                </div>
                                                                <div className="turn-difference-column">
                                                                    { fieldChange(formatPercentage(item.before_beta_up), formatPercentage(item.after_beta_up)) }
                                                                </div>
                                                                <div className="turn-difference-column">
                                                                    { fieldChange(formatPercentage(item.before_beta_down), formatPercentage(item.after_beta_down)) }
                                                                </div>
                                                                <div className="turn-difference-column">
                                                                    { fieldChange(item.before_lag, item.after_lag) }
                                                                </div>
                                                                <div className="turn-difference-column">
                                                                    { fieldChange(formatCurrency(item.before_monthly_fee), formatCurrency(item.after_monthly_fee)) }
                                                                </div>
                                                                <div className="turn-difference-column">
                                                                    { fieldChange(formatCurrency(item.before_nsf_fee), formatCurrency(item.after_nsf_fee)) }
                                                                </div>
                                                                <div className="turn-difference-column">
                                                                    { fieldChange(formatCurrency(item.before_minimum_balance), formatCurrency(item.after_minimum_balance)) }
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        : <div className="no-changes">No changes</div>
                                    }
                                </Col>
                            </Row>

                            { 
                                !lite ?
                                <Row className="turn-difference-table-row">
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className="turn-difference-heading">NMD  - Money Market</div>
                                        { 
                                            moneyMarket.length > 0 ?
                                                <div className="turn-difference-table">
                                                    <div className="turn-difference-row">
                                                        <div className="turn-difference-column header change-name">Account</div>
                                                        <div className="turn-difference-column header">Offer Rate</div>
                                                        <div className="turn-difference-column header">Beta Up</div>
                                                        <div className="turn-difference-column header">Beta Down</div>
                                                        <div className="turn-difference-column header">Lag</div>
                                                        <div className="turn-difference-column header">Monthly Fee</div>
                                                        <div className="turn-difference-column header">NSF Fee</div>
                                                        <div className="turn-difference-column header">Minimum Balance</div>
                                                    </div>
                                                    {
                                                        moneyMarket.map(function(item)
                                                        {
                                                            return (
                                                                <div className="turn-difference-row">
                                                                    <div className="turn-difference-column change-name">{ item.name }</div>
                                                                    <div className="turn-difference-column">
                                                                        { fieldChange(formatPercentage(item.before_rate), formatPercentage(item.after_rate)) }
                                                                    </div>
                                                                    <div className="turn-difference-column">
                                                                        { fieldChange(formatPercentage(item.before_beta_up), formatPercentage(item.after_beta_up)) }
                                                                    </div>
                                                                    <div className="turn-difference-column">
                                                                        { fieldChange(formatPercentage(item.before_beta_down), formatPercentage(item.after_beta_down)) }
                                                                    </div>
                                                                    <div className="turn-difference-column">
                                                                        { fieldChange(item.before_lag, item.after_lag) }
                                                                    </div>
                                                                    <div className="turn-difference-column">
                                                                        { fieldChange(formatCurrency(item.before_monthly_fee), formatCurrency(item.after_monthly_fee)) }
                                                                    </div>
                                                                    <div className="turn-difference-column">
                                                                        { fieldChange(formatCurrency(item.before_nsf_fee), formatCurrency(item.after_nsf_fee)) }
                                                                    </div>
                                                                    <div className="turn-difference-column">
                                                                        { fieldChange(formatCurrency(item.before_minimum_balance), formatCurrency(item.after_minimum_balance)) }
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            : <div className="no-changes">No changes</div>
                                        }
                                    </Col>
                                </Row>
                                : ""
                            }

                            <Row className="turn-difference-table-row">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="turn-difference-heading">CDs - Brokered</div>
                                    { 
                                        brokeredCDs.length > 0 ?
                                            <div className="turn-difference-table">
                                                <div className="turn-difference-row">
                                                    <div className="turn-difference-column header change-name">Account</div>
                                                    <div className="turn-difference-column header fixed-column">Volume (000s)</div>
                                                    <div className="turn-difference-column header">Exit Market</div>
                                                </div>
                                                {
                                                    brokeredCDs.map(function(item)
                                                    {
                                                        return (
                                                            <div className="turn-difference-row">
                                                                <div className="turn-difference-column change-name">{ item.cd_account }</div>
                                                                <div className="turn-difference-column fixed-column">
                                                                    { fieldChange(formatCurrency(item.before_monthly_cap), formatCurrency(item.after_cap)) }
                                                                </div>
                                                                <div className="turn-difference-column">
                                                                    { fieldChange(formatYesNo(item.before_out_of_market), formatYesNo(item.after_out_of_market)) }
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        : <div className="no-changes">No changes</div>
                                    }
                                </Col>
                            </Row>

                            {
                                !lite ?
                                <Row className="turn-difference-table-row">
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className="turn-difference-heading">CDs - Internet</div>
                                        { 
                                            internetCDs.length > 0 ?
                                                <div className="turn-difference-table">
                                                    <div className="turn-difference-row">
                                                        <div className="turn-difference-column header change-name">Account</div>
                                                        <div className="turn-difference-column header fixed-column">Rate</div>
                                                        <div className="turn-difference-column header fixed-column">Cap (000s)</div>
                                                        <div className="turn-difference-column header fixed-column">Exit Market</div>
                                                    </div>
                                                    {
                                                        internetCDs.map(function(item)
                                                        {
                                                            return (
                                                                <div className="turn-difference-row">
                                                                    <div className="turn-difference-column change-name">{ item.cd_account }</div>
                                                                    <div className="turn-difference-column fixed-column">
                                                                        { fieldChange(formatPercentage(item.market_rate), formatPercentage(item.rate)) }
                                                                    </div>
                                                                    <div className="turn-difference-column fixed-column">
                                                                        { fieldChange(formatCurrency(item.before_cap), formatCurrency(item.after_cap)) }
                                                                    </div>
                                                                    <div className="turn-difference-column fixed-column">
                                                                        { fieldChange(formatYesNo(item.before_out_of_market), formatYesNo(item.after_out_of_market)) }
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            : <div className="no-changes">No changes</div>
                                        }
                                    </Col>
                                </Row>
                                : ""
                            }

                            {
                                !lite ?
                                <Row className="turn-difference-table-row">
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className="turn-difference-heading">CDs - Public\Gov't</div>
                                        { 
                                            publicCDs.length > 0 ?
                                                    <div className="turn-difference-table">
                                                    <div className="turn-difference-row">
                                                        <div className="turn-difference-column header change-name">Account</div>
                                                        <div className="turn-difference-column header fixed-column">Rate</div>
                                                        <div className="turn-difference-column heade fixed-column">Cap (000s)</div>
                                                        <div className="turn-difference-column header fixed-column">Exit Market</div>
                                                    </div>
                                                    {
                                                        publicCDs.map(function(item)
                                                        {
                                                            return (
                                                                <div className="turn-difference-row">
                                                                    <div className="turn-difference-column change-name">{ item.cd_account }</div>
                                                                    <div className="turn-difference-column">
                                                                        { fieldChange(formatPercentage(item.market_rate), formatPercentage(item.rate)) }
                                                                    </div>
                                                                    <div className="turn-difference-column">
                                                                        { fieldChange(formatCurrency(item.before_cap), formatCurrency(item.after_cap)) }
                                                                    </div>
                                                                    <div className="turn-difference-column">
                                                                        { fieldChange(formatYesNo(item.before_out_of_market), formatYesNo(item.after_out_of_market)) }
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            : <div className="no-changes">No changes</div>
                                        }
                                    </Col>
                                </Row>
                                : ""
                            }

                            <Row className="turn-difference-table-row">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="turn-difference-heading">CDs - Retail</div>
                                    { 
                                        retailCDs.length > 0 ?
                                                <div className="turn-difference-table">
                                                <div className="turn-difference-row">
                                                    <div className="turn-difference-column header change-name">Account</div>
                                                    <div className="turn-difference-column header fixed-column">Rate</div>
                                                    <div className="turn-difference-column header">Exit Market</div>
                                                </div>
                                                {
                                                    retailCDs.map(function(item)
                                                    {
                                                        return (
                                                            <div className="turn-difference-row">
                                                                <div className="turn-difference-column change-name">{ item.cd_account }</div>
                                                                <div className="turn-difference-column fixed-column">
                                                                    { fieldChange(formatPercentage(item.market_rate), formatPercentage(item.rate)) }
                                                                </div>
                                                                <div className="turn-difference-column">
                                                                    { fieldChange(formatYesNo(item.before_out_of_market), formatYesNo(item.after_out_of_market)) }
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        : <div className="no-changes">No changes</div>
                                    }
                                </Col>
                            </Row>

                            <Row className="turn-difference-table-row">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="turn-difference-heading">Borrowings</div>
                                    { 
                                        comparison.borrowing.length > 0 ?
                                                <div className="turn-difference-table">
                                                <div className="turn-difference-row">
                                                    <div className="turn-difference-column header change-name">Ammortizing/Bullet</div>
                                                    <div className="turn-difference-column header fixed-column">Fixed/Variable</div>
                                                    <div className="turn-difference-column header">Term</div>
                                                    <div className="turn-difference-column header">Rate</div>
                                                    <div className="turn-difference-column header">Prepay</div>
                                                </div>
                                                {
                                                    comparison.borrowing.map(function(item)
                                                    {
                                                        return (
                                                            <div className="turn-difference-row">
                                                                <div className="turn-difference-column change-name">{ item.amortizing_or_bullet }</div>
                                                                <div className="turn-difference-column fixed-column">{ item.fixed_or_variable }</div>
                                                                <div className="turn-difference-column">{ item.term }</div>
                                                                <div className="turn-difference-column">{ formatCurrency(item.maturities) }</div>
                                                                <div className="turn-difference-column">
                                                                { 
                                                                    item.prepay ? 
                                                                        <div className="after">Yes (Turn #{ item.turn_num })</div>
                                                                    : "No"
                                                                }
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        : <div className="no-changes">No changes</div>
                                    }
                                </Col>
                            </Row>

                            {
                                !lite ?
                                <Row className="turn-difference-table-row">
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className="turn-difference-heading">Long-Term Debt</div>
                                        { 
                                            comparison.termDebt.length > 0 ?
                                                <div className="turn-difference-table">
                                                    <div className="turn-difference-row">
                                                    <div className="turn-difference-column header change-name">Fixed/Variable</div>
                                                        <div className="turn-difference-column header fixed-column">Term</div>
                                                        <div className="turn-difference-column header fixed-column">Rate</div>
                                                        <div className="turn-difference-column header">Amount (000s)</div>
                                                    </div>
                                                    {
                                                        comparison.termDebt.map(function(item)
                                                        {
                                                            return (
                                                                <div className="turn-difference-row">
                                                                    <div className="turn-difference-column change-name">{ item.fixed_or_variable }</div>
                                                                    <div className="turn-difference-column fixed-column">{ item.term }</div>
                                                                    <div className="turn-difference-column fixed-column">{ formatPercentage(item.rate) }</div>
                                                                    <div className="turn-difference-column">{ formatCurrency(item.cap) }</div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            : <div className="no-changes">No changes</div>
                                        }
                                    </Col>
                                </Row>
                                : ""
                            }

                            <Row className="turn-difference-table-row">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="turn-difference-heading">Equity</div>
                                    { 
                                        comparison.equity.length > 0 ?
                                            <div className="turn-difference-table">
                                                <div className="turn-difference-row">
                                                <div className="turn-difference-column header change-name">Option</div>
                                                    <div className="turn-difference-column header fixed-column">Repurchase (000s)</div>
                                                    <div className="turn-difference-column header">Issue (000s)</div>
                                                </div>
                                                {
                                                    comparison.equity.map(function(item)
                                                    {
                                                        return (
                                                            <div className="turn-difference-row">
                                                                <div className="turn-difference-column change-name">{ item.option }</div>
                                                                <div className="turn-difference-column fixed-column">
                                                                {
                                                                    parseFloat(item.repurchase) !== 0 ?
                                                                        <div className="after">{ formatCurrency(item.repurchase) }</div>
                                                                    :
                                                                    formatCurrency(item.repurchase)
                                                                }
                                                                </div>
                                                                <div className="turn-difference-column">
                                                                {
                                                                    parseFloat(item.raised) !== 0 ?
                                                                        <div className="after">{ formatCurrency(item.raised) }</div>
                                                                    :
                                                                    formatCurrency(item.raised)
                                                                }
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        : <div className="no-changes">No changes</div>
                                    }
                                </Col>
                            </Row>

                            <Row className="turn-difference-table-row">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="turn-difference-heading">Business Development</div>
                                    { 
                                        comparison.busDev.length > 0 ?
                                            <div className="turn-difference-table">
                                                <div className="turn-difference-row">
                                                <div className="turn-difference-column header change-name">Type</div>
                                                    <div className="turn-difference-column header">Amount</div>
                                                </div>
                                                {
                                                    comparison.busDev.map(function(item)
                                                    {
                                                        return (
                                                            <div className="turn-difference-row">
                                                                <div className="turn-difference-column change-name">{ item.type }</div>
                                                                <div className="turn-difference-column">
                                                                    { fieldChange(formatCurrency(item.before_amount), formatCurrency(item.after_amount)) }
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        : <div className="no-changes">No changes</div>
                                    }
                                </Col>
                            </Row>

                            {
                                !lite ?
                                <Fragment>
                                    <Row className="turn-difference-table-row">
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <div className="turn-difference-heading">Facilities</div>
                                            { 
                                                comparison.facilities.length > 0 ?
                                                    <div className="turn-difference-table">
                                                        <div className="turn-difference-row">
                                                        <div className="turn-difference-column header change-name">Type</div>
                                                            <div className="turn-difference-column header">Amount</div>
                                                        </div>
                                                        {
                                                            comparison.facilities.map(function(item)
                                                            {
                                                                return (
                                                                    <div className="turn-difference-row">
                                                                        <div className="turn-difference-column change-name">{ item.type }</div>
                                                                        <div className="turn-difference-column">
                                                                            { fieldChange(formatCurrency(item.before_amount), formatCurrency(item.after_amount)) }
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                : <div className="no-changes">No changes</div>
                                            }
                                        </Col>
                                    </Row>

                                    <Row className="turn-difference-table-row">
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <div className="turn-difference-heading">IT</div>
                                            { 
                                                it.length > 0 ?
                                                    <div className="turn-difference-table">
                                                        <div className="turn-difference-row">
                                                        <div className="turn-difference-column header change-name">Type</div>
                                                            <div className="turn-difference-column header">Amount</div>
                                                        </div>
                                                        {
                                                            it.map(function(item)
                                                            {
                                                                return (
                                                                    <div className="turn-difference-row">
                                                                        <div className="turn-difference-column change-name">{ item.type }</div>
                                                                        <div className="turn-difference-column">
                                                                            { fieldChange(formatCurrency(item.before_amount), formatCurrency(item.after_amount)) }
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                : <div className="no-changes">No changes</div>
                                            }
                                        </Col>
                                    </Row>
                                </Fragment>
                                : ""
                            }
                                

                            <Row className="turn-difference-table-row">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="turn-difference-heading">Learning / Development</div>
                                    { 
                                        learning.length > 0 ?
                                            <div className="turn-difference-table">
                                                <div className="turn-difference-row">
                                                <div className="turn-difference-column header change-name">Type</div>
                                                    <div className="turn-difference-column header">Amount</div>
                                                </div>
                                                {
                                                    learning.map(function(item)
                                                    {
                                                        return (
                                                            <div className="turn-difference-row">
                                                                <div className="turn-difference-column change-name">{ item.type }</div>
                                                                <div className="turn-difference-column">
                                                                    { fieldChange(formatCurrency(item.before_amount), formatCurrency(item.after_amount)) }
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        : <div className="no-changes">No changes</div>
                                    }
                                </Col>
                            </Row>

                            <Row className="turn-difference-table-row">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="turn-difference-heading">Staffing</div>
                                    { 
                                        staffing.length > 0 ?
                                            <div className="turn-difference-table">
                                                <div className="turn-difference-row">
                                                <div className="turn-difference-column header change-name">Type</div>
                                                    <div className="turn-difference-column header">Amount</div>
                                                </div>
                                                {
                                                    staffing.map(function(item)
                                                    {
                                                        return (
                                                            <div className="turn-difference-row">
                                                                <div className="turn-difference-column change-name">{ item.type }</div>
                                                                <div className="turn-difference-column">
                                                                    { fieldChange(formatCurrency(item.before_amount), formatCurrency(item.after_amount)) }
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        : <div className="no-changes">No changes</div>
                                    }
                                </Col>
                            </Row>

                            <Row className="turn-difference-table-row">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <div className="turn-difference-heading">Miscellaneous</div>
                                    { 
                                        comparison.miscSection.length > 0 ?
                                            <div className="turn-difference-table">
                                                <div className="turn-difference-row">
                                                <div className="turn-difference-column header change-name">Name</div>
                                                    <div className="turn-difference-column header">Amount</div>
                                                </div>
                                                {
                                                    comparison.miscSection.map(function(item)
                                                    {
                                                        let value;

                                                        switch (item.name)
                                                        {
                                                            case "Dividends":
                                                                value = fieldChange(item.original_value != null ? formatCurrencyWithPennies(item.original_value) : "", formatCurrencyWithPennies(item.value));
                                                                break;

                                                                case "Loan Loss":
                                                                value = fieldChange(item.original_value != null ? formatCurrency(item.original_value * 1000) : "", formatCurrency(item.value * 1000));
                                                                break;

                                                            case "Mortgages":
                                                                value = fieldChange(item.original_value != null ? formatPercentage(item.original_value) : "", formatPercentage(item.value));
                                                                break;

                                                            case "Term Maturity":
                                                                value = fieldChange(item.original_value != null ? formatCurrency(item.original_value * 1000000) : "", formatCurrency(item.value * 1000000));
                                                                break;

                                                            case "Swaps":
                                                            default:
                                                                value = fieldChange(item.original_value != null ? item.original_value : "", item.value);
                                                                break;
                                                        }

                                                        return (
                                                            <div className="turn-difference-row">
                                                                <div className="turn-difference-column change-name">{ item.name }</div>
                                                                <div className="turn-difference-column">{ value }</div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        : <div className="no-changes">No changes</div>
                                    }
                                </Col>
                            </Row>

                            <div class="turn-changes-bottom-filler"></div>
                        </Container>
                    : ""
                }
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={ props.onClose }>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default TurnChangesModal;
