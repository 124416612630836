import { Fragment } from 'react';
import { withRouter, Prompt } from "react-router";
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import BaseDecisionGridView from "./BaseDecisionGridView";
import { COLUMNS, LITE_COLUMNS } from "./grid/LoanOriginationColumns";
import TurnSelect from "./components/TurnSelect";
import DecisionGrid from "./components/DecisionGrid";
import ScenarioName from "./components/ScenarioName";
import Utils from "../../../utils/Utils";

import DecisionBody from "./DecisionBody";
import gameService from "../../../services/GameService";
import intraturnService from "../../../services/IntraturnService";
import { parsePercentage } from '../../../controls/Percentage';
import SelectControl from '../../../controls/form/SelectControl';
import NumberControl from '../../../controls/form/NumberControl';
import PercentageInput from '../../../controls/PercentageInput';


/**
 * The LoanOriginationView class.
 */
class LoanOriginationView extends BaseDecisionGridView
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the loaded flag
        this.loaded = false;

        // Initialize the state
        this.state =
        {
            rows: [],
            dirtyRows: {},
            sortColumns: [],
            selectedLoanType: "Loans",
            selectedTurn: -1,
            basisPoints: 1,
            grid:
            {
                columns: 11,
                extraHeader:
                [
                    [ 
                        { start: 3,  span: 3, text: "Summary Rate for Credit Categories" }, 
                        { start: 6,  span: 6, text: "Rate Categories for Target Credit Categories" },
                        { start: 14, span: 2, text: "BP" } 
                    ]
                ]
            }
        };

        // Initialize the columns
        this.columns = [];
    }

    /**
     * This method loads the loan originations.
     *
     * @param turn The turn.
     * @param loanType The loan type.
     */
    loadLoanOriginations(turn, loanType)
    {
        // Get out if the state is loaded
        if (!this.props.game)
            return;

        // Only load once...
        if (this.loadingLoanOriginations)
            return;

        // Get out if nothing to do...
        if ((this.state.selectedTurn === turn) && (this.state.selectedLoanType === loanType))
            return;

        // Warn the user if there are changes...
        if (this.isDirty())
        {
            if (!window.confirm("You have unsaved changes.  Do you wish to proceed?"))
                return;
        }

        // Mark us as loading...
        this.loadingLoanOriginations = true;

        // Load the loan originations
        gameService.loadLoanOriginations(this.props.match.params.team_id, turn, loanType, this.onLoanOriginationsLoaded);
    }


    /**
     * The loan originations loaded event handler.
     *
     * @param {*} loanOriginations The loan originations.
     * @param {*} mortgagesPipelined The mortgages pipelined value.
     * @param {*} lastUpdated The last updated time.
     * @param {*} turn The turn.
     * @param {*} loanType The loan type.
     */
    onLoanOriginationsLoaded = (loanOriginations, mortgagesPipelined, lastUpdated, turn, loanType) =>
    {
        // Make sure we have something...
        if (!loanOriginations)
            loanOriginations = [];

        // Set the text
        for (let loanOrigination of loanOriginations)
        {
            loanOrigination.basis_point_up   = "+";
            loanOrigination.basis_point_down = "-";
        }

        // Initialize the state
        let state =
        { 
            rows: loanOriginations, 
            selectedTurn: turn, 
            selectedLoanType: loanType,
            mortgagesPipelined: mortgagesPipelined,
            originalMortgagesPipelined: mortgagesPipelined,
            lastUpdated: lastUpdated
        }

        // Set the intraturn Id if appropriate
        if (loanOriginations.length > 0)
            state.intraturnId = loanOriginations[0].intraturn_id;

        // Set the state
        this.setState(state);

        // Clear the dirty rows
        this.clearDirtyRows();

        // Reset the loading loan originations flag
        this.loadingLoanOriginations = false;

        // Set the loaded flag
        this.loaded = true;
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Call mom...
        let result = super.isComponentReady();

        // Load the loan originations if necessary...
        if (this.props.game && !this.loaded)
        {
            if (this.props.game.type === "Full")
                this.columns = COLUMNS;
            else
                this.columns = LITE_COLUMNS;

            // Load the loan originations
            this.loadLoanOriginations(this.props.game.turns_completed + 1, this.state.selectedLoanType);

            // Not ready...
            return false;
        }

        // Get out...
        return result;
    }

    /**
     * This method updates the state when the cell value has been modified.
     *
     * @param {*} params The params.
     */
    onCellValueChanged = (params) =>
    {
        // Get the dirty rows map...
        let dirtyRows = this.state.dirtyRows;

        // Adjust for the shortcuts...
        if (params.column)
        {
            if ((params.column.colId === "rate_1") || (params.column.colId === "rate_3") || (params.column.colId === "rate_6"))
            {
                var diff13 = (parseFloat(params.data.rate_3) - parseFloat(params.data.rate_1)) / 2;
                var diff36 = (parseFloat(params.data.rate_6) - parseFloat(params.data.rate_3)) / 3;

                params.data.target_1 = parseFloat(params.data.rate_1);
                params.data.target_2 = parseFloat(params.data.rate_1) + diff13;
                params.data.target_3 = parseFloat(params.data.rate_3);
                params.data.target_4 = parseFloat(params.data.rate_3) + diff36;
                params.data.target_5 = parseFloat(params.data.rate_3) + (diff36 * 2);
                params.data.target_6 = parseFloat(params.data.rate_6);
            }
        }
            
        // Initialize the delta
        let delta = 0;

        if (params.data.basis_point_up === "click")
        {
            // Reset the text
            params.data.basis_point_up = "+";

            // Determine the delta
            delta = parseInt(this.state.basisPoints) / 100.0;
        }
        else
        if (params.data.basis_point_down === "click")
        {
            // Reset the text
            params.data.basis_point_down = "-";

            // Determine the delta
            delta = -parseInt(this.state.basisPoints) / 100.0;
        }
        else
        {
            // Mark the row dirty...
            dirtyRows[params.data.loan_origin_id] = true;
        }

        // Only update if there was a change...
        if (delta !== 0)
        {
            // Upate the basis points
            this.updateBasisPoints(params.data, delta);

            // Mark the row dirty...
            dirtyRows[params.data.loan_origin_id] = true;
        }

        // Displays should most recent up to date values.
        this.api.refreshCells();
    
        // Save the state
        this.setState(
        {
            dirtyRows: dirtyRows
        });
    }


    /**
     * This method handles view saves.
     */
    onSave = () =>
    {
        // Get out if this is not actually dirty (shouldn't happen)
        if (!this.isDirty())
            return;

        // Validate the mortgages pipelined field...
        if (parsePercentage(this.state.mortgagesPipelined) > 100)
        {
            alert("The mortgages pipelined field cannot be more than 100%.");
            return;
        }

        // Handle the save...
        if (this.hasDirtyFields())
            this.updateDirtyFields(false);
        else
            this.updateDirtyRows(false);

        // Get out...
        return true;
    }


    /**
     * This method updates the dirty fields.
     *
     * @param {*} force The force flag.
     */
    updateDirtyFields(force)
    {
        // Update the mortgages pipelined
        intraturnService.updateMortgagesPipelined(this.state.intraturnId, parsePercentage(this.state.mortgagesPipelined), this.state.lastUpdated, force, this.onUpdateMortgagesPipelinedComplete);
    }


    /**
     * This method handles the update mortgages pipelined complete event.
     *
     * @param {*} modifiedBy The modified by user.
     * @param {*} lastUpdated The last updated time.
     * @param {*} mortgagesPipelined The mortgages pipelined.
     */
    onUpdateMortgagesPipelinedComplete = (modifiedBy, lastUpdated, mortgagesPipelined) =>
    {
        // See if it was modified since we loaded the data...
        if (modifiedBy)
        {
            // See if the user wants to force the matter...
            if (!window.confirm("Loan Origination records were modified by " + modifiedBy + " at " + new Date(lastUpdated).toLocaleTimeString() + ".\n\nWould you like save your changes anyway?"))
                return;

            // Force the update
            this.updateDirtyFields(true);

            // Get out...
            return;
        }

        // Set the state
        this.setState(
        {
            mortgagesPipelined: mortgagesPipelined, 
            originalMortgagesPipelined: mortgagesPipelined,
            lastUpdated: lastUpdated
        });

        // Show the alert if there are no dirty rows
        if (!this.updateDirtyRows(false))
            alert("Saved");
    }



    /**
     * This method updates the dirty rows.
     * 
     * @param {*} force The force flag.
     */
    updateDirtyRows(force)
    {
        // Initialize the updated rows
        let updatedRows = [];

        // Check each row...
        for (let row of this.state.rows)
        {
            // See if it is dirty...
            if (!this.state.dirtyRows[row.loan_origin_id])
                continue;

            // Converts rates
            row.rate_1 = parsePercentage(row.rate_1).toFixed(3);
            row.rate_3 = parsePercentage(row.rate_3).toFixed(3);
            row.rate_6 = parsePercentage(row.rate_6).toFixed(3);

            // Converts targets
            row.target_1 = parsePercentage(row.target_1).toFixed(3);
            row.target_2 = parsePercentage(row.target_2).toFixed(3);
            row.target_3 = parsePercentage(row.target_3).toFixed(3);
            row.target_4 = parsePercentage(row.target_4).toFixed(3);
            row.target_5 = parsePercentage(row.target_5).toFixed(3);
            row.target_6 = parsePercentage(row.target_6).toFixed(3);

            // Convert origination fee
            row.org_fee = parsePercentage(row.org_fee).toFixed(3);
            
            // Convert annual fee
            row.annual_fee =  Utils.parseCurrency(row.annual_fee);
            // create a new row
            let updatedRow = { ...row };

            // Push the updated row
            updatedRows.push(updatedRow);
        }

        // Get out if nothing to do...
        if (updatedRows.length === 0)
            return false;

        // Update the loan originations
        intraturnService.updateLoanOriginations(this.state.intraturnId, updatedRows, this.state.lastUpdated, force, this.onSaveComplete);

        // Get out...
        return true;
    }


    /**
     * The save complete event handler.
     *
     * @param {*} modifiedBy The modified by user.
     * @param {*} lastUpdated The last updated time.
     */
    onSaveComplete = ({ modifiedBy, lastUpdated }) =>
    {
        // See if it was modified since we loaded the data...
        if (modifiedBy)
        {
            // See if the user wants to force the matter...
            if (!window.confirm("Loan Adminstration records were modified by " + modifiedBy + " at " + new Date(lastUpdated).toLocaleTimeString() + ".\n\nWould you like save your changes anyway?"))
                return;

            // Force the update
            this.updateDirtyFields(true);

            // Get out...
            return;
        }

        // Set the last updated flag
        this.setState({ lastUpdated: lastUpdated });

        // Clear the dirty flag
        this.clearDirtyRows();

        // let the user know
        alert("Saved.");
    }


    /**
     * This method clears the dirty rows.
     */
    clearDirtyRows()
    {
        // Reset the dirty IDs
        this.setState(
        {
            dirtyRows: {}
        });
    }


    /**
     * This method handles turn changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onTurnChange = (name, value) =>
    {
        // Load the loan originations
        this.loadLoanOriginations(parseInt(value), this.state.selectedLoanType);
    }


    /**
     * This method handles loan type changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onLoanTypeChange = (name, value) =>
    {
        // Load the loan originations
        this.loadLoanOriginations(this.state.selectedTurn, value);
    }


    /**
     * This method handles field changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onFieldChange = (name, value) =>
    {
        // Get out if no change...
        if ((this.state[name] === value) || (!this.state[name] && !value))
            return;

        // Set the name/value pair...
        let state = {};
        state[name] = value;

        // Set the state
        this.setState(state);
    }


    /**
     * This method increments the basis points.
     *
     * @param {*} direction The direction.
     */
    incrementBasisPoints(direction)
    {
        // Create the delta
        let delta = direction * parseInt(this.state.basisPoints) / 100.0;

        // Get out if nothing to do...
        if (delta === 0)
            return;

        // Create the new rows
        let newRows = [ ...this.state.rows ];

        // Create the new dirty rows
        let dirtyRows = { };

        // Update each row
        for (let row of newRows)
        {
            // Update the basis points
            this.updateBasisPoints(row, delta);

            // Mark it as dirty
            dirtyRows[row.loan_origin_id] = true;
        }

        // Set the state
        this.setState({ rows: newRows, dirtyRows: dirtyRows });

        // Displays should most recent up to date values.
        this.api.refreshCells();
    }


    /**
     * This method updates the basis points for the row.
     *
     * @param {*} row The row.
     * @param {*} delta The delta.
     */
    updateBasisPoints(row, delta)
    {
        // Update the rates
        row.rate_1 = parsePercentage(row.rate_1) + delta;
        row.rate_3 = parsePercentage(row.rate_3) + delta;
        row.rate_6 = parsePercentage(row.rate_6) + delta;

        // Update the target
        row.target_1 = parsePercentage(row.target_1) + delta;
        row.target_2 = parsePercentage(row.target_2) + delta;
        row.target_3 = parsePercentage(row.target_3) + delta;
        row.target_4 = parsePercentage(row.target_4) + delta;
        row.target_5 = parsePercentage(row.target_5) + delta;
        row.target_6 = parsePercentage(row.target_6) + delta;
    }


    /**
     * This method determines if any fields are dirty.
     *
     * @returns true if any fields are dirty, else false.
     */
    hasDirtyFields()
    {
        // See if any fields are dirty...
        if (parsePercentage(this.state.originalMortgagesPipelined) !== parsePercentage(this.state.mortgagesPipelined))
            return true;
            
        return false;
    }


    /**
     * This method renders the view.
     */
    renderGridView()
    {
        // Initialize the editable flag
        let editable = !this.props.team.frozen && (this.state.selectedTurn === this.props.game.turns_completed + 1) ? true : false;
        
        // Initialize the loan types
        let loanTypes = [ { key: "All", value: "All" }, ...this.props.gameDetails.loan_origin_types.map(type => ( { key: type, value: type })) ];


        return (
            <Fragment>
                <Prompt when={ this.isDirty() } message="There are unsaved changes on this screen.  Are you sure you want to leave?" />

                <DecisionBody name="Loan Origination" noteType="Loan" game={ this.props.game } intraturnId={ this.state.intraturnId } onSave={ this.onSave } dirty={ this.isDirty() }>

                    <Row>
                        <Col xs={6} sm={6} md={4} lg={4}>
                            <TurnSelect name="turns" onChange={ this.onTurnChange } value= { this.state.selectedTurn } />
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4}>
                        {
                            true ?
                            <SelectControl items={ loanTypes } name="loanTypes"  onChange={ this.onLoanTypeChange } value= { this.state.selectedLoanType } />
                            : ""
                        }
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="right col-padding-right bold col-middle-align">
                            <ScenarioName intraturnId={ this.state.intraturnId } />
                        </Col>
                    </Row>

                    <div class="grid-body">
                        <DecisionGrid 
                            ref={ (dataGrid) => { this.dataGrid = dataGrid; }} 
                            turn={ this.state.selectedTurn }
                            columns={ this.columns } 
                            sortColumns={ this.state.sortColumns }
                            rowData={ this.state.rows } 
                            onCellValueChanged={ this.onCellValueChanged }
                            onSortColumnsChange={ this.onSortColumnsChange }
                            onGridReady = { (params) => { this.api = params.api }}
                        />
                    </div>

                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className="vertical-spacing">
                        {
                            this.props.game.type !== "Lite" ?
                                <Fragment>% 30 Year Mortgages Pipelined <PercentageInput name="mortgagesPipelined" value={ this.state.mortgagesPipelined } onChange={ (event) => { this.onFieldChange(event.target.name, event.target.value); } } min={0} style={{ width: "75px", display: "inline" }} className="auto-input" disabled={ !editable } /></Fragment>
                            : ""
                        }
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className="vertical-spacing right">
                            <Button variant="primary" onClick={ () => this.incrementBasisPoints(-1) } disabled={ !editable }>- BP</Button>
                            <NumberControl name="basisPoints" value={ this.state.basisPoints } min={1} onChange={ this.onFieldChange } style={{ width: "75px", display: "inline" }} className="auto-input form-control" disabled={ !editable } />
                            <Button variant="primary" onClick={ () => this.incrementBasisPoints(1) } disabled={ !editable }>+ BP</Button>
                        </Col>
                    </Row>
                </DecisionBody>
            </Fragment>
        );
    }
}

// Export the decisions view...
export default withRouter(connect(BaseDecisionGridView.mapStateToProps)(LoanOriginationView));