import React from "react";
import Draggable from 'react-draggable';
import ModalDialog from 'react-bootstrap/ModalDialog';

/**
 * The DraggableModalDialog component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
class DraggableModalDialog extends React.Component
{
    render()
    {
        return <Draggable handle=".modal-title"><ModalDialog {...this.props} /></Draggable>
    }
}

// Export the draggable modal dialog...
export default DraggableModalDialog;