import React  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf  } from "@fortawesome/free-solid-svg-icons";
import gameService from '../../../../services/GameService';
import Utils from '../../../../utils/Utils';


/**
 * The download turn report link class.
 */
class DownloadTurnReportsLink extends React.Component
{
    /**
     * The download reports click event handler.
     */
    onClickDownloadReports = () =>
    {
        gameService.downloadTurnReports(this.props.gameId, this.props.teamId, this.props.turnNum, this.onReportsDownloaded);
    }


    /**
     * The report downloaded event handler.
     *
     * @param {*} data The data.
     */
    onReportsDownloaded = (response) =>
    {
        Utils.download(response.fileName, response.data);
    }


    /**
     * This method renders the component.
     */
    render()
    {
        return (
            <span class="fa-buttons" style={{color:"orange"}} onClick={ this.onClickDownloadReports }><FontAwesomeIcon icon = {faFilePdf} /></span>
        );
    }
}

export default DownloadTurnReportsLink;