import { withRouter } from "react-router";
import { connect } from 'react-redux';

import gameService from '../../services/GameService';
import BaseDashboardView from './BaseDashboardView';


/**
 * The KPI types.
 */
const KPI_TYPES = ["Income Statement", "Balance Sheet", "Ratios"];



/**
 * The TeamDashboardView class.
 */
class TeamDashboardView extends BaseDashboardView
{
    /**
     * The object constructor
     */
    constructor(props)
    {
        // Call mom
        super(props, "Dashboard", KPI_TYPES);

        // Initialize the loaded flag
        this.loaded= false;

        // Get the dashboard details
        if (props.game && props.team)
        {
            // Get the dashboard details
            gameService.getDashboardDetails(props.game.game_id, props.team.team_id);

            // Set the requested flag
            this.state.requested = true;
        }
    }


    /**
    * This method maps the state to the properties.
    * 
    * @param {*} state The state.
    * @param {*} ownProps The own properties.
    * @returns The mapping.
    */
    static mapStateToProps(state, ownProps)
    {
        // Get the dashboard details
        let dashboardDetails = state.games.dashboardDetailsMap[ownProps.match.params.team_id];

        // Call the base class...
        return BaseDashboardView.mapStateToProps(state, ownProps, dashboardDetails);
    }


    /**
     * This method handles component updates.
     *
     * @param {*} prevProps The previous properties.
     */
    componentDidUpdate(prevProps)
    {
        // Get out if there is nothing to do...
        if (!this.props.game || !this.props.team)
            return;

        // Get the previous team ID
        let previousTeamId = prevProps.team ? prevProps.team.team_id : null;

        // See if the team ID changed...
        if (!this.state.requested || (this.props.team.team_id !== previousTeamId))
        {
            // Get the dashboard details
            gameService.getDashboardDetails(this.props.game.game_id, this.props.team.team_id);

            // Update the state
            this.setState({ requested: true });
        }
    }
}

// Export the game view...
export default withRouter(connect(TeamDashboardView.mapStateToProps)(TeamDashboardView));