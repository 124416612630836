import ReactDOM from 'react-dom';
import { Fragment } from 'react';
import $ from "jquery";

import BaseDecisionView from "./BaseDecisionView";
import ErrorBox from "../../../components/ErrorBox";
import Utils from "../../../utils/Utils"


/**
 * The grid header template.
 */
const GRID_HEADER_START_TEMPLATE = '<div data-id="extra-header-row" role="row" aria-rowindex="1" tabindex="-1" class="rdg-header-row h197vzie700-beta7">';

/**
 * The grid header row template.
 */
const GRID_HEADER_ROW_TEMPLATE = '<div role="columnheader" aria-colindex="{0}" aria-selected="false" tabindex="0" class="rdg-cell c1wupbe700-beta7 rdg-cell-resizable celq7o9700-beta7" style="grid-column-start: {0}; grid-column-end: {1}"><span tabindex="-1" class="rdg-header-sort-cell h13yq3r8700-beta7"><span class="ht6rdyl700-beta7" style="text-align:center">{2}</span><span></span></span></div>';

/**
 * The grid header end template.
 */
const GRID_HEADER_END_TEMPLATE = "</div>";



/**
 * The BaseDecisionGridView class.
 */
class BaseDecisionGridView extends BaseDecisionView
{
    /**
    * This method maps the state to the properties.
    * 
    * @param {*} state The state.
    * @param {*} ownProps The own properties.
    * @returns The mapping.
    */
    static mapStateToProps(state, ownProps)
    {
        // Call mom...
        return BaseDecisionView.mapStateToProps(state, ownProps);
    }


    /**
     * This method determines if the state is dirty.
     *
     * @returns true if the state is dirty, else false.
     */
    isDirty()
    {
        // Are we dirty?
        if (this.hasDirtyFields() || (Object.keys(this.state.dirtyRows).length > 0))
            return true;

        return false;
    }


    /**
     * This method determines if any fields are dirty.
     *
     * @returns true if any fields are dirty, else false.
     */
    hasDirtyFields()
    {
        return false;
    }


    /**
     * This method handles sort column changes.
     *
     * @param {*} sortColumns The sorted columns.
     */
    onSortColumnsChange = (sortColumns) =>
    {
        // Create the adjusted sort columns array
        let adjustedSortColumns = [];

        // Only add columns that can be sorted
        for (let sortColumn of sortColumns)
        {
            if (this.columns.find(column => column.key === sortColumn.columnKey).comparator)
                adjustedSortColumns.push(sortColumn);
        }

        // Sort the rows
        let rows = this.sortRows(adjustedSortColumns);

        // Update the state
        this.setState(
        {
            rows: rows,
            sortColumns: adjustedSortColumns
        })
    }


    /**
     * This method sorts the rows.
     *
     * @param {*} sortColumns The sort columns.
     * @returns The sorted rows.
     */
    sortRows(sortColumns)
    {
        return this.sortRowData(this.state.rows, this.columns, sortColumns);
    }


    /**
     * This method sorts the row data.
     *
     * @param {*} rows The rows.
     * @param {*} columns The columns.
     * @param {*} sortColumns The sort columns.
     * @returns The sorted rows.
     */
    sortRowData(rows, columns, sortColumns)
    {
        // Get out if nothing ot do
        if (sortColumns.length === 0) 
            return rows;

        // Clone the rows
        const sortedRows = [...rows];

        // Sort the rows
        sortedRows.sort((row1, row2) => 
        {
            for (const sort of sortColumns) 
            {
                // Get the column
                let column = columns.find((element) => element.key === sort.columnKey);

                // Skip if the column wasn't found (shouldn't happen)
                if (!column)
                    continue;

                // Skip if there is no comparator for the column...
                if (!column.comparator)
                    continue;

                // Compare the columns
                const compResult = column.comparator(row1[column.key], row2[column.key]);
                if (compResult !== 0)
                    return sort.direction === 'ASC' ? compResult : -compResult;
            }

            // They're equal...
            return 0;
        });

        return sortedRows;
    }


    /**
     * The component did update event handler.
     *
     * @param {*} prevProps The previous properties.
     */
    componentDidUpdate(prevProps)
    {
        // Call mom...
        super.componentDidUpdate(prevProps);

        // Get out if there are no header rows...
        if (!this.state.grid || true || !this.state.grid.extraHeader)
            return;

        // Get our DOM node
        let domNode = $(ReactDOM.findDOMNode(this));

        // Get the rows
        let rows = domNode.find(".rdg-row");

        // Update their positions
        for (let counter = 0; counter < rows.length; counter++)
        {
            // Get the row
            let row = $(ReactDOM.findDOMNode(rows[counter]));

            // Skip the summary row...
            if (row.hasClass("rdg-summary-row"))
                continue;

            // Get the current top position
            let top = 35 * (counter + 2);
            
            // Move it down...
            $(row).css("top", top + "px");
        }

        // See if the header row exists yet...
        let headerRows = domNode.find("[data-id='extra-header-row']");

        // Don't add it if they is already in the DOM
        if (headerRows.length)
            return;

        // Find the grid
        let grid = domNode.find("[role='grid']");

        // Get out if there is no grid...
        if (!grid.length)
            return;

        // Initialize the header HTML
        let headerHtml = "";

        // Process each row
        for (let row of this.state.grid.extraHeader)
        {
            // Start the row
            headerHtml += GRID_HEADER_START_TEMPLATE;

            // Initialize the last column
            let lastColumn = 1;

            // Process each column
            for (let column of row)
            {
                // Add a buffer column if necessary...
                if (column.start > lastColumn)
                    headerHtml += Utils.formatText(GRID_HEADER_ROW_TEMPLATE, lastColumn, column.start, "");

                // Add the HTML
                headerHtml += Utils.formatText(GRID_HEADER_ROW_TEMPLATE, column.start, column.start + column.span, column.text);

                // Move the last column
                lastColumn = column.start + column.span;
            }

            // Add the end column if necessary...
            if (lastColumn < this.state.grid.columns)
                    headerHtml += Utils.formatText(GRID_HEADER_ROW_TEMPLATE, lastColumn, this.state.grid.columns + 1, "");

            // End the template
            headerHtml += GRID_HEADER_END_TEMPLATE;
        }

        // Add the extra header row
        grid.prepend(headerHtml);
    }


    /**
     * This method renders the view.
     *
     * @returns The view.
     */
    renderView()
    {
        // Get the game
        let team = this.props.team;
        if (team == null)
        {
            return (
                <ErrorBox>The team was not found.</ErrorBox>
            );
        }

        // Render the grid view...
        return this.renderGridView();
    }


    /**
     * This method renders the grid view.
     *
     * @returns The grid view.
     */
    renderGridView()
    {
        return <Fragment />; 
    }
}

// Export the base decision view...
export default BaseDecisionGridView;