import React, { Component } from "react";
import { dateFormatter } from "../../../controls/grid/GridFormatters";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from "@fortawesome/free-solid-svg-icons";


/** 
 * The team notes columns.
 */
const COLUMNS =
[
    {
        field: 'text',
        headerName: '',
        maxWidth: 50,
        resizable: false,
        headerCheckboxSelection: true,
        colSpan: params => params.data.type === 'secondary' ? 5 : 1,
        cellClass: params => { return params.data.type },
        checkboxSelection: (props) => (props.data.type === 'primary') ? true : false,
        cellRendererSelector: (props) => 
        {
            if (props.data.type === 'secondary') {
                return {
                    component: TeamNoteRenderer,
                };
            }
        },
    },

    {
        field: "turn_name",
        headerName: "Turn Name",
        minWidth: 150,
    },

    {
        field: "scenario_name",
        headerName: "Scenario Name",
        minWidth: 150,
    },

    {
        field: "note_section",
        headerName: "Note Type",
        minWidth: 150,
    },

    {
        field: "updated_tzts",
        headerName: "Last Modified",
        minWidth: 120,
        gridRenderer: dateFormatter,
    }
];


class TeamNoteRenderer extends Component
{
    /**
     * The clicked handler.
     */
    clickedHandler = () => 
    {
        // Grab the column ID
        let colId = this.props.column.colId;

        // Grab the text
        let value = this.props.data.text;

        // Trigger the 'clicked'
        this.props.node.setDataValue(colId, 'click');

        // Reset the value to the tex
        this.props.node.setDataValue(colId, value);
    }

    /**
     * The render method.
     *
     * @returns The rendered component.
     */
    render() 
    {
        return (
            <div className="grid-text">
                <div className="fa-buttons float-right">
                    <FontAwesomeIcon icon = { faEdit } onClick={ this.clickedHandler } />
                </div>
                <div className="grid-text-holder" data-note-id={ this.props.data.note_id } dangerouslySetInnerHTML={ {__html: this.props.data.text }}></div>
            </div>
        )
    }

}


export default COLUMNS