import { Fragment } from 'react';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react'

import BaseDecisionGridView from "./BaseDecisionGridView";
import { HOUSEKEEPING_COLUMNS } from "./grid/HousekeepingColumns";
import DecisionGrid from "./components/DecisionGrid";
import InputControl from "../../../controls/form/InputControl";

import GeneralAdminTemplate from "./GeneralAdminTemplate";
import gameService from "../../../services/GameService";

/**
 * The HousekeepingView class.
 */
class HousekeepingView extends BaseDecisionGridView
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the loaded flag
        this.loaded = false;

        // Initialize the state
        this.state =
        {
            teamName: null,
            housekeepingRows: [],
            dirtyRows: {},
            sortColumns: [],
            selectedTurn: -1
        };
    }

    /**
     * The component did update event handler.
     */
    componentDidUpdate(prevProps)
    {
        // Call mom...
        super.componentDidUpdate(prevProps);

        // Get out if nothing...
        if (!this.props.team || (this.state.teamName !== null))
            return;
            
        // Set the team name
        this.setState(
        { 
            teamName: this.props.team.team_name 
        });
    }

    /**
     * This method determines if the state is dirty.
     *
     * @returns true if the state is dirty, else false.
     */
    isDirty()
    {
        // Are we dirty?
        if (super.isDirty() || (this.state.teamName !== this.props.team.team_name))
            return true;

        return false;
    }

    /**
     * This method loads the team member roles.
     *
     * @param turn The turn.
     */
    loadHouseKeeping(turn)
    {
        // Get out if the state is loaded
        if (!this.props.game)
            return;

        // Only load once...
        if (this.loadingHouseKeeping)
            return;

        // Get out if nothing to do...
        if (this.state.selectedTurn === turn)
            return;

        // Warn the user if there are changes...
        if (super.isDirty())
        {
            if (!window.confirm("You have unsaved changes.  Do you wish to proceed?"))
                return;
        }

        // Mark us as loading...
        this.loadingHouseKeeping = true;

        // Load the team roles
        gameService.loadHouseKeeping(this.props.match.params.team_id, turn, this.onHouseKeepingLoaded);
    }

    
    /**
     * The house keeping loaded event handler.
     *
     * @param {*} teamRoles The team roles.
     * @param {*} intraturnId The intraturn ID.
     * @param {*} lastUpdated THe last updated value.
     * @param {*} turn The turn.
     */
    onHouseKeepingLoaded = (teamRoles, intraturnId, turn) =>
    {
        // Make sure we have something...
        if (!teamRoles)
            teamRoles = [];

        // Initialize the state
        let state =
        { 
            housekeepingRows: teamRoles,
            selectedTurn: turn,
            intraturnId: intraturnId
        }

        // Set the state
        this.setState(state);

        // Clear the dirty rows
        this.clearDirtyRows();

        // Reset the loading housekeeping flag
        this.loadingHouseKeeping = false;

        // Set the loaded flag
        this.loaded = true;
    }

    /**
     * This method handles view saves.
     */
    onSave = () =>
    {
        // Get out if this is not actually dirty (shouldn't happen)
        if (!this.isDirty())
            return;

        // Update the dirty rows
        this.updateDirtyRows(false);
    }
     

    /**
     * The save complete event handler.
     */
    onSaveComplete = () =>
    {
        // Clear the dirty flag
        this.clearDirtyRows();

        // let the user know
        alert("Saved.");
    }

    /**
     * This method updates the state when the cell value has been modified.
     *
     * @param {*} params The params.
     */
    onCellValueChanged = (params) =>
    {
        // Get the dirty rows map...
        let dirtyRows = this.state.dirtyRows;

        // Mark it dirty
        dirtyRows[params.data.user_id] = true;

        // Save the state
        this.setState(
        {
            dirtyRows: dirtyRows
        });
    }


    /**
     * This method updates the dirty rows.
     *
     * @param {*} force The force flag.
     */
    updateDirtyRows(force)
    {
        // Initialize the updated rows
        let updatedRows = [];

        // Check each row...
        for (let row of this.state.housekeepingRows)
        {
            // See if it is dirty...
            if (!this.state.dirtyRows[row.user_id])
                continue;

            // Create the updated row
            let updatedRow = 
            {
                user_id: row.user_id,
                roles: row.roles
            };

            // Push the updated row
            updatedRows.push(updatedRow);
        }

        // Update the roles
        gameService.updateHousekeeping(this.props.game.game_id, parseInt(this.props.match.params.team_id), this.state.teamName, updatedRows, this.onSaveComplete );
        
        // Get out...
        return true;
    }


    /**
     * This method clears the dirty rows.
     */
    clearDirtyRows()
    {
        // Reset the dirty IDs
        this.setState(
        {
            dirtyRows: {}
        });
    }

    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
     isComponentReady()
     {
        // Call mom...
        let result = super.isComponentReady();

        // Load the facilities if necessary...
        if (this.props.game && !this.loaded)
        {
            // Load the housekeeping
            this.loadHouseKeeping(this.props.game.turns_completed + 1);

            // Not ready...
            return false;
        }

        // Get out...
        return result;
     }

    /**
     * This method renders the view.
     */
    renderView()
    {          
        return (
            <Fragment>
                <GeneralAdminTemplate name="Housekeeping" game={ this.props.game } intraturnId={ this.state.intraturnId } onSave={ this.onSave } dirty={ this.isDirty() }>
                    <Row>
                        <Col xs={12} sm={12} md={{ offset: 1, span: 11 }} lg={{ offset: 1, span: 11 }}>
                            Enter&nbsp;Bank&nbsp;Name:&nbsp;<InputControl name="teamName"  disabled={ this.props.team.frozen } onChange={this.onFieldChange} value ={this.state.teamName} min={0} style={{ width: "40vh", display: "inline" }} className="auto-input form-control"  />
                        </Col>
                    </Row>

                    <div class="grid-body">
                        <DecisionGrid 
                            columns={ HOUSEKEEPING_COLUMNS } 
                            turn={ this.state.selectedTurn }
                            sortColumns={ this.state.sortColumns }
                            rowData={ this.state.housekeepingRows } 
                            onCellValueChanged={ this.onCellValueChanged } 
                            onSortColumnsChange={ this.onSortColumnsChange }
                        />
                    </div>
                </GeneralAdminTemplate>
            </Fragment>
        );
    }
}

// Export the decisions view...
export default withRouter(connect(BaseDecisionGridView.mapStateToProps)(HousekeepingView));