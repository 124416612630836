import React from 'react';

import InputControl from "./InputControl";

/**
 * The NumberControl component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
class NumberControl extends React.Component
{
    /**
     * Object Constructor.
     * 
     * @param {*} props The properties.
     */
    constructor(props)
    {
        super(props);

        this.state = { value: null };
    }

    /**
     * The change event handler.
     * 
     * @param {*} name The name.
     * @param {*} value The value
     */
    onChange = (name, value) =>
    {
        // Set our own state
        this.setState({ value: value });

        // Handle the change event
        this.props.onChange(name, value);
    }


    /**
     * This method handles component property updates.
     *
     * @param {*} prevProps The previous properties.
     */
    componentDidUpdate(prevProps)
    {
        if (this.state.value !== this.props.value)
            this.setState({ value: this.props.value });
    }


    /**
     * The render method.
     * 
     * @returns The object model.
     */
    render()
    {
        let value = (this.state.value !== null) && (this.state.value !== undefined) ? this.state.value : this.props.value;

        let newProps = { ...this.props };
        delete newProps.onChange;
        delete newProps.value;

        // Get out...
        return (
            <InputControl type="number" { ...this.props } number="int" onChange={ this.onChange } value={ value } />
        );
    }
}

// Get out...
export default NumberControl;



