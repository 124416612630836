/**
 * The ErrorBox component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
const ErrorBox = (props) =>
{
    // Get out...
    return (
        <div class="error-summary">
            <div className="error-summary-title">{ props.title ? props.title : "Error" }</div>
            <div className="error-message" >{ props.children }</div>
        </div>
    );
}

// Get out...
export default ErrorBox;
