import { withRouter } from "react-router";
import { connect } from 'react-redux';

import BaseView from "../BaseView";
import FormControl from "../../controls/form/FormControl";
import InputControl from "../../controls/form/InputControl";
import CheckBoxControl from "../../controls/form/CheckBoxControl";
import NumberControl from "../../controls/form/NumberControl";
import SelectControl from "../../controls/form/SelectControl";
import TimeControl from "../../controls/form/TimeControl";
import { ValidationSummary, Validator } from "../../components/ValidationSummary";

import gameService from "../../services/GameService";
import { Fragment } from "react";

/**
 * The GameDetailsView class.
 */
class GameDetailsView extends BaseView
{
    /**
     * Object Constructor.
     * 
     * @param {*} props The properties.
     */
    constructor(props)
    {
        super(props);

        // Initialize the state
        this.state =
        {
            validation: Validator.createSuccess(),
            game:
            {
                game_name: "",
                force_roles: false,
                foresight_community_name: "",
                force_visits: false,
                manda_enabled: false,
                abbreviation: "",
                interest_rate_code: null,
                disable_view_reports: false,
                number_of_teams: 6,
                disable_whatifs: false,
                turns_allowed: 9,
                disable_scenarios: false,
                max_what_ifs_per_turn: 99,
                disable_outside_turnin: false,
                template_details_id: null,
                type: "Full",
                lockdown_time: ""
            }
        }

        // Load the game details if necessary
        if (this.props.match.params.game_id)
            gameService.loadGames();
    }


    /**
     * This method maps the state to the properties.
     * 
     * @param {*} state The state.
     * @param {*} ownProps The own properties.
     * @returns The mapping.
     */
    static mapStateToProps(state, ownProps)
    {
        let result =
        {
            game: state.games.gameMap[ownProps.match.params.game_id],
        };
    
        return result;
    }


    /**
     * The component did update event handler.
     *
     * @param {*} prevProps The previous properties.
     */
    componentDidUpdate(prevProps)
    {
        // Get out if nothing to do...
        if (this.state.game.game_id)
            return;

        if (!this.props.game)
            return;

        // Get the game
        let game = { ...this.props.game };
        if (game)
            this.setState({ game: game });
    }


    /**
     * The submit event handler.
     * 
     * @param {*} event The event.
     */
    onSubmit = (event) =>
    {
        // Validate the form
        let validation = Validator.validate(this.state.game, this.props.match.params.game_id ? "updateGame" : "createGame");
        if (!validation.result)
        {
            // Set the state
            this.setState({ validation: validation});

            // Get out...
            return;
        }

        // Make the ajax call...
        if (this.props.match.params.game_id)
            gameService.updateGame(this.state.game, this.onComplete);
        else
            gameService.createGame(this.state.game, this.onComplete);
    }

    /**
     * The complete event handler.
     * 
     * @param {*} response The response.
     */
     onComplete = (response) =>
    {
        // Navigate to the beginning...
        this.props.history.push("/");
    }



    /**
     * This method handles form changes.
     * @param {*} event The event.
     */
    onFieldChange = (name, value) =>
    {
        let game = { ...this.state.game };
        game[name] = value;
 
        this.setState({ game : game });
    }


    /**
     * This method renders the component.
     */
    render()
    {
        return (
            <FormControl name={ this.state.game.game_id ? "Update Game" : "Create Game" } >
                <div className="row">
                    <div className="left offset-sm-2 offset-md-2 offset-lg-2 col-sm-8 col-md-8 col-lg-8"><ValidationSummary validation={ this.state.validation } /></div>
                </div>

                <div className="row">
                    <div className="col-heading col-sm-4 col-md-4 col-lg-4"><label for="game_name" className="form-label">Game Name</label></div>
                    <div className="col-sm-4 col-md-4 col-lg-4"><InputControl name="game_name" value={ this.state.game.game_name } onChange={ this.onFieldChange } /></div>
                    <div className="left col-sm-4 col-md-4 col-lg-4"><CheckBoxControl name="force_roles" onChange={ this.onFieldChange } checked={ this.state.game.force_roles ? "checked" : "" } /> <label for="force_roles" className="form-check-label">Force Roles</label></div>
                </div>

                <div className="row">
                    <div className="col-heading col-sm-4 col-md-4 col-lg-4"><label for="interest_rate_code" className="form-label">Interest Rate Environment</label></div>
                    <div className="col-sm-4 col-md-4 col-lg-4"><SelectControl name="interest_rate_code" onChange={ this.onFieldChange } value={ this.state.game.interest_rate_code } url="/select/interest-rate-environments" /></div>
                    <div className="left col-sm-4 col-md-4 col-lg-4"><CheckBoxControl name="force_visits" onChange={ this.onFieldChange } checked={ this.state.game.force_visits ? "checked" : "" } /> <label for="force_visits" className="form-check-label">Force Visits</label></div>
                </div>

                <div className="row">
                    <div className="col-heading col-sm-4 col-md-4 col-lg-4"><label for="number_of_teams" className="form-label">Number of Teams</label></div>
                    <div className="col-sm-4 col-md-4 col-lg-4"><NumberControl name="number_of_teams" onChange={ this.onFieldChange } value={ this.state.game.number_of_teams } min="2" max="10" /></div>
                    <div className="left col-sm-4 col-md-4 col-lg-4"><CheckBoxControl name="manda_enabled" onChange={ this.onFieldChange } checked={ this.state.game.manda_enabled ? "checked" : "" } /> <label for="manda_enabled" className="form-check-label">M&A Enabled</label></div>
                </div>

                <div className="row">
                    <div className="col-heading col-sm-4 col-md-4 col-lg-4"><label for="turns_allowed" className="form-label">Number of Turns</label></div>
                    <div className="col-sm-4 col-md-4 col-lg-4"><NumberControl name="turns_allowed" onChange={ this.onFieldChange } value={ this.state.game.turns_allowed } min="2" max="15" /></div>
                </div>

                <div className="row">
                    <div className="col-heading col-sm-4 col-md-4 col-lg-4"><label for="max_what_ifs_per_turn" className="form-label">Number of Forecasts</label></div>
                    <div className="col-sm-4 col-md-4 col-lg-4"><NumberControl name="max_what_ifs_per_turn" onChange={ this.onFieldChange } value={ this.state.game.max_what_ifs_per_turn } min="1" /></div>
                </div>

                <div className="row">
                    <div className="col-heading col-sm-4 col-md-4 col-lg-4"><label for="lockdown_time" className="form-label">Lockdown Time (EST / Military Time)</label></div>
                    <div className="col-sm-4 col-md-4 col-lg-4"><TimeControl name="lockdown_time" onChange={ this.onFieldChange } value={ this.state.game.lockdown_time } /></div>
                </div>

                {
                    !this.state.game.game_id ?
                        <Fragment>
                            <div className="row">
                                <div className="col-heading col-sm-4 col-md-4 col-lg-4"><label for="template_details_id" className="form-label">Template</label></div>
                                <div className="col-sm-4 col-md-4 col-lg-4"><SelectControl name="template_details_id" onChange={ this.onFieldChange }  value={ this.state.game.template_details_id } url="/select/templates" required="true" /></div>
                            </div>
                            <div className="row">
                                <div className="col-heading col-sm-4 col-md-4 col-lg-4"><label for="type" className="form-label">Type</label></div>
                                <div className="col-sm-4 col-md-4 col-lg-4"><SelectControl name="type" onChange={ this.onFieldChange } value={ this.state.game.type } items={ [ { value: "Full" }, { value: "Lite"  } ] } /></div>
                            </div>
                        </Fragment>

                    : ""
                }

                <div className="row">
                    <div className="left offset-sm-4 offset-md-4 offset-lg-4 col-sm-4 col-md-4 col-lg-4"><button className="btn btn-primary" onClick={ (event) => this.onSubmit(event) } >{ this.props.match.params.game_id ? "Update Game" : "Create Game" }</button></div>
                </div>
            </FormControl>
        );
    }
}

export default withRouter(connect(GameDetailsView.mapStateToProps)(GameDetailsView));