import React, { Fragment, useState } from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { faBuildingColumns, faCodeCompare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountDownTimer from '../components/CountDownTimer';
import TurnChangesModal from './TurnChangesModal';


/**
  * This method maps the state to the properties.
  * 
  * @param {*} state The state.
  * @param {*} ownProps The own properties.
  * @returns The mapping.
  */
function mapStateToProps(state, ownProps)
{
    let result =
    {
        team: state.games.teamMap[ownProps.match.params.team_id]
    };

    return result;
}



/**
 * The DecisionBody component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
const DecisionBody = (props) =>
{
    // Create the state methods
    const [ showTurnCompare, setShowTurnCompare ] = useState(false);

    let teamName = props.team ? props.team.team_name + " - " : "";
    let teamId   = props.team ? props.team.team_id : 0;
    let noteType = props.noteType ? "/" + props.noteType : "";

    // Get out...
    return (
        <Fragment>
            <TurnChangesModal visible={ showTurnCompare } onClose={ () => { setShowTurnCompare(false); } } intraturnId={ props.intraturnId } gameType={ props.game.type }/>

            <div className="container left">
                <CountDownTimer />
                <div className={ "decision-container" + (props.className ? " " + props.className : "") }>
                    <div className="fisim-header">
                        <div className="fisim-header-left">{ teamName + props.name }</div>
                        <div className="fisim-header-right">
                            {
                                !props.hideSaveAndHome ?
                                (
                                    <Fragment>
                                        <div className="decision-header-icon">
                                            {
                                                !props.team.frozen && props.dirty ?
                                                    <span onClick={ props.onSave }><i title="Save" className="decision-icon-color fa fa-floppy-o" ></i></span>
                                                    :
                                                    <span><i title="Save" className="disabled decision-icon-color fa fa-floppy-o" ></i></span>
                                            }
                                        </div>
                                    </Fragment>
                                )
                                : ""
                            }

                            {
                                teamId ?
                                <div className="decision-header-icon">
                                    <div className="decision-header-icon decision-header-icon-size">
                                        <FontAwesomeIcon className="decision-icon-color-small" title="Compare" icon={ faCodeCompare } onClick={ () => setShowTurnCompare(!showTurnCompare) } />
                                    </div>
                                </div> : ""
                            }

                            {
                                !props.hideNotes ?
                                (
                                    <Fragment>
                                        <div className={ "decision-header-icon" + (props.hideSaveAndHome ? " decision-header-icon-size" : "") } >
                                            <Link to={ "/game/" + props.match.params.game_id + "/team-notes/" + teamId + noteType }>
                                                <img className="decision-icon" src={ process.env.PUBLIC_URL + "/images/team-notes.png" } alt="Team Notes" title="Team Notes" />
                                            </Link>
                                        </div>
                                    </Fragment>
                                )
                                : ""
                            }
                            {
                                !props.hideSaveAndHome ?
                                (
                                    <Fragment>
                                        <div className="decision-header-link decision-header-icon">
                                            <Link to={ "/game/" + props.match.params.game_id + "/decisions/" + props.match.params.team_id }>
                                                <FontAwesomeIcon className="decision-icon-color-small" title="Decisions" icon={ faBuildingColumns } />
                                            </Link>
                                        </div>
                                    </Fragment>
                                ) : ""
                            }
                            <div className="decision-header-icon">
                                <Link to={ "/game/" + props.match.params.game_id + "/team/" + props.match.params.team_id }>
                                    <i title="Home" className="decision-icon-color fa fa-home"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="decision-body">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="decision-inner-body">
                                    { props.children }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

// Get out...
export default withRouter(connect(mapStateToProps)(DecisionBody));


