import { Fragment } from 'react';
import { withRouter, Prompt } from "react-router";
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import BaseDecisionGridView from "./BaseDecisionGridView";
import { POTENTIAL_EXPENSE_COLUMNS, INCURRED_EXPENSE_COLUMNS } from "./grid/GenAdminColumns";
import TurnSelect from "./components/TurnSelect";
import DecisionGrid from "./components/DecisionGrid";

import gameService from "../../../services/GameService";
import intraturnService from "../../../services/IntraturnService";
import Utils from '../../../utils/Utils';
import BDMViewTemplate from './BDMViewTemplate';


/**
 * The BDMGenAdinView class.
 */
class BDMGenAdminView extends BaseDecisionGridView
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the loaded flag
        this.loaded = false;

        // Initialize the state
        this.state =
        {
            rows: [],
            incurredExpenseRows: [],
            dirtyRows: {},
            sortColumns: [],
            incurredExpenseSortColumns: [],
            selectedTurn: -1
        };

        // Set the columns
        this.columns = POTENTIAL_EXPENSE_COLUMNS;
    }


    /**
     * The component did updated event handler.
     *
     * @param {*} prevProps The previous props.
     */
    componentDidUpdate(prevProps)
    {
        // Call mom...
        super.componentDidUpdate(prevProps);

        // Check the account...
        if (this.props.account !== prevProps.account)
            this.loadGeneralAdministration(this.state.selectedTurn);
    }


    /**
     * This method loads the general administration.
     *
     * @param turn The turn.
     */
    loadGeneralAdministration(turn)
    {
        // Get out if the state is loaded
        if (!this.props.game)
            return;

        // Only load once...
        if (this.loadingGeneralAdministration)
            return;

        // Get out if nothing to do...
        if ((this.state.selectedTurn === turn) && (this.state.account === this.props.account))
            return;

        // Warn the user if there are changes...
        if (this.isDirty())
        {
            if (!window.confirm("You have unsaved changes.  Do you wish to proceed?"))
                return;
        }

        // Mark us as loading...
        this.loadingGeneralAdministration = true;

        // Load the general administration
        gameService.loadGeneralAdministration(this.props.match.params.team_id, turn, this.props.account, this.onGeneralAdministrationLoaded);
    }


    /**
     * The general administration loaded event handler.
     *
     * @param {*} potentialExpenses The potential expenses.
     * @param {*} incurredExpenses The incurred expenses.
     * @param {*} lastUpdated The last updated time.
     * @param {*} turn The turn.
     * @param {*} account The account.
     */
    onGeneralAdministrationLoaded = (potentialExpenses, incurredExpenses, lastUpdated, turn, account) =>
    {
        // Make sure we have something...
        if (!potentialExpenses)
            potentialExpenses = [];

        // Make sure we have something...
        if (!incurredExpenses)
            incurredExpenses = [];


        // Determine if the spend is being made
        for (let row of potentialExpenses)
        {
            if (parseFloat(row.amount) > 0)
                row.spend = true;
            else
                row.spend = false;

            // Set the read-only flag if appropriate
            if (this.props.account === "IT")                
                row.editable = false;
        }

        // Determine if the spend is being made
        for (let row of incurredExpenses)
        {
            // Set the read-only flag if appropriate
            if (this.props.account === "IT")                
                row.editable = false;
        }


        // Initialize the state
        let state =
        { 
            rows: potentialExpenses,
            incurredExpenseRows: incurredExpenses,
            selectedTurn: turn,
            account: account,   
            lastUpdated: lastUpdated
        }

        // Set the intraturn Id if appropriate
        if (potentialExpenses.length > 0)
            state.intraturnId = potentialExpenses[0].intraturn_id;
        else
        if (incurredExpenses.length > 0)
            state.intraturnId = incurredExpenses[0].intraturn_id;


        // Set the state
        this.setState(state);

        // Clear the dirty rows
        this.clearDirtyRows();

        // Calculate the summaries
        this.calculatePotentialExpensesSummary(potentialExpenses);
        this.calculateIncurredExpensesSummary(incurredExpenses);

        // Reset the loading general administration flag
        this.loadingGeneralAdministration = false;

        // Set the loaded flag
        this.loaded = true;
    }


    /**
     * This method calulates the potential expenses summary.
     *
     * @param {*} rows The rows.
     */
    calculatePotentialExpensesSummary(rows)
    {
        this.calculateSummary(rows, "summary");
    }
    

    /**
     * This method calculates the incurred expenses summary.
     *
     * @param {*} rows The rows.
     */
    calculateIncurredExpensesSummary(rows)
    {
        this.calculateSummary(rows, "incurredExpenseSummary");
    }


    /**
     * This method calculates the summary.
     *
     * @param {*} rows The rows.
     * @param {*} summaryName The summary name.
     */
    calculateSummary(rows, summaryName)
    {
        // Initialize the summary
        let summary =
        {
            type: "Total",
            proposed_spending: 0,
            amount: 0
        };

        // Perform the calculation...
        for (let row of rows)
        {
            summary.proposed_spending += Utils.parseCurrency(row.proposed_spending);
            summary.amount += Utils.parseCurrency(row.amount);
        }

        // Create the state
        let state = {};
        state[summaryName] = summary;

        // Set the state
        this.setState(state);
    }



    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Call mom...
        let result = super.isComponentReady();

        // Load the investment sales if necessary...
        if (this.props.game && !this.loaded)
        {
            // Load the general administration
            this.loadGeneralAdministration(this.props.game.turns_completed + 1);

            // Not ready...
            return false;
        }

        // Get out...
        return result;
    }


    /**
     * This method updates the state when a general administration row has been modified.
     *
     * @param {*} params The params.
     */
    setRows = (params) =>
    {
        // Get the dirty rows map...
        let dirtyRows = this.state.dirtyRows;

        // Update the amount
        if (params.data.spend)
        {
            params.data.amount         = Utils.parseCurrency(params.data.proposed_spending);
            params.data.spend_turn_num = this.state.selectedTurn;
        }
        else
        {
            params.data.amount = 0;
            params.data.spend_turn_num = 0;
        }

        // Mark the row as dirty
        dirtyRows[params.data.gen_admin_id] = true;

        // calculate the summary
        this.calculatePotentialExpensesSummary(this.state.rows);

        // Save the state
        this.setState(
        {
            dirtyRows: dirtyRows
        });

        // Displays should most recent up to date values.
        params.api.refreshCells();
    }



    /**
     * This method updates the state when a general administration incurred expense row has been modified.
     *
     * @param {*} params The params.
     */
    setIncurredRows = (params) =>
    {
        // Get the dirty rows map...
        let dirtyRows = this.state.dirtyRows;
        
        dirtyRows[params.data.gen_admin_id] = true;

        // Parse amount
        params.data.amount         = Utils.parseCurrency(params.data.amount);

        // Calculate the summary
        this.calculateIncurredExpensesSummary(this.state.incurredExpenseRows);

        // Save the state
        this.setState(
        {
            dirtyRows: dirtyRows
        });

        // Displays should most recent up to date values.
        params.api.refreshCells();
    }


    /**
     * This method handles view saves.
     */
    onSave = () =>
    {
        // Get out if this is not actually dirty (shouldn't happen)
        if (!this.isDirty())
            return;

        // Handle the save...
        this.updateDirtyRows(false);
    }


    /**
     * This method updates the dirty rows.
     *
     * @param {*} force The force flag.
     * @return true if there are dirty rows, else false...
     */
    updateDirtyRows(force)
    {
        // Get out if nothing to do...
        if (Object.keys(this.state.dirtyRows).length === 0)
            return false;

        // Initialize the updated rows
        let updatedRows = [];

        // Check each row...
        for (let row of this.state.rows)
        {
            // See if it is dirty...
            if (!this.state.dirtyRows[row.gen_admin_id])
                continue;

            // Push the updated row
            updatedRows.push(row);
        }

        // Check each row...
        for (let row of this.state.incurredExpenseRows)
        {
            // See if it is dirty...
            if (!this.state.dirtyRows[row.gen_admin_id])
                continue;

            // Push the updated row
            updatedRows.push(row);
        }

        // Update the general administration
        intraturnService.updateGeneralAdministration(this.state.intraturnId, updatedRows, this.props.account, this.state.lastUpdated, force, this.onSaveComplete);

        // Get out...
        return true;
    }


    /**
     * The save complete event handler.
     *
     * @param {*} modifiedBy The modified by user.
     * @param {*} lastUpdated The last updated time.
     */
    onSaveComplete = ({ modifiedBy, lastUpdated }) =>
    {
        // See if it was modified since we loaded the data...
        if (modifiedBy)
        {
            // See if the user wants to force the matter...
            if (!window.confirm("General Administration records have modified by " + modifiedBy + " at " + new Date(lastUpdated).toLocaleTimeString() + ".\n\nWould you like save your changes anyway?"))
                return;

            // Force the update
            this.updateDirtyRows(true);

            // Get out...
            return;
        }

        // Update the state
        this.setState({ lastUpdated: lastUpdated });

        // Clear the dirty flag
        this.clearDirtyRows();

        // let the user know
        alert("Saved.");
    }


    /**
     * This method clears the dirty rows.
     */
    clearDirtyRows()
    {
        // Reset the dirty IDs
        this.setState(
        {
            dirtyRows: {}
        });
    }


    /**
     * This method handles turn changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onTurnChange = (name, value) =>
    {
        // Load the general administration
        this.loadGeneralAdministration(parseInt(value));
    }


     /**
     * This method handles sort column changes.
     *
     * @param {*} sortColumns The sorted columns.
     */
    onIncurredExpenseSortColumnsChange = (sortColumns) =>
    {
        // Sort the rows
        let rows = this.sortRowData(this.state.incurredExpenseRows, INCURRED_EXPENSE_COLUMNS, sortColumns);

        // Update the state
        this.setState(
        {
            incurredExpenseRows: rows,
            incurredExpenseSortColumns: sortColumns
        })
    }


   /**
     * This method renders the view.
     */
    renderGridView()
    {
        return (
            <Fragment>
                <Prompt when={ this.isDirty() } message="There are unsaved changes on this screen.  Are you sure you want to leave?" />

                <BDMViewTemplate name={ this.props.account } game={ this.props.game } onSave={ this.onSave } dirty={ this.isDirty() } intraturnId={ this.state.intraturnId } >

                    <Row>
                        <Col xs={6} sm={6} md={4} lg={4}>
                            <TurnSelect name="turns" onChange={ this.onTurnChange } value= { this.state.selectedTurn } />
                        </Col>
                    </Row>

                    { 
                        (this.state.rows.length > 0) ?
                        <div class="grid-body potential-expenses">
                            <DecisionGrid 
                                    columns={ this.columns } 
                                    turn={ this.state.selectedTurn }
                                    sortColumns={ this.state.sortColumns }
                                    rowData={ this.state.rows } 
                                    onCellValueChanged={ this.setRows } 
                                    onSortColumnsChange={ this.onSortColumnsChange }
                                    pinnedBottomRowData={ [ this.state.summary ] }
                            />
                        </div> : ""
                    }

                    { 
                        (this.state.incurredExpenseRows.length > 0) ?
                        <div class="grid-body incurred-expenses">
                            <DecisionGrid 
                                    columns={ INCURRED_EXPENSE_COLUMNS } 
                                    turn={ this.state.selectedTurn }
                                    sortColumns={ this.state.incurredExpenseSortColumns }
                                    rowData={ this.state.incurredExpenseRows } 
                                    onCellValueChanged={ this.setIncurredRows } 
                                    onSortColumnsChange={ this.onIncurredExpenseSortColumnsChange }
                                    pinnedBottomRowData={ [ this.state.incurredExpenseSummary ] }
                            />
                        </div> : ""
                    }
                </BDMViewTemplate>
            </Fragment>
        );
    }
}

// Export the decisions view...
export default withRouter(connect(BaseDecisionGridView.mapStateToProps)(BDMGenAdminView));