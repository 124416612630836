/**
 * This method parses the percentage.
 *
 * @param {*} percentage The percentage.
 * @returns the parsed percentage.
 */
export const parsePercentage = function(percentage)
{
    // Get out if not a string
    if (!(typeof percentage === 'string') && !(percentage instanceof String))
        return percentage;

    // Look for a percentage symbol
    let index = percentage.indexOf("%");

    // Remove it if it's there...
    if (index >= 0)
        percentage = percentage.substring(0, index);

    // Zero if blank...
    if (!percentage)
        return 0;

    // Parse the value
    return parseFloat(percentage);
}


/**
 * This method formats the percentage.
 *
 * @param {*} percentage The percentage.
 * @returns The formatted percentage.
 */
export const formatPercentage = function(percentage)
{
    if (!percentage)
        return "0.000%";

    return parsePercentage(percentage).toFixed(3) + "%";
}


/**
 * The percentage control.
 * @param {*} props The properties
 * @returns The formatted percentage.
 */
export default function Percentage(props) 
{
    return formatPercentage(props.value);
}


