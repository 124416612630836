import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Fragment, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import SendMessageModal from "./SendMessageModal";

import FormControl from "../../../controls/form/FormControl";
import MeasuresOfSuccessModal from "../decisions/components/MeasuresOfSuccessModal";
import { connect } from "react-redux";




/**
* This method maps the state to the properties.
* 
* @param {*} state The state.
* @param {*} ownProps The own properties.
* @returns The mapping.
*/
function mapStateToProps(state, ownProps)
{
    let result = {};

    // Get the dashboard details
    let dashboardDetails = state.games.regulatoryReportMap[ownProps.match.params.team_id];

    // Get the regulator report....
    if (dashboardDetails && dashboardDetails.turns && dashboardDetails.turns.length)
        result.regulator_enabled = true;
    else
        result.regulator_enabled = false;

    return result;
}

/**
 * The dashboard actions class.
 * 
 * @param {*} props The properties.
 * @returns The dashboard actions view.
 */
const DashboardActions = (props) =>
{
    // Define the state variables...
    let [showSendMessage, setShowSendMessage ] = useState(false);
    let [ showMeasuresOfSuccess, setShowMeasuresOfSuccess ] = useState(false);


    // Save the game & team
    let game = props.game;
    let team = props.team;

    return (
        <Fragment>
            <SendMessageModal gameId={ props.match.params.game_id } teamId={ props.match.params.team_id } visible={ showSendMessage } onHide = { () => setShowSendMessage(false) } />
            <MeasuresOfSuccessModal team={ props.team } onHide={ () => setShowMeasuresOfSuccess(false) } visible={ showMeasuresOfSuccess } />
            <FormControl name="Actions" containerClassName="full-height" className="full-height">
                <Container className="center full-height d-flex flex-column">
                    <Row className="g-1 flex-grow-0">
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Button variant="primary" className="dashboard-action-button" onClick={ () => { props.history.push("/game/" + game.game_id +"/decisions/" + team.team_id); } }>Decision Areas</Button>
                        </Col>
                    </Row>

                    {
                        (props.mode !== "Dashboard") ?
                            <Row className="g-1 flex-grow-0">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Button variant="primary" className="dashboard-action-button" onClick={ () => { props.history.push("/game/" + game.game_id +"/team/" + team.team_id); } }>Dashboard</Button>
                                </Col>
                            </Row> : ""
                    }

                    {
                        (game.type !== "Lite") && (game.turns_completed > 0) && (props.mode !== "Management Ratios") ?
                            <Row className="g-1 flex-grow-0">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Button variant="primary" className="dashboard-action-button" onClick={ () => { props.history.push("/game/" + game.game_id +"/management-ratios/" + team.team_id); } }>Management Ratios</Button>
                                </Col>
                            </Row> : ""
                    }

                    {
                        (props.mode !== "M&A") && game.manda_enabled ?
                            <Row className="g-1 flex-grow-0">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Button variant="primary" className="dashboard-action-button" onClick={ () => { props.history.push("/game/" + game.game_id +"/mergers-and-acquisitions/" + team.team_id); } }>Mergers &amp; Acquisitions</Button>
                                </Col>
                            </Row>
                        : ""
                    }

{
                        (props.mode !== "Regulator Report") && props.regulator_enabled ?
                            <Row className="g-1 flex-grow-0">
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Button variant="primary" className="dashboard-action-button" onClick={ () => { props.history.push("/game/" + game.game_id +"/regulator/" + team.team_id); } }>Regulator Report</Button>
                                </Col>
                            </Row>
                        : ""
                    }

                    <Row className="g-1 flex-grow-0">
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Button variant="primary" className="dashboard-action-button" onClick={ () => { props.history.push("/game/" + game.game_id +"/team-notes/" + team.team_id); } }>Team Notes</Button>
                        </Col>
                    </Row>
                    <Row className="g-1 flex-grow-0">
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Button variant="primary" className="dashboard-action-button" onClick={ () => { setShowMeasuresOfSuccess(true); } }>Measures of Success</Button>
                        </Col>
                    </Row>
                    <Row className="g-1 flex-grow-0">
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Button variant="primary" className="dashboard-action-button" onClick={ () => { setShowSendMessage(true); } }>Send Message</Button>
                        </Col>
                    </Row>
                    <Row className="flex-row flex-grow-1">
                        <Col xs={12} sm={12} md={12} lg={12}>&nbsp;</Col>
                    </Row>
                </Container>
            </FormControl>
        </Fragment>
    )
}

// Get out...
export default withRouter(connect(mapStateToProps)(DashboardActions));