import {CheckBoxRenderer,  DisabledCheckBoxRenderer, currencyRenderer} from "../../../../controls/grid/GridRenderers";
import { Currency, formatCurrency } from "../../../../controls/Currency";
import SortFunctions from "../../../../controls/grid/SortFunctions";

/**
 * The IT columns.
 */
export const IT_COLUMNS =
[
    { 
        field: 'type', 
        headerName: 'Type',
        width: 400,
        minWidth: 300,
        comparator: SortFunctions.alphaNumeric,
    },

    { 
        field: 'proposed_spending',
        headerName: 'Spend Amount', 
        minWidth: 200,
        comparator: SortFunctions.numeric,
        cellRenderer: currencyRenderer,
    },

    { 
        field: 'spend', 
        headerName: 'Spend',
        cellClass: 'center',
        minWidth: 80,
        width: 80,
        cellRenderer: DisabledCheckBoxRenderer,
        editableCellRenderer: CheckBoxRenderer
    }
];


/**
 * The IT maintenance columns.
 */
export const IT_MAINTENANCE_COLUMNS =
[
    { 
        field: 'type', 
        headerName: 'Type',
        minWidth: 200,
        comparator: SortFunctions.alphaNumeric,
    },

    { 
        field: 'amount',
        headerName: 'Amount', 
        minWidth: 200,
        comparator: SortFunctions.numeric,
        cellRenderer: currencyRenderer
    },
];