import { Component } from "react";

import { withRouter } from "react-router";
import { connect } from 'react-redux';


/**
 * The CountDownTimer class.
 */
class CountDownTimer extends Component
{
    /**
     * Object constructor.
     * 
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Create the state
        this.state = {};
    }

    /**
     * This method maps the state to the properties.
     * 
     * @param {*} state The state.
     * @param {*} ownProps The own properties.
     * @returns The mapping.
     */
    static mapStateToProps(state, ownProps)
    {
        let result =
        {
            game: state.games.gameMap[ownProps.match.params.game_id],
        };
    
        return result;
    }


    componentDidMount()
    {
        // Update the timer.
        this.updateTimer();
    }


    /**
     * The component did update event handler.
     *
     * @param {*} prevProps The previous properties.
     */
    componentDidUpdate(prevProps)
    {
        // Update the timer
        this.updateTimer();
    }


    /**
     * This method updates the timer.
     */
    updateTimer()
    {
        // If no game, clear any timer...
        if (!this.props.game || !this.props.game.lockdown_time || !this.props.game.lockdown_time.length)
        {
            // Stop the timer
            this.stopTimer();

            // Get out...
            return;
        }

        // Start the timer
        this.startTimer();
    }


    /**
     * Clear the timer if one was set.
     */
    componentWillUnmount() 
    {
        // Get out if nothing to do...
        if (!this.state.timerId)
            return;

        // Clear the interval
        clearInterval(this.state.timerId);
    }

    /**
     * This method starts the timer. 
     */
    startTimer()
    {
        // No need to start the timer if it's already running
        if (this.state.timerId)
            return;

        // Start the timer
        let timerId = setInterval(this.onTimer, 1000);

        // Run the timer so the screen immediately updates...
        this.onTimer();

        // Set the timer ID
        this.setState({ timerId: timerId });
    }


    /**
     * This method stops the timer.
     * 
     * @returns The timer.
     */
    stopTimer()
    {
        // Get out if there is no timer...
        if (!this.state.timerId)
            return;

        // Clear the timer
        clearInterval(this.state.timerId);

        // Reset the timer ID
        this.setState({ timerId: null });
    }


    /**
     * The timer event handler.
     */
    onTimer = () =>
    {
        // Get the time left
        let timeLeft = this.getTimeLeft();

        // Set the state
        this.setState({ timeLeft: timeLeft });
    }


    /**
     * This method gets the time left.
     */
    getTimeLeft()
    {
        if (!this.props.game || this.props.game.frozen || !this.props.game.lockdown_time || !this.props.game.lockdown_time.length)
            return null;

        // Get the parts
        let parts = this.props.game.lockdown_time.split(":");
        if (parts.length !== 2)
            return null;

        // Get the hour & minute...
        let lockdownHour = parseInt(parts[0]);
        let lockdownMinute = parseInt(parts[1]);

        // Make sure the hour & minute are valid...
        if (isNaN(lockdownHour) || isNaN(lockdownMinute) || 
            (lockdownHour < 0) || (lockdownHour >= 24) ||
            (lockdownMinute < 0) || (lockdownMinute >= 60))
        {
            return null;
        }

        // Get the current time
        let now = new Date();

        // Get the hour & minute
        let hour = now.getHours();
        let minute = now.getMinutes();

        // Get the seconds left
        let secondsLeft = ((lockdownHour - hour) * 3600) + ((lockdownMinute - minute) * 60) - now.getSeconds();
        if ((secondsLeft >= 3600) || (secondsLeft < 0))
            return null;

        // Calculate the time left
        let timeLeft =
        {
            minutes: Math.floor(secondsLeft / 60),
            seconds: secondsLeft  % 60
        }

        // Get out...
        return timeLeft;
    }


    /**
     * This method renders the component.
     */
    render()
    {
        let textClassName;

        // Get out if nothing to do...
        if (!this.state.timeLeft)
            return "";
        
        // Get the minutes & seconds
        let minutes = ("00" + this.state.timeLeft.minutes).slice(-2);
        let seconds = ("00" + this.state.timeLeft.seconds).slice(-2);

        // Initialize the class names
        let className= "countdown-timer";
        let innerClassName = "countdown-timer-inner";
 
        // Determine the class name
        if (minutes < 10)
        {
            className += " less-than-10";
            innerClassName += " less-than-10";
        }

        // Determine the text class name
        if (minutes < 1)
        {
            if (seconds < 30)
                textClassName = "blink-fast";
            else
                textClassName = "blink";
        }
        else
            textClassName = "";


        // Render the component...
        return (
            <div className={ className }>
                Time Left:
                <div className={ innerClassName }>
                    <div className={ textClassName }>{ minutes + ":" + seconds}</div>
                </div>
            </div>
        );
    }
}

// Export the view
export default withRouter(connect(CountDownTimer.mapStateToProps)(CountDownTimer));

