import { CurrencyCellEditor } from "../../../../controls/grid/GridEditors";
import SortFunctions from "../../../../controls/grid/SortFunctions";
import { currencyRenderer, percentageRenderer } from "../../../../controls/grid/GridRenderers";
/**
 * The columns.
 */
const COLUMNS =
[
    { 
        field: 'fixed_or_variable', 
        headerName: 'Fixed or Variable',
        minWidth: 200,
        comparator: SortFunctions.alphaNumeric
    },

    { 
        field: 'term', 
        headerName: 'Term', 
        minWidth: 120,
        comparator: SortFunctions.numeric
    },

    { 
        field: 'rate', 
        headerName: 'Rate', 
        minWidth: 100,
        cellRenderer: percentageRenderer,
        comparator: SortFunctions.numeric
    },

    {
        field: 'maturities', 
        headerName: 'Matured this Period', 
        minWidth: 180,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric,
    },

    { 
        field: 'cap', 
        headerName: 'Amount (000s)', 
        minWidth: 180,
        cellEditor: CurrencyCellEditor,
        cellRenderer: currencyRenderer,
        comparator: SortFunctions.numeric,
    },
];


export default COLUMNS;