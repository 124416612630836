import { createSlice } from '@reduxjs/toolkit';

// Create the initial state
const initialState = 
{
    redirectRoute: null,
    returnRoute: null
};

// Create the slice
export const redirectSlice = createSlice(
{
    name: 'redirect',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: 
    {
        /**
         * The redirect method.
         * 
         * @param {*} state The state.
         * @param {*} action The action.
         */
        redirect: (state, action) => 
        {
            state.redirectRoute = action.payload;
            state.returnRoute   = null;
        },

        /**
         * This method redirects witha return URL.
         * 
         * @param {*} state The state.
         * @param {*} action The action.
         */
        redirectWithReturn: (state, action) =>
        {
            state.redirectRoute = action.payload.redirectRoute;
            state.returnRoute   = action.payload.returnRoute;
        },

        /**
         * This method clears the redirect route.
         * 
         * @param {*} state The state.
         */
        clearRedirectRoute: (state) =>
        {
            state.redirectRoute = null;
        },

        /**
         * This method clears the return route.
         * 
         * @param {*} state The state.
         */
        clearReturnRoute: (state) =>
        {
            state.returnRoute = null;
        }
    }
});

// Export the block action
export const { redirect, redirectWithReturn, clearRedirectRoute, clearReturnRoute } = redirectSlice.actions;

// Get out...
export default redirectSlice.reducer;
