import axios from 'axios';

import Utils from "./Utils";
import log from './Log';

// The default error message.
const DEFAULT_ERROR_MESSAGE = "An error occurred while processing your request.";


/**
 * This method alerts the errors.
 * 
 * @param {*} errors The errors.
 * @param {*} defaultErrorMessage The default error message.
 */
function alertErrors(errors, defaultErrorMessage)
{
    if (errors && (errors.length > 0))
        alert(errors.join("\n"));
    else
    if (defaultErrorMessage)
        alert(defaultErrorMessage);
    else
        alert(DEFAULT_ERROR_MESSAGE);
}

/**
 * The AJAX class.
 */
class Ajax
{
    /**
     * This method makes an AJAX call.
     * 
     * @param {*} url The URL.
     * @param {*} data The data.
     * @param {*} successCallback The success callback.
     * @param {*} blockMessage The block message.
     * @param {*} errorMessage The error message.
     * @param {*} context The context.
     * @param {*} errorCallback The error callback.
     * @param {*} silent The silent flag.
     */
    postSilent(url, data = {}, successCallback = null, blockMessage = null, errorMessage = null, context = null, errorCallback = null)
    {
        // Post silently...
        return this.post(url, data, successCallback, blockMessage, errorMessage, context, errorCallback, true);
    }


    /**
     * This method makes an AJAX call.
     * 
     * @param {*} url The URL.
     * @param {*} data The data.
     * @param {*} successCallback The success callback.
     * @param {*} blockMessage The block message.
     * @param {*} errorMessage The error message.
     * @param {*} context The context.
     * @param {*} errorCallback The error callback.
     * @param {*} silent The silent flag.
     */
    post(url, data = {}, successCallback = null, blockMessage = null, errorMessage = null, context = null, errorCallback = null, silent = false)
    {
        // Initialize the block object function...
        let blockObject = null;

        // Get the active route
        let activeRoute = Utils.getActiveRoute();

        // Block the UI if requested...
        if (blockMessage != null)
            blockObject = Utils.blockUI(blockMessage);

        // Make sure the URL starts with a /...
        if (!url.startsWith("/"))
            url = "/" + url;

        axios(
        {
            method: 'post',
            url: "/api" + url,
            data: data
        })
        .catch(error => 
        {
            // Alert the errors
            if (!silent)
                alertErrors(null, errorMessage);

            // Call the error callback if appropriate
            if (errorCallback)
                errorCallback();
        })
        .then(response => 
        {
            var newBlockObject = null;

            if (blockObject != null)
            {
               Utils.unblockUI(blockObject);

               newBlockObject = Utils.blockUI(null);

               window.app.forceUpdate();
            }

            Utils.execute(newBlockObject != null ? true : false, () =>
            {
                try
                {
                    if (response.data && response.data.status)
                    {
                        if (response.data.status === "Success")
                        {
                            if (successCallback)
                                successCallback(response.data, context);
                        }
                        else
                        {
                            if (response.data.requiresLogin && !silent)
                            {
                                // Call the error callback if appropriate
                                if (errorCallback)
                                    errorCallback();

                                // Do the redirect...
                                Utils.redirectWithReturnRoute("/session-timeout", activeRoute);
                            }
                            else
                            {
                                // Alert the errors...
                                if (!silent)
                                    alertErrors(response.data.errors, errorMessage);

                                // Call the error callback if appropriate
                                if (errorCallback)
                                    errorCallback();
                            }
                        }
                    }
                    else
                    {
                        // Alert the errors...
                        if (!silent)
                            alertErrors(null, errorMessage);

                        // Call the error callback if appropriate
                        if (errorCallback)
                            errorCallback();
                    }
                }

                finally
                {
                    if (newBlockObject)
                       Utils.unblockUI(newBlockObject);
                }
            });
        })
        .catch(error => 
        {
            if (url === "/log")
                log.logErrorToConsoleOnly(error);
            else
                log.error(error);
        })
        .finally(() =>
        {
            if (blockObject != null)
               Utils.unblockUI(blockObject);
        });
    }
}

// Export the object
export default new Ajax();