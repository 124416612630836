import { Fragment } from 'react';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import _ from 'lodash';

import BaseGameView from "./../BaseGameView";
import TeamDetails from "./../components/TeamDetails";
import ErrorBox from "../../../components/ErrorBox";
import SendMessageModal from "./../components/SendMessageModal";

import gameService from '../../../services/GameService';
import Statistics from './Statistics';
import Disclosure from './Disclosure';
import calculationHelpers from './CalculationHelpers';
import HoldingCompanyStatistics from './HoldingCompanyStatistics';
import InputControl from '../../../controls/form/InputControl';
import CountDownTimer from '../components/CountDownTimer';
import DashboardActions from '../components/DashboardActions';

/**
 * The default common state.
 */
const DEFAULT_COMMON_STATE =
{
    team2: 0,
    team3: 0,

    holdingCompanyDividends: 0,
    costSavingsPercentage1: 0,
    costSavingsPercentage2: 0,
    costSavingsPercentage3: 0,
    enhancementRate1: 0,
    enhancementRate2: 0,
    enhancementRate3: 0,
    feeEnhancementRate1: 0,
    feeEnhancementRate2: 0,
    feeEnhancementRate3: 0,
    stockPremiumOffered2: 0,
    stockPremiumOffered3: 0,

    cashPaid2: 0,
    cashPaid3: 0,

    cashFromNewCapitalIssues: 0,
    cashFromBorrowings: 0
}




/**
 * The MergersAndAcquisitionsView class.
 */
class MergersAndAcquisitionsView extends BaseGameView
{
    /**
     * The object constructor
     */
    constructor(props)
    {
        // Call mom
        super(props);

        // Initialize the loaded flag
        this.loaded= false;

        // Initialize the state
        this.state =
        {
            activeTab: "Statistics",
            showSendMessage: false,
            activeScenario: "",
            scenario: "",

            commonState: { team1: parseInt(props.match.params.team_id), ...DEFAULT_COMMON_STATE },
            originalState: DEFAULT_COMMON_STATE
        }

        // Get the dashboard details
        if (props.game && props.team)
        {
            // Get the mergers & acquisitions
            gameService.getMergersAndAcquisitions(props.game.game_id, props.team.team_id);

            // Set the requested flag
            this.state.requested = true;
        }
    }


    /**
    * This method maps the state to the properties.
    * 
    * @param {*} state The state.
    * @param {*} ownProps The own properties.
    * @returns The mapping.
    */
    static mapStateToProps(state, ownProps)
    {
        // Call mom...
        let result = BaseGameView.mapStateToProps(state, ownProps);

        // Add the team
        result.team = state.games.teamMap[ownProps.match.params.team_id];

        // Add the teams
        result.teams = state.games.allGameTeamsMap[ownProps.match.params.game_id];

        // Save the all teams map
        result.allTeamsMap = state.games.allTeamsMap;

        // Initialize the scenario map
        result.scenarioMap = {};

        // Get the scenarios
        result.scenarios = state.games.mAndAScenariosMap[ownProps.match.params.team_id];
        if (result.scenarios)
        {
            // Populate the scenario map...
            for (let scenario of result.scenarios)
                result.scenarioMap[scenario.m_and_a_id] = JSON.parse(scenario.data);
        }
        else
            result.scenarios = [];

        // Get the mergers & acquisitions
        let mergersAndAcquisitions = state.games.mergersAndAcquisitionsMap[ownProps.match.params.team_id];
        if (mergersAndAcquisitions)
        {
            // Create the mergers and acquisitions map
            let mergersAndAcquisitionsMap = {};

            // Build the mergers and acquisitions map
            for (let item of mergersAndAcquisitions)
            {
                let items = mergersAndAcquisitionsMap[item.team_id];
                if (!items)
                {
                    items = {};

                    mergersAndAcquisitionsMap[item.team_id] = items;
                }

                items[item.name] = item;
            }

            // Set the map
            result.mergersAndAcquisitionsMap = mergersAndAcquisitionsMap;

            // Now, calculate the additional values...
            for (let teamId in mergersAndAcquisitionsMap)
            {
                // Get the team
                let team = mergersAndAcquisitionsMap[teamId];

                // Calculate the leverage ratio
                let leverageRatio = calculationHelpers.getValue(team, "Tier 1 Leverage Ratio Numerator") / calculationHelpers.getValue(team, "Tier 1 Leverage Ratio Denominator");

                // Set leverage ratio
                team["Leverage Ratio"] = { name: "Leverage Ratio", value: leverageRatio };

                // Calculate the EPS
                let eps = calculationHelpers.getValue(team, "Net Income after Tax") * 1000000 / calculationHelpers.getValue(team, "Common Shares");

                // Set the EPS
                team["EPS"] = { name: "EPS", value: eps };

                // Set the PE
                team["PE"] = { name: "PE", value: calculationHelpers.getValue(team, "Stock Price") / (eps / 1000000) };

                // Calculate the Market Cap
                let marketCap = calculationHelpers.getValue(team, "Stock Price") * calculationHelpers.getValue(team, "Common Shares");

                // Set the Market Cap
                team["Market Cap"] = { name: "Market Cap", value: marketCap };

                // Set the Market Book
                team["Market Book"] = { name: "Market Book", value: marketCap / calculationHelpers.getValue(team, "Equity") };


                // Calculate the earnings per share
                let earningsPerShare = calculationHelpers.getValue(team, "Net Income after Tax") / calculationHelpers.getValue(team, "Common Shares");

                // Save the annual earnings per share
                team["Annual Earnings Per Share"] = { name: "Annual Earnings Per Share", value: earningsPerShare };
            }
        }

        // Get out...
        return result;
    }


    /**
     * This method handles component updates.
     *
     * @param {*} prevProps The previous properties.
     */
    componentDidUpdate(prevProps)
    {
        // Get out if there is nothing to do...
        if (!this.props.game || !this.props.team)
            return;

        // Initialize the state
        let state = {};

        // Update the active scenario if appropriate...
        if (!this.state.activeScenario && this.props.scenarios && this.props.scenarios.length)
        {
            // Update the active scenario
            state.activeScenario = this.props.scenarios[0].m_and_a_id;

            // Update the common state
            state.commonState = JSON.parse(this.props.scenarios[0].data);

            // And the original state...
            state.originalState = JSON.parse(this.props.scenarios[0].data);
        }

        // Get the previous team ID
        let previousTeamId = prevProps.team ? prevProps.team.team_id : null;

        // See if the team ID changed...
        if (!this.state.requested || (this.props.team.team_id !== previousTeamId))
        {
            // Get the mergers & acquisitions
            gameService.getMergersAndAcquisitions(this.props.game.game_id, this.props.team.team_id);

            // Update the state
            state.requested = true;
        }

        // Update the state
        if (Object.keys(state).length > 0)
            this.setState(state);
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        if (!super.isComponentReady())
            return false;

        // Get out if we are not ready...
        if (!this.props.team)
            return false;

        // Ready to go!
        return true;
    }


    /**
     * The team change event handler.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onChangeTeam = (name, value) =>
    {
        let state = {};
        state[name] = value;

        this.setState(state);
    }


    /**
     * The field change event handler.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onFieldChange = (name, value) =>
    {
        let commonState = { ...this.state.commonState };
        commonState[name] = value;

        this.setState({ commonState: commonState });
    }


    /**
     * This method determines if the scenario is dirty.
     */
    isDirty()
    {
        // Determine if it's dirty
        let dirty = !_.isEqual(this.state.commonState, this.state.originalState);
       
        // Get out...
        return dirty;
    }


    /**
     * This method saves the new scenario.
     */
    onSaveNewScenario = () =>
    {
        // Hide the new modal & update the active scenario
        this.setState({ showNewModal: false });

        // Save the scenario
        gameService.addMandAScenario(this.props.match.params.team_id, this.state.scenario, this.state.commonState, this.onSaveComplete);
    }


    /**
     * The save event handler.
     */
    onSave = () =>
    {
        // Update the scenario
        gameService.updateMandAScenario(this.props.match.params.team_id, this.state.activeScenario, this.state.commonState, this.onSaveComplete);
    }


    /**
     * The save complete event handler.
     *
     * @param {*} mAndAId The M&A ID.
     */
    onSaveComplete = (mAndAId) =>
    {
        this.setState({ activeScenario: mAndAId, originalState: _.cloneDeep(this.state.commonState) });
    }


    /**
     * This method changes the scenario.
     *
     * @param {*} event The event.
     */
    onChangeScenario = (event) =>
    {
        // Set the state...
        this.setState({ activeScenario: event.target.value, commonState: this.props.scenarioMap[event.target.value], originalState: _.cloneDeep(this.props.scenarioMap[event.target.value]) });
    }


    /**
     * This method renders the view.
     */
    renderView()
    {
        // Get the game
        let game = this.props.game;
        if (game === null)
        {
            return (
                <ErrorBox>The game was not found.</ErrorBox>
            );
        }

        // Get the team
        let team = this.props.team;
        if (team === null)
        {
            return (
                <ErrorBox>The team was not found.</ErrorBox>
            )
        }

        // Get out if manda is disabled...
        if (!game.manda_enabled)
        {
            this.props.history.replace("/game/" + this.props.game.game_id + "/team/" + team.team_id);
        }

        return(
            <Fragment>
                <SendMessageModal gameId={ this.props.match.params.game_id } teamId={ this.props.match.params.team_id } visible={ this.state.showSendMessage } onHide = { () => this.setState({ showSendMessage: false }) } />

                <Modal show={ this.state.showNewModal } onHide={() => this.setState({ showNewModal: false }) }>
                    <Modal.Header closeButton>
                        <Modal.Title>New M&A Scenario</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col xs={6} sm={6} md={4} lg={4}><label>Scenario Name</label></Col>
                                <Col xs={12} sm={12} md={8} lg={8}><InputControl name="scenario" value={ this.state.scenario } onChange={ (name, value) => { this.setState({ scenario: value });  } } /></Col>
                            </Row>
                        </Container>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="primary" onClick={ () => this.onSaveNewScenario() }>Save</Button>
                        <Button variant="secondary" onClick={() => this.setState({ showNewModal: false }) }>Cancel</Button>
                    </Modal.Footer>
                </Modal>


                <Container>
                    <CountDownTimer />
                    <Row className="dashboard-action-container g-1">
                        <Col xs={8} sm={8} md={10} lg={10} gu>
                            <Container className="center full-width full-height">
                                <Row className="g-1">
                                    <Col xs={12} sm={12} md={12} lg={12} className="left">
                                        <TeamDetails game={ game } team={ team } />

                                        <div className="manda-scenario-selection">
                                            <select name={ this.props.name } className="form-select" onChange={ this.onChangeScenario }>
                                            {
                                                this.props.scenarios.map((scenario, key) =>
                                                {
                                                    return <option key={ key } value={ scenario.m_and_a_id } selected={ scenario.m_and_a_id === this.state.activeScenario } >{ scenario.name }</option>;
                                                })
                                            }
                                            </select>
                                            {
                                                this.isDirty() && this.state.activeScenario ?
                                                    <span className="save" onClick={ this.onSave }><i title="Save" className="decision-icon-color fa fa-floppy-o" ></i></span>
                                                :
                                                    <span className="save"><i title="Save" className="decision-icon-color disabled fa fa-floppy-o" ></i></span>
                                            }
                                            <Button variant="primary" onClick={ () => this.setState({ showNewModal: true }) } disabled={ !this.isDirty() && !this.state.activeScenario }>Save as New Scenario</Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="g-1 decision-inner-body">
                                    <Col xs={12} sm={12} md={12} lg={12} className="small">
                                        <Tabs activeKey={ this.state.activeTab } onSelect={ (key) => { this.setState({ activeTab: key }) } }>
                                            <Tab eventKey="Statistics" title="Statistics">
                                                <Statistics team={ team } teams={ this.props.teams } teamMap={ this.props.allTeamsMap } mergersAndAcquisitionsMap={ this.props.mergersAndAcquisitionsMap } commonState={ this.state.commonState } onFieldChange={ this.onFieldChange } />
                                            </Tab>
                                            <Tab eventKey="Disclosure" title="Disclosure">
                                                <Disclosure team={ team } teams={ this.props.teams } teamMap={ this.props.allTeamsMap } mergersAndAcquisitionsMap={ this.props.mergersAndAcquisitionsMap } commonState={ this.state.commonState } onFieldChange={ this.onFieldChange } />
                                            </Tab>
                                            <Tab eventKey="Holding Company" title="Holding Company">""
                                                <HoldingCompanyStatistics team={ team } teams={ this.props.teams } teamMap={ this.props.allTeamsMap } mergersAndAcquisitionsMap={ this.props.mergersAndAcquisitionsMap } commonState={ this.state.commonState } onFieldChange={ this.onFieldChange } />
                                            </Tab>
                                        </Tabs>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col xs={4} sm={4} md={2} lg={2}>
                            <DashboardActions mode="M&A" game={ this.props.game } team={ this.props.team } />
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

// Export the game view...
export default withRouter(connect(MergersAndAcquisitionsView.mapStateToProps)(MergersAndAcquisitionsView));