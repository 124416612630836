import { faArrowTrendDown, faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react"
import { Col, Row } from "react-bootstrap";
import { formatCurrency } from "../../../controls/Currency";
import { formatPercentage } from "../../../controls/Percentage";

/**
 * The KPI formats.
 */
export const KPI_FORMATS = 
{
    CURRENCY: "Currency",
    PERCENTAGE: "Percentage"
}

/**
 * This method formats the value.
 *
 * @param {*} value The value.
 * @param {*} formatType The format type.
 * @returns The formatted value.
 */
function format(value, formatType)
{
    // Get out if no value...
    if ((value == undefined) || (value == null))
        return "";

    // Format the value...
    if (formatType == KPI_FORMATS.PERCENTAGE)
        return formatPercentage(value);

    return formatCurrency(value);
}


/**
 * This method computes the YTD average.
 * 
 * @param {*} quarters The list of quarters.
 * @returns The YTD average.
 */
function ytd(quarters)
{
    let total = 0;
    let count = 0;

    for (let counter = quarters.length - 1; (counter > quarters.length - 5) && (counter >= 0); counter--)
    {
        total += parseFloat(quarters[counter]);
        count++;
    }

    return total / count;
}


/**
 * The YTD KPI component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
const YTDKPI = (props) =>
{
    // Get out if nothing to do...
    if (!props.kpi)
        return <Fragment />;

    // Return the view...
    return (
        <Fragment>
            <Row>
                <Col xs={6} sm={6} md={4} lg={4} className="key-indicator">
                    { props.kpi.type }
                </Col>
            </Row>
            {
                props.kpi.kpis.map((item) =>
                {
                    let current  = item.quarters && (item.quarters.length > 1) ? item.quarters[item.quarters.length - 1 ] : 0;
                    let communityQuarter = item.communityQuarters && (item.communityQuarters.length > 1) ? item.communityQuarters[item.communityQuarters.length - 1 ] : 0;

                    return (
                        <Row>
                            <Col xs={6} sm={6} md={4} lg={4} className="key-indicator-name">
                                { item.name }
                            </Col>
                            <Col xs={4} sm={4} md={2} lg={2}>
                                { format(current, item.format) }
                            </Col>
                            <Col xs={4} sm={4} md={2} lg={2}>
                                { format(ytd(item.quarters), item.format) }
                            </Col>
                            <Col xs={4} sm={4} md={2} lg={2}>
                                { format(communityQuarter, item.format) }
                            </Col>
                        </Row>
                    )
                })
            }
        </Fragment>
    );
}

// Export the component
export default YTDKPI;