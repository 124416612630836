import { Container, Row, Col } from "react-bootstrap";

/**
 * The team details component.
 * 
 * @param {*} props The properties.
 * @returns The component.
 */
const TeamDetails = (props) =>
{
    // Get out...
    return (
        <div className="team-details">
            <div className="team-details-row">
                <div className="team-details-header">Community:</div>
                <div className="team-details-value">{ props.game.game_name }</div>
            </div>
            <div className="team-details-row">
                <div className="team-details-header">Team:</div>
                <div className="team-details-value">{ props.team.team_name }</div>
            </div>
        </div>
    );
}

// Get out...
export default TeamDetails;


