import React, { Fragment } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import InputControl from '../../../../controls/form/InputControl';
import intraturnService from "../../../../services/IntraturnService";


/**
 * The new scenario class.
 */
class NewScenarioButton extends React.Component
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the state
        this.state = { show: false, scenarioName: "" };
    }


    /**
     * This method shows the modal.
     *
     * @param {*} show The show flag.
     */
    showModal(show)
    {
        this.setState({ show: show });
    }

    /**
     * The scenario name change event handler.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onChangeScenarioName = (name, value) =>
    {
        this.setState({ scenarioName: value });
    }


    /**
     * This method saves the new scenario.
     */
    save()
    {
        // Validate the scenario name...
        if (this.state.scenarioName === "")
        {
            alert("The scenario name cannot be blank.");
            return;
        }

        // Create the scenario
        intraturnService.createScenario(this.state.scenarioName, this.props.activeIntraturnId, this.onSaveComplete);
    }


    /**
     * This method handles the save complete event.
     */
    onSaveComplete = (intraturn) =>
    {
        // Handle the new scenario event
        this.props.onNewScenario(intraturn);

        // Hide the modal...
        this.showModal(false);
    }


    /**
     * This method renders the component.
     */
    render()
    {
        return (
            <Fragment>
                <Button variant="primary" onClick={ () => this.showModal(true) } disabled={ this.props.disabled }>Save as New Scenario</Button>

                <Modal show={ this.state.show } onHide={() => this.showModal(false) }>
                    <Modal.Header closeButton>
                        <Modal.Title>New Scenario</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col xs={6} sm={6} md={4} lg={4}><label>Scenario Name</label></Col>
                                <Col xs={12} sm={12} md={8} lg={8}><InputControl name="scenario" onChange={ this.onChangeScenarioName } /></Col>
                            </Row>
                        </Container>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="primary" onClick={ () => this.save() }>Save</Button>
                        <Button variant="secondary" onClick={() => this.showModal(false) }>Cancel</Button>
                    </Modal.Footer>
                </Modal>
        </Fragment>
        );
    }
}

export default NewScenarioButton;