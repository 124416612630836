import { createSlice } from '@reduxjs/toolkit';

// Create the initial state
const initialState = 
{
    noteSections: [],
    noteSectionsLoading: false
};

// Create the slice
export const staticSlice = createSlice(
{
    name: 'static',
    initialState,

    // The `reducers` field lets us define reducers and generate associated actions
    reducers: 
    {
        /**
         * This method sets the note sections.
         * 
         * @param {*} state The state.
         * @param {*} action The action.
         */
        setNoteSections: (state, action) => 
        {
            state.noteSections = action.payload;
        },


        /**
         * This method sets the note sections loading flag.
         *
         * @param {*} state The state.
         * @param {*} action The action.
         */
        setNoteSectionsLoading: (state, action) =>
        {
            state.noteSectionsLoading = action.payload;
        }
    }
});

// Export the actions
export const { setNoteSections, setNoteSectionsLoading } = staticSlice.actions;

// Get out...
export default staticSlice.reducer;
