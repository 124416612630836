import { Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import SelectControl from "../../../controls/form/SelectControl";
import gameService from "../../../services/GameService";


/**
 * THe ratings.
 */
let RATINGS = [ { value: 1 }, { value: 2 }, { value: 3 } , { value: 4 } , { value: 5 } ];

/**
 * The default row.
 */
let DEFAULT_ROW = { rating: 1, comments: "" };

/**
 * The regulator report class.
 */
class RegulatorReport extends Component
{
    /**
     * Object Constructor.
     * 
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the state
        this.state =
        {
            report:
            {
                capital: { ...DEFAULT_ROW },
                assetQuality: { ...DEFAULT_ROW },
                management: { ...DEFAULT_ROW },
                earnings: { ...DEFAULT_ROW },
                liquidity: { ...DEFAULT_ROW },
                marketRisk: { ...DEFAULT_ROW },
                compositeRating: { ...DEFAULT_ROW }
            }
        }

        // Load the regulator report if appropriate...
        if (props.gameId && props.teamId && (props.turnNum > 0))
            this.loadRegulatorReport();
    }




    /**
     * This method handles component updates.
     *
     * @param {*} prevProps The previous properties.
     */
    componentDidUpdate(prevProps)
    {
        // Get out if there is nothing to do...
        if (!this.props.gameId || !this.props.teamId || (this.props.turnNum <= 0))
            return;

        // See if something changed...
        if ((this.props.gameId === prevProps.gameId) &&
            (this.props.teamId === prevProps.teamId) &&
            (this.props.turnNum === prevProps.turnNum))
        {
            return;
        }

        // Load the regulator report
        this.loadRegulatorReport();
    }


    /**
     * This method loads the regulator report.
     */
    loadRegulatorReport()
    {
        // Get the regulator report
        gameService.getRegulatorReport(this.props.gameId, this.props.teamId, this.props.turnNum, this.onLoaded);
    }


    /**
     * The load event.
     * 
     * @param {*} regulatorReport The regulator report.
     */
    onLoaded = (response) =>
    {
        // Get the regulator report
        let regulatorReport = response.regulatorReport;

        // Make sure the components exist...
        if (!regulatorReport.capital)
            regulatorReport.capital = { ...DEFAULT_ROW };

        if (!regulatorReport.assetQuality)
            regulatorReport.assetQuality = { ...DEFAULT_ROW };

        if (!regulatorReport.management)
            regulatorReport.management = { ...DEFAULT_ROW };

        if (!regulatorReport.earnings)
            regulatorReport.earnings = { ...DEFAULT_ROW };

        if (!regulatorReport.liquidity)
            regulatorReport.liquidity = { ...DEFAULT_ROW };

        if (!regulatorReport.marketRisk)
            regulatorReport.marketRisk = { ...DEFAULT_ROW };

        if (!regulatorReport.compositeRating)
            regulatorReport.compositeRating = { ...DEFAULT_ROW };

        this.setState({ report: regulatorReport });
    }


    /**
     * This method creates the static rating.
     * 
     * @param {*} heading The heading.
     * @param {*} type The type.
     * @param {*} stats The statistics.
     * @returns The rating control.
     */
    createStaticRating(heading, type, stats)
    {
        if (!stats)
            stats = [];

        return (
            <Row className={ "regulatory-row" }>
                <Col xs={ 4 } sm={ 4 } md={ 3 } lg={ 3 }>{ heading }</Col>
                <Col xs={ 2 } sm={ 2 } md={ 2 } lg={ 2 }>{ this.state.report[type].rating }</Col>
                <Col xs={ 6 } sm={ 6 } md={ 7 } lg={ 7 }>
                    { this.state.report[type].comments }
                    {
                        stats.map((stat, index) =>
                        {
                            return (
                                <div className="regulatory-stat-table" key={ "stats." + index } >
                                    <div className="regulatory-stat-cell"><b>{ stat.name }</b></div>
                                    <div className="regulatory-stat-cell"><b>{ stat.value }</b></div>
                                </div>
                            );
                        })
                    }
                    </Col>
            </Row>
        );
    }


    /**
     * This method creates the rating.
     * 
     * @param {*} heading The heading.
     * @param {*} type The type.
     * @returns The rating control.
     */
    createRating(heading, type)
    {
        return (
            <Row className={ "regulatory-row" }>
                <Col xs={ 4 } sm={ 4 } md={ 3 } lg={ 3 }>{ heading }</Col>
                <Col xs={ 2 } sm={ 2 } md={ 2 } lg={ 2 }><SelectControl name={ type } value={ this.state.report[type].rating } onChange={ (name, value) => this.updateRating(type, parseInt(value)) } items={ RATINGS } className="regulator-rating" /></Col>
                <Col xs={ 6 } sm={ 6 } md={ 7 } lg={ 7 }>
                    <textarea className="regulator" name={ type } rows={ 3 }  onChange={ (e) => this.updateComment(type, e.target.value) } value={ this.state.report[type].comments }></textarea>
                </Col>
            </Row>
        );
    }

    /**
     * This method updates the rating.
     * 
     * @param {*} type The type.
     * @param {*} rating The rating.
     */
    updateRating(type, rating)
    {
        // Clone the report
        let report = { ...this.state.report };

        // Update it...
        report[type].rating = rating;

        // Set the state
        this.setState({ rating: report });
    }


    /**
     * This method updates the comment.
     * 
     * @param {*} type The type.
     * @param {*} value The value.
     */
    updateComment(type, value)
    {
        // Clone the report
        let report = { ...this.state.report };

        // Update it...
        report[type].comments = value;

        // Update the state
        this.setState({ report: report });
    }


    /**
     * The save click event handler.
     */
    onClickSave = () =>
    {
        // Save the report
        gameService.saveRegulatorReport(this.props.gameId, this.props.teamId, this.props.turnNum, this.state.report, () => alert("Saved"));
    }


    /**
     * The render method.
     * 
     * @returns The rendered component.
     */
    render()
    {
        if (this.props.readOnly || (this.props.role === "Student") || (this.props.turnNum !== this.props.currentTurnNum))
            return this.renderReport();

        return this.renderForm();
    }


    /**
     * This method renders the report.
     * 
     * @returns The report.
     */
    renderReport()
    {
        let stats = this.props.stats;
        if (!stats)
            stats = {};


        return (
            <Container className={ this.props.className ? this.props.className : "" }>
                <Row>
                    <Col xs={ 4 } sm={ 4 } md={ 3 } lg={ 3 }><b>Component</b></Col>
                    <Col xs={ 2 } sm={ 2 } md={ 2 } lg={ 2 }><b>Rating</b></Col>
                    <Col xs={ 6 } sm={ 6 } md={ 7 } lg={ 7 }><b>Comments</b></Col>
                </Row>
                { this.createStaticRating("Capital", "capital", stats.capital) }
                { this.createStaticRating("Asset Quality", "assetQuality", stats.assetQuality) }
                { this.createStaticRating("Management", "management") }
                { this.createStaticRating("Earnings", "earnings", stats.earnings) }
                { this.createStaticRating("Liquidity", "liquidity", stats.liquidity) }
                { this.createStaticRating("Sensitivity to Market Risk", "marketRisk", stats.marketRisk) }
                { this.createStaticRating("Composite Rating", "compositeRating") }
            </Container>
        );
    }

    /**
     * This method renders the form.
     * 
     * @returns The rendered form.
     */
    renderForm()
    {
        return (
            <Container className={ this.props.className ? this.props.className : "" }>
                <Row>
                    <Col xs={ 4 } sm={ 4 } md={ 3 } lg={ 3 }><b>Component</b></Col>
                    <Col xs={ 2 } sm={ 2 } md={ 2 } lg={ 2 }><b>Rating</b></Col>
                    <Col xs={ 6 } sm={ 6 } md={ 7 } lg={ 7 }><b>Comments</b></Col>
                </Row>
                { this.createRating("Capital", "capital") }
                { this.createRating("Asset Quality", "assetQuality") }
                { this.createRating("Management", "management") }
                { this.createRating("Earnings", "earnings") }
                { this.createRating("Liquidity", "liquidity") }
                { this.createRating("Sensitivity to Market Risk", "marketRisk") }
                { this.createRating("Composite Rating", "compositeRating") }

                <Row>
                    <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 }>
                        <Button variant="primary" onClick={ this.onClickSave }>Save</Button>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default RegulatorReport;