import { Component } from 'react';
import { SelectCellFormatter } from 'react-data-grid';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';

import Currency from "../Currency";
import Percentage from "../Percentage";


/**
 * The number formatter.
 *
 * @param {*} props The properties.
 */
export const NumberFormatter = function(props)
{
    // Get the value...
    let value = props.row[props.column.key].toString();

    // Strip trailing zeros after decimal points...
    let periodIndex = value.indexOf(".");

    if (periodIndex >= 0)
    {
        // Remove all trailing spaces (and the period if need be...)
        for (let counter = value.length - 1; counter >= periodIndex; counter--)
        {
            // Get out if not a zero or period...
            if ((value[counter] !== "0") && (value[counter] !== "."))
                break;

            // Trim the string
            value = value.substring(0, value.length - 1);
        }
    }

    // Get out if nothing to do...
    if (value === "")
        value = 0;


    // Get the last digit
    let lastDigit = (periodIndex >= 0) ? periodIndex : value.length;

    // Add the commas...
    while (lastDigit > 3)
    {
        // Move the last digit
        lastDigit -= 3;

        // Create the value
        value = value.substring(0, lastDigit) + "," + value.substring(lastDigit);
    }

    // Handle additional properties...
    let additionalProps = {};

    // Handle read-only rows...
    if (props.column.editor && (!props.column.readOnlyProperty || !props.row[props.column.readOnlyProperty]))
        additionalProps.className = "editable-cell";
    else
        additionalProps.className = "read-only-cell";

    return <div { ...additionalProps }>{ value }</div>;
}


/**
 * The currency formatter.
 *
 * @param {*} props The properties.
 */
 export const currencyFormatter = function(props)
 {
     let additionalProps = {};
 
     // Handle read-only rows...
     //if (props.column.cellEditor && (!props.column.readOnlyProperty || !props.row[props.column.readOnlyProperty]))
     if (props.column.cellEditor && (!props.column.readOnlyProperty || !props.value.readOnlyProperty))
         additionalProps.className = "editable-cell";
     else
         additionalProps.className = "read-only-cell";
 
     return <div { ...additionalProps }><Currency value={props.value} /></div>;
 }

/**
 * The percentage formatter.
 */
 export const percentageFormatter = function(props) 
 {
     let additionalProps = {};
 
     // Handle read-only rows...
     if (props.column.cellEditor && (!props.column.readOnlyProperty || !props.value.readOnlyProperty))
         additionalProps.className = "editable-cell";
     else
         additionalProps.className = "read-only-cell";
 
     return <div { ...additionalProps }><Percentage value={props.value} /></div>;
 }


/**
 * The checkbox formatter class.
 */
export class CheckBoxFormatter extends Component
{
    /**
     * The on change event handler.
     *
     * @param {*} value The value.
     */
    onChange = (value) =>
    {
        if (this.props.row[this.props.column.key] === value)
            return;

        // Create the new row
        let newRow = { ...this.props.row };

        // Set the new value
        newRow[this.props.column.key] = value;
        
        // Change the row
        this.props.onRowChange(newRow, true);
    }


    /**
     * This method renders the component.
     *
     * @returns The JSX.
     */
    render()
    {
        return <SelectCellFormatter onChange={ this.onChange } value={ this.props.row[this.props.column.key] } />;
    }
}


/**
 * The disabled checkbox formatter.
 *
 * @param {*} props The properties.
 * @returns The disabled checkbox formatter.
 */
 export const DisabledCheckBoxFormatter = function(props)
 {
     return <SelectCellFormatter onChange={ () => {} } disabled={ true }  value={ props.row[props.column.key] } />;
 }
 

/**
 * The MultiSelect formatter class.
 */
 export class MultiSelectFormatter extends Component
 {
    /**
    * The on change event handler.
    *
    * @param {*} value The value.
    */
    onChange = (value) =>
    {
        if (this.props.row[this.props.column.key] === value)
            return;
  
        // Create the new row
        let newRow = { ...this.props.row };
  
        // Set the new value
        newRow[this.props.column.key] = value.map(role => role.value).join(",");
          
        // Change the row
        this.props.onRowChange(newRow, true);
    }


    /**
     * This function finds the value.
     * 
     * @param {*} values The values.
     * @return The value/label pair.
     */
    findValue(values)
    {
        // Get out if nothing to do...
        if (!values)
            return [];

        // Initialize the map
        let itemMap = {};

        // Populate the map
        this.props.options.forEach(item => itemMap[item.value] = item);

        // Get the array of values
        values = values.split(",");

        // Initialize the result
        let result = [];

        // For each item
        for (let value of values)
        {
            // Get it from the map
            let item = itemMap[value];

            // If not found, skip...
            if (!item)
                continue;

            // Add it to the list
            result.push(item);
        }

        // Get out...
        return result;
    }

      
    /**
     * This method renders the component.
     *
     * @returns The JSX.
    */
    render()
    {
        // Get the selected value
        let selectedValue = this.findValue(this.props.row[this.props.column.key]);

        const customStyles = {
            control: styles => ({ ...styles,                 
            width: '700px',
            right: '10px'
            }),
            option: styles => ({ ...styles,    
         
            }),
            menu: styles => ({ ...styles,                 

            })                 

        };
        return <Select 
            options={this.props.options} 
            isMulti={true}
            onChange={ this.onChange } 
            menuPortalTarget={ document.body }  
            value={ selectedValue }
            styles={customStyles}
        />;
    }
}


/**
 * The date formatter.
 * 
 * @returns The date formatter.
 */
export const dateFormatter = function(props)
{
    const options = { year: "numeric", month: "2-digit", day: "2-digit" }
    return new Date(props.value).toLocaleDateString(undefined, options); 
}

/**
 * The date time formatter.
 * 
 * @returns The date time formatter.
 */
export const dateTimeFormatter = function(props)
{
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };

    // Create the date
    let date = new Date(props.value);

    // Format the date & time
    return date.toLocaleDateString(undefined, options) + " " + date.toLocaleTimeString(); 
}

/**
 * The boolean formatter.
 * 
 * @returns The boolean formatter.
 */
export const booleanFormatter = function(props)
{
    return props.value ? "Y" : "N";
}

/**
 * The bold formatter.
 *
 * @param {*} Formatter The formatter.
 * @returns The bolded formatter.
 */
export const boldFormatter = (Formatter) => (props) =>
{
    return <bold><Formatter { ...props } /></bold>;
}


/**
 * The button formatter class.
 */
export class ButtonFormatter extends Component
{
    /**
     * The on change event handler.
     *
     * @param {*} value The value.
     */
    onChange = () =>
    {
        // Create the new row
        let newRow = { ...this.props.row };

        // Set the new value
        newRow[this.props.column.key] = "click";
        
        // Change the row
        this.props.onRowChange(newRow, true);
    }


    /**
     * This method renders the component.
     *
     * @returns The JSX.
     */
    render()
    {
        return <Button className="grid-button" onClick={ () => this.onChange() }  disabled={ this.props.disabled }>{ this.props.row[this.props.column.key] }</Button>;
    }
}


/**
 * The disabled checkbox formatter.
 *
 * @returns The disabled checkbox formatter.
 */
export const DisabledButtonFormatter = function(props)
{
    return <ButtonFormatter disabled={ true } { ...props } />;
}


/**
 * The cell expander formatter class.
 */
export class CellExpanderFormatter extends Component
{
    /**
     * The render method.
     *
     * @returns The rendered component.
     */
    render()
    {
        return (
            <div className="d">
                <span onClick={this.props.onCellExpand } onKeyDown={() => {}}>
                    <span>
                        {this.props.expanded ? '\u25BC' : '\u25B6'}
                    </span>
                </span>
            </div>
        );
    }
}