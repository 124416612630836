import { withRouter } from "react-router";
import { connect } from 'react-redux';

import BaseView from "../BaseView";
import FormControl from "../../controls/form/FormControl";
import InputControl from "../../controls/form/InputControl";
import PasswordControl from "../../controls/form/PasswordControl";
import ajax from "../../utils/Ajax";
import { store } from "../../store";
import { login } from "../../../features/authentication/AuthenticationSlice";
import watchService from "../../services/WatchService";


/**
 * This method maps the state to the properties.
 * 
 * @param {*} state The state.
 * @param {*} ownProps The own properties.
 * @returns The mapping.
 */
const mapStateToProps = (state, ownProps) =>
{
    let result = 
    {
        returnRoute: state.redirect.returnRoute
    };
 
    return result;
}
 

/**
 * The login view class.
 */
class LoginView extends BaseView
{
    /**
     * The submit event handler.
     * 
     * @param {*} event The event.
     */
    onSubmit = (event) =>
    {
        // Make the ajax call...
        ajax.post("user/login", this.state, this.onLoginComplete, "Logging In", "An error occurred during the login process.");
    }

    /**
     * The login complete event handler.
     * 
     * @param {*} response The response.
     */
    onLoginComplete = (response) =>
    {
        // Perform the login...
        store.dispatch(login({ userId: response.userId, role: response.role, fullName: response.fullName, email: this.state.email }));

        // Handle the return route if appropriate
        if (this.props.returnRoute)
            this.props.history.replace(this.props.returnRoute);
        else
            this.props.history.replace("/");


        // Kick off a watch...
        watchService.watch();
    }

    /**
     * This method renders the component.
     */
    render()
    {
        return (
            <FormControl name="Sign In" className="sign-in-form">
                <div className="row">
                    <div className="offset-sm-1 offset-md-1 offset-lg-1 col-sm-3 col-md-2 col-lg-2"><label for="email" className="form-label">Email</label></div>
                    <div className="col-sm-7 col-md-8 col-lg-8"><InputControl name="email" onChange={ this.onFieldChange } /></div>
                </div>

                <div className="row">
                    <div className="offset-sm-1 offset-md-1 offset-lg-1 col-sm-3 col-md-2 col-lg-2"><label for="password" className="form-label">Password</label></div>
                    <div className="col-sm-7 col-md-8 col-lg-8"><PasswordControl name="password" onChange={ this.onFieldChange } /></div>
                </div>

                <div className="row">
                    <div className="offset-sm-4 offset-md-3 offset-lg-3 col-sm-3 col-md-2 col-lg-2"><button className="btn btn-primary" onClick={ (event) => this.onSubmit(event) } >Login</button></div>
                </div>
            </FormControl>
        );
    }
}

export default connect(mapStateToProps)(withRouter(LoginView));