import { Component } from "react";
import { withRouter } from 'react-router';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import DecisionBody from "./DecisionBody";
import ScenarioName from "./components/ScenarioName";


/**
 * The general administration template.
 */
class GeneralAdminTemplate extends Component
{
    /**
     * This method handles tab selection.
     *
     * @param {*} key The key.
     */
    onSelectTab = (key) =>
    {
        // Get the last slash
        let lastSlash = this.props.location.pathname.lastIndexOf("/");

        // Get the base path
        let basePath = this.props.location.pathname.substring(0, lastSlash + 1);

        // Navigate to the appropriate view
        this.props.history.push(basePath + key);
    }


    /**
     * The render method.
     * 
     * @returns The rendered object.
     */
    render()
    {
        // Initialize the end
        let end = this.props.location.pathname.length;

        // Get the last slash
        let lastSlash = this.props.location.pathname.lastIndexOf("/");

        // Get the active tab
        let activeTab = this.props.location.pathname.substring(lastSlash + 1, end);

        return (
                <DecisionBody name="Dividends & Housekeeping" noteType="Admn" game={ this.props.game } intraturnId={ this.props.intraturnId } onSave={ this.props.onSave } dirty={ this.props.dirty }>
                    <div className="general-admin-scenario-name" >
                        <ScenarioName intraturnId={ this.props.intraturnId } />
                    </div>

                    <Tabs activeKey={ activeTab } onSelect={ this.onSelectTab }>
                        <Tab eventKey="dividends" title="Dividends">
                            {activeTab === "dividends" ? this.props.children : "1" }
                        </Tab>
                        <Tab eventKey="housekeeping" title="Housekeeping">
                            {activeTab === "housekeeping" ? this.props.children : "2" }
                        </Tab>
                        <Tab eventKey="measures-of-success" title="Measures of Success">
                            {activeTab === "measures-of-success" ? this.props.children : "3" }
                        </Tab>
                    </Tabs>
                </DecisionBody>
            );
    }
}

// Export the template
export default withRouter(GeneralAdminTemplate);