import { Fragment } from 'react';
import { withRouter, Prompt } from "react-router";
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import BaseDecisionGridView from "./BaseDecisionGridView";
import COLUMNS from "./grid/InvestmentSalesColumns";
import TurnSelect from "./components/TurnSelect";
import DecisionGrid from "./components/DecisionGrid";
import ScenarioName from "./components/ScenarioName";
import PercentageInput from "../../../controls/PercentageInput";

import DecisionBody from "./DecisionBody";
import gameService from "../../../services/GameService";
import intraturnService from "../../../services/IntraturnService";
import SelectControl from '../../../controls/form/SelectControl';
import { parsePercentage } from "../../../controls/Percentage";
import Utils from '../../../utils/Utils';

/**
 * The InvestmentSalesView class.
 */
class InvestmentSalesView extends BaseDecisionGridView
{
    /**
     * Object Constructor.
     *
     * @param {*} props The properties.
     */
    constructor(props)
    {
        // Call mom...
        super(props);

        // Initialize the loaded flag
        this.loaded = false;

        // Initialize the state
        this.state =
        {
            rows: [],
            dirtyRows: {},
            sortColumns: [],
            selectedInvestmentCategory: "All",
            selectedTurn: -1,
            fillPercent: "0.00%"
        };

        // Initialize the columns
        this.columns = COLUMNS;
    }


    /**
     * This method loads the investment sales.
     *
     * @param turn The turn.
     * @param investmentCategory The investment category type.
     */
    loadInvestmentSales(turn, investmentCategory)
    {
        // Get out if the state is loaded
        if (!this.props.game)
            return;

        // Only load once...
        if (this.loadingInvestmentSales)
            return;

        // Get out if nothing to do...
        if ((this.state.selectedTurn === turn) && (this.state.selectedInvestmentCategory === investmentCategory))
            return;

        // Warn the user if there are changes...
        if (this.isDirty())
        {
            if (!window.confirm("You have unsaved changes.  Do you wish to proceed?"))
                return;
        }

        // Mark us as loading...
        this.loadingInvestmentSales = true;

        // Load the investment sales
        gameService.loadInvestmentSales(this.props.match.params.team_id, turn, investmentCategory, this.onInvestmentSalesLoaded);
    }


    /**
     * The investment sales loaded event handler.
     *
     * @param {*} investmentSales The investment sales.
     * @param {*} lastUpdated The last updated time.
     * @param {*} turn The turn.
     * @param {*} investmentCategory The investment category type.
     */
    onInvestmentSalesLoaded = (investmentSales, lastUpdated, turn, investmentCategory) =>
    {
        // Make sure we have something...
        if (!investmentSales)
            investmentSales = [];

        // Calculate the sale gain or loss
        for (let investmentSale of investmentSales)
        {
            // Parse the sales pecent
            let salePercent = parseFloat(investmentSale.sale_percent);

            if (salePercent === 0)
                investmentSale.sale_gain_or_loss = 0;
            else
                investmentSale.sale_gain_or_loss = parseFloat(investmentSale.gain_or_loss) * parseFloat(investmentSale.sale_percent) / 100.0;

            // Set the checkbox
            if (salePercent === 100)
                investmentSale.sell_100_percent = true;
            else
                investmentSale.sell_100_percent = false;
        }

        // Initialize the state
        let state =
        { 
            rows: investmentSales, 
            pinnedBottomRowData: this.calculateSummary(investmentSales),
            selectedTurn: turn, 
            selectedInvestmentCategory: investmentCategory,
            lastUpdated: lastUpdated
        }

        // Set the intraturn Id if appropriate
        if (investmentSales.length > 0)
            state.intraturnId = investmentSales[0].intraturn_id;

        // Set the state
        this.setState(state);

        // Clear the dirty rows
        this.clearDirtyRows();

        // Reset the loading investment sales flag
        this.loadingInvestmentSales = false;

        // Set the loaded flag
        this.loaded = true;
    }


    /**
     * This method determines if the component is ready.
     *
     * @returns true if the component is ready, else false.
     */
    isComponentReady()
    {
        // Call mom...
        let result = super.isComponentReady();

        // Load the investment sales if necessary...
        if (this.props.game && !this.loaded)
        {
            // Load the investment sales
            this.loadInvestmentSales(this.props.game.turns_completed + 1, this.state.selectedInvestmentCategory);

            // Not ready...
            return false;
        }

        // Get out...
        return result;
    }


    /**
     * This method updates the state when the cell value has been modified.
     *
     * @param {*} params The params.
     */
    onCellValueChanged = (params) =>
    {
        let markDirty;

        // Get the dirty rows map...
        let dirtyRows = this.state.dirtyRows;

        // Update the sale percent if the checkbox was clicked, or the sale percent was reduced below 100%
        if (params.column.colId === "sell_100_percent")
        {
            // Determine if a value is actually changing...
            if (params.data.sell_100_percent && (parseFloat(params.data.sale_percent) !== 100))
            {
                // Update the sale percentage...
                params.data.sale_percent = "100.0";

                // Mark it dirty...
                markDirty = true;
            }
            else
            {
                // No change...
                markDirty = false;
            }
        }
        else 
        if(params.column.colId === "sale_percent") 
        {
            if (parseInt((params.data.sale_percent)) !== 100)
                params.data.sell_100_percent = false;
            else
                params.data.sell_100_percent = true;
                
            // Set the mark dirty flag
            markDirty = true;
        }
        
        // Mark the row as dirty
        if (markDirty)
            dirtyRows[params.data.invest_sale_id] = true;

        // Adjust the sale amount & sale gain or loss...
        params.data.sale_amount = parseFloat(params.data.sale_percent) * params.data.amount / 100;
        params.data.sale_gain_or_loss = parseFloat(params.data.sale_percent) * params.data.gain_or_loss / 100;
    
        // Update Summary
        let summary = this.calculateSummary(this.state.rows);

        // Save the state
        this.setState(
        {
            dirtyRows: dirtyRows,
            summary: summary
        });

        // Displays should most recent up to date values.
        params.api.refreshCells();
    }

        
    /**
     * This method handles view saves.
     */
    onSave = () =>
    {
        // Get out if this is not actually dirty (shouldn't happen)
        if (!this.isDirty())
            return;

        // Handle the save...
        this.updateDirtyRows(false);
    }


    /**
     * This method updates the dirty rows.
     *
     * @param force The force flag.
     * @return true if there are dirty rows, else false...
     */
    updateDirtyRows(force)
    {
        // Get out if nothing to do...
        if (Object.keys(this.state.dirtyRows).length === 0)
            return false;

        // Initialize the updated rows
        let updatedRows = [];

        // Check each row...
        for (let row of this.state.rows)
        {
            // See if it is dirty...
            if (!this.state.dirtyRows[row.invest_sale_id])
                continue;

            // Convert the Sale Percent
            row.sale_percent = parsePercentage(row.sale_percent).toFixed(3);

            // Push the updated row
            updatedRows.push(row);
        }

        // Update the investment sales
        intraturnService.updateInvestmentSales(this.state.intraturnId, updatedRows, this.state.lastUpdated, force, this.onSaveComplete);

        // Get out...
        return true;
    }


    /**
     * The save complete event handler.
     *
     * @param {*} modifiedBy The modified by user.
     * @param {*} lastUpdated The last updated time.
     */
    onSaveComplete = ({ modifiedBy, lastUpdated }) =>
    {
        // See if it was modified since we loaded the data...
        if (modifiedBy)
        {
            // See if the user wants to force the matter...
            if (!window.confirm("Investment Sales were modified by " + modifiedBy + " at " + new Date(lastUpdated).toLocaleTimeString() + ".\n\nWould you like save your changes anyway?"))
                return;

            // Force the update
            this.updateDirtyRows(true);

            // Get out...
            return;
        }

        // Set the last updated time
        this.setState({ lastUpdated: lastUpdated });

        // Clear the dirty flag
        this.clearDirtyRows();

        // let the user know
        alert("Saved.");
    }


    /**
     * This method clears the dirty rows.
     */
    clearDirtyRows()
    {
        // Reset the dirty IDs
        this.setState(
        {
            dirtyRows: {}
        });
    }


    /**
     * This method handles turn changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onTurnChange = (name, value) =>
    {
        // Load the investment sales
        this.loadInvestmentSales(parseInt(value), this.state.selectedInvestmentCategory);
    }


    /**
     * This method handles investment category changes.
     *
     * @param {*} name The name.
     * @param {*} value The value.
     */
    onInvestmentCategoryChange = (name, value) =>
    {
        // Load the investment categories
        this.loadInvestmentSales(this.state.selectedTurn, value);
    }


    /**
     * This method handles fill percentage changes.
     *
     * @param {*} event The event.
     */
    onChangeFillPercent = (event) =>
    {
        // save the value
        this.state.fillPercent = event.target.value;
    }


    /**
     * This method fills the sales percentage.
     */
    fillSalePercent()
    {
        // Get the percentage
        let percent = this.state.fillPercent;

        // Get the % index...
        let index = percent.indexOf("%");
        if (index !== -1)
            percent = percent.substring(0, index);

        // Parse the percentage
        percent = parseFloat(percent);

        // Get it as text...
        let percentText = percent.toFixed(3);

        // Initialize the sell all flag
        let sellAll = (percent === 100) ? true : false;

        // Create the new rows
        let newRows = [ ...this.state.rows ];

        // Create the dirty rows
        let dirtyRows = {};

        // Update the rows
        for (let row of newRows)
        {
            // Skip if there is nothing to do...
            if (row.sale_percent === percentText)
                continue;

            // Mark the row as dirty
            dirtyRows[row.invest_sale_id] = true;

            // Set the percentage
            row.sale_percent = percentText;
            row.sell_100_percent = sellAll;

            // Adjust the sale amount & sale gain or loss...
            row.sale_amount = percent * row.amount / 100;
            row.sale_gain_or_loss = percent * parseFloat(row.gain_or_loss) / 100;
        }

        // Recalculate the summary
        let summary = this.calculateSummary(newRows);

        // Set the state
        this.setState({ rows: newRows, summary: summary, dirtyRows: dirtyRows });

        // Displays should most recent up to date values.
        this.api.refreshCells();
    }


    /**
     * This method calculates the summary.
     *
     * @param {*} rows The rows.
     * @returns The summary row.
     */
    calculateSummary(rows)
    {
        // Initialize the summary
        let summary =
        {
            invest_account: 'Total',
            amount: 0,
            gain_or_loss: 0,
            sale_percent: 0,
            sale_amount: 0,
            sale_gain_or_loss: 0
        }

        // Calculate the summary...
        for (let row of rows)
        {
            summary.amount            += Utils.parseCurrency(row.amount);
            summary.gain_or_loss      += Utils.parseCurrency(row.gain_or_loss);
            summary.sale_percent      += parsePercentage(row.sale_percent);
            summary.sale_amount       += Utils.parseCurrency(row.sale_amount);
            summary.sale_gain_or_loss += Utils.parseCurrency(row.sale_gain_or_loss);
        }

        // Adjust for the number of rows...
        summary.sale_percent /= rows.length;

        // Get out...
        return summary;
    }


    /**
     * This method renders the view.
     */
    renderGridView()
    {
        // Initialize the editable flag
        let editable = !this.props.team.frozen && (this.state.selectedTurn === this.props.game.turns_completed + 1) ? true : false;
        
        // Initialize the investment category types
        let investmentCategoryTypes = [ { key: "All", value: "All" }, ...this.props.gameDetails.invest_categories.map(type => ( { key: type, value: type })) ];

        return (
            <Fragment>
                <Prompt when={ this.isDirty() } message="There are unsaved changes on this screen.  Are you sure you want to leave?" />

                <DecisionBody name="Investment Sales" noteType="Invs" game={ this.props.game } intraturnId={ this.state.intraturnId } onSave={ this.onSave } dirty={ this.isDirty() }>

                    <Row>
                        <Col xs={6} sm={6} md={4} lg={4}>
                            <TurnSelect name="turns" onChange={ this.onTurnChange } value= { this.state.selectedTurn } />
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4}>
                            <SelectControl items={ investmentCategoryTypes } name="investmentCategoryTypes"  onChange={ this.onInvestmentCategoryChange } value= { this.state.selectedInvestmentCategory } />
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="right col-padding-right bold col-middle-align">
                            <ScenarioName intraturnId={ this.state.intraturnId } />
                        </Col>
                    </Row>

                    <div class="grid-body">
                        <DecisionGrid 
                                columns={ this.columns } 
                                turn={ this.state.selectedTurn }
                                sortColumns={ this.state.sortColumns }
                                rowData={ this.state.rows } 
                                onCellValueChanged={ this.onCellValueChanged } 
                                onSortColumnsChange={ this.onSortColumnsChange }
                                pinnedBottomRowData={[ this.state.summary ]}
                                onGridReady = { (params) => { this.api = params.api }}
                        />
                    </div>
                    <Row>
                        <Col xs={12} sm={12} md={ { span: 7, offset: 5 } } lg={ { span: 5, offset: 7 } } className="vertical-spacing right">
                            <PercentageInput value={ this.state.fillPercent } onChange={ this.onChangeFillPercent } min={0} style={{ width: "90px", display: "inline" }} className="auto-input form-control" disabled={ !editable } />
                            <Button variant="primary" onClick={ () => this.fillSalePercent() } disabled={ !editable }>Fill&nbsp;Sale&nbsp;Percent&nbsp;</Button>
                        </Col>
                    </Row>
                </DecisionBody>
            </Fragment>
        );
    }
}

// Export the decisions view...
export default withRouter(connect(BaseDecisionGridView.mapStateToProps)(InvestmentSalesView));