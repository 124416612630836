import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import DownloadTurnReportsLink from '../decisions/components/DownloadTurnReportsLink';
import DownloadTeamReportsLink from '../decisions/components/DownloadTeamReportsLink';
import FreezeTeamLink from '../components/FreezeTeamLink';
import WhatIfLink from '../components/WhatIfLink';


/**
 * The teams columns.
 */
export const COLUMNS = 
[
    {
        field: "team_num",
        headerName: "Team Number",
        minWidth: 130,
        headerClass: "panel-header",
        cellClass: 'number-cell',
        cellRenderer: (props) => { return <div style={{textAlign: "right"}}>{ props.value } </div> }
    },

    {
        field: "team_name",
        headerName: "Team Name",
        headerClass: "panel-header",
        minWidth: 300,
        cellRenderer: (props) => 
        { 
            return <div style={{textAlign: "left"}}><Link to= {"/game/" + props.data.game_id + "/team/" + props.data.team_id}>{props.data.team_name}</Link></div>
        }
    },

    {
        field: "decision_in",
        headerName: "Decision In",
        headerClass: "panel-header",
        minWidth: 130,
        cellRenderer: (props) => 
        { 
            return (
                <div style={{textAlign: "center"}}> 
                {
                    props.data.decision_in ? <FontAwesomeIcon color={ "green" } icon={ faCheckCircle } /> : ''
                }  
                </div>
            ) 
        }     
    },

    {
        field: "whatif_count",
        headerName: "Forecasts",
        minWidth: 130,
        headerClass: "panel-header"
    },

    {
        field:"disable_whatifs",
        headerName: "Forecasts Enabled",
        headerClass: "panel-header",
        minWidth: 150,
        cellRenderer: (props) => { 
            return ( 
                <div style={{textAlign: "center"}}> 
                {
                    <WhatIfLink gameId={ props.data.game_id } teamId={ props.data.team_id } disable_whatifs={ props.data.disable_whatifs } />
                }
                </div> 
            )
        }
    },
    
    {
        field: "Freeze",
        headerName: "Freeze",
        headerClass: "panel-header",
        minWidth: 130,
        cellRenderer: (props) => 
            <span class = "fa-buttons">
                <FreezeTeamLink gameId={ props.data.game_id } teamId={ props.data.team_id } frozen={ props.data.frozen }/>
            </span>
    },

    {
        field: "Reports",
        headerName: "Reports",
        headerClass: "panel-header",
        minWidth: 130,
        cellRenderer: (props) => {
            return (
                <span>
                    <DownloadTurnReportsLink gameId={ props.data.game_id } teamId={ props.data.team_id } turnNum={ props.data.turns_completed } />                    
                    &nbsp; &nbsp; &nbsp; 
                    <DownloadTeamReportsLink teamId={ props.data.team_id } />
                </span> 
            )
        }
    }
];




/**
 * The student teams columns.
 */
export const STUDENT_COLUMNS = 
[
    {
        field: "team_num",
        headerName: "Team Number",
        headerClass: "panel-header",
        minWidth: 130,
        cellRenderer: (props) => { return <div style={{textAlign: "right"}}>{props.data.team_num}</div> }
    },

    {
        field: "team_name",
        headerName: "Team Name",
        headerClass: "panel-header",
        minWidth: 300,
        cellRenderer: (props) => {
            return (
                <div style={{textAlign: "left"}}>
                    <Link to= {"/game/" + props.data.game_id + "/team/" + props.data.team_id}>{props.data.team_name}</Link>
                </div>
            )
        }
    },

    {
        field: "decision_in",
        headerName: "Decision In",
        headerClass: "panel-header",
        minWidth: 130,
        cellRenderer: (props) => { 
            return (
                <div style={{textAlign: "center"}}> 
                    {props.data.decision_in ? <FontAwesomeIcon color={ "green" } icon={ faCheckCircle } /> : ''} 
                </div>
            )
        }
    },

    {
        field: "whatif_count",
        headerName: "Forecasts",
        minWidth: 130,
        headerClass: "panel-header"
    },

    {
        headerName: "Reports",
        headerClass: "panel-header",
        minWidth: 130,
        cellRenderer: (props) => {
            return (
                <span>
                    <DownloadTurnReportsLink gameId={ props.data.game_id } teamId={ props.data.team_id } turnNum={ props.data.turns_completed } />                    
                    &nbsp; &nbsp; &nbsp; 
                    <DownloadTeamReportsLink teamId={ props.data.team_id } />
                </span> 
            )
        }
    }
];